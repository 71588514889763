import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core'
import { FolderDownload } from '@app/models'

@Component({
	selector: 'dam-download-dialog-item',
	templateUrl: './download-dialog-item.component.html',
	styleUrls: ['./download-dialog-item.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadDialogItemComponent implements OnInit {
	@Input()
	name: string

	@Input()
	description: string

	@Input()
	path: string

	@Input()
	url: string

	@Input()
	completed: boolean

	@Input()
	statistics: string

	@Input()
	notifyMe: boolean

	@Output()
	onClear: EventEmitter<FolderDownload> = new EventEmitter()

	@Output()
	onNotifyMe: EventEmitter<boolean> = new EventEmitter()

	constructor() {}

	ngOnInit() {}

	notifyMeChanged() {
		this.onNotifyMe.emit(this.notifyMe)
	}
}
