<header class="modal-header">
  <h2 class="modal-title">
    Delete
  </h2>
  <button class="modal-header-btn modal-close" type="button" (click)="onCancel.emit()">
    <img src="../../../assets/images/icons/close.svg" alt="Close" title="Close">
  </button>
</header>
<div class="modal-content">
  <form class="new-project-form form" (ngSubmit)="onSubmit.emit(name)" #frm="ngForm" novalidate>
    <div class="form-group">
      <h3 *ngIf="type == 'folder'">Are you sure you want to delete '{{name}}' and its contents?</h3>
      <h3 *ngIf="type == 'file'">Are you sure you want to delete '{{name}}'?</h3>
      <h3 *ngIf="type == 'multiple'">Are you sure you want to delete {{name}} assets?</h3>
    </div>
    <div class="form-actions modal-footer">
      <button class="form-btn modal-close" mat-button type="button" (click)="onCancel.emit()">CANCEL</button>
      <button class="form-btn" mat-button type="submit" [disabled]="!frm.form.valid">OK</button>
    </div>
  </form>
</div>
