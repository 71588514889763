import { environment } from '../../environments/environment'
import { SearchFileResult, DamFile, SearchFolderResult, Folder } from '@app/models'

export type RepositoryTypes = 'projects' | 'guidelines' | 'library'

export const mapRepositoryNameToId = (repositoryName: RepositoryTypes): string => {
	switch (repositoryName) {
		case 'library':
			return environment.libraryRepositoryId
		case 'guidelines':
			return environment.guideRepositoryId
		case 'projects':
		default:
			return environment.projectRepositoryId
	}
}

export const mapRepositoryIdToName = (repositoryId: string): RepositoryTypes => {
	switch (repositoryId) {
		case environment.guideRepositoryId:
			return 'guidelines'
		case environment.libraryRepositoryId:
			return 'library'
		case environment.projectRepositoryId:
		default:
			return 'projects'
	}
}

export const mapSortField = (sortId: string): string => {
	switch (sortId) {
		case 'name':
			return 'Name'
		case 'createdOn':
			return 'Created on'
		case 'createdBy':
			return 'Created by'
	}
}

export const mapSearchFileResultToFile = (searchResult: Array<SearchFileResult>): Array<DamFile> => {
	return searchResult.map(t => {
		return {
			name: t.name,
			file_id: t.file_id,
			folder_path: t.folder_path,
			createdBy: t.createdBy,
			createdOn: t.createdOn,
			modifiedBy: t.modifiedBy,
			modifiedOn: t.modifiedOn,
			size: t.size,
			status: t.status,
			property: t.property,
			folder_id: t.folder_id,
			auditHistory: t.auditHistory,
		} as DamFile
	})
}

export const mapSearchFoldersResultToFolder = (searchResult: Array<SearchFolderResult>): Array<Folder> => {
	return searchResult
		.filter(t => t.property && t.property.length)
		.map(t => {
			return {
				name: t.name,
				folder_path: t.folder_path,
				createdBy: t.createdBy,
				createdOn: t.createdOn,
				modifiedBy: t.modifiedBy,
				modifiedOn: t.modifiedOn,
				size: t.size,
				status: t.status,
				folder_id: t.folder_id,
				repo_id: t.repo_id,
				description: t.description,
				user_permission: t.user_permission,
				isFavorite: false,
				isBusy: false,
				property: t.property,
			} as Folder
		})
}

export const addMetadataToSearchFoldersResult = (searchResult: Array<Folder>, projects: Array<Folder>): Array<Folder> => {
	return searchResult.reduce((filtered, t) => {
		let projectName = getFolderPath(t)
		let project = projects.find(x => x.name == projectName)
		if (project)
			filtered.push({
				...t,
				property: project.property || [],
			} as Folder)
		return filtered
	}, [])
}

export const getMetadataBase = (metadata, key) => {
	if (!key || !metadata) return ''
	const m = metadata.find(x => x.name.toLowerCase() == key.toLowerCase())
	return m ? m.value : ''
}

export const getFolderPath = (folder: Partial<Folder>) => {
	let path = folder.folder_path.split('>')
	return path.length == 1 ? folder.name : path[1].trim()
}

export const mapSortFromState = layoutStore => {
	let sortDirection: string = layoutStore.sortDirection ? '-1' : '1'
	let sortField: string
	switch (layoutStore.sortField) {
		case 'name': {
			sortField = 'name'
			break
		}
		case 'property-Deadline': {
			sortField = 'deadLine'
			break
		}
		case 'property-Reference number': {
			sortField = 'referenceNumber'
			break
		}
		case 'property-Status': {
			sortField = 'projStatus'
			break
		}
	}

	return { sortField, sortDirection }
}

export const generateId = function() {
	return (
		'_' +
		Math.random()
			.toString(36)
			.substr(2, 9)
	)
}
