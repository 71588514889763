import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { Action, Store, select } from '@ngrx/store'
import { Observable, of as observableOf, from, of } from 'rxjs'
import { catchError, map, mergeMap, withLatestFrom, switchMap, debounceTime, delay, exhaustMap, tap } from 'rxjs/operators'
import { FileService, FolderService } from '../../services'
import { HttpEventType } from '@angular/common/http'
import { RootStoreState } from '..'
import { FolderStoreSelectors } from '../folder-store'
import { environment } from 'src/environments/environment'
import { LayoutStoreActions } from '../layout-store'
import { TabBottomSidenav } from 'src/app/models/sidenav.model'
import * as folderActions from '../folder-store/actions/folder.actions'
import * as folderSelectors from '../folder-store/selectors'
import * as featureActions from './actions'
import { FileUploadStep1, FileUpload, FileUploadStep3, FileReplaceStep1 } from '@app/models'
import * as featureSelectors from './selectors'
import * as uploadSelectors from '@app/root-store/upload-store/selectors'
import { generateId } from '@app/common/helpers'

@Injectable()
export class UploadStoreEffects {
	constructor(
		private fileService: FileService,
		private actions$: Actions,
		private store$: Store<RootStoreState.State>,
		private folderService: FolderService,
	) {}

	@Effect()
	verifyUploadRequestEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.VerifyUploadRequestAction>(featureActions.ActionTypes.VERIFY_UPLOAD_REQUEST),
		mergeMap(
			(action): Observable<Action> => {
				const uploadFiles = action.payload.data
				const uploadFolders = action.payload.data.reduce((acc, val) => {
					let folder = val.folder_tree.find(ft => +ft.folder_level === val.folder_upload_level)

					if (folder && !~acc.indexOf(folder.folder_name)) {
						acc.push(folder.folder_name)
					}
					return acc
				}, [])

				// Validate if files or folders exist in the destination folder
				return this.folderService.getFolder(action.payload.folder.repo_id, action.payload.folder.folder_id).pipe(
					map(folderWrapper => {
						if (folderWrapper.folders) {
							// const uploadFoldersName = uploadFolders.map(t => Object.keys(t)[0])
							let duplicatedFolders = folderWrapper.folders.filter(x => {
								return uploadFolders.some(t => t === x.name)
							})

							if (duplicatedFolders.length) {
								const payload = {
									...action.payload,
									folders: folderWrapper.folders,
									files: folderWrapper.files,
									duplicated: duplicatedFolders,
								}
								return new featureActions.DuplicateFolderRequestAction(payload)
							}
						}

						if (folderWrapper.files) {
							let duplicatedFiles = folderWrapper.files.filter(x => {
								const uploadFilesNames = uploadFiles.filter(u => u.location === 'root').map(u => u.data.name)
								return uploadFilesNames.some(t => encodeURI(t) === encodeURI(x.name))
							})

							if (duplicatedFiles.length) {
								const payload = { ...action.payload, files: folderWrapper.files, duplicated: duplicatedFiles }
								return new featureActions.DuplicateFileRequestAction(payload)
							}
						}

						return new featureActions.EnqueueAction(action.payload.data)
					}),
				)
			},
		),
	)

	@Effect()
	openFileUploadEffect$: Observable<Action> = this.actions$.pipe(
		ofType(featureActions.ActionTypes.ENQUEUE),
		map(t => new LayoutStoreActions.SetBottomSidenav({ visible: true })),
	)

	@Effect()
	enqueueEffect$: Observable<Action> = this.actions$.pipe(
		ofType(featureActions.ActionTypes.ENQUEUE, featureActions.ActionTypes.UPLOAD_3_SUCCESS),
		map(t => new featureActions.ProcessQueueAction()),
	)

	@Effect()
	endQueueEffect$: Observable<Action> = this.actions$.pipe(
		ofType(featureActions.ActionTypes.PROCESS_QUEUE_END),
		withLatestFrom(this.store$.pipe(select(folderSelectors.selectCurrentFolder))),
		withLatestFrom(this.store$.pipe(select(uploadSelectors.selectAllFiles))),
		delay(2500),
		switchMap(([[action, currentFolder], fileManager]) => {
			const filesInCurrentFolder = fileManager.filter(f => f.folder_id === currentFolder.folder_id && !f.refreshed)

			if (filesInCurrentFolder.length) {
				return this.folderService
					.getFolder(currentFolder.repo_id, currentFolder.folder_id)
					.pipe(
						mergeMap(folder => [
							new featureActions.UpdateRefreshEntityState(),
							new folderActions.ReloadSuccessAction({ rootFolder: folder }),
							new folderActions.LoadFileMetadataRequestAction({ repositoryId: currentFolder.repo_id, folderId: currentFolder.folder_id }),
						]),
					)
			}

			return [{ type: '[Queue] Process queue end NoopAction' }]
		}),
	)

	@Effect()
	processUploadEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.ProcessQueueAction>(featureActions.ActionTypes.PROCESS_QUEUE),
		withLatestFrom(this.store$.pipe(select(featureSelectors.selectMaxConcurrentUploads))),
		withLatestFrom(this.store$.pipe(select(featureSelectors.selectAllFiles))),
		mergeMap(([[action, maxConcurrentUploads], files]) => {
			const noopAction = { type: '[Queue] Process queue NoopAction' }
			const noopEmptyAction = { type: '[Queue] Queue Empty NoopAction' }
			const uploadsInProgress = files.filter(t => t.status === 'uploading').length

			if (uploadsInProgress < maxConcurrentUploads) {
				// Root files process
				const queue = files.filter(t => t.status === 'queue' && t.location === 'root')
				const emptySlots = maxConcurrentUploads - uploadsInProgress
				const workItems = queue.slice(0, emptySlots)

				// Add subfolder files process, subfolders are uploaded only one at a time
				if (
					!files.filter(t => t.status === 'uploading' && t.location === 'subFolder').length &&
					files.filter(t => t.status === 'queue' && t.location === 'subFolder').length &&
					workItems.length + uploadsInProgress < maxConcurrentUploads
				) {
					const subFolderWorkItem = files.filter(t => t.status === 'queue' && t.location === 'subFolder')
					workItems.push(subFolderWorkItem[0])
				}

				if (!workItems.length) {
					if (uploadsInProgress) {
						return of(noopEmptyAction)
					}
					return of(new featureActions.ProcessQueueEndAction())
				}

				let actions = []
				actions.push(
					...workItems.map(item => {
						const step1Model = {
							id: item.id,
							file_description: '',
							file_name: item.file_name,
							// file_permissions: [],
							file_properties: [],
							repo_id: environment.projectRepositoryId,
							folder_tree: item.folder_tree,
							folder_id: item.folder_id,
						} as FileUploadStep1
						return new featureActions.UploadStep1RequestAction({ model: step1Model, file: item.data, location: item.location })
					}),
				)

				if (actions.length) {
					// actions.push(new LayoutStoreActions.SetActiveTabBottomSidenav({ tab: TabBottomSidenav.Upload }))
					// actions.push(new LayoutStoreActions.SetBottomSidenav({ visible: true }))
				}
				return actions
			}

			if (uploadsInProgress) {
				return of(noopAction)
			}

			return of(new featureActions.ProcessQueueEndAction())
		}),
	)

	@Effect()
	uploadStep1RequestEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.UploadStep1RequestAction>(featureActions.ActionTypes.UPLOAD_1_REQUEST),
		withLatestFrom(this.store$.pipe(select(FolderStoreSelectors.selectProjectMetadata))),
		mergeMap(
			([action, projectMetadata]): Observable<Action> => {
				// Assign project metadata
				const payload = {
					...action.payload.model,
					file_properties: projectMetadata || [], // VERIFY
				}

				return this.fileService.uploadStep1(payload).pipe(
					map(
						result =>
							new featureActions.UploadStep2RequestAction({
								model: result,
								req: payload,
								file: action.payload.file,
								location: action.payload.location,
							}),
					),
					catchError(error => [
						new LayoutStoreActions.ShowAlertToast({
							message: 'An error has occurred and the upload cannot be completed.',
						}),
						new featureActions.UploadStep1FailureAction({ error }),
					]),
				)
			},
		),
	)

	@Effect()
	uploadStep2RequestEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.UploadStep2RequestAction>(featureActions.ActionTypes.UPLOAD_2_REQUEST),
		mergeMap(action =>
			this.fileService.uploadStep2(action.payload.model.upload_url, action.payload.file, true).pipe(
				map(result => {
					if (result.type === HttpEventType.Sent || result.type === HttpEventType.UploadProgress) {
						const progress = result.loaded && result.total ? Math.floor((result.loaded / result.total) * 100) : 0

						const info = {
							id: action.payload.req.id,
							file_id: action.payload.model.file_id,
							file_name: action.payload.req.file_name,
							folder_id: action.payload.req.folder_id,
							total: result.total,
							loaded: result.loaded,
							progress: progress,
						} as FileUpload

						return new featureActions.UploadReportProgressAction({ info })
					}
					if (result.type === HttpEventType.Response) {
						const model = {
							id: action.payload.req.id,
							bucket_name: action.payload.model.bucket_name,
							file_description: action.payload.model.description,
							file_id: action.payload.model.file_id,
							file_name: action.payload.file.name,
							// file_permissions: action.payload.req.file_permissions,
							file_properties: action.payload.req.file_properties,
							folder_id: action.payload.req.folder_id,
							object_name: action.payload.model.object_name,
							repo_drive_id: action.payload.model.repo_drive_id,
							repo_id: action.payload.req.repo_id,
							// is_sync: (action.payload.file as File).size <= 20000000,
							folder_tree: action.payload.req.folder_tree,
						} as FileUploadStep3

						return new featureActions.UploadStep3RequestAction({ model, location: action.payload.location })
					}

					return { type: '[Upload] Upload step 2 NoopAction' } // hack others states...
				}),
				catchError(error => [
					new LayoutStoreActions.ShowAlertToast({
						message: 'An error has occurred and the upload cannot be completed.',
					}),
					new featureActions.UploadStep2FailureAction({ error }),
				]),
			),
		),
	)

	@Effect()
	uploadStep3RequestEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.UploadStep3RequestAction>(featureActions.ActionTypes.UPLOAD_3_REQUEST),
		withLatestFrom(this.store$.pipe(select(FolderStoreSelectors.selectProjectMetadata))),
		delay(1000),
		mergeMap(([action, projectMetadata]) => {
			const payload = {
				...action.payload.model,
				file_properties: projectMetadata || [],
				is_folder_metadata: true,
			}

			return this.fileService.uploadStep3(payload).pipe(
				mergeMap(result => [
					new featureActions.UploadStep3SuccessAction({ model: payload }),
					action.payload.location === 'root'
						? new folderActions.UploadFileFolderSuccessAction({ model: payload })
						: { type: '[Upload asset] Subfolder NoopAction' },
					new featureActions.DuplicateFileSuccessAction(),
					new folderActions.FolderStateChangeAction({ folderId: payload.folder_id, isEmpty: false }),
				]),
				catchError(error => [
					new LayoutStoreActions.ShowAlertToast({
						message: 'An error has occurred and the upload cannot be completed.',
					}),
					new featureActions.UploadStep3FailureAction({ error }),
				]),
			)
		}),
	)

	// TODO  Fabricio  Verify duplicate versioning and update effect testing
	@Effect()
	versionRequestEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.VersionFileRequestAction>(featureActions.ActionTypes.VERSION_FILE_REQUEST),
		mergeMap(action => {
			return this.fileService.getFileVersions(action.payload.version.file_id).pipe(
				switchMap(result => {
					let version = { ...action.payload.version, version_name: 'version-' + result.versions_list.length }
					return this.fileService.versionate(version).pipe(
						map(result => {
							//add conditional for override
							return new featureActions.ReplaceFileStep1RequestAction({
								version,
								folder: action.payload.folder,
								file: action.payload.file,
								id: generateId(),
							})
						}),
						catchError(error => observableOf(new featureActions.VersionFileFailureAction({ error }))),
					)
				}),
				catchError(error => observableOf(new featureActions.VersionFileFailureAction({ error }))),
			)
		}),
	)

	@Effect()
	fileReplaceStep1RequestEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.ReplaceFileStep1RequestAction>(featureActions.ActionTypes.REPLACE_FILE_STEP1_REQUEST),
		mergeMap(action => {
			let payload = {
				id: action.payload.id,
				file_id: action.payload.version.file_id,
				version_name: action.payload.version.version_name,
				description: 'override version file',
				created_by: action.payload.version.created_by,
				repo_id: action.payload.folder.repo_id,
				file_name: action.payload.file.name,
			} as FileReplaceStep1

			return this.fileService.replaceFileStep1(payload).pipe(
				mergeMap(result => {
					let actions: Action[] = []

					actions.push(new LayoutStoreActions.SetActiveTabBottomSidenav({ tab: TabBottomSidenav.Upload }))
					actions.push(new LayoutStoreActions.SetBottomSidenav({ visible: true }))
					actions.push(new featureActions.ReplaceFileStep1SuccessAction())
					actions.push(
						new featureActions.ReplaceFileStep2RequestAction({
							context: { ...result, file_id: action.payload.version.file_id, id: action.payload.id },
							file: action.payload.file,
							folder: action.payload.folder,
						}),
					)

					return actions
				}),
				catchError(error => [
					new LayoutStoreActions.ShowAlertToast({
						message: 'An error has occurred and the replacement cannot be completed.',
					}),
					new featureActions.ReplaceFileStep1FailureAction({ error }),
				]),
			)
		}),
	)

	@Effect()
	fileReplaceStep2RequestEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.ReplaceFileStep2RequestAction>(featureActions.ActionTypes.REPLACE_FILE_STEP2_REQUEST),
		mergeMap(action => {
			return this.fileService.replaceFileStep2(action.payload.context.upload_url, action.payload.file).pipe(
				map(result => {
					if (result.type === HttpEventType.Sent || result.type === HttpEventType.UploadProgress) {
						const progress = result.loaded && result.total ? Math.floor((result.loaded / result.total) * 100) : 0

						const info = {
							id: action.payload.context.id,
							file_id: action.payload.context.file_id,
							file_name: action.payload.file.name,
							folder_id: action.payload.folder.folder_id,
							total: result.total,
							loaded: result.loaded,
							progress: progress,
						} as FileUpload

						return new featureActions.UploadReportProgressAction({ info })
					}
					if (result.type === HttpEventType.Response) {
						return new featureActions.ReplaceFileStep3RequestAction({
							context: action.payload.context,
							folder: action.payload.folder,
							name: action.payload.file.name,
						})
					}

					return { type: '[Upload] Replace file step2 request NoopAction' } // hack others states...
				}),
				catchError(error => [
					new LayoutStoreActions.ShowAlertToast({
						message: 'An error has occurred and the replacement cannot be completed.',
					}),
					new featureActions.ReplaceFileStep2FailureAction({ error }),
				]),
			)
		}),
	)

	@Effect()
	fileReplaceStep3RequestEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.ReplaceFileStep3RequestAction>(featureActions.ActionTypes.REPLACE_FILE_STEP3_REQUEST),
		mergeMap(action => {
			let payload = {
				bucket_name: action.payload.context.bucket_name,
				file_id: action.payload.context.file_id,
				file_name: action.payload.name,
				is_upload_from_file_version: true,
				object_name: action.payload.context.object_name,
				repo_drive_id: action.payload.context.repo_drive_id,
				folder_id: action.payload.folder.folder_id,
				repo_id: action.payload.folder.repo_id,
			}
			return this.fileService.replaceFileStep3(payload).pipe(
				map(result => new featureActions.ReplaceFileStep3SuccessAction({ id: action.payload.context.id })),
				catchError(error => [
					new LayoutStoreActions.ShowAlertToast({
						message: 'An error has occurred and the replacement cannot be completed.',
					}),
					new featureActions.ReplaceFileStep3FailureAction({ error }),
				]),
			)
		}),
	)
}
