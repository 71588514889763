import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core'

@Component({
	selector: 'dam-wf-login',
	templateUrl: './wf-login.component.html',
	styleUrls: ['./wf-login.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WFLoginComponent implements OnInit {
	@Output()
	onSubmit: EventEmitter<any> = new EventEmitter()

	model = { email: '', password: '' }

	constructor() {}

	ngOnInit(): void {}

	submit(): void {
		this.onSubmit.emit(this.model)
		sessionStorage.setItem('wfusr', this.model.email)
		sessionStorage.setItem('wfpass', this.model.password)
	}
}
