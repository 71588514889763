import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { Store } from '@ngrx/store'
import { trigger, style, transition, animate, state } from '@angular/animations'
import {
	RootStoreState,
	UploadStoreActions,
	UploadStoreSelectors,
	FolderStoreSelectors,
	RootStoreSelectors,
	FolderStoreActions,
	RouteActions,
	UserStoreSelectors,
	LayoutStoreSelectors,
	LayoutStoreActions,
	SearchStoreActions,
	SearchStoreSelectors,
	ApmStoreActions,
} from '../../root-store'
import { ModalService, RequestCacheService } from '../../services'
import { BreadcrumbStoreSelectors } from '../../root-store/breadcrumb-store'
import { environment } from '../../../environments/environment'
import { ActivatedRoute } from '@angular/router'
import { IContextMenuAction } from '../../models/context-menu.model'
import { filter, map, take, takeUntil } from 'rxjs/operators'
import { ContextMenuService } from '../../services/context-menu.service'
import { FileActions } from '../../root-store/folder-store/actions'
import { DeviceService } from '../../services/device.service'
import { RepositoryTypes } from 'src/app/common/helpers'
import { DownloadFolderStoreSelectors, DownloadFolderStoreActions } from 'src/app/root-store/download-store'
import { TabBottomSidenav } from 'src/app/models/sidenav.model'
import { ProcessFileService } from 'src/app/services/process-file.service'
import {
	DamFile,
	Folder,
	FileUpload,
	FolderDownload,
	MoveItems,
	FileVersion,
	FolderCreate,
	ProjectMetadata,
	User,
	FileUploadStep1,
	SearchFileResult,
	DamContext,
	Breadcrumb,
} from '@app/models'

@Component({
	selector: 'dam-explorer',
	templateUrl: './explorer.component.html',
	styleUrls: ['./explorer.component.css'],
	animations: [
		trigger('listAnimation', [
			state('void', style({ opacity: 0 })),
			state('*', style({ opacity: 1 })),
			transition('void => *', animate('0.5s ease')),
		]),
		trigger('slideInOut', [
			state(
				'in',
				style({
					transform: 'translate3d(0, 0, 0)',
				}),
			),
			state(
				'out',
				style({
					transform: 'translate3d(100%, 0, 0)',
					opacity: 0,
					width: 0,
				}),
			),
			transition('in => out', animate('300ms ease-in-out')),
			transition('out => in', animate('400ms ease-in-out')),
		]),
	],
})
export class ExplorerComponent implements OnInit, OnDestroy {
	destroy$ = new Subject()
	files$: Observable<DamFile[]>
	files: DamFile[]
	folders$: Observable<Folder[]>
	isLoading$: Observable<boolean>
	user$: Observable<User>
	breadcrumbs$: Observable<Breadcrumb[]>
	item$: Observable<DamFile | Folder>
	selectedItem$: Observable<(DamFile | Folder)[]>
	uploadFiles$: Observable<FileUpload[]>
	downloadFolders$: Observable<Array<Partial<FolderDownload>>>
	bottomSidenavIsMinimized$: Observable<boolean>
	bottomSidenavActiveTab$: Observable<TabBottomSidenav>
	bottomSidenavActiveTab: TabBottomSidenav
	error$: Observable<any>

	damContext: DamContext
	selectedItems: (DamFile | Folder)[] = []
	contextMenuActions: IContextMenuAction[] = []
	sortField$: Observable<string>
	sortDirection$: Observable<boolean>
	showDetailSidenav$: Observable<boolean>
	projectMetadata$: Observable<ProjectMetadata>
	projectMetadataIsLoading$: Observable<boolean>
	searchResult$: Observable<SearchFileResult[]>
	selectMyProjectsFilter$: Observable<boolean>
	selectActiveProjectsFilter$: Observable<boolean>
	isSearchActive$: Observable<boolean>
	isAuditHistoryLoading$: Observable<boolean>
	selectedItemAuditHistory$: Observable<any>
	selectSidenavActiveTab$: Observable<string>
	gridLayout$: Observable<string>
	duplicateFiles$: Observable<FileUploadStep1[]>
	duplicateFolders$: Observable<any>

	//
	isSearchActive: boolean
	selectMyProjectsFilter: boolean
	isDetailSidenavOn: boolean
	//
	selectProjectFromSearch$: Observable<any>

	lineOfServiceValues = environment.los
	selectLineOfServiceValue$: Observable<string>
	businessUnitValues = environment.businessUnit
	selectBusinessUnitValue$: Observable<string>
	breadcrumbs: Breadcrumb[]

	user: User

	constructor(
		private store$: Store<RootStoreState.State>,
		private modalService: ModalService,
		private contexMenuService: ContextMenuService,
		private route: ActivatedRoute,
		private deviceService: DeviceService,
		private cacheService: RequestCacheService,
		private processFileService: ProcessFileService,
		private change: ChangeDetectorRef,
	) {
		this.route.data.pipe(takeUntil(this.destroy$)).subscribe(data => {
			this.damContext = data.data
			this.contexMenuService.setTab(this.damContext.tab as RepositoryTypes)
			this.contextMenuActions =
				this.damContext.folder_type === 'folder'
					? this.contexMenuService.getContextMenu('main-folder')
					: this.contexMenuService.getContextMenu('main')
		})

		// Remove all old references
		this.modalService.removeAll()
	}

	ngOnInit() {
		this.isLoading$ = this.store$.select(RootStoreSelectors.selectIsLoading)
		this.files$ = this.store$.select(FolderStoreSelectors.selectFiles)
		this.folders$ = this.store$.select(FolderStoreSelectors.selectFolders)
		this.selectedItem$ = this.store$.select(FolderStoreSelectors.selectedItems)
		this.item$ = this.store$.select(FolderStoreSelectors.selectedFirstItem)
		this.user$ = this.store$.select(UserStoreSelectors.selectUser)
		this.breadcrumbs$ = this.store$.select(BreadcrumbStoreSelectors.selectBreadcrumb)
		this.uploadFiles$ = this.store$.select(UploadStoreSelectors.selectAllFiles)
		this.downloadFolders$ = this.store$.select(DownloadFolderStoreSelectors.selectDownloadFolders)
		this.bottomSidenavIsMinimized$ = this.store$.select(LayoutStoreSelectors.selectBottomSidenavIsMinimized)
		this.bottomSidenavActiveTab$ = this.store$.select(LayoutStoreSelectors.selectBottomSidenavActiveTab)
		this.sortField$ = this.store$.select(LayoutStoreSelectors.selectSortField)
		this.sortDirection$ = this.store$.select(LayoutStoreSelectors.selectSortDirection)
		this.showDetailSidenav$ = this.store$.select(LayoutStoreSelectors.selectDetailSidenav)
		this.projectMetadata$ = this.store$.select(FolderStoreSelectors.selectProjectMetadata)
		this.projectMetadataIsLoading$ = this.store$.select(FolderStoreSelectors.selectProjectMetadataIsLoading)
		this.selectMyProjectsFilter$ = this.store$.select(SearchStoreSelectors.selectMyProjectsFilter)
		this.selectActiveProjectsFilter$ = this.store$.select(SearchStoreSelectors.selectActiveProjectsFilter)
		this.selectBusinessUnitValue$ = this.store$.select(SearchStoreSelectors.selectFilterBusinessUnit)
		this.selectLineOfServiceValue$ = this.store$.select(SearchStoreSelectors.selectFilterLoS)
		this.isSearchActive$ = this.store$.select(SearchStoreSelectors.isSearchActive)
		this.selectedItemAuditHistory$ = this.store$.select(FolderStoreSelectors.selectedAuditHistory)
		this.isAuditHistoryLoading$ = this.store$.select(FolderStoreSelectors.selectIsAuditHistoryLoading)
		this.selectSidenavActiveTab$ = this.store$.select(LayoutStoreSelectors.selectDetailSidenavActiveTab)
		this.gridLayout$ = this.store$.select(LayoutStoreSelectors.selectView)
		this.duplicateFiles$ = this.store$.select(UploadStoreSelectors.selectDuplicateFiles)
		this.duplicateFolders$ = this.store$.select(UploadStoreSelectors.selectDuplicateFolders)
		this.error$ = this.store$.select(FolderStoreSelectors.selectError)

		this.selectedItem$.pipe(takeUntil(this.destroy$)).subscribe(event => (this.selectedItems = event))
		this.files$.pipe(takeUntil(this.destroy$)).subscribe(event => (this.files = event))
		this.isSearchActive$.pipe(takeUntil(this.destroy$)).subscribe(t => (this.isSearchActive = t))
		this.user$.pipe(takeUntil(this.destroy$)).subscribe(t => (this.user = t))
		this.duplicateFiles$.pipe(takeUntil(this.destroy$)).subscribe(t => {
			if (t) {
				const model = { action: { modalId: 'duplicate-file', type: 'duplicate-file' }, model: t }
				this.showModal(model.action.modalId, model)
			}
		})

		this.duplicateFolders$.pipe(takeUntil(this.destroy$)).subscribe(t => {
			if (t) {
				const model = { action: { modalId: 'duplicate-folder', type: 'duplicate-folder' }, model: t }
				this.showModal(model.action.modalId, model)
			}
		})

		// Selected project metadata (project view)
		this.item$.pipe(takeUntil(this.destroy$)).subscribe(i => {
			const folderId = i ? i.folder_id : ''
			const name = i ? i.name : ''
			this.selectProjectFromSearch$ = this.store$.select(SearchStoreSelectors.selectProjectFromSearch(folderId, name))
		})

		this.breadcrumbs$.pipe(takeUntil(this.destroy$)).subscribe(t => {
			this.breadcrumbs = t
		})

		this.error$.pipe(takeUntil(this.destroy$)).subscribe(t => {
			if (t) {
				this.showModal('load-folder-error')
			}
		})
	}

	ngOnDestroy(): void {
		this.destroy$.next(true)
	}

	dbClick($event, item, type) {
		$event.stopPropagation()
		const folder = item as Folder
		if (type === 'folder') {
			const path = this.damContext.folder_id !== this.damContext.repo_id ? '../../folders' : 'folders'
			this.store$.dispatch(new RouteActions.Go({ path: [path, folder.folder_id], extras: { relativeTo: this.route } }))
		} else {
			// download file
			if (item.status == 'Active') {
				this.store$.dispatch(new FileActions.DownloadRequestAction({ file: item as DamFile, multiple: false }))
			}
		}
	}

	onFolderPathClick(folderId) {
		if (this.isSearchActive) {
			// const path = `folders/${folderId}`;
			const path = folderId !== this.damContext.repo_id ? '../../folders' : 'folders'
			this.store$.dispatch(new RouteActions.Go({ path: [path, folderId], extras: { relativeTo: this.route } }))
			this.store$.dispatch(new LayoutStoreActions.ShowDetailSidenav({ visible: false }))
			this.store$.dispatch(new SearchStoreActions.ClearAction())
		}
	}

	setMultipleSelection(items: any) {
		if (this.deviceService.isMobile && items[0] && items[0].folder_id) {
			//if device is mobile navigate with selection
			// const path = `folders/${items[0].folder_id}`;
			const path = items[0].folder_id !== this.damContext.repo_id ? '../../folders' : 'folders'
			this.store$.dispatch(new RouteActions.Go({ path: [path, items[0].folder_id], extras: { relativeTo: this.route } }))
		}

		if (!items.length) {
			this.store$.dispatch(new LayoutStoreActions.ShowDetailSidenav({ visible: false }))
		}

		if (items.length === 1) {
			this.setSelectedItem(items[0])
		} else {
			if (this.isSearchActive) {
				this.store$.dispatch(new FolderStoreActions.SetSelectedFromSearchAction({ item: items }))
			} else {
				const itemIds = items.map(t => t.file_id || t.folder_id)
				this.store$.dispatch(new FolderStoreActions.SetSelectedAction({ item: itemIds }))
			}
		}
	}

	setSelectedItem(payload: any) {
		// selected item from search window
		if (payload.property && this.isSearchActive) {
			const projectMetadata = {
				projectId: payload.folder_id,
				metadata: payload.property,
			} as ProjectMetadata
			this.store$.dispatch(new FolderStoreActions.LoadProjectMetadataSuccessAction({ data: projectMetadata }))
			this.store$.dispatch(new FolderStoreActions.SetSelectedFromSearchAction({ item: payload }))
		} else {
			// selected item from repository window
			this.store$.dispatch(new FolderStoreActions.SetSelectedAction({ item: [payload.file_id || payload.folder_id] }))
		}

		this.selectSidenavActiveTab$
			.pipe(
				take(1),
				filter(t => t === 'activity'),
			)
			.subscribe(tab => {
				if (payload.file_id) {
					this.store$.dispatch(new FileActions.LoadAuditHistoryRequestAction({ fileId: payload.file_id }))
				}
			})
	}

	clearSelection() {
		this.store$.dispatch(new FolderStoreActions.SetSelectedAction({ item: null }))
	}

	createFolder(folder: FolderCreate) {
		folder.repo_id = this.damContext.repo_id
		folder.parent_id = this.damContext.folder_id || null
		this.store$.dispatch(new FolderStoreActions.CreateRequestAction({ folder }))
	}

	showModal(modalId: string, model: any = null) {
		this.modalService.open(modalId, model)
	}

	closeModal(modalId: string): any {
		this.modalService.close(modalId)
	}

	onDragLeave(event, uploadInsideFolder: boolean = false) {
		// TODO refactor with onDragLeave method
		if (this.damContext.folder_type !== 'folder') {
			return
		}

		let data = uploadInsideFolder ? event.event : event
		let folderId = uploadInsideFolder ? event.model.folder_id : this.damContext.folder_id

		this.processFileService.makeUploadEntryFromDrag(data, this.breadcrumbs, folderId, uploadInsideFolder).subscribe(files => {
			this.store$.dispatch(
				new ApmStoreActions.StartUpload({
					folder: { folder_id: folderId, repo_id: this.damContext.repo_id },
					data: data.map(t => t.data.name),
				}),
			)
			this.store$.dispatch(
				new UploadStoreActions.VerifyUploadRequestAction({
					folder: { folder_id: folderId, repo_id: this.damContext.repo_id },
					data: files,
				}),
			)
		})
	}

	/**
	 * Context Menu Actions
	 */
	onContextAction(event) {
		const action = event.action as IContextMenuAction
		if (action.modalId) {
			if (this.selectedItems.length > 1) {
				event.items = this.selectedItems
			} else if (event.payload) {
				event.items = [event.payload]
				this.setSelectedItem(event.payload)
			}

			this.showModal(action.modalId, event)
			return
		}

		switch (action.type) {
			case 'DownloadAsset': {
				if (this.selectedItems.length) {
					// Download multiple files
					for (let item of this.selectedItems) {
						this.store$.dispatch(new FileActions.DownloadRequestAction({ file: item as DamFile, multiple: true }))
					}
				} else {
					this.store$.dispatch(new FileActions.DownloadRequestAction({ file: event.payload }))
				}
				break
			}
			case 'DownloadFolder': {
				this.breadcrumbs$.pipe(take(1)).subscribe(breadcrumbs => {
					const folder = {
						folder_id: event.payload.folder_id,
						folder_name: event.payload.name,
						repo_id: event.payload.repo_id,
						path: breadcrumbs.map(t => t.label).join('/'),
					}
					this.store$.dispatch(new DownloadFolderStoreActions.DownloadFolderRequestAction({ folder }))
				})
				break
			}
			case 'ViewDetails': {
				this.setSelectedItem(event.payload)
				this.showDetailSidenav(true)
				break
			}
		}
	}

	replaceDuplicateAction() {
		this.modalService
			.getContext()
			.pipe(
				filter(ctx => ctx.model),
				map(ctx => ctx.model),
				take(1),
			)
			.subscribe(ctx => {
				this.closeModal(ctx.action.modalId)

				let newVersionUoploadFiles = []
				let normalUploadFiles = []

				for (let i = 0; i < ctx.model.data.length; i++) {
					let item = ctx.model.data[i]
					let itemName = item.data.name

					if (ctx.model.duplicated.some(t => t.name === itemName)) {
						newVersionUoploadFiles.push(item)
					} else {
						normalUploadFiles.push(item)
					}
				}

				if (normalUploadFiles.length) {
					this.store$.dispatch(
						new UploadStoreActions.VerifyUploadRequestAction({
							folder: { folder_id: ctx.model.folder.folder_id, repo_id: this.damContext.repo_id },
							data: normalUploadFiles,
						}),
					)
				}

				for (let file of newVersionUoploadFiles) {
					let fileName = file.data.name
					const version = {
						file_id: ctx.model.duplicated.find(x => x.name == fileName).file_id,
						is_upload_from_file_version: true,
						version_name: '',
						description: null,
						created_by: this.user.email,
						is_overwrite: false,
					} as FileVersion

					this.store$.dispatch(new UploadStoreActions.RemoveElementUpload(version.file_id))
					this.store$.dispatch(
						new UploadStoreActions.VersionFileRequestAction({
							version,
							folder: ctx.model.folder,
							file: file.data,
						}),
					)
				}

				this.store$.dispatch(new UploadStoreActions.DuplicateFileSuccessAction())
			})
	}

	renameDuplicateAction() {
		this.modalService
			.getContext()
			.pipe(
				filter(ctx => ctx.model),
				map(ctx => ctx.model),
				take(1),
			)
			.subscribe(modalCtx => {
				const ctx = { ...modalCtx }
				this.closeModal(ctx.action.modalId)

				const duplicated = ctx.model.duplicated.map(t => t.name)

				for (let i = 0; i < ctx.model.data.length; i++) {
					let item = ctx.model.data[i]
					let itemName = item.data.name
					let itemNewName = ''

					if (ctx.action.modalId !== 'duplicate-folder') {
						itemNewName = this.generateFileName(itemName, ctx.model.files)
						if (itemName === itemNewName) {
							continue
						}
						item.data = new File([item.data], itemNewName)
					} else if (item.location === 'subFolder' && !!~duplicated.indexOf(item.folder_tree[item.folder_upload_level].folder_name)) {
						itemNewName = this.generateFolderName(item.folder_tree[item.folder_upload_level].folder_name, ctx.model.folders)
						item.folder_tree[item.folder_upload_level].folder_name = itemNewName
					}
				}

				this.store$.dispatch(
					new UploadStoreActions.VerifyUploadRequestAction({
						folder: { folder_id: ctx.model.folder.folder_id, repo_id: this.damContext.repo_id },
						data: ctx.model.data,
					}),
				)
			})
	}

	generateFileName(fileName, currentFiles): string {
		if (!currentFiles.some(x => x.name == fileName)) {
			return fileName
		}

		let count = 1
		const pattern = /[^\\]*\.(\w+)$/
		const paths = fileName.match(pattern)
		const extension = paths.length > 1 ? '.' + paths[1] : ''
		let name = fileName.replace(new RegExp(extension + '$'), `(${count})${extension}`)

		while (currentFiles.find(x => x.name == name)) {
			count++
			name = fileName.replace(new RegExp(extension + '$'), `(${count})${extension}`)
		}
		return name
	}

	generateFolderName(folderName, currentFolders): string {
		if (!currentFolders.some(x => x.name == folderName)) {
			return folderName
		}

		let count = 1
		let name = `${folderName} (${count})`

		while (currentFolders.find(x => x.name == name)) {
			count++
			name = `${folderName} (${count})`
		}
		return name
	}

	cancelDuplicateAction() {
		this.modalService
			.getContext()
			.pipe(
				filter(ctx => ctx.model),
				map(ctx => ctx.model),
				take(1),
			)
			.subscribe(ctx => {
				this.closeModal(ctx.action.modalId)
				this.store$.dispatch(new UploadStoreActions.DuplicateFolderSuccessAction())
				this.store$.dispatch(new UploadStoreActions.DuplicateFileCancelAction())
			})
	}

	submitDeleteAction() {
		this.modalService
			.getContext()
			.pipe(
				filter(ctx => ctx.model),
				map(ctx => ctx.model),
				take(1),
			)
			.subscribe(ctx => {
				this.closeModal(ctx.action.modalId)
				let files =
					ctx.items
						.filter(x => x.file_id)
						.map(x => {
							return { file_id: x.file_id /*file_name: x.name*/ }
						}) || []
				let folders =
					ctx.items
						.filter(x => !x.file_id)
						.map(x => {
							return { folder_id: x.folder_id /*folder_name: x.name*/ }
						}) || []
				if (files.length || folders.length) {
					const assets = {
						files: files,
						folders: folders,
						repo_id: this.damContext.repo_id,
					}
					this.store$.dispatch(new FileActions.DeleteRequestAction({ assets, folderId: this.damContext.folder_id }))
				}
			})
	}

	submitRenameAction(newName: string) {
		this.modalService
			.getContext()
			.pipe(
				filter(ctx => ctx.model),
				map(ctx => ctx.model),
				take(1),
			)
			.subscribe(ctx => {
				this.closeModal(ctx.action.modalId)

				if (ctx.payload.file_id) {
					const file = {
						file_id: ctx.payload.file_id,
						repo_id: this.damContext.repo_id,
						folder_id: this.damContext.folder_id,
						file_name: newName,
					}
					this.store$.dispatch(new FileActions.UpdateRequestAction({ file }))
				} else {
					ctx.payload.name = newName
					this.cacheService.expire(`${this.damContext.repo_id}-${this.damContext.folder_id}`)
					this.store$.dispatch(new FolderStoreActions.UpdateRequestAction({ folder: ctx.payload }))
				}
			})
	}

	submitMoveAction(data: any) {
		this.modalService
			.getContext()
			.pipe(
				filter(ctx => ctx.model),
				map(ctx => ctx.model),
				take(1),
			)
			.subscribe(ctx => {
				let files: MoveItems = {
					folders: ctx.items
						.filter(t => !t.file_id)
						.map(x => {
							return { folder_id: x.folder_id /*name: x.name*/ }
						}),
					files: ctx.items
						.filter(t => t.file_id)
						.map(x => {
							return { file_id: x.file_id }
						}),
					folder_id: data.folder_id,
					source_folder_id: this.damContext.folder_id,
					repo_id: data.repo_id,
					updated_by: this.user.email,
				}
				this.store$.dispatch(new FileActions.MoveToRequestAction({ files }))
				this.closeModal('move-folder')
			})
	}

	expandCollapseUploadDialog() {
		this.store$.dispatch(new LayoutStoreActions.ToggleBottomSidenav())
	}

	trackByFile(index, item) {
		return item ? item.file_id : undefined
	}
	trackByFolder(index, item) {
		return item ? item.folder_id : undefined
	}

	sortBy(field) {
		this.store$.dispatch(new LayoutStoreActions.SortFieldChange({ field }))
	}

	changeSortDirection() {
		this.store$.dispatch(new LayoutStoreActions.SortDirectionChange())
	}

	clearUploadFile(file: FileUpload) {
		this.store$.dispatch(new UploadStoreActions.ClearUploadListAction({ file }))
	}

	clearDoownloadFolder(folder: FolderDownload) {
		this.store$.dispatch(new DownloadFolderStoreActions.RemoveDownloadFolderRequestAction({ folder }))
	}

	clearAllUploads() {
		this.store$.dispatch(new UploadStoreActions.ClearAllUploadListAction())
	}

	clearAllDownloads() {
		this.store$.dispatch(new DownloadFolderStoreActions.ClearDownloadFoldersAllAction())
	}

	switchView(view) {
		this.store$.dispatch(new LayoutStoreActions.ViewChange({ view: view }))
	}

	onClearSearch() {
		this.store$.dispatch(new LayoutStoreActions.ShowDetailSidenav({ visible: false }))
		this.store$.dispatch(new SearchStoreActions.ClearAction())
	}

	toggleMyProjects(event) {
		this.store$.dispatch(new SearchStoreActions.SetFilterMyProjectsAction({ value: event.target.checked }))
	}

	toggleActiveProjects(event) {
		this.store$.dispatch(new SearchStoreActions.SetFilterActiveProjectsAction({ value: event.target.checked }))
	}

	filterProjectsBy(type, event) {
		switch (type) {
			case 'L': {
				this.store$.dispatch(new SearchStoreActions.SetFilterLoSAction({ value: event.target.value }))
				break
			}
			case 'B': {
				this.store$.dispatch(new SearchStoreActions.SetFilterBusinessUnitAction({ value: event.target.value }))
				break
			}
		}
	}

	fileIsSelected(items: Array<any>, file: any) {
		return items && items.some(t => t.file_id === file.file_id)
	}

	folderIsSelected(items: Array<any>, folder: any) {
		return items && items.some(t => t.folder_id === folder.folder_id)
	}

	onSlideInOut(event) {
		this.isDetailSidenavOn = event.toState == 'in'
	}

	showDetailSidenav(visible) {
		this.store$.dispatch(new LayoutStoreActions.ShowDetailSidenav({ visible }))
	}

	onSidenavOpenTab(event) {
		if (event.tab === 'activity') {
			this.store$.dispatch(new LayoutStoreActions.SetSidenavActiveTab({ tab: 'activity' }))
			this.store$.dispatch(new FileActions.LoadAuditHistoryRequestAction({ fileId: event.fileId }))
		} else {
			this.store$.dispatch(new LayoutStoreActions.SetSidenavActiveTab({ tab: 'details' }))
		}
	}

	refresh() {
		this.cacheService.clearCache()
		this.store$.dispatch(
			new FolderStoreActions.LoadFileMetadataRequestAction({
				repositoryId: this.damContext.repo_id,
				folderId: this.damContext.folder_id,
			}),
		)
		this.store$.dispatch(
			new FolderStoreActions.LoadRequestAction({
				repositoryId: this.damContext.repo_id,
				folderId: this.damContext.folder_id,
			}),
		)
	}

	changeSidenavActiveTab(tab) {
		this.store$.dispatch(new LayoutStoreActions.SetActiveTabBottomSidenav({ tab }))
	}

	notifyDownload(checked: boolean, f: Partial<FolderDownload>) {
		const folder = { ...f, notify_user: checked }
		this.store$.dispatch(new DownloadFolderStoreActions.NotifyFolderDownload({ folder }))
	}

	uploadFile(files) {
		if (this.damContext.folder_type !== 'folder') {
			return
		}

		const data = this.processFileService.makeUploadEntryFromInput(files, this.breadcrumbs, this.damContext.folder_id)
		this.store$.dispatch(
			new ApmStoreActions.StartUpload({
				folder: { folder_id: this.damContext.folder_id, repo_id: this.damContext.repo_id },
				data: data.map(t => t.data.name),
			}),
		)
		this.store$.dispatch(
			new UploadStoreActions.VerifyUploadRequestAction({
				folder: { folder_id: this.damContext.folder_id, repo_id: this.damContext.repo_id },
				data: data,
			}),
		)
	}

	loadFolderError() {
		this.store$.dispatch(new RouteActions.Go({ path: [''] }))
	}
}
