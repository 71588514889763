import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { Observable, of } from 'rxjs'
import { Action, select, Store } from '@ngrx/store'
import { AlertToastService } from '../../services/alert-toast.service'
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import * as featureActions from './actions'
import { mapSortFromState } from '@app/common/helpers'
import * as searchSelectors from '@app/root-store/search-store/selectors'
import { RootStoreState } from '..'
import { FolderService } from '@app/services'
import { FolderWrapper } from '@app/models'
import * as FolderStoreActions from '../folder-store/actions/folder.actions'

@Injectable()
export class LayoutStoreEffects {
	constructor(
		private actions$: Actions,
		private alertService: AlertToastService,
		private store$: Store<RootStoreState.State>,
		private folderService: FolderService,
	) {}

	@Effect({ dispatch: false })
	showAlertToastEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.ShowAlertToast>(featureActions.ActionTypes.SHOW_ALERT_TOAST),
		tap<featureActions.ShowAlertToast>(t => this.alertService.success(t.payload.message, true, t.payload.autoDismiss)),
	)

	@Effect()
	loadProjectsSortChangeEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.SortFieldChange>(featureActions.ActionTypes.SORT_FIELD_CHANGE),
		withLatestFrom(this.store$.pipe(select(searchSelectors.selectActiveProjectsFilter))),
		withLatestFrom(this.store$.pipe(select(s => s.layout))),
		switchMap(([[action, activeProjectFilter], layoutStore]) => {
			if (!activeProjectFilter) {
				const { sortField, sortDirection } = mapSortFromState(layoutStore)
				return this.folderService.getAllProjectFromStorage(layoutStore.tablePage, layoutStore.tableLimit, sortField, sortDirection).pipe(
					map(
						(rootFolder: FolderWrapper) =>
							new FolderStoreActions.LoadProjectFromStorageSuccessAction({
								rootFolder: rootFolder,
								setFolders: true,
							}),
					),
					catchError(error => [
						new featureActions.ShowAlertToast({ message: 'An error has occurred loading folders.' }),
						new FolderStoreActions.LoadProjectFromStorageFailureAction({ error }),
					]),
				)
			}
			return of({ type: '[Layout] Sort field change NoopAction' })
		}),
	)
}
