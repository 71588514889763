<li class="bottom-dialog-item">
  <img class="bottom-dialog-item-img" src="../../../assets/images/icons/file-upload.svg" alt="">
  <div class="bottom-dialog-item-info">
    <span class="bottom-dialog-item-name">{{folderTree}}/<b>{{name}}</b></span>
    <div class="file-uploads-progress" *ngIf="!completed">
      <mat-progress-bar  [mode]="progress ? 'determinate' : 'buffer'" [value]="progress"></mat-progress-bar>
      <!--/* <div class="file-uploads-progress-bar" role="progressbar" aria-valuenow="58" aria-valuemin="0" aria-valuemax="100" [style.width]="progress + '%'"></div> */-->
    </div>
    <div *ngIf="!completed && progress">
      <span class="bottom-dialog-item-loaded">
        <!-- {{(loaded / 1024).toFixed(2)}} -->
        {{(loaded || 0) | fileSize}}
      </span>
       of
      <span class="bottom-dialog-item-total">
        <!-- {{(size / 1024).toFixed(2)}} -->
        {{(size || 0) | fileSize}}
      </span>
      
      <span class="bottom-dialog-item-progress-wrapper">
        (
        <span class="bottom-dialog-item-progress">{{progress}}</span>
        % done )
      </span>

    </div>
  </div>
  <!-- <button class="bottom-dialog-item-cancel" type="button" *ngIf="!completed">
    <span class="bottom-dialog-item-cancel-text">
      CANCEL
    </span>
    <span class="bottom-dialog-item-cancel-text-xs">
      &times;
    </span>
  </button> -->
  <button class="bottom-dialog-item-cancel" type="button" (click)="onClear.emit()" *ngIf="completed">
    <span class="bottom-dialog-item-cancel-text">
      &times;
    </span>
  </button>
</li>
