import { Component, OnInit, Output, Input, EventEmitter, ChangeDetectionStrategy } from '@angular/core'
import { FolderCreate, Folder } from '@app/models'

@Component({
	selector: 'dam-create-folder-modal',
	templateUrl: './create-folder-modal.component.html',
	styleUrls: ['./create-folder-modal.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateFolderModalComponent implements OnInit {
	@Input()
	folders: Folder[]

	@Output()
	onSubmit: EventEmitter<FolderCreate> = new EventEmitter()

	@Output()
	onCancel: EventEmitter<any> = new EventEmitter()

	model: FolderCreate

	constructor() {
		this.resetForm()
	}

	ngOnInit() {}

	resetForm() {
		this.model = { folder_name: null, parent_id: '', repo_id: '', description: '', permissions: [] }
	}

	submit() {
		this.onSubmit.emit(this.model)
		this.resetForm()
	}

	cancel() {
		this.onCancel.emit()
		this.resetForm()
	}

	get isValidFolderName(): boolean {
		return !this.folders.some(t => t.name === this.model.folder_name)
	}
}
