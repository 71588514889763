import { Injectable } from '@angular/core'
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Store } from '@ngrx/store'
import { FolderStoreActions } from './../../folder-store'
import { RootStoreState, LayoutStoreActions } from '../..'
import { SearchStoreActions } from '../../search-store'
import { environment } from '../../../../environments/environment'
import { BreadcrumbStoreActions } from '../../breadcrumb-store'
import { DamContext } from '@app/models'

/**
 * @deprecated
 */
@Injectable()
export class FolderListResolver implements Resolve<DamContext> {
	constructor(private store$: Store<RootStoreState.State>) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const folderId = route.paramMap.get('id')
		const repositoryId = environment.projectRepositoryId

		this.store$.dispatch(
			new SearchStoreActions.AddContextContentAction({
				folderId: folderId, // || repositoryId,
				repositoryId: repositoryId,
			}),
		)

		// if (!folderId) {
		//     this.store$.dispatch(new FolderStoreActions.ClearProjectMetadataAction());
		//     this.store$.dispatch(new SearchStoreActions.AddQueryContentAction({ value: environment.metadataFileName }));
		//     this.store$.dispatch(new SearchStoreActions.ProjectFilterRequestAction());
		//     this.store$.dispatch(new BreadcrumbStoreActions.ClearAction());
		// }
		// else {
		this.store$.dispatch(new FolderStoreActions.LoadFileMetadataRequestAction({ repositoryId, folderId })) // Search
		this.store$.dispatch(new FolderStoreActions.LoadProjectMetadataRequestAction({ repositoryId: repositoryId, folderId: folderId }))
		this.store$.dispatch(new BreadcrumbStoreActions.LoadRequestAction({ repositoryId: repositoryId, folderId: folderId }))
		// }

		this.store$.dispatch(new FolderStoreActions.LoadRequestAction({ repositoryId: repositoryId, folderId: folderId }))
		this.store$.dispatch(new LayoutStoreActions.ShowDetailSidenav({ visible: false }))

		return {
			folder_id: folderId,
			repo_id: repositoryId,
			folder_type: 'folder',
		} as DamContext
	}
}
