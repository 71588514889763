import { Actions, FolderActions, FileActions } from './actions'
import { initialState, State } from './state'
import { environment } from '../../../environments/environment'
import { Folder } from '@app/models'
import { arrayUpdate } from '@app/common/extensions'

export function featureReducer(state = initialState, action: Actions): State {
	switch (action.type) {
		/***
		 * Folder actions
		 */
		case FolderActions.ActionTypes.LOAD_REQUEST: {
			return {
				...state,
				folders: [],
				files: [],
				isLoading: true,
				error: null,
			}
		}
		case FolderActions.ActionTypes.LOAD_SUCCESS: {
			return {
				...state,
				folders: action.payload.rootFolder.folders || [],
				files: action.payload.rootFolder.files || [],
				currentFolder: { folder_id: action.payload.rootFolder.folder_id, repo_id: action.payload.rootFolder.repo_id },
				isLoading: false,
				error: null,
			}
		}
		case FolderActions.ActionTypes.LOAD_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: action.payload.error,
			}
		}
		case FolderActions.ActionTypes.RELOAD_SUCCESS: {
			if (state.currentFolder.folder_id === action.payload.rootFolder.folder_id) {
				return {
					...state,
					folders: action.payload.rootFolder.folders || [],
					files: action.payload.rootFolder.files || [],
					isLoading: false,
					error: null,
				}
			}

			return state
		}
		case FolderActions.ActionTypes.LOAD_PROJECT_FROM_STORAGE_REQUEST: {
			return {
				...state,
				projects: [],
				folders: [],
				files: [],
				isLoading: true,
				error: null,
			}
		}
		case FolderActions.ActionTypes.LOAD_PROJECT_FROM_STORAGE_SUCCESS: {
			if (action.payload.setFolders) {
				return {
					...state,
					projects: action.payload.rootFolder.folders || [],
					folders: action.payload.rootFolder.folders || [],
					files: action.payload.rootFolder.files || [],
					//currentProjectId: action.payload.rootFolder.folders[0] ? action.payload.rootFolder.folders[0].folder_id : "",
					currentFolder: { folder_id: action.payload.rootFolder.folder_id, repo_id: action.payload.rootFolder.repo_id },
					isLoading: false,
					error: null,
				}
			}
			return {
				...state,
				projects: action.payload.rootFolder.folders || [],
				error: null,
			}
		}
		case FolderActions.ActionTypes.LOAD_PROJECT_FROM_STORAGE_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: action.payload.error,
			}
		}

		case FolderActions.ActionTypes.SET_SELECTED_ITEM: {
			if (action.payload.item) {
				return {
					...state,
					selectedItems: [
						...state.files.filter(x => action.payload.item.indexOf(x.file_id) != -1).map(t => t.file_id),
						...state.folders.filter(x => action.payload.item.indexOf(x.folder_id) != -1).map(t => t.folder_id),
					],
				}
			}
			return state
		}

		case FolderActions.ActionTypes.SET_SELECTED_ITEM_FROM_SEARCH: {
			if (action.payload.item) {
				const selectedItems = Array.isArray(action.payload.item)
					? [...action.payload.item.map(t => t.file_id || t.folder_id)]
					: [action.payload.item.file_id || action.payload.item.folder_id]

				return {
					...state,
					selectedItems: selectedItems,
				}
			}
			return state
		}

		/***
		 * Load file info
		 */
		case FolderActions.ActionTypes.LOAD_FILE_LINK_REQUEST: {
			return state
		}
		case FolderActions.ActionTypes.LOAD_FILE_LINK_SUCCESS: {
			if (!state.files || !state.files.length) return state

			const index = state.files.findIndex(t => t.file_id === action.payload.file.file_id)
			if (index === -1) {
				return state
			}

			const oldItem = state.files[index]
			const newItem = {
				...oldItem,
				fileInfo: { ...action.payload.file },
			}

			return {
				...state,
				files: arrayUpdate(state.files, newItem, index),
			}
		}
		case FolderActions.ActionTypes.LOAD_FILE_LINK_FAILURE: {
			return state
		}

		/**
		 * Files metadata
		 */
		case FolderActions.ActionTypes.LOAD_FILES_METADATA_SUCCESS: {
			if (!state.files || !state.files.length || !action.payload.data.files) return state

			const files = state.files.map(t => {
				const metadata = action.payload.data.files.find(f => f.file_id === t.file_id)
				if (metadata) {
					return {
						...t,
						createdBy: metadata.createdBy,
						createdOn: metadata.createdOn,
						property: metadata.property,
						modifiedBy: metadata.modifiedBy,
						modifiedOn: metadata.modifiedOn,
						size: metadata.size,
						folder_path: metadata.folder_path,
					}
				}

				return t
			})

			return {
				...state,
				files: files,
			}
		}

		/**
		 * Create folder actions
		 */
		case FolderActions.ActionTypes.CREATE_REQUEST: {
			return {
				...state,
				isLoading: true,
				error: null,
			}
		}
		case FolderActions.ActionTypes.CREATE_SUCCESS: {
			const folder: Folder = {
				folder_id: action.payload.folder.folder.folder_id,
				repo_id: action.payload.folder.folder.repo_id,
				isFavorite: false,
				status: action.payload.folder.folder.status,
				user_permission: null,
				name: action.payload.folder.folder.name,
				isBusy: false,
			}

			const folders = state.folders ? [...state.folders, folder] : [folder]

			return {
				...state,
				folders: folders,
				isLoading: false,
				error: null,
			}
		}
		case FolderActions.ActionTypes.CREATE_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: action.payload.error,
			}
		}

		/**
		 * Update folder actions
		 */
		case FolderActions.ActionTypes.UPDATE_REQUEST: {
			const index = state.folders.findIndex(t => t.folder_id === action.payload.folder.folder_id)
			if (index === -1) {
				return state
			}

			const oldItem = state.folders[index]
			const newItem = {
				...oldItem,
				name: action.payload.folder.name,
			}

			return {
				...state,
				folders: arrayUpdate(state.folders, newItem, index),
			}
		}
		/**
		 * Move file actions
		 */
		case FileActions.ActionTypes.MOVE_TO_REQUEST: {
			let folders = state.folders.map(t => {
				if (action.payload.files.folders.find(e => e.folder_id === t.folder_id)) {
					return {
						...t,
						isBusy: true,
					}
				}
				return t
			})

			let files = state.files.map(t => {
				if (action.payload.files.files.find(e => e.file_id === t.file_id)) {
					return {
						...t,
						isBusy: true,
					}
				}
				return t
			})

			return {
				...state,
				folders,
				files,
				isLoading: false,
			}
		}
		case FileActions.ActionTypes.MOVE_TO_SUCCESS: {
			if (!action.payload.files.files.length && !action.payload.files.folders.length) {
				return state
			}

			return {
				...state,
				folders: state.folders.filter(x => !action.payload.files.folders.find(e => e.folder_id === x.folder_id)),
				files: state.files.filter(x => !action.payload.files.files.find(e => e.file_id === x.file_id)),
				isLoading: false,
			}
		}
		case FileActions.ActionTypes.MOVE_TO_FAILURE: {
			return {
				...state,
				isLoading: false,
			}
		}
		/**
		 * Delete file actions
		 */
		case FileActions.ActionTypes.FILE_DELETE_REQUEST: {
			if (action.payload.assets.files.length == 0 && action.payload.assets.folders.length == 0) return state

			return {
				...state,
				files: state.files.map(x => {
					if (action.payload.assets.files.findIndex(e => e.file_id == x.file_id) != -1) x.isBusy = true
					return x
				}),
				folders: state.folders.map(x => {
					if (action.payload.assets.folders.findIndex(e => e.folder_id == x.folder_id) != -1) x.isBusy = true
					return x
				}),
				error: null,
			}
		}
		case FileActions.ActionTypes.FILE_DELETE_SUCCESS: {
			if (action.payload.assets.files.length == 0 && action.payload.assets.folders.length == 0) return state

			const newFiles = [...state.files.filter(x => action.payload.assets.files.findIndex(e => e.file_id == x.file_id) == -1)]
			const newFolders = [...state.folders.filter(x => action.payload.assets.folders.findIndex(e => e.folder_id == x.folder_id) == -1)]
			return {
				...state,
				files: newFiles,
				folders: newFolders,
				error: null,
			}
		}
		case FileActions.ActionTypes.FILE_DELETE_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: action.payload.error,
			}
		}
		/**
		 * Update file actions
		 */
		case FileActions.ActionTypes.FILE_UPDATE_REQUEST: {
			const index = state.files.findIndex(t => t.file_id === action.payload.file.file_id)
			if (index === -1) {
				return state
			}

			const oldItem = state.files[index]
			const newItem = {
				...oldItem,
				name: action.payload.file.file_name,
			}

			return {
				...state,
				files: arrayUpdate(state.files, newItem, index),
			}
		}
		/**
		 * File download actions
		 */
		case FileActions.ActionTypes.FILE_DOWNLOAD_REQUEST: {
			const index = state.files.findIndex(t => t.file_id === action.payload.file.file_id)
			if (index === -1) {
				return state
			}

			const oldItem = state.files[index]
			const newItem = {
				...oldItem,
				isBusy: true,
			}

			return {
				...state,
				files: arrayUpdate(state.files, newItem, index),
			}
		}
		case FileActions.ActionTypes.FILE_DOWNLOAD_SUCCESS: {
			const index = state.files.findIndex(t => t.file_id === action.payload.file.file_id)
			if (index === -1) {
				return state
			}

			const oldItem = state.files[index]
			const newItem = {
				...oldItem,
				isBusy: false,
			}

			return {
				...state,
				files: arrayUpdate(state.files, newItem, index),
			}
		}
		/**
		 * Upload new file
		 */
		case FolderActions.ActionTypes.ADD_FILE_SUCCESS: {
			let files = []

			if (state.files && state.files.length) {
				const index = state.files.findIndex(t => t.file_id === action.payload.file.file_id)

				if (index != -1) {
					// Update file
					files = arrayUpdate(state.files, action.payload.file, index)
				} else {
					files = [...state.files, action.payload.file]
				}
			} else {
				files = [action.payload.file]
			}

			return {
				...state,
				files: files,
			}
		}

		/**
		 * Load project metadata
		 */
		case FolderActions.ActionTypes.CLEAR_PROJECT_METADATA: {
			return {
				...state,
				currentProjectId: null,
			}
		}

		case FolderActions.ActionTypes.LOAD_PROJECT_METADATA_FAILURE: {
			return {
				...state,
				projectMetadataIsLoading: false,
				error: action.payload.error,
				currentProjectId: null,
			}
		}
		case FolderActions.ActionTypes.LOAD_PROJECT_METADATA_REQUEST: {
			return {
				...state,
				projectMetadataIsLoading: true,
			}
		}
		case FolderActions.ActionTypes.LOAD_PROJECT_METADATA_SUCCESS: {
			if (!action.payload.data.metadata) {
				return {
					...state,
					projectMetadataIsLoading: false,
					currentProjectId: action.payload.data.projectId,
				}
			}

			const newMetadata = action.payload.data.metadata.map(t => {
				if (t.type === 'Date') {
					t.value = new Date(t.value).toISOString()
				}
				return { ...t }
			})

			let metadata = { ...state.projectMetadata }
			metadata[action.payload.data.projectId] = newMetadata

			const folderIndex = state.folders.findIndex(t => t.folder_id === action.payload.data.projectId)
			let newFolder = null
			if (folderIndex !== -1) {
				const oldFolder = state.folders[folderIndex]
				newFolder = {
					...oldFolder,
					property: newMetadata,
					metadataFileId: action.payload.data.metadataFileId,
					metadataLoaded: true,
				}
			}

			return {
				...state,
				folders: newFolder ? arrayUpdate(state.folders, newFolder, folderIndex) : state.folders,
				projectMetadataIsLoading: false,
				projectMetadata: metadata,
				currentProjectId: action.payload.data.projectId,
			}
		}

		case FolderActions.ActionTypes.UPDATE_FOLDER_METADATA_FROM_SEARCH: {
			if (!action.payload.data) {
				return state
			}

			const folders = state.folders.map(t => {
				const searchProject = action.payload.data.find(p => p.folder_id === t.folder_id)

				if (!searchProject) return t

				const referenceNumber = searchProject.property.find(x => x.name == environment.metadata.referenceNumber.name)
				const deadline = searchProject.property.find(x => x.name == environment.metadata.deadline.name)

				return {
					...t,
					property: searchProject.property,
					createdOn: searchProject.createdOn,
					createdBy: searchProject.createdBy,
					metadataProjection: {
						referenceNumber: referenceNumber ? +referenceNumber.value : null,
						deadline: deadline ? new Date(deadline.value) : null,
					},
				}
			})

			return {
				...state,
				folders: [...folders],
			}
		}

		case FolderActions.ActionTypes.UPDATE_FOLDER_METADATA: {
			const folderIndex = state.folders.findIndex(t => t.metadataFileId === action.payload.data.file_id)
			if (folderIndex === -1) {
				return state
			}

			const oldFolder = state.folders[folderIndex]
			const newFolder = {
				...oldFolder,
				property: oldFolder.property.map(t => {
					const updatedPropertyIndex = action.payload.data.file_properties.findIndex(p => t.name.toLowerCase() == p.name.toLowerCase())
					return !!~updatedPropertyIndex ? action.payload.data.file_properties[updatedPropertyIndex] : t
				}),
			}

			return {
				...state,
				folders: arrayUpdate(state.folders, newFolder, folderIndex),
			}
		}

		/**
		 * Load file audit history
		 */
		case FileActions.ActionTypes.LOAD_AUDIT_HISTORY_REQUEST: {
			return {
				...state,
				isLoadingAuditHistory: true,
			}
		}

		case FileActions.ActionTypes.LOAD_AUDIT_HISTORY_SUCCESS: {
			const index = state.files.findIndex(t => t.file_id === action.payload.fildeId)

			if (index === -1) {
				return {
					...state,
					isLoadingAuditHistory: false,
				}
			}

			const newFile = {
				...state.files[index],
				auditHistory: [...action.payload.auditHistory],
			}

			return {
				...state,
				isLoadingAuditHistory: false,
				files: [...arrayUpdate(state.files, newFile, index)],
			}
		}

		// case FolderActions.ActionTypes.FOLDER_STATE_CHANGE: {
		// 	const index = state.projects.findIndex(t => t.folder_id === action.payload.folderId)
		// 	if (index === -1) {
		// 		return state
		// 	}

		// 	const oldFolder = state.projects[index]

		// 	if(oldFolder.isEmptyFolder === action.payload.isEmpty) {
		// 		return state
		// 	}

		// 	const newFolder = {
		// 		...oldFolder,
		// 		isEmptyFolder: action.payload.isEmpty
		// 	}
		// 	return {
		// 		...state,
		// 		projects: arrayUpdate(state.projects, newFolder, index)
		// 	}
		// }

		default: {
			return state
		}
	}
}
