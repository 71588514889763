import { Action } from '@ngrx/store'
import {
	FileUploadStep3,
	FolderWrapper,
	FolderCreate,
	FolderActionResult,
	DamFile,
	FileLink,
	SearchResult,
	Folder,
	ProjectMetadata,
	SearchFileResult,
	FileUpdate,
} from '@app/models'

export enum ActionTypes {
	LOAD_REQUEST = '[Folder] Load Request',
	LOAD_FAILURE = '[Folder] Load Failure',
	LOAD_SUCCESS = '[Folder] Load Success',
	RELOAD_SUCCESS = '[Folder] Reload Success',

	LOAD_FILE_LINK_REQUEST = '[Folder files] Load File Link Ruccess',
	LOAD_FILE_LINK_SUCCESS = '[Folder files] Load File Link Success',
	LOAD_FILE_LINK_FAILURE = '[Folder files] Load File Link Failure',

	LOAD_FILES_METADATA_REQUEST = '[Folder files] Load files metadata Request (search)',
	LOAD_FILES_METADATA_SUCCESS = '[Folder files] Load files metadata Success (search)',
	LOAD_FILES_METADATA_FAILURE = '[Folder files] Load files metadata Failure (search)',

	ADD_FILE_SUCCESS = '[Folder files] Add new file',

	CHANGE_FOLDER_REQUEST = '[Folder] Change folder', //validate

	UPLOAD_INSIDE_FOLDER_SUCCESS = '[Folder] Upload inside the folder sucess',

	CREATE_REQUEST = '[Folder] Create Request',
	CREATE_FAILURE = '[Folder] Create Failure',
	CREATE_SUCCESS = '[Folder] Create Success',

	UPDATE_REQUEST = '[Folder] Update Request',
	UPDATE_FAILURE = '[Folder] Update Failure',
	UPDATE_SUCCESS = '[Folder] Update Success',

	DELETE_UI_CONFIRMATION_REQUEST = '[Resource] UI Modal Delete Confirmation Request',
	DELETE_UI_CONFIRMATION_YES = '[Resource] UI Modal Delete Confirmation Request',
	DELETE_UI_CONFIRMATION_NO = '[Resource] UI Modal Delete Confirmation Request',

	SET_SELECTED_ITEM = '[Folder] Set selected item',

	LOAD_PROJECT_METADATA_REQUEST = '[Folder project] Load project metadata Request',
	LOAD_PROJECT_METADATA_FAILURE = '[Folder project] Load project metadata Failure',
	LOAD_PROJECT_METADATA_SUCCESS = '[Folder project] Load project metadata Success',

	UPDATE_FOLDER_METADATA = '[Folder project] Update folder metadata from UI',

	UPDATE_FOLDER_METADATA_FROM_SEARCH = '[Folder project] Update folder metadata from search',

	CLEAR_PROJECT_METADATA = '[Folder project] Clear project metadata',

	SET_SELECTED_ITEM_FROM_SEARCH = '[Folder] Set selected from search store',

	LOAD_PROJECT_FROM_STORAGE_REQUEST = '[Folder project] Load project from storage Request',
	LOAD_PROJECT_FROM_STORAGE_FAILURE = '[Folder project] Load project from storage Failure',
	LOAD_PROJECT_FROM_STORAGE_SUCCESS = '[Folder project] Load project from storage Success',
	LOAD_PROJECT_PAGE_FROM_STORAGE_REQUEST = '[Folder project] Load project with pagination from storage Request',

	FOLDER_STATE_CHANGE = '[Folder] Change if folder is empty or not',
}

export class UploadFileFolderSuccessAction implements Action {
	readonly type = ActionTypes.UPLOAD_INSIDE_FOLDER_SUCCESS
	constructor(public payload: { model: FileUploadStep3 }) {}
}

export class SetSelectedAction implements Action {
	readonly type = ActionTypes.SET_SELECTED_ITEM
	constructor(public payload: { item: any[] }) {}
}

export class SetSelectedFromSearchAction implements Action {
	readonly type = ActionTypes.SET_SELECTED_ITEM_FROM_SEARCH
	constructor(public payload: { item: any }) {}
}

export class LoadRequestAction implements Action {
	readonly type = ActionTypes.LOAD_REQUEST
	constructor(public payload: { repositoryId: string; folderId: string }) {}
}

export class LoadFailureAction implements Action {
	readonly type = ActionTypes.LOAD_FAILURE
	constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
	readonly type = ActionTypes.LOAD_SUCCESS
	constructor(public payload: { rootFolder: FolderWrapper }) {}
}

export class ReloadSuccessAction implements Action {
	readonly type = ActionTypes.RELOAD_SUCCESS
	constructor(public payload: { rootFolder: FolderWrapper }) {}
}

export class ChangeFolderRequestAction implements Action {
	readonly type = ActionTypes.CHANGE_FOLDER_REQUEST
	constructor(public payload: { folderId: string }) {}
}

export class CreateRequestAction implements Action {
	readonly type = ActionTypes.CREATE_REQUEST
	constructor(public payload: { folder: FolderCreate }) {}
}

export class CreateSuccessAction implements Action {
	readonly type = ActionTypes.CREATE_SUCCESS
	constructor(public payload: { folder: FolderActionResult }) {}
}

export class CreateFailureAction implements Action {
	readonly type = ActionTypes.CREATE_FAILURE
	constructor(public payload: { error: string }) {}
}

export class CreateProjectAction implements Action {
	readonly type = ActionTypes.CREATE_FAILURE
	constructor(public payload: { error: string }) {}
}

export class LoadFileLinkRequestAction implements Action {
	readonly type = ActionTypes.LOAD_FILE_LINK_REQUEST
	constructor(public payload: { files: DamFile[] }) {}
}

export class LoadFileLinkSuccessAction implements Action {
	readonly type = ActionTypes.LOAD_FILE_LINK_SUCCESS
	constructor(public payload: { file: FileLink }) {}
}

export class LoadFileLinkFailureAction implements Action {
	readonly type = ActionTypes.LOAD_FILE_LINK_FAILURE
	constructor(public payload: { error: string }) {}
}

export class LoadFileMetadataRequestAction implements Action {
	readonly type = ActionTypes.LOAD_FILES_METADATA_REQUEST
	constructor(public payload: { repositoryId: string; folderId: string }) {}
}

export class LoadFileMetadataSuccessAction implements Action {
	readonly type = ActionTypes.LOAD_FILES_METADATA_SUCCESS
	constructor(public payload: { data: SearchResult }) {}
}

export class LoadFileMetadataFailureAction implements Action {
	readonly type = ActionTypes.LOAD_FILES_METADATA_FAILURE
	constructor(public payload: { error: string }) {}
}

export class UpdateRequestAction implements Action {
	readonly type = ActionTypes.UPDATE_REQUEST
	constructor(public payload: { folder: Folder }) {}
}

export class UpdateSuccessAction implements Action {
	readonly type = ActionTypes.UPDATE_SUCCESS
	constructor(public payload: { folder: Folder }) {}
}

export class UpdateFailureAction implements Action {
	readonly type = ActionTypes.UPDATE_FAILURE
	constructor(public payload: { error: string }) {}
}

export class AddFileSuccessAction implements Action {
	readonly type = ActionTypes.ADD_FILE_SUCCESS
	constructor(public payload: { file: DamFile }) {}
}

export class LoadProjectMetadataRequestAction implements Action {
	readonly type = ActionTypes.LOAD_PROJECT_METADATA_REQUEST
	constructor(public payload: { repositoryId: string; folderId: string }) {}
}

export class LoadProjectMetadataFailureAction implements Action {
	readonly type = ActionTypes.LOAD_PROJECT_METADATA_FAILURE
	constructor(public payload: { error: string }) {}
}

export class LoadProjectMetadataSuccessAction implements Action {
	readonly type = ActionTypes.LOAD_PROJECT_METADATA_SUCCESS
	constructor(public payload: { data: ProjectMetadata }) {}
}

export class ClearProjectMetadataAction implements Action {
	readonly type = ActionTypes.CLEAR_PROJECT_METADATA
}

export class UpdateFolderMetadataFromSearch implements Action {
	readonly type = ActionTypes.UPDATE_FOLDER_METADATA_FROM_SEARCH
	constructor(public payload: { data: Array<SearchFileResult> }) {}
}

export class UpdateFolderMetadata implements Action {
	readonly type = ActionTypes.UPDATE_FOLDER_METADATA
	constructor(public payload: { data: FileUpdate }) {}
}

export class LoadProjectFromStorageRequestAction implements Action {
	readonly type = ActionTypes.LOAD_PROJECT_FROM_STORAGE_REQUEST
	constructor(public payload: { setFolders: boolean; disablePagination?: boolean }) {}
}

export class LoadProjectFromStorageSuccessAction implements Action {
	readonly type = ActionTypes.LOAD_PROJECT_FROM_STORAGE_SUCCESS
	constructor(public payload: { rootFolder: FolderWrapper; setFolders: boolean }) {}
}

export class LoadProjectFromStorageFailureAction implements Action {
	readonly type = ActionTypes.LOAD_PROJECT_FROM_STORAGE_FAILURE
	constructor(public payload: { error: string }) {}
}

export class FolderStateChangeAction implements Action {
	readonly type = ActionTypes.FOLDER_STATE_CHANGE
	constructor(public payload: { folderId: string; isEmpty?: boolean; verify?: boolean }) {}
}

export class LoadProjectPageAction implements Action {
	readonly type = ActionTypes.LOAD_PROJECT_PAGE_FROM_STORAGE_REQUEST
}
