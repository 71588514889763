import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core'

@Component({
	selector: 'dam-search-no-results',
	templateUrl: './search-no-results.component.html',
	styleUrls: ['./search-no-results.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchNoResultsComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
