import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { Action } from '@ngrx/store'
import { Observable } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { SocialAuthService, GoogleLoginProvider } from 'angularx-social-login'
import { UserService } from '../../services/user.service'
import { LayoutStoreActions } from '../layout-store'

import * as featureActions from './actions'

@Injectable()
export class UserStoreEffects {
	constructor(private actions$: Actions, private authService: SocialAuthService, private userService: UserService) {}

	@Effect({ dispatch: false })
	loginRequestEffect$ = this.actions$.pipe(
		ofType<featureActions.LoginRequestAction>(featureActions.ActionTypes.LOGIN_REQUEST),
		switchMap(action =>
			this.authService.signIn(GoogleLoginProvider.PROVIDER_ID, {
				ux_mode: 'redirect',
				redirect_uri: window.location.origin,
			}),
		),
		// TODO:// note, esto no se ejecuta nunca ? parece que esta usando siempre el de canActivate
		switchMap(action => {
			return this.authService.authState.pipe(
				map(user => (user ? new featureActions.LoginSuccessAction({ user }) : new featureActions.LoginFailureAction({ error: 'error' }))),
				catchError(error => [
					new LayoutStoreActions.ShowAlertToast({ message: 'An error has occurred while logging in' }),
					new featureActions.LoginFailureAction({ error }),
				]),
			)
		}),
	)

	@Effect()
	userIsAdminEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.LoadPermissionsRequestAction>(featureActions.ActionTypes.LOAD_PERMISSIONS_REQUEST),
		switchMap(action =>
			this.userService.getPermissions().pipe(
				map(value => new featureActions.LoadPermissionsSuccessAction({ value })),
				catchError(error => [
					new LayoutStoreActions.ShowAlertToast({ message: 'An error has occurred while loading permissions' }),
					new featureActions.LoadPermissionsFailureAction({ error }),
				]),
			),
		),
	)
}
