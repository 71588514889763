import { Injectable, Inject } from '@angular/core'
import { environment } from 'src/environments/environment'
import { ApmTag } from '../models/apm-tags.model'
import { User } from '@app/models'
import { ApmService } from '@elastic/apm-rum-angular'
// import { ApmService } from './apm-service'

@Injectable({
	providedIn: 'root',
})
export class CustomApmService {
	private apm: any
	private assetCount: number = 0
	private transactions = new Map<string, any>()
	private transactionQueue = new Map<string, any>()

	constructor(apmService: ApmService) {
		this.apm = apmService.init({
			serviceName: environment.apmServiceName,
			serverUrl: environment.apmServer,
			serviceVersion: environment.version,
			environment: environment.production ? 'production' : 'development',
		})

		this.apm.addFilter(function(payload) {
			if (payload.transactions) {
				payload.transactions.forEach(function(tr) {
					if (tr.type == 'page-load') {
						tr.spans = []
					}
				})
			}
			return payload
		})
	}

	captureError(name: string, type: string, error: Error, isAssetTx: boolean = false) {
		this.apm.captureError(error)
		this.endTransaction(name, type, isAssetTx)
	}

	startTransaction(name: string, type: string, payload = {}) {
		try {
			let txKey = `${name}-${type}`
			let txContext = this.getTransaction(name, type)
			let tx, tags: ApmTag

			if (!txContext) {
				tx = this.apm.startTransaction(name, type, {
					managed: false,
				})
				tx._scheduledTasks = ['tx']
				tags = new ApmTag()
				this.transactions.set(txKey, { tx, tags })
			} else {
				tx = txContext.tx
				tags = txContext.tags
			}

			let newTags = this.makePayload(payload, txKey, tags)
			tx.addLabels(newTags)

			//update tags
			this.transactions.set(txKey, { tx, tags: newTags })
		} catch (error) {
			this.captureError(name, type, error)
		}
	}

	endTransaction(name: string, type: string, isAssetTx: boolean = false) {
		try {
			let txContext = this.getTransaction(name, type)
			if (!txContext) {
				return
			}

			if (isAssetTx) {
				this.assetCount = this.assetCount - 1
			}

			if (!isAssetTx) {
				txContext.tx.end()
				this.deleteTransaction(name, type)
			} else if (this.assetCount == 0 && isAssetTx) {
				txContext.tx._scheduledTasks = []

				txContext.tx.end()
				this.deleteTransaction(name, type)
			}
		} catch (error) {
			this.apm.captureError(error)
		}
	}

	addAsset() {
		this.assetCount = this.assetCount + 1
	}

	setUser(user: User) {
		if (user) this.apm.setUserContext({ username: user.name, email: user.email })
		else this.apm.setUserContext({ username: ' ', email: ' ' })
	}

	private makePayload(payload, type: string, tags: ApmTag) {
		Object.keys(tags).forEach(function(key) {
			if (payload.hasOwnProperty(key)) {
				tags[key] = tags[key] + `${type}-${payload[key]}; `
			}
		})
		return tags
	}

	private getTransaction(name: string, type: string) {
		return this.transactions.get(`${name}-${type}`)
	}

	private deleteTransaction(name: string, type: string) {
		this.transactions.delete(`${name}-${type}`)
	}
}
