<div class="asset-list workfront">
  <div class="asset-navigation wf">
    <dam-search class="navigation-search" (onSearchInputChange)="onSearchInputChange($event)" (onClearSearch)="searchFilter=''"></dam-search>
    <div class="actions-buttons">
      <button mat-button (click)="refresh()">Refresh</button>
      <!--/* <button mat-button (click)="generateStorageProjectsFile()" style="display: none;" >Generate projects</button> */-->
      <button mat-button (click)="generateStorageProjectsFile()"  >Generate projects</button>
      <a class="navigation-user" mat-button (click)="workfrontSignOut()">C3 sign out</a>
    </div>
  </div>
  <div class="asset-list-header">
    <span class="asset-list-header-item">Name</span>
    <span class="asset-list-header-item">Reference #</span>
    <span class="asset-list-header-item">Status</span>
    <span class="asset-list-header-item">Entry</span>
    <span class="asset-list-header-item">Deadline</span>
    <span class="asset-list-header-item"></span>
  </div>
  <div class="asset-item-container">
    <div class="asset-item" *ngFor="let project of projects | filter: searchFilter : 'name'" [class.new]="!project.isCreated">
      <div class="asset-item-name">
        <a href="{{wfUrl}}{{project.workfront_id}}" target="_blank" rel="noopener noreferrer">{{project.name}}</a>
      </div>
      <div>
        {{getMetadata(project,'Reference number')}}
      </div>
      <div class="asset-item-update text-ellipsis">
        {{getMetadata(project,'Status')}}
      </div>
      <div class="asset-item-update text-ellipsis">
        {{getMetadata(project,'Entry date') | date}}
      </div>
      <div class="asset-item-update text-ellipsis">
        {{getMetadata(project,'Deadline') | date}}
      </div>
      <div class="m-auto">
        <button (click)="onAddProject.emit(project)" class="button add-new-btn" *ngIf="!project.isBusy && !project.isCreated"
          matTooltip="Add project">
          <img alt="Add" src="../../../assets/images/icons/plus.svg">
        </button>
        <button (click)="onSyncProject.emit(project)" class="button refresh-btn" *ngIf="!project.isBusy && project.isCreated"
          matTooltip="Sync project">
          <img alt="Add" src="../../../assets/images/icons/refresh.svg">
        </button>
        <button (click)="linkProject(project)" class="button refresh-btn" *ngIf="!project.isBusy && project.isCreated"
          matTooltip="Link project">
          <img alt="Add" src="../../../assets/images/icons/link-variant-white.svg">
        </button>
        <div class="loader" *ngIf="project.isBusy">Loading...</div>
      </div>
    </div>
  </div>
</div>