<header class="modal-header">
  <h2 class="modal-title">
    Rename
  </h2>
  <button class="modal-header-btn modal-close" type="button" (click)="onCancel.emit()">
    <img src="../../../assets/images/icons/close.svg" alt="Close" title="Close">
  </button>
</header>
<div class="modal-content">
  <form class="new-project-form form" (ngSubmit)="onSubmit.emit(name)" #frm="ngForm" autocomplete="off" novalidate>
    <div class="form-group">
      <label class="form-label">
        Please enter a new name for the item:
        <input class="form-input" type="text" placeholder="Folder name" name="rename" [(ngModel)]="name" #rename="ngModel"
          required>
      </label>
    </div>
    <div class="form-actions modal-footer">
      <button class="modal-close form-btn" mat-button type="button" (click)="onCancel.emit()">CANCEL</button>
      <button class="mat-button form-btn" mat-button [disabled]="!frm.form.valid" type="submit">OK</button>
    </div>
  </form>
</div>