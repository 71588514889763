import { Actions, ActionTypes } from './actions'
import { initialState, State } from './state'

export function featureReducer(state = initialState, action: Actions): State {
	switch (action.type) {
		/***
		 * Modal actions
		 */
		case ActionTypes.SORT_FIELD_CHANGE: {
			let sortFields = state.sortFields
			if (state.sortField == action.payload.field) {
				sortFields[action.payload.field] = !state.sortFields[action.payload.field]
			}

			return {
				...state,
				sortField: action.payload.field || 'name',
				sortDirection: sortFields[action.payload.field || 'name'],
				sortFields: sortFields,
			}
		}
		case ActionTypes.SORT_DIRECTION_CHANGE: {
			return {
				...state,
				sortDirection: !state.sortDirection,
			}
		}
		case ActionTypes.VIEW_CHANGE: {
			return {
				...state,
				view: action.payload.view,
			}
		}
		case ActionTypes.SHOW_DETAIL_SIDENAV: {
			return {
				...state,
				showDetailSidenav: action.payload.visible,
			}
		}
		case ActionTypes.SET_SIDENAV_ACTIVE_TAB: {
			return {
				...state,
				detailSidenavActiveTab: action.payload.tab,
			}
		}
		case ActionTypes.SET_BOTTOM_SIDENAV: {
			return {
				...state,
				showBottomSidenav: action.payload.visible,
			}
		}
		case ActionTypes.TOGGLE_BOTTOM_SIDENAV: {
			return {
				...state,
				showBottomSidenav: !state.showBottomSidenav,
			}
		}
		case ActionTypes.SET_BOTTOM_SIDENAV_ACTIVE_TAB: {
			return {
				...state,
				bottomSidenavActiveTab: action.payload.tab,
			}
		}
		case ActionTypes.TABLE_SET_PAGINATION: {
			return {
				...state,
				tableCount: action.payload.count,
			}
		}
		case ActionTypes.TABLE_CHANGE_PAGE: {
			return {
				...state,
				tableLimit: action.payload.limit ?? state.tableLimit,
				tablePage: action.payload.page ?? state.tablePage,
				tableCount: action.payload.count ?? state.tableCount,
			}
		}
		default: {
			return state
		}
	}
}
