import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import {
	RootStoreState,
	UserStoreSelectors,
	UserStoreActions,
	SearchStoreActions,
	LayoutStoreActions,
	FolderStoreSelectors,
	SearchStoreSelectors,
} from '@app/root-store'
import { Observable, Subject } from 'rxjs'
import { Router, ActivatedRoute } from '@angular/router'
import { takeUntil } from 'rxjs/operators'
import { environment } from '@environment/environment'
import { CustomApmService, RequestCacheService } from '@app/services'
import { User, DamContext } from '@app/models'

@Component({
	selector: 'dam-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.css'],
})
export class MainComponent implements OnInit {
	destroy$ = new Subject()
	user$: Observable<User>
	isAdminUser$: Observable<boolean>
	isSearchEnabled$: Observable<boolean>
	damContext: DamContext

	constructor(
		private store$: Store<RootStoreState.State>,
		private route: ActivatedRoute,
		private apmService: CustomApmService,
		private cacheService: RequestCacheService,
	) {}

	ngOnDestroy(): void {
		this.destroy$.next(true)
	}

	ngOnInit() {
		this.cacheService.validateVersion()
		this.user$ = this.store$.select(UserStoreSelectors.selectUser)
		this.isAdminUser$ = this.store$.select(UserStoreSelectors.selectIsAdmin)
		this.store$.dispatch(new UserStoreActions.LoadPermissionsRequestAction())
		this.isSearchEnabled$ = this.store$.select(SearchStoreSelectors.isSearchEnabled)

		this.store$
			.select(FolderStoreSelectors.selectCurrentFolder)
			.pipe(takeUntil(this.destroy$))
			.subscribe(t => {
				this.damContext = {
					folder_type: !t.folder_id ? 'repository' : 'folder',
					folder_id: t.folder_id,
					repo_id: environment.projectRepositoryId,
				}
			})

		this.user$.pipe(takeUntil(this.destroy$)).subscribe(x => this.apmService.setUser(x))
	}

	onSearch() {
		this.store$.dispatch(
			new SearchStoreActions.AddContextContentAction({
				repositoryId: this.damContext.repo_id,
				folderId: this.damContext.folder_id,
			}),
		)

		this.store$.dispatch(new SearchStoreActions.SearchFolderRequestAction())
		this.store$.dispatch(new SearchStoreActions.SearchRequestAction())
	}

	onSearchInputChange(value: string) {
		this.store$.dispatch(new SearchStoreActions.AddQueryContentAction({ value }))
	}

	onClearSearch() {
		this.store$.dispatch(new SearchStoreActions.ClearAction())
		this.store$.dispatch(new LayoutStoreActions.ShowDetailSidenav({ visible: false }))
	}

	onSearchInputError() {
		this.store$.dispatch(new LayoutStoreActions.ShowAlertToast({ message: 'Please insert at least 3 characters' }))
	}
}
