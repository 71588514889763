<header class="modal-header">
  <h2 class="modal-title">
    Create New Folder
  </h2>
  <button class="modal-header-btn modal-close" type="button" (click)="cancel()">
    <img src="../../../assets/images/icons/close.svg" alt="Close" title="Close">
  </button>
</header>
<div class="modal-content">
  <form class="new-project-form form" (ngSubmit)="submit()" #folderFrm="ngForm" autocomplete="off" novalidate>
    <div class="form-group">
      <label class="form-label">
        Folder Name
        <input class="form-input" type="text" placeholder="Write name" name="name"
        (input)="isValidFolderName" 
        [(ngModel)]="model.folder_name" 
        #name="ngModel"
        required>
      </label>
    </div>
    <div class="form-label-error" *ngIf="!isValidFolderName">
      Folder name already exists
    </div>
    <div class="form-actions modal-footer">
      <button class="form-btn modal-close" mat-button type="button" (click)="cancel()">CANCEL</button>
      <button class="form-btn" type="submit" mat-button [disabled]="!folderFrm.form.valid || !isValidFolderName">CREATE</button>
    </div>
  </form>
</div>
