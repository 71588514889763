import { Injectable, ErrorHandler } from '@angular/core'
import { CustomApmService } from '../services'

@Injectable()
export class ErrorsHandler implements ErrorHandler {
	constructor(private apmService: CustomApmService) {}

	handleError(error: Error) {
		this.apmService.captureError('GLOBAL', 'GLOBAL', error)
	}
}
