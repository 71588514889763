import { Repository, RepositoryWrapper } from '@app/models'

export interface State extends RepositoryWrapper {
	isBusy: boolean
}

export const initialState: State = {
	repo: null,
	repoConfiguration: null,
	isBusy: false,
}
