import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core'
import { environment } from '../../../environments/environment'
import { AuditHistory, Metadata } from '@app/models'

@Component({
	selector: 'dam-detail-sidebar',
	templateUrl: './detail-sidebar.component.html',
	styleUrls: ['./detail-sidebar.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailSidebarComponent implements OnInit {
	@Input()
	activeTab: string

	@Input()
	type: string = 'folder'

	@Input()
	isLoading: boolean

	@Input()
	isActivityLoading: boolean

	@Input()
	activities: Array<AuditHistory> = []

	@Input()
	model: any

	details: Array<Metadata> = []
	metadataWorkfrontId = environment.metadata.workfrontId.name.toLowerCase()
	metadataParentProject = environment.metadata.parentProject.name.toLowerCase()
	wfUrl = environment.workfrontSiteUrl + '/project/view?ID='
	repoRootName = environment.repoRootName

	@Input('details')
	set setDetails(value: any) {
		if (!value) {
			return
		}

		if (this.type === 'folder') {
			this.details = !value ? [] : this.metadataFormat(value)
		} else {
			value.file_id = null // Hack to show folder icon
			this.details = this.metadataFormat(value.property)
			this.model = value
		}
	}
	metadataFormat(metadata: Array<Metadata>) {
		if (!metadata) {
			return []
		}

		return metadata
			.map(t => {
				let metadata = { ...t }
				if (metadata.type === 'Date') {
					metadata.value = new Date(metadata.value)
				} else if (metadata.name === environment.metadata.projectUsers.name && metadata.value) {
					const users = JSON.parse(metadata.value)
					metadata.value = users.map(t => t.name)
				}
				return metadata
			})
			.filter(t => {
				return !environment.metadataHidden.some(h => t.name.toLowerCase() === h.toLowerCase())
			})
	}

	@Output()
	onVisible: EventEmitter<any> = new EventEmitter()

	@Output()
	onLoadActivity: EventEmitter<any> = new EventEmitter()

	@Output()
	onOpenTab: EventEmitter<any> = new EventEmitter()

	@Output()
	onFolderPathClick: EventEmitter<string> = new EventEmitter()

	constructor() {}

	ngOnInit() {}

	openTab(selected) {
		this.onOpenTab.emit({ tab: selected, fileId: this.model.file_id })
	}

	closeSidebar() {
		this.onVisible.emit(false)
	}

	navigateToFilePath(folderId) {
		this.onFolderPathClick.emit(folderId)
	}
}
