import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { map, tap } from 'rxjs/operators'
import { Observable, of } from 'rxjs'
import { UserPermissions } from '../models/permission.enum'
import { RequestCacheService } from './request-cache.service'

@Injectable({
	providedIn: 'root',
})
export class UserService {
	private userPerissions: UserPermissions

	constructor(private http: HttpClient, private cache: RequestCacheService) {}

	getPermissions(): Observable<UserPermissions> {
		const payload = { repo_id: environment.projectRepositoryId }

		if (this.userPerissions) {
			return of(this.userPerissions)
		}

		// return this.http.post<any>(`${environment.apiBaseUrl}${environment.userPermissions}?key=${environment.apiKey}`, payload).pipe(
		// 	map(t => t.permission),
		// 	tap(t => (this.userPerissions = t)),
		// )

		return this.http.post<any>(`${environment.apiBaseUrl}${environment.userPermissions}`, payload).pipe(
			map(t => t.permission),
			tap(t => (this.userPerissions = t)),
		)
	}

	setReturnUrl(url: string) {
		const validPaths = ['/projects/folders/', '/workfront']
		if (url && validPaths.findIndex(t => url.match(t)) !== -1) {
			this.cache.put('returnUrl', [url], true)
		} else {
			this.expireReturnUrl()
		}
	}

	expireReturnUrl() {
		return this.cache.expire('returnUrl')
	}

	getReturnUrl() {
		return this.cache.get('returnUrl')
	}
}
