import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { map, tap } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { CustomApmService } from './apm.service'
import { RepositorySchema, RepositorySchemaWrapper, SearchCriteria, SearchResult, RepositoryWrapper, Repository, User } from '@app/models'

@Injectable({
	providedIn: 'root',
})
export class RepositoryService {
	constructor(private http: HttpClient, private apmService: CustomApmService) {}

	get(repositoryId: string): Observable<RepositoryWrapper> {
		return this.http.get<RepositoryWrapper>(
			`${environment.apiBaseUrl}${environment.repositoryGet}?repo_id=${repositoryId}&key=${environment.apiKey}`,
		)
	}

	lookup(query: string, repositoryId: string): Observable<User[]> {
		return this.http
			.get(`${environment.apiBaseUrl}${environment.userLookup}?query=${query}&repo_id=${repositoryId}&key=${environment.apiKey}`)
			.pipe(map((t: any) => t.users))
	}

	update(repository: RepositoryWrapper): Observable<any> {
		const payload = {
			repo: repository.repo,
			repoConfiguration: repository.repoConfiguration,
			repo_id: environment.projectRepositoryId,
		}
		return this.http.put(`${environment.apiBaseUrl}${environment.repositoryUpdate}?key=${environment.apiKey}`, repository.repo)
	}

	getMetadataSchema(repositoryId: string): Observable<Array<RepositorySchema>> {
		return this.http
			.get<RepositorySchemaWrapper>(`${environment.repositoryApiBaseUrl}${environment.repositoryGetMetadata}?repo_id=${repositoryId}`)
			.pipe(
				map(t => t.file_properties),
				tap(t => {
					console.log('[RepositoryService]: Metadata', t)
				}),
			)
	}

	search(searchCriteria: SearchCriteria): Observable<SearchResult> {
		if (searchCriteria.query_content) {
			const apmPayload = {
				query: searchCriteria.query_content,
			}
			this.apmService.startTransaction('SEARCH', 'SEARCH', apmPayload)
		}
		return this.http.post<SearchResult>(`${environment.searchApiBaseUrl}${environment.searchAllList}`, searchCriteria)
	}

	searchFolders(searchCriteria: SearchCriteria): Observable<SearchResult> {
		return this.http.post<SearchResult>(`${environment.searchApiBaseUrl}${environment.searchFolders}`, searchCriteria)
	}
}
