import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store'
import { State } from './state'

export const selectLayoutState: MemoizedSelector<object, State> = createFeatureSelector<State>('layout')
export const selectSortField = createSelector(selectLayoutState, (state: State) => state.sortField)
export const selectSortDirection = createSelector(selectLayoutState, (state: State) => state.sortDirection)
export const selectView = createSelector(selectLayoutState, (state: State) => state.view)
export const selectDetailSidenav = createSelector(selectLayoutState, (state: State) => state.showDetailSidenav)
export const selectDetailSidenavActiveTab = createSelector(selectLayoutState, (state: State) => state.detailSidenavActiveTab)
export const selectBottomSidenavIsMinimized = createSelector(selectLayoutState, (state: State) => !state.showBottomSidenav)
export const selectBottomSidenavActiveTab = createSelector(selectLayoutState, (state: State) => state.bottomSidenavActiveTab)
export const selectTablePage = createSelector(selectLayoutState, (state: State) => state.tablePage)
export const selectTableLimit = createSelector(selectLayoutState, (state: State) => state.tableLimit)
export const selectTableCount = createSelector(selectLayoutState, (state: State) => state.tableCount)
