import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core'
import { ModalService } from 'src/app/services'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
	selector: 'dam-duplicate-folder-modal',
	templateUrl: './duplicate-folder-modal.component.html',
	styleUrls: ['./duplicate-folder-modal.component.css'],
})
export class DuplicateFolderModalComponent implements OnInit, OnDestroy {
	destroy$ = new Subject()

	@Output()
	onRename: EventEmitter<any> = new EventEmitter()

	@Output()
	onReplace: EventEmitter<any> = new EventEmitter()

	@Output()
	onCancel: EventEmitter<any> = new EventEmitter()

	name: string

	folders: any[]

	constructor(private modalService: ModalService) {}

	ngOnInit() {
		this.modalService
			.getContext()
			.pipe(takeUntil(this.destroy$))
			.subscribe(t => {
				this.folders = t.model && t.model.model && t.model.model.duplicated
			})
	}

	ngOnDestroy(): void {
		this.destroy$.next(true)
	}
}
