import { Actions, Effect, createEffect, ofType } from '@ngrx/effects'
import { Injectable } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { Router } from '@angular/router'
import { tap, map, withLatestFrom, filter } from 'rxjs/operators'
import * as assetsActions from '../folder-store/actions'
import * as userActions from '../user-store/actions'
import * as uploadActions from '../upload-store/actions'
import * as layoutActions from '../layout-store/actions'
import * as searchActions from '../search-store/actions'
import * as workfrontActions from '../workfront-store/actions'
import * as routeActions from '../route-store/actions'
import * as breadcrumbActions from '../breadcrumb-store/actions'
import * as searchStoreSelectors from '../search-store/selectors'
import * as breadcrumbSelectors from '../breadcrumb-store/selectors'
import { GoogleAnalyticsService } from '../../services'
import { environment } from '../../../environments/environment'
import { NavigationEnd } from '@angular/router'

@Injectable()
export class GoogleAnalyticsEffects {
	constructor(
		private googleAnalyticsService: GoogleAnalyticsService,
		private actions$: Actions,
		private store: Store<any>,
		private router: Router,
	) {}

	/**
	 * Assets
	 */
	@Effect({ dispatch: false })
	createFolder$ = this.actions$.pipe(
		ofType(assetsActions.FolderActions.ActionTypes.CREATE_REQUEST),
		//map((action: RouteActions.Go) => action.payload),
		tap(() => this.googleAnalyticsService.trackEvent('create-folder', environment.GACategories.asset)),
	)

	@Effect({ dispatch: false })
	deleteAsset$ = this.actions$.pipe(
		ofType(assetsActions.FileActions.ActionTypes.FILE_DELETE_REQUEST),
		tap(() => this.googleAnalyticsService.trackEvent('delete-asset', environment.GACategories.asset)),
	)

	@Effect({ dispatch: false })
	moveFile$ = this.actions$.pipe(
		ofType(assetsActions.FileActions.ActionTypes.MOVE_TO_REQUEST),
		tap(() => this.googleAnalyticsService.trackEvent('move-asset', environment.GACategories.asset)),
	)

	@Effect({ dispatch: false })
	downloadFile$ = this.actions$.pipe(
		ofType(assetsActions.FileActions.ActionTypes.FILE_DOWNLOAD_REQUEST),
		map((action: assetsActions.FileActions.DownloadRequestAction) => action.payload),
		tap(action => this.googleAnalyticsService.trackEvent('download-asset', environment.GACategories.asset, action.file.file_id)),
	)

	@Effect({ dispatch: false })
	uploadFile$ = this.actions$.pipe(
		ofType(uploadActions.ActionTypes.UPLOAD_1_REQUEST),
		map((action: uploadActions.UploadStep1RequestAction) => action.payload),
		tap(action => this.googleAnalyticsService.trackEvent('upload-asset', environment.GACategories.asset, action.file.size)),
	)

	@Effect({ dispatch: false })
	switchView$ = this.actions$.pipe(
		ofType(layoutActions.ActionTypes.VIEW_CHANGE),
		tap(() => this.googleAnalyticsService.trackEvent('switch-view', environment.GACategories.asset)),
	)

	/**
	 * Global
	 */
	@Effect({ dispatch: false })
	pageView$ = this.actions$.pipe(
		ofType(breadcrumbActions.ActionTypes.LOAD_SUCCESS),
		map((action: breadcrumbActions.LoadSuccessAction) => action.payload),
		tap(action => {
			const pageUrl = this.router.url

			if (action.items.length > 0) {
				const path = action.items.map(b => b.label).join('/')
				this.googleAnalyticsService.trackPageView(pageUrl, path)
			} else {
				this.googleAnalyticsService.trackPageView(pageUrl)
			}
		}),
	)

	@Effect({ dispatch: false })
	userLogin$ = this.actions$.pipe(
		ofType(userActions.ActionTypes.LOGIN_SUCCESS),
		map((action: userActions.LoginSuccessAction) => action.payload),
		tap(action => this.googleAnalyticsService.trackEvent('login', environment.GACategories.global, action.user.email)),
	)

	@Effect({ dispatch: false })
	userLogOut$ = this.actions$.pipe(
		ofType(userActions.ActionTypes.LOGOUT_SUCCESS),
		map((action: userActions.LogoutSuccessAction) => action.payload),
		tap(action => this.googleAnalyticsService.trackEvent('logout', environment.GACategories.global, action.user.email)),
	)

	@Effect({ dispatch: false })
	search$ = this.actions$.pipe(
		ofType(searchActions.ActionTypes.SEARCH_REQUEST),
		withLatestFrom(this.store.pipe(select(searchStoreSelectors.selectSearchCriteria))),
		tap(([action, search]) => this.googleAnalyticsService.trackEvent('search', environment.GACategories.global, search.query_content)),
	)

	/**
	 * Filters
	 */
	@Effect({ dispatch: false })
	myProjectsFilter$ = this.actions$.pipe(
		ofType(searchActions.ActionTypes.SET_FILTER_MY_PROJECTS),
		tap(() => this.googleAnalyticsService.trackEvent('my-project-filter', environment.GACategories.filters)),
	)

	@Effect({ dispatch: false })
	activeFilter$ = this.actions$.pipe(
		ofType(searchActions.ActionTypes.SET_FILTER_ACTIVE_PROJECTS),
		tap(() => this.googleAnalyticsService.trackEvent('active-project-filter', environment.GACategories.filters)),
	)

	@Effect({ dispatch: false })
	losFilter$ = this.actions$.pipe(
		ofType(searchActions.ActionTypes.SET_FILTER_LOS),
		map((action: searchActions.SetFilterLoSAction) => action.payload),
		tap(({ value }) => this.googleAnalyticsService.trackEvent('los-filter', environment.GACategories.filters, value)),
	)

	@Effect({ dispatch: false })
	buFilter$ = this.actions$.pipe(
		ofType(searchActions.ActionTypes.SET_FILTER_BUSINESS_UNIT),
		map((action: searchActions.SetFilterLoSAction) => action.payload),
		tap(({ value }) => this.googleAnalyticsService.trackEvent('bu-filter', environment.GACategories.filters, value)),
	)

	/**
	 * Workfront
	 */
	@Effect({ dispatch: false })
	loadProjects$ = this.actions$.pipe(
		ofType(workfrontActions.ActionTypes.LOAD_WF_PROJECTS),
		tap(() => this.googleAnalyticsService.trackEvent('get-workfront-projects', environment.GACategories.workfront)),
	)

	@Effect({ dispatch: false })
	createProject$ = this.actions$.pipe(
		ofType(workfrontActions.ActionTypes.CREATE_PROJECT_REQUEST),
		tap(() => this.googleAnalyticsService.trackEvent('add-workfront-project', environment.GACategories.workfront)),
	)

	@Effect({ dispatch: false })
	syncProjects$ = this.actions$.pipe(
		ofType(workfrontActions.ActionTypes.SYNC_PROJECTS_REQUEST),
		tap(() => this.googleAnalyticsService.trackEvent('sync-workfront-projects', environment.GACategories.workfront)),
	)
}
