import { Component, OnInit, OnDestroy } from '@angular/core'
import { RootStoreState, UserStoreActions, RouteActions } from '../../root-store'
import { Store } from '@ngrx/store'
import { SocialAuthService } from 'angularx-social-login'
import { ActivatedRoute } from '@angular/router'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { UserService } from '../../services/user.service'
import { ApiGeeService } from '@app/services/api-gee.service'

declare const window: any

@Component({
	selector: 'dam-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, OnDestroy {
	destroy$ = new Subject()

	constructor(
		private store$: Store<RootStoreState.State>,
		private authService: SocialAuthService,
		private route: ActivatedRoute,
		private userService: UserService,
		private apiGeeService: ApiGeeService,
	) {}

	ngOnInit() {
		this.route.queryParamMap.pipe(takeUntil(this.destroy$)).subscribe(t => {
			this.userService.setReturnUrl(t.get('returnUrl'))
		})
	}

	signInWithGoogle(): void {
		this.store$.dispatch(new UserStoreActions.LoginRequestAction())
	}

	ngOnDestroy(): void {
		this.destroy$.next(true)
	}
}
