import { Component, OnInit, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core'
import { ModalService } from '../../services'

@Component({
	selector: 'dam-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.css'],
})
export class ModalComponent implements OnInit, OnDestroy {
	@Input() id: string
	element: any
	isVisible: boolean = false

	constructor(private modalService: ModalService, private el: ElementRef, private renderer: Renderer2) {
		this.element = el.nativeElement
	}

	ngOnInit() {
		if (!this.id) {
			console.error('Modal must have an Id')
			return
		}

		this.element.style.display = 'none'
		this.modalService.add(this)
	}

	open(): void {
		this.isVisible = true
		this.element.style.display = 'flex'
		this.renderer.addClass(document.body, 'modal-open')
	}

	close(): void {
		this.isVisible = false
		this.element.style.display = 'none'
		this.renderer.removeClass(document.body, 'modal-open')
	}

	ngOnDestroy(): void {
		this.close()
	}
}
