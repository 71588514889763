import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router'
import { Observable, of } from 'rxjs'
import { UserService } from '../../../services'
import { switchMap } from 'rxjs/operators'
import { UserPermissions } from '@app/models'

@Injectable({
	providedIn: 'root',
})
export class AdminGuard implements CanActivate {
	constructor(private router: Router, private userService: UserService, private route: ActivatedRoute) {}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		return this.userService.getPermissions().pipe(
			switchMap(t => {
				const permission = t as UserPermissions

				if (permission === UserPermissions.admins || permission === UserPermissions.fullAccess) {
					return of(true)
				}

				this.router.navigate(['/projects'])
				return of(false)
			}),
		)
	}
}
