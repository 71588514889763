import { Component, Inject, OnInit } from '@angular/core'
import { environment } from 'src/environments/environment'

@Component({
	selector: 'dam-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
	constructor() {}

	version: string = environment.version
	ngOnInit() {}
}
