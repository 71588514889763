import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store'
import { State } from './state'
import { environment } from '../../../environments/environment'
import {
	selectSearchFiles,
	isSearchActive,
	selectMyProjectsFilter,
	selectActiveProjectsFilter,
	selectSearchFolders,
} from '../search-store/selectors'
import { getMetadataBase, addMetadataToSearchFoldersResult } from 'src/app/common/helpers'
import { selectUser } from '../user-store/selectors'
import { DamFile, Folder } from '@app/models'

export const getError = (state: State): any => state.error
export const getIsLoading = (state: State): boolean => state.isLoading
export const getProjectMetadataIsLoading = (state: State): boolean => state.projectMetadataIsLoading
export const getIsAuditHistoryLoading = (state: State): boolean => state.isLoadingAuditHistory
export const selectFolderState: MemoizedSelector<object, State> = createFeatureSelector<State>('folders')

export const selectFolders = createSelector(
	selectFolderState,
	isSearchActive,
	selectSearchFolders,
	selectMyProjectsFilter,
	selectActiveProjectsFilter,
	selectUser,
	(state: State, isSearchActive, searchFolders, selectMyProjectsFilter, selectActiveProjectsFilter, user) => {
		if (isSearchActive || !user) {
			return searchFolders
		}

		let folders = state.folders

		/**
		 * If current folder is repository, apply the projects filters
		 */
		if (!state.currentFolder.folder_id) {
			folders = state.folders.map(t => {
				const ownersJSON = getMetadataBase(t.property, environment.metadata.projectUsers.name)
				if (ownersJSON) {
					const owners = JSON.parse(ownersJSON || '')
					t.myProject = owners && owners.some(u => u.username == user.email)
				}
				return t
			})

			/**
			 * My projects filter
			 */
			if (selectMyProjectsFilter) {
				folders = state.folders.filter(x => x.myProject == true)
			}
		}

		return folders
	},
)

export const selectFiles = createSelector(
	selectFolderState,
	selectSearchFiles,
	isSearchActive,
	(state: State, searchFiles, isSearchActive) => {
		const result = isSearchActive ? searchFiles : state.files ? state.files : []
		return result.filter(t => t.name != environment.metadataFileName)
	},
)

export const selectCurrentFolder = createSelector(selectFolderState, (state: State) => state.currentFolder)
export const selectedItems = createSelector(
	selectFolderState,
	selectSearchFiles,
	selectSearchFolders,
	(state: State, selectSearchFiles, selectSearchFolders) => {
		return state.selectedItems
			.map(i => {
				let item: DamFile | Folder = state.files.find(t => t.file_id === i)

				//from search folder
				if (!item) {
					item = selectSearchFolders.find(t => t.folder_id === i)
				}

				if (!item) {
					item = state.folders.find(t => t.folder_id === i)
				}

				if (!item) {
					//from search
					item = selectSearchFiles.find(t => t.file_id === i)
				}

				return item
			})
			.filter(t => t)
	},
)

export const selectedFirstItem = createSelector(selectFolderState, selectedItems, (state: State, selectedItems) =>
	selectedItems && selectedItems.length ? selectedItems[0] : null,
)

export const selectedAuditHistory = createSelector(selectFolderState, selectSearchFiles, (state: State, selectSearchFiles) => {
	if (state.selectedItems && state.selectedItems.length) {
		let item = state.files.find(t => state.selectedItems[0] === t.file_id)

		if (!item) {
			item = selectSearchFiles.find(t => state.selectedItems[0] === t.file_id)
		}

		if (item) return item.auditHistory
	}
	return null
})

export const selectProjectMetadata = createSelector(selectFolderState, (state: State) => {
	return state.currentProjectId ? state.projectMetadata[state.currentProjectId] : null
})

export const selectMetadataFile = createSelector(selectFolderState, (state: State) => {
	return state.files ? state.files.find(t => t.name === environment.metadataFileName) : null
})

export const selectError: MemoizedSelector<object, any> = createSelector(selectFolderState, getError)
export const selectProjectMetadataIsLoading: MemoizedSelector<object, boolean> = createSelector(
	selectFolderState,
	getProjectMetadataIsLoading,
)
export const selectIsLoading: MemoizedSelector<object, boolean> = createSelector(
	selectFolderState,
	state => (state.isLoading || state.projectMetadataIsLoading) && !state.error,
)

export const selectIsAuditHistoryLoading = createSelector(selectFolderState, getIsAuditHistoryLoading)
