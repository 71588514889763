import { Actions, Effect, ofType } from '@ngrx/effects'
import { Injectable } from '@angular/core'
import { map, tap } from 'rxjs/operators'
import { FileActions, FolderActions } from '../folder-store/actions'
import { UploadStoreActions } from '../upload-store'
import { DownloadFolderStoreActions } from '../download-store'
import { WorkfrontStoreActions } from '../workfront-store'
import { SearchStoreActions } from '../search-store'
import * as featureActions from './actions'

import { CustomApmService } from '@app/services/apm.service'

@Injectable()
export class ApmEffects {
	constructor(private actions$: Actions, private apmService: CustomApmService) {}

	@Effect({ dispatch: false })
	apmUploadRequest$ = this.actions$.pipe(
		ofType(
			FileActions.ActionTypes.MOVE_TO_REQUEST,
			FileActions.ActionTypes.FILE_DELETE_REQUEST,

			FolderActions.ActionTypes.CREATE_REQUEST,
			DownloadFolderStoreActions.ActionTypes.DOWNLOAD_FOLDER_REQUEST,
			FolderActions.ActionTypes.UPDATE_REQUEST,

			FileActions.ActionTypes.FILE_DOWNLOAD_REQUEST,
			FileActions.ActionTypes.FILE_UPDATE_REQUEST,

			WorkfrontStoreActions.ActionTypes.CREATE_PROJECT_REQUEST,
			WorkfrontStoreActions.ActionTypes.SYNC_PROJECTS_REQUEST,
		),
		tap((action: any) => {
			let payload = action.payload
			switch (action.type) {
				case FileActions.ActionTypes.MOVE_TO_REQUEST: {
					const apmPayload = {
						source_folder_id: payload.files.source_folder_id,
						destination_folder_id: payload.files.folder_id,
						files: payload.files.files.map(x => x.file_id).join(','),
						folders: payload.files.folders.map(x => x.folder_id).join(','),
					}
					this.apmService.startTransaction('MOVE', 'COMMON', apmPayload)
					break
				}
				case FileActions.ActionTypes.FILE_DELETE_REQUEST: {
					const apmPayload = {
						repo_id: payload.assets.repo_id,
						folder_id: payload.folderId,
						files: payload.assets.files.map(x => JSON.stringify({ file_id: x.file_id, file_name: x.file_name })).join(','),
						folders: payload.assets.folders.map(x => JSON.stringify({ folder_id: x.folder_id, folder_name: x.folder_name })).join(','),
					}
					this.apmService.startTransaction('DELETE', 'COMMON', apmPayload)
					break
				}
				case FolderActions.ActionTypes.CREATE_REQUEST: {
					this.apmService.startTransaction('NEW', 'FOLDER', payload.folder)
					break
				}
				case FolderActions.ActionTypes.UPDATE_REQUEST: {
					const apmPayload = {
						folder_id: payload.folder.folder_id,
						repo_id: payload.folder.repo_id,
						name: payload.folder.name,
					}
					this.apmService.startTransaction('UPDATE', 'FOLDER', apmPayload)
					break
				}
				case DownloadFolderStoreActions.ActionTypes.DOWNLOAD_FOLDER_REQUEST: {
					const apmPayload = {
						folder_id: payload.folder.folder_id,
						repo_id: payload.folder.repo_id,
					}
					this.apmService.startTransaction('DOWNLOAD', 'FOLDER', apmPayload)
					break
				}
				case FileActions.ActionTypes.FILE_DOWNLOAD_REQUEST: {
					const apmPayload = {
						file_id: payload.file.file_id,
						folder_id: payload.file.folder_id,
					}
					this.apmService.startTransaction('DOWNLOAD', 'FILE', apmPayload)
					break
				}
				case FileActions.ActionTypes.FILE_UPDATE_REQUEST: {
					this.apmService.startTransaction('UPDATE', 'FILE', payload.file)
					break
				}
				case WorkfrontStoreActions.ActionTypes.CREATE_PROJECT_REQUEST: {
					const apmPayload = {
						wf_id: payload.project.workfront_id,
						name: payload.project.name,
					}
					this.apmService.startTransaction('ADD', 'WORKFRONT', apmPayload)
					break
				}
				case WorkfrontStoreActions.ActionTypes.SYNC_PROJECTS_REQUEST: {
					const apmPayload = {
						folders: action.payload.fileProjects.map(x => x.folder_id).join(','),
					}
					this.apmService.startTransaction('SYNC', 'WORKFRONT', apmPayload)
					break
				}
				case featureActions.ActionTypes.LINK_PROJECT: {
					this.apmService.startTransaction('LINK', 'WORKFRONT', payload.file_id)
					break
				}
				default: {
					break
				}
			}
		}),
	)

	@Effect({ dispatch: false })
	apmRequestFailure$ = this.actions$.pipe(
		ofType(
			//File
			FileActions.ActionTypes.MOVE_TO_FAILURE,
			FileActions.ActionTypes.FILE_DELETE_FAILURE,
			FileActions.ActionTypes.FILE_DOWNLOAD_FAILURE,
			FileActions.ActionTypes.FILE_UPDATE_FAILURE,
			//Folder
			FolderActions.ActionTypes.CREATE_FAILURE,
			FolderActions.ActionTypes.UPDATE_FAILURE,
			DownloadFolderStoreActions.ActionTypes.DOWNLOAD_FOLDER_FAILURE,
			//WF
			WorkfrontStoreActions.ActionTypes.CREATE_PROJECT_FAILURE,
			WorkfrontStoreActions.ActionTypes.SYNC_PROJECTS_FAILURE,
			//Search
			SearchStoreActions.ActionTypes.SEARCH_FAILURE,
		),
		tap((action: any) => {
			let error = action.payload.error

			let name: string, type: string

			switch (action.type) {
				case FileActions.ActionTypes.MOVE_TO_FAILURE: {
					name = 'MOVE'
					type = 'COMMON'
					break
				}
				case FileActions.ActionTypes.FILE_DELETE_FAILURE: {
					name = 'DELETE'
					type = 'COMMON'
				}
				case FileActions.ActionTypes.FILE_DOWNLOAD_FAILURE: {
					name = 'DOWNLOAD'
					type = 'FILE'
					break
				}
				case FileActions.ActionTypes.FILE_UPDATE_FAILURE: {
					name = 'UPDATE'
					type = 'FILE'
					break
				}
				case FolderActions.ActionTypes.CREATE_FAILURE: {
					name = 'NEW'
					type = 'FOLDER'
					break
				}
				case FolderActions.ActionTypes.UPDATE_FAILURE: {
					name = 'UPDATE'
					type = 'FOLDER'
					break
				}
				case DownloadFolderStoreActions.ActionTypes.DOWNLOAD_FOLDER_FAILURE: {
					name = 'DOWNLOAD'
					type = 'FOLDER'
					break
				}
				case WorkfrontStoreActions.ActionTypes.CREATE_PROJECT_FAILURE: {
					name = 'ADD'
					type = 'WORKFRONT'
					break
				}
				case WorkfrontStoreActions.ActionTypes.SYNC_PROJECTS_FAILURE: {
					name = 'SYNC'
					type = 'WORKFRONT'
					break
				}
				case SearchStoreActions.ActionTypes.SEARCH_FAILURE: {
					name = 'SEARCH'
					type = 'SEARCH'
					break
				}
			}

			this.apmService.captureError(name, type, error)
		}),
	)

	@Effect({ dispatch: false })
	apmRequestSuccess$ = this.actions$.pipe(
		ofType(
			//File
			FileActions.ActionTypes.MOVE_TO_SUCCESS,
			FileActions.ActionTypes.FILE_DELETE_SUCCESS,
			FileActions.ActionTypes.FILE_DOWNLOAD_SUCCESS,
			FileActions.ActionTypes.FILE_UPDATE_SUCCESS,
			// UploadStoreActions.ActionTypes.DUPLICATE_FILE_CANCEL,
			//Folder
			FolderActions.ActionTypes.CREATE_SUCCESS,
			FolderActions.ActionTypes.UPDATE_SUCCESS,
			DownloadFolderStoreActions.ActionTypes.DOWNLOAD_FOLDER_SUCCESS,
			//WF
			WorkfrontStoreActions.ActionTypes.CREATE_PROJECT_SUCCESS,
			WorkfrontStoreActions.ActionTypes.SYNC_PROJECTS_SUCCESS,
			//Search
			SearchStoreActions.ActionTypes.SEARCH_SUCCESS,
		),
		tap(action => {
			let name: string, type: string

			switch (action.type) {
				case FileActions.ActionTypes.MOVE_TO_SUCCESS: {
					name = 'MOVE'
					type = 'COMMON'
					break
				}
				case FileActions.ActionTypes.FILE_DELETE_SUCCESS: {
					name = 'DELETE'
					type = 'COMMON'
					break
				}
				case FileActions.ActionTypes.FILE_DOWNLOAD_SUCCESS: {
					name = 'DOWNLOAD'
					type = 'FILE'
					break
				}
				case FileActions.ActionTypes.FILE_UPDATE_SUCCESS: {
					name = 'UPDATE'
					type = 'FILE'
					break
				}
				case FolderActions.ActionTypes.CREATE_SUCCESS: {
					name = 'NEW'
					type = 'FOLDER'
					break
				}
				case FolderActions.ActionTypes.UPDATE_SUCCESS: {
					name = 'UPDATE'
					type = 'FOLDER'
					break
				}
				case DownloadFolderStoreActions.ActionTypes.DOWNLOAD_FOLDER_SUCCESS: {
					name = 'DOWNLOAD'
					type = 'FOLDER'
					break
				}
				case WorkfrontStoreActions.ActionTypes.CREATE_PROJECT_SUCCESS: {
					name = 'ADD'
					type = 'WORKFRONT'
					break
				}
				case WorkfrontStoreActions.ActionTypes.SYNC_PROJECTS_SUCCESS: {
					name = 'SYNC'
					type = 'WORKFRONT'
					break
				}
				case SearchStoreActions.ActionTypes.SEARCH_SUCCESS: {
					name = 'SEARCH'
					type = 'SEARCH'
					break
				}
			}

			this.apmService.endTransaction(name, type)
		}),
	)

	@Effect({ dispatch: false })
	apmStartUpload$ = this.actions$.pipe(
		ofType(featureActions.ActionTypes.START_UPLOAD),
		map((action: featureActions.StartUpload) => action.payload),
		tap(action => {
			this.apmService.startTransaction('UPLOAD', 'COMMON', {
				folder_id: action.folder.folder_id,
				repo_id: action.folder.repo_id,
				files: JSON.stringify(action.data),
			})
		}),
	)

	@Effect({ dispatch: false })
	apmUploadStep1Request$ = this.actions$.pipe(
		ofType(UploadStoreActions.ActionTypes.UPLOAD_1_REQUEST, UploadStoreActions.ActionTypes.REPLACE_FILE_STEP1_REQUEST),
		map((action: UploadStoreActions.UploadStep1RequestAction) => action.payload),
		tap(action => {
			this.apmService.addAsset()
		}),
	)

	@Effect({ dispatch: false })
	apmUploadStep3Success$ = this.actions$.pipe(
		ofType(UploadStoreActions.ActionTypes.UPLOAD_3_SUCCESS, UploadStoreActions.ActionTypes.REPLACE_FILE_STEP3_SUCCESS),
		map((action: UploadStoreActions.UploadStep3SuccessAction) => action.payload),
		tap(action => {
			this.apmService.endTransaction('UPLOAD', 'COMMON', true)
		}),
	)

	@Effect({ dispatch: false })
	apmUploadStepsFailure$ = this.actions$.pipe(
		ofType(
			UploadStoreActions.ActionTypes.UPLOAD_1_FAILURE,
			UploadStoreActions.ActionTypes.UPLOAD_2_FAILURE,
			UploadStoreActions.ActionTypes.UPLOAD_3_FAILURE,

			UploadStoreActions.ActionTypes.REPLACE_FILE_STEP1_FAILURE,
			UploadStoreActions.ActionTypes.REPLACE_FILE_STEP2_FAILURE,
			UploadStoreActions.ActionTypes.REPLACE_FILE_STEP3_FAILURE,
		),
		tap((action: any) => {
			let error = action.payload.error
			this.apmService.captureError('UPLOAD', 'COMMON', error, true)
		}),
	)
}
