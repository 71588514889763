<header class="modal-header">
  <h2 class="modal-title">
    Duplicate file
  </h2>
  <button class="modal-header-btn modal-close" type="button" (click)="onCancel.emit()">
    <img src="../../../assets/images/icons/close.svg" alt="Close" title="Close">
  </button>
</header>
<div class="modal-content">
  <form class="new-project-form form" #frm="ngForm" autocomplete="off" novalidate>
    <div class="form-group">
      There is already a file with the same name in this location <br>
      <div *ngFor="let action of files">{{action.name}}</div>
    </div>
    <div class="form-actions modal-footer">
      <button class="form-btn modal-close" mat-button type="button" (click)="onCancel.emit()">CANCEL</button>
      <button class="form-btn modal-close" mat-button type="button" (click)="onReplace.emit(files)">REPLACE</button>
      <button class="form-btn" type="button" mat-button (click)="onRename.emit(files)">RENAME</button>
    </div>
  </form>
</div>