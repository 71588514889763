import { State, featureAdapter } from './state'
import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store'
import { Breadcrumb } from '@app/models'

export const getError = (state: State): any => state.error

export const getIsLoading = (state: State): boolean => state.isLoading

export const selectBreadcrumbState: MemoizedSelector<object, State> = createFeatureSelector<State>('breadcrumb')

export const selectBreadcrumb: (state: any) => Breadcrumb[] = featureAdapter.getSelectors(selectBreadcrumbState).selectAll

export const selectProjectFolder = createSelector(selectBreadcrumbState, selectBreadcrumb, state => {
	const breadcrumbs = featureAdapter.getSelectors().selectAll(state)
	return breadcrumbs.filter(t => t.level === '1')
})
