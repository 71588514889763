import { Actions, ActionTypes } from './actions'
import { featureAdapter, initialState, State } from './state'

export function featureReducer(state = initialState, action: Actions): State {
	switch (action.type) {
		case ActionTypes.DOWNLOAD_FOLDER_REQUEST: {
			return state
		}
		case ActionTypes.DOWNLOAD_FOLDER_SUCCESS: {
			return featureAdapter.addOne(action.payload.folder, state)
		}
		case ActionTypes.LOAD_REQUEST: {
			return state
		}
		case ActionTypes.LOAD_SUCCESS: {
			return featureAdapter.setAll(action.payload.folders, state)
		}
		case ActionTypes.SYNCHRONIZE_REQUEST: {
			return state
		}
		case ActionTypes.SYNCHRONIZE_SUCCESS: {
			return featureAdapter.upsertMany(action.payload.folders, state)
		}
		case ActionTypes.REMOVE_DOWNLOAD_FOLDER_REQUEST: {
			return state
		}
		case ActionTypes.REMOVE_DOWNLOAD_FOLDER_SUCCESS: {
			return featureAdapter.removeOne(action.payload.folder.folder_id, {
				...state,
			})
		}
		case ActionTypes.CLEAR_DOWNLOAD_FOLDERS_ALL: {
			return featureAdapter.removeAll({
				...state,
			})
		}
		case ActionTypes.DOWNLOAD_ZIP_FOLDER_REQUEST: {
			return featureAdapter.updateOne({ id: action.payload.folder.folder_id, changes: { downloaded: true } }, state)
		}
		case ActionTypes.NOTIFY_FOLDER_DOWNLOAD: {
			return featureAdapter.updateOne(
				{ id: action.payload.folder.folder_id, changes: { notify_user: action.payload.folder.notify_user } },
				state,
			)
		}
	}

	return state
}
