import { Actions, Effect, ofType } from '@ngrx/effects'
import { Injectable } from '@angular/core'
import { Location } from '@angular/common'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { map, tap } from 'rxjs/operators'
import * as RouteActions from './actions'

@Injectable()
export class RouterEffects {
	constructor(private actions$: Actions, private router: Router, private location: Location, private store: Store<any>) {}

	@Effect({ dispatch: false })
	navigate$ = this.actions$.pipe(
		ofType(RouteActions.ActionTypes.ROUTE_GO),
		map((action: RouteActions.Go) => action.payload),
		tap(({ path, queryParams, extras }) => this.router.navigate(path, { queryParams, ...extras })),
	)

	@Effect({ dispatch: false })
	navigateBack$ = this.actions$.pipe(
		ofType(RouteActions.ActionTypes.ROUTE_BACK),
		tap(() => this.location.back()),
	)

	@Effect({ dispatch: false })
	navigateForward$ = this.actions$.pipe(
		ofType(RouteActions.ActionTypes.ROUTE_FORWARD),
		tap(() => this.location.forward()),
	)
}
