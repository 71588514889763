import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core'
import { ModalService } from '../../services'
import { Subject } from 'rxjs'
import { takeUntil, filter, map } from 'rxjs/operators'
import { ContextMenuRename } from '@app/models'

@Component({
	selector: 'dam-rename-modal',
	templateUrl: './rename-modal.component.html',
	styleUrls: ['./rename-modal.component.css'],
})
export class RenameModalComponent implements OnInit, OnDestroy {
	@Output()
	onSubmit: EventEmitter<any> = new EventEmitter()

	@Output()
	onCancel: EventEmitter<any> = new EventEmitter()

	destroy$ = new Subject()
	name: string

	constructor(private modalService: ModalService) {}

	ngOnInit() {
		this.modalService
			.getContext()
			.pipe(
				takeUntil(this.destroy$),
				filter(ctx => ctx.model && ctx.model.action.type == ContextMenuRename.type),
				map(ctx => ctx.model),
			)
			.subscribe(context => (this.name = context.payload.name))
	}

	ngOnDestroy(): void {
		this.destroy$.next(true)
	}
}
