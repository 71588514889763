import { TabBottomSidenav } from 'src/app/models/sidenav.model'

export interface State {
	sortField: string
	sortDirection: boolean
	view: string
	showDetailSidenav: boolean
	detailSidenavActiveTab: string
	sortFields: any
	showBottomSidenav: boolean
	bottomSidenavActiveTab: TabBottomSidenav
	tablePage: number
	tableLimit: number
	tableCount: number
}

export const initialState: State = {
	sortField: 'name',
	sortDirection: true,
	view: 'list',
	showDetailSidenav: false,
	detailSidenavActiveTab: 'details',
	sortFields: {
		name: true,
		createdBy: true,
		createdOn: true,
		size: true,
		'property-Reference number': true,
		'property-Deadline': true,
		'property-Status': true,
	},
	showBottomSidenav: false,
	bottomSidenavActiveTab: TabBottomSidenav.Upload,
	tablePage: 1,
	tableLimit: 25,
	tableCount: 0,
}
