import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core'

@Component({
	selector: 'dam-asset-item-icon',
	template:
		'<img class="asset-item-asset" src="../assets/images/icons/files/{{fileType}}.svg" onerror="this.src=\'../assets/images/icons/files/generic.svg\'" alt="Asset Image" >',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetItemIconComponent {
	@Input()
	name: string = ''

	get fileType(): string {
		return /[.]/.exec(this.name) ? /[^.]+$/.exec(this.name)[0].toLowerCase() : ''
	}
}
