import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store'
import { featureAdapter, State } from './state'

const { selectIds, selectEntities, selectAll, selectTotal } = featureAdapter.getSelectors()

export const getIsMinimized = (state: State): boolean => state.isMinimized
export const getIsLoading = (state: State): boolean => state.isLoading
export const getMaxConcurrentUploads = (state: State): number => state.maxConcurrentUploads

export const selectAssetState: MemoizedSelector<object, State> = createFeatureSelector<State>('fileManager')

export const getUploadEntities = createSelector(selectAssetState, selectEntities)

export const selectDuplicateFiles = createSelector(selectAssetState, (state: State) => {
	return state.duplicateFiles
})

export const selectDuplicateFolders = createSelector(selectAssetState, (state: State) => {
	return state.duplicateFolders
})

export const selectAllFiles = createSelector(selectAssetState, selectAll)

export const selectAssetById = () => createSelector(getUploadEntities, (entities, fileId) => entities[fileId])

export const selectUploadDialogIsMinimized: MemoizedSelector<object, boolean> = createSelector(selectAssetState, getIsMinimized)

export const selectIsLoading: MemoizedSelector<object, boolean> = createSelector(selectAssetState, getIsLoading)

export const selectMaxConcurrentUploads: MemoizedSelector<object, number> = createSelector(selectAssetState, getMaxConcurrentUploads)
