<li class="bottom-dialog-item">
  <a target="_blank" href="{{url}}">
    <img class="bottom-dialog-item-img" src="../../../assets/images/icons/folder-download.svg" alt="">
  </a>
  <div class="bottom-dialog-item-info">
    <div class="bottom-dialog-item-info-title">
      <span class="bottom-dialog-item-name">{{name}}</span>
      <span [hidden]="!statistics" class="bottom-dialog-item-info-title-sub">Processed: {{statistics}}</span>
    </div>

    <mat-progress-bar *ngIf="!completed" mode="indeterminate"> </mat-progress-bar>
    <div>
      <span class="bottom-dialog-item-description">
        {{path}}
      </span>

      <!-- <span *ngIf="completed"  class="bottom-dialog-item-progress-wrapper">
        (
        <span class="bottom-dialog-item-progress"></span>
        )
      </span> -->
    </div>
  </div>
  <!-- <div class="bottom-dialog-item-actions">
    <mat-slide-toggle color="accent" *ngIf="!completed" [(ngModel)]="notifyMe" class="bottom-dialog-item-cancel"
      (change)="notifyMeChanged()" matTooltip="Notify me when the download is complete"></mat-slide-toggle>
  </div> -->


  <!-- <a target="_blank" href="{{url}}" matTooltip="Download" class="menu-item" *ngIf="completed" rel="noopener noreferrer">
    <img class="icon" src="./../../../assets/images/icons/download-rose.svg" alt="">
  </a> -->

  <button class="bottom-dialog-item-cancel" type="button" (click)="onClear.emit()">
    <span class="bottom-dialog-item-cancel-text">
      &times;
    </span>
  </button>

</li>