import { SearchFileResult, SearchFolderResult, SearchCriteria, SearchFolderCriteria } from '@app/models'

export interface State {
	projects: Array<SearchFileResult>
	files: Array<SearchFileResult>
	folders: Array<SearchFolderResult>
	searchCriteria?: SearchCriteria
	searchFolderCriteria?: SearchFolderCriteria
	filterMyProjects: boolean
	filterActiveProjects: boolean
	filterLoS: string
	filterBusinessUnit: string
	isSearchFilesActive: boolean
	isSearchFolderActive: boolean
	isLoading?: boolean
	enabled: boolean
	error?: any
}

const defaultCriteria: SearchCriteria = {
	search_criteria: [
		{
			folders: null,
			metadata_list: [],
			repo_id: '',
		},
	],
	include_subfolders: true,
	search_all: false,
	query_content: '',
	limit: 50,
}

const folderCriteria: SearchFolderCriteria = {
	search_criteria: [
		{
			folders: null,
			repo_id: '',
		},
	],
	query_content: '',
	is_exactsearch: false,
	search_all: false,
	limit: 100,
	next_page_token: '',
	include_subfolders: true,
	pagination_required: false,
}

export const initialState: State = {
	projects: [],
	files: [],
	folders: [],
	searchCriteria: defaultCriteria,
	searchFolderCriteria: folderCriteria,
	filterMyProjects: false,
	filterActiveProjects: true,
	filterLoS: null,
	filterBusinessUnit: null,
	isSearchFilesActive: false,
	isSearchFolderActive: false,
	isLoading: false,
	error: null,
	enabled: true,
}
