import { Action } from '@ngrx/store'
import {
	FolderBase,
	FileUploadStep1,
	FileUploadStep1Result,
	FileUploadStep3,
	FileUpload,
	DamFile,
	FileVersion,
	FileReplaceStep2,
} from '@app/models'

export enum ActionTypes {
	VERIFY_UPLOAD_REQUEST = '[Upload Assets] Verify Upload File Request',

	UPLOAD_1_REQUEST = '[Upload Assets] Step1 Upload File Request',
	UPLOAD_1_FAILURE = '[Upload Assets] Step1 Upload File Failure',
	UPLOAD_1_SUCCESS = '[Upload Assets] Step1 Upload File Success',

	UPLOAD_2_REQUEST = '[Upload Assets] Step2 Upload File Request',
	UPLOAD_2_FAILURE = '[Upload Assets] Step2 Upload File Failure',
	UPLOAD_2_SUCCESS = '[Upload Assets] Step2 Upload File Success',

	UPLOAD_3_REQUEST = '[Upload Assets] Step3 Upload File Request',
	UPLOAD_3_FAILURE = '[Upload Assets] Step3 Upload File Failure',
	UPLOAD_3_SUCCESS = '[Upload Assets] Step3 Upload File Success',

	UPLOAD_REPORT_PROGRESS = '[Upload Assets] Upload Report Progress',
	TOGGLE_UPLOAD_DIALOG = '[Upload Dialog] Toggle',
	SET_UPLOAD_DIALOG = '[Upload Dialog] Set upload dialog status',

	DELETE_REQUEST = '[Upload Assets] Delete File Request',
	DELETE_FAILURE = '[Upload Assets] Delete File Failure',
	DELETE_SUCCESS = '[Upload Assets] Delete File Success',

	DUPLICATE_FILE_REQUEST = '[Upload Assets] Duplicate File Request',
	DUPLICATE_FILE_SUCCESS = '[Upload Assets] Duplicate File Success',
	DUPLICATE_FILE_CANCEL = '[Upload Assets] Duplicate File Cancel',

	DUPLICATE_FOLDER_REQUEST = '[Upload Assets] Duplicate Folder Request',
	DUPLICATE_FOLDER_SUCCESS = '[Upload Assets] Duplicate Folder Success',

	UPLOAD_VALIDATE_STRUCTURE_REQUEST = '[Upload Assets] Validate structure for upload Request',
	UPLOAD_VALIDATE_STRUCTURE_SUCCESS = '[Upload Assets] Validate structure for upload Success',

	VERSION_FILE_REQUEST = '[Upload Assets] Version File Request',
	VERSION_FILE_FAILURE = '[Upload Assets] Version File Failure',
	VERSION_FILE_SUCCESS = '[Upload Assets] Version File Success',

	REPLACE_FILE_STEP1_REQUEST = '[Upload Assets] Replace File step 1 Request',
	REPLACE_FILE_STEP1_FAILURE = '[Upload Assets] Replace File step 1 Failure',
	REPLACE_FILE_STEP1_SUCCESS = '[Upload Assets] Replace File step 1 Success',

	REPLACE_FILE_STEP2_REQUEST = '[Upload Assets] Replace File step 2 Request',
	REPLACE_FILE_STEP2_FAILURE = '[Upload Assets] Replace File step 2 Failure',
	REPLACE_FILE_STEP2_SUCCESS = '[Upload Assets] Replace File step 2 Success',

	REPLACE_FILE_STEP3_REQUEST = '[Upload Assets] Replace File step 3 Request',
	REPLACE_FILE_STEP3_FAILURE = '[Upload Assets] Replace File step 3 Failure',
	REPLACE_FILE_STEP3_SUCCESS = '[Upload Assets] Replace File step 3 Success',

	REMOVE_ELEMENT_UPLOAD = '[Upload List] Remove element from upload list',
	CLEAR_UPLOAD = '[Upload List] Clear',
	CLEAR_UPLOAD_ALL = '[Upload List] Clear All',

	ENQUEUE = '[Queue] Enqueue files',
	PROCESS_QUEUE = '[Queue] Process queue',
	PROCESS_QUEUE_END = '[Queue] Process queue end',

	UPDATE_REFRESH_ENTITY_STATE = '[Queue] Update refresh satate',
}

export class UpdateRefreshEntityState implements Action {
	readonly type = ActionTypes.UPDATE_REFRESH_ENTITY_STATE
}

export class EnqueueAction implements Action {
	readonly type = ActionTypes.ENQUEUE
	constructor(public payload: FileUpload | FileUpload[]) {}
}

export class ProcessQueueAction implements Action {
	readonly type = ActionTypes.PROCESS_QUEUE
}

export class ProcessQueueEndAction implements Action {
	readonly type = ActionTypes.PROCESS_QUEUE_END
}

export class ToggleUploadDialogAction implements Action {
	readonly type = ActionTypes.TOGGLE_UPLOAD_DIALOG
}

export class SetUploadDialogAction implements Action {
	readonly type = ActionTypes.SET_UPLOAD_DIALOG
	constructor(public payload: boolean) {}
}

export class VerifyUploadRequestAction implements Action {
	readonly type = ActionTypes.VERIFY_UPLOAD_REQUEST
	constructor(public payload: { folder: FolderBase; data: any[] }) {}
}

export class VerifyUploadStructureRequestAction implements Action {
	readonly type = ActionTypes.UPLOAD_VALIDATE_STRUCTURE_REQUEST
	constructor(public payload: { folder: FolderBase; data: any[] }) {}
}

export class VerifyUploadStructureSuccessAction implements Action {
	readonly type = ActionTypes.UPLOAD_VALIDATE_STRUCTURE_SUCCESS
}

export class UploadStep1RequestAction implements Action {
	readonly type = ActionTypes.UPLOAD_1_REQUEST
	constructor(public payload: { model: FileUploadStep1; file: any; location: 'root' | 'subFolder' }) {}
}

export class UploadStep1FailureAction implements Action {
	readonly type = ActionTypes.UPLOAD_1_FAILURE
	constructor(public payload: { error: string }) {}
}

export class UploadStep1SuccessAction implements Action {
	readonly type = ActionTypes.UPLOAD_2_SUCCESS
	constructor(public payload: { model: FileUploadStep1Result; file: any }) {}
}

export class UploadStep2RequestAction implements Action {
	readonly type = ActionTypes.UPLOAD_2_REQUEST
	constructor(public payload: { model: FileUploadStep1Result; req: FileUploadStep1; file: any; location: 'root' | 'subFolder' }) {}
}

export class UploadStep2FailureAction implements Action {
	readonly type = ActionTypes.UPLOAD_2_FAILURE
	constructor(public payload: { error: string }) {}
}

export class UploadStep2SuccessAction implements Action {
	readonly type = ActionTypes.UPLOAD_2_SUCCESS
	constructor(public payload: { model: FileUploadStep1Result }) {}
}

export class UploadStep3RequestAction implements Action {
	readonly type = ActionTypes.UPLOAD_3_REQUEST
	constructor(public payload: { model: FileUploadStep3; location: 'root' | 'subFolder' }) {}
}

export class UploadStep3FailureAction implements Action {
	readonly type = ActionTypes.UPLOAD_3_FAILURE
	constructor(public payload: { error: string }) {}
}

export class UploadStep3SuccessAction implements Action {
	readonly type = ActionTypes.UPLOAD_3_SUCCESS
	constructor(public payload: { model: FileUploadStep3 }) {}
}

export class UploadReportProgressAction implements Action {
	readonly type = ActionTypes.UPLOAD_REPORT_PROGRESS
	constructor(public payload: { info: FileUpload }) {}
}

export class DeleteRequestAction implements Action {
	readonly type = ActionTypes.DELETE_REQUEST
	constructor(public payload: { file: DamFile }) {}
}

export class DeleteFailureAction implements Action {
	readonly type = ActionTypes.DELETE_FAILURE
	constructor(public payload: { error: string }) {}
}

export class DeleteSuccessAction implements Action {
	readonly type = ActionTypes.DELETE_SUCCESS
	constructor(public payload: { file: DamFile }) {}
}

export class DuplicateFileRequestAction implements Action {
	readonly type = ActionTypes.DUPLICATE_FILE_REQUEST
	constructor(public payload: { folder: FolderBase; data: any[]; files: any[]; duplicated: any[] }) {}
}

export class DuplicateFileSuccessAction implements Action {
	readonly type = ActionTypes.DUPLICATE_FILE_SUCCESS
}

export class DuplicateFileCancelAction implements Action {
	readonly type = ActionTypes.DUPLICATE_FILE_CANCEL
}

export class DuplicateFolderRequestAction implements Action {
	readonly type = ActionTypes.DUPLICATE_FOLDER_REQUEST
	constructor(public payload: { folder: FolderBase; data: any[]; folders: any[]; files: any[]; duplicated: any[] }) {}
}

export class DuplicateFolderSuccessAction implements Action {
	readonly type = ActionTypes.DUPLICATE_FOLDER_SUCCESS
}

export class VersionFileRequestAction implements Action {
	readonly type = ActionTypes.VERSION_FILE_REQUEST
	constructor(public payload: { version: FileVersion; folder: FolderBase; file: File }) {}
}

export class VersionFileFailureAction implements Action {
	readonly type = ActionTypes.VERSION_FILE_FAILURE
	constructor(public payload: { error: string }) {}
}

export class VersionFileSuccessAction implements Action {
	readonly type = ActionTypes.VERSION_FILE_SUCCESS
}

export class ReplaceFileStep1RequestAction implements Action {
	readonly type = ActionTypes.REPLACE_FILE_STEP1_REQUEST
	constructor(public payload: { version: FileVersion; folder: FolderBase; file: File; id: string }) {}
}

export class ReplaceFileStep1FailureAction implements Action {
	readonly type = ActionTypes.REPLACE_FILE_STEP1_FAILURE
	constructor(public payload: { error: string }) {}
}

export class ReplaceFileStep1SuccessAction implements Action {
	readonly type = ActionTypes.REPLACE_FILE_STEP1_SUCCESS
}

export class ReplaceFileStep2RequestAction implements Action {
	readonly type = ActionTypes.REPLACE_FILE_STEP2_REQUEST
	constructor(public payload: { context: FileReplaceStep2; folder: FolderBase; file: File }) {}
}

export class ReplaceFileStep2FailureAction implements Action {
	readonly type = ActionTypes.REPLACE_FILE_STEP2_FAILURE
	constructor(public payload: { error: string }) {}
}

export class ReplaceFileStep2SuccessAction implements Action {
	readonly type = ActionTypes.REPLACE_FILE_STEP2_SUCCESS
}

export class ReplaceFileStep3RequestAction implements Action {
	readonly type = ActionTypes.REPLACE_FILE_STEP3_REQUEST
	constructor(public payload: { context: any; folder: FolderBase; name: string }) {}
}

export class ReplaceFileStep3FailureAction implements Action {
	readonly type = ActionTypes.REPLACE_FILE_STEP3_FAILURE
	constructor(public payload: { error: string }) {}
}

export class ReplaceFileStep3SuccessAction implements Action {
	readonly type = ActionTypes.REPLACE_FILE_STEP3_SUCCESS
	constructor(public payload: { id: string }) {}
}

export class ClearUploadListAction implements Action {
	readonly type = ActionTypes.CLEAR_UPLOAD
	constructor(public payload: { file: FileUpload }) {}
}

export class ClearAllUploadListAction implements Action {
	readonly type = ActionTypes.CLEAR_UPLOAD_ALL
}

export class RemoveElementUpload implements Action {
	readonly type = ActionTypes.REMOVE_ELEMENT_UPLOAD
	constructor(public payload: string) {}
}

export type Actions =
	| UploadStep1RequestAction
	| UploadStep1FailureAction
	| UploadStep1SuccessAction
	| UploadStep2RequestAction
	| UploadStep2FailureAction
	| UploadStep2SuccessAction
	| UploadStep3RequestAction
	| UploadStep3FailureAction
	| UploadStep3SuccessAction
	| UploadReportProgressAction
	| DeleteRequestAction
	| DeleteSuccessAction
	| DeleteFailureAction
	| DuplicateFileRequestAction
	| DuplicateFileSuccessAction
	| DuplicateFileCancelAction
	| DuplicateFolderRequestAction
	| DuplicateFolderSuccessAction
	| VersionFileRequestAction
	| VersionFileSuccessAction
	| VersionFileFailureAction
	| VerifyUploadRequestAction
	| VerifyUploadStructureRequestAction
	| VerifyUploadStructureSuccessAction
	| ReplaceFileStep1RequestAction
	| ReplaceFileStep1SuccessAction
	| ReplaceFileStep1FailureAction
	| ReplaceFileStep2RequestAction
	| ReplaceFileStep2SuccessAction
	| ReplaceFileStep2FailureAction
	| ReplaceFileStep3RequestAction
	| ReplaceFileStep3SuccessAction
	| ReplaceFileStep3FailureAction
	| ToggleUploadDialogAction
	| SetUploadDialogAction
	| ClearAllUploadListAction
	| ClearUploadListAction
	| RemoveElementUpload
	| EnqueueAction
	| ProcessQueueAction
	| ProcessQueueEndAction
	| UpdateRefreshEntityState
