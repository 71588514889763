<header class="modal-header">
  <h2 class="modal-title">
    <ng-content select="label.title"></ng-content>
  </h2>
</header>
<div class="modal-content">
  <form class="new-project-form form">
    <div class="form-group">
      <label class="form-label">
        <ng-content select="div.message"></ng-content>
      </label>
    </div>
    <div class="form-actions modal-footer">
      <button class="form-btn" type="button" mat-button (click)="onOk.emit()">OK</button>
    </div>
  </form>
</div>
