import { Action } from '@ngrx/store'
import { NavigationExtras } from '@angular/router'

export enum ActionTypes {
	ROUTE_GO = '[Router] Go',
	ROUTE_BACK = '[Router] Back',
	ROUTE_FORWARD = '[Router] Forward',
}

export class Go implements Action {
	readonly type = ActionTypes.ROUTE_GO
	constructor(public payload: { path: any[]; queryParams?: object; extras?: NavigationExtras }) {}
}

export class Back implements Action {
	readonly type = ActionTypes.ROUTE_BACK
}

export class Forward implements Action {
	readonly type = ActionTypes.ROUTE_FORWARD
}

export type Actions = Go | Back | Forward
