import { Folder, DamFile, ProjectMetadata } from '@app/models'

export interface State {
	projects: Folder[]
	folders: Folder[]
	files: DamFile[]
	projectMetadata: any
	projectMetadataIsLoading: boolean
	currentProjectId: string
	currentFolder: Partial<Folder>
	selectedItems: Array<string>
	isLoadingAuditHistory: boolean
	isLoading?: boolean
	error?: any
}

export const initialState: State = {
	projects: [],
	folders: [],
	files: [],
	projectMetadata: null,
	projectMetadataIsLoading: false,
	currentProjectId: null,
	selectedItems: [],
	currentFolder: {},
	isLoadingAuditHistory: false,
	isLoading: false,
	error: null,
}
