import { Action } from '@ngrx/store'
import { Repository, RepositoryWrapper } from '@app/models'

export enum ActionTypes {
	REPO_REQUEST = '[Repo] Repository Request',
	REPO_FAILURE = '[Repo] Repository Failure',
	REPO_SUCCESS = '[Repo] Repository Success',

	UPDATE_REPO_REQUEST = '[Repo] Update Repository Request',
	UPDATE_REPO_FAILURE = '[Repo] Update Repository Failure',
	UPDATE_REPO_SUCCESS = '[Repo] Update Repository Success',
}

export class RepoRequestAction implements Action {
	readonly type = ActionTypes.REPO_REQUEST
}

export class RepoFailureAction implements Action {
	readonly type = ActionTypes.REPO_FAILURE
	constructor(public payload: { error: string }) {}
}

export class RepoSuccessAction implements Action {
	readonly type = ActionTypes.REPO_SUCCESS
	constructor(public payload: RepositoryWrapper) {}
}

export class UpdateRequestAction implements Action {
	readonly type = ActionTypes.UPDATE_REPO_REQUEST
	constructor(public payload: RepositoryWrapper) {}
}

export class UpdateFailureAction implements Action {
	readonly type = ActionTypes.UPDATE_REPO_FAILURE
	constructor(public payload: { error: string }) {}
}

export class UpdateSuccessAction implements Action {
	readonly type = ActionTypes.UPDATE_REPO_SUCCESS
	constructor(public payload: RepositoryWrapper) {}
}

export type Actions =
	| RepoRequestAction
	| RepoFailureAction
	| RepoSuccessAction
	| UpdateRequestAction
	| UpdateFailureAction
	| UpdateSuccessAction
