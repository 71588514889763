import { Component, OnInit } from '@angular/core'
import { Subject, Observable } from 'rxjs'
import { IContextMenuAction } from 'src/app/models/context-menu.model'
import { environment } from 'src/environments/environment'
import { Store } from '@ngrx/store'
import { ModalService, ContextMenuService, RequestCacheService, FolderService } from 'src/app/services'
import { ActivatedRoute } from '@angular/router'
import { DeviceService } from 'src/app/services/device.service'
import { takeUntil, take, filter } from 'rxjs/operators'
import { BreadcrumbStoreSelectors } from 'src/app/root-store/breadcrumb-store'
import { FileActions } from 'src/app/root-store/folder-store/actions'
import { trigger, state, style, transition, animate } from '@angular/animations'
import { RepositoryTypes, getMetadataBase } from 'src/app/common/helpers'
import {
	RootStoreState,
	RootStoreSelectors,
	FolderStoreSelectors,
	UserStoreSelectors,
	UploadStoreSelectors,
	LayoutStoreSelectors,
	SearchStoreSelectors,
	RouteActions,
	LayoutStoreActions,
	SearchStoreActions,
	FolderStoreActions,
	UploadStoreActions,
} from 'src/app/root-store'
import { TabBottomSidenav } from 'src/app/models/sidenav.model'
import { DownloadFolderStoreSelectors, DownloadFolderStoreActions } from 'src/app/root-store/download-store'
import { DamFile, DamContext, FileUpload, Folder, User, Breadcrumb, FolderDownload, ProjectMetadata, SearchFileResult } from '@app/models'

@Component({
	selector: 'dam-project-explorer',
	templateUrl: './project-explorer.component.html',
	styleUrls: ['./project-explorer.component.css'],
	animations: [
		trigger('listAnimation', [
			state('void', style({ opacity: 0 })),
			state('*', style({ opacity: 1 })),
			transition('void => *', animate('0.5s ease')),
		]),
		trigger('slideInOut', [
			state(
				'in',
				style({
					transform: 'translate3d(0, 0, 0)',
				}),
			),
			state(
				'out',
				style({
					transform: 'translate3d(100%, 0, 0)',
					opacity: 0,
					width: 0,
				}),
			),
			transition('in => out', animate('300ms ease-in-out')),
			transition('out => in', animate('400ms ease-in-out')),
		]),
	],
})
export class ProjectExplorerComponent implements OnInit {
	destroy$ = new Subject()
	files$: Observable<DamFile[]>
	folders$: Observable<Folder[]>
	isLoading$: Observable<boolean>
	user$: Observable<User>
	breadcrumbs$: Observable<Breadcrumb[]>
	item$: Observable<DamFile | Folder>
	selectedItem$: Observable<(DamFile | Folder)[]>
	uploadFiles$: Observable<FileUpload[]>
	downloadFolders$: Observable<Array<Partial<FolderDownload>>>
	bottomSidenavIsMinimized$: Observable<boolean>
	bottomSidenavActiveTab$: Observable<TabBottomSidenav>

	// uploadDialogIsMinimized$: Observable<boolean>;
	damContext: DamContext
	selectedItems: (DamFile | Folder)[] = []
	contextMenuActions: IContextMenuAction[] = []
	sortField$: Observable<string>
	sortDirection$: Observable<boolean>
	showDetailSidenav$: Observable<boolean>
	projectMetadata$: Observable<ProjectMetadata>
	projectMetadataIsLoading$: Observable<boolean>
	searchResult$: Observable<SearchFileResult[]>
	selectMyProjectsFilter$: Observable<boolean>
	selectActiveProjectsFilter$: Observable<boolean>
	isSearchActive$: Observable<boolean>
	isAuditHistoryLoading$: Observable<boolean>
	selectedItemAuditHistory$: Observable<any>
	selectSidenavActiveTab$: Observable<string>
	gridLayout$: Observable<string>

	//
	isSearchActive: boolean
	selectMyProjectsFilter: boolean
	activeProjectFilter: boolean
	isDetailSidenavOn: boolean
	//
	selectProjectFromSearch$: Observable<any>

	lineOfServiceValues = environment.los
	selectLineOfServiceValue$: Observable<string>
	businessUnitValues = environment.businessUnit
	selectBusinessUnitValue$: Observable<string>

	user: User
	sortField: string

	//
	tableCount$: Observable<number>
	tablePage$: Observable<number>
	tableLimit$: Observable<number>
	//

	constructor(
		private store$: Store<RootStoreState.State>,
		private modalService: ModalService,
		private contexMenuService: ContextMenuService,
		private route: ActivatedRoute,
		private deviceService: DeviceService,
		private cacheService: RequestCacheService,
		private folderService: FolderService,
	) {
		this.route.data.pipe(takeUntil(this.destroy$)).subscribe(data => {
			this.damContext = data.data
			this.contexMenuService.setTab(this.damContext.tab as RepositoryTypes)
			this.contextMenuActions =
				this.damContext.folder_type === 'folder'
					? this.contexMenuService.getContextMenu('main-folder')
					: this.contexMenuService.getContextMenu('main')
		})
	}

	ngOnInit() {
		this.isLoading$ = this.store$.select(RootStoreSelectors.selectIsLoading)
		this.files$ = this.store$.select(FolderStoreSelectors.selectFiles)
		this.folders$ = this.store$.select(FolderStoreSelectors.selectFolders)
		this.selectedItem$ = this.store$.select(FolderStoreSelectors.selectedItems)
		this.item$ = this.store$.select(FolderStoreSelectors.selectedFirstItem)
		this.user$ = this.store$.select(UserStoreSelectors.selectUser)
		this.breadcrumbs$ = this.store$.select(BreadcrumbStoreSelectors.selectBreadcrumb)
		this.uploadFiles$ = this.store$.select(UploadStoreSelectors.selectAllFiles)
		this.downloadFolders$ = this.store$.select(DownloadFolderStoreSelectors.selectDownloadFolders)
		this.bottomSidenavIsMinimized$ = this.store$.select(LayoutStoreSelectors.selectBottomSidenavIsMinimized)
		this.bottomSidenavActiveTab$ = this.store$.select(LayoutStoreSelectors.selectBottomSidenavActiveTab)
		// this.uploadDialogIsMinimized$ = this.store$.select(UploadStoreSelectors.selectUploadDialogIsMinimized);
		this.sortField$ = this.store$.select(LayoutStoreSelectors.selectSortField)
		this.sortDirection$ = this.store$.select(LayoutStoreSelectors.selectSortDirection)
		this.showDetailSidenav$ = this.store$.select(LayoutStoreSelectors.selectDetailSidenav)
		this.projectMetadata$ = this.store$.select(FolderStoreSelectors.selectProjectMetadata)
		this.projectMetadataIsLoading$ = this.store$.select(FolderStoreSelectors.selectProjectMetadataIsLoading)
		this.selectMyProjectsFilter$ = this.store$.select(SearchStoreSelectors.selectMyProjectsFilter)
		this.selectActiveProjectsFilter$ = this.store$.select(SearchStoreSelectors.selectActiveProjectsFilter)
		this.selectBusinessUnitValue$ = this.store$.select(SearchStoreSelectors.selectFilterBusinessUnit)
		this.selectLineOfServiceValue$ = this.store$.select(SearchStoreSelectors.selectFilterLoS)
		this.isSearchActive$ = this.store$.select(SearchStoreSelectors.isSearchActive)
		this.selectedItemAuditHistory$ = this.store$.select(FolderStoreSelectors.selectedAuditHistory)
		this.isAuditHistoryLoading$ = this.store$.select(FolderStoreSelectors.selectIsAuditHistoryLoading)
		this.selectSidenavActiveTab$ = this.store$.select(LayoutStoreSelectors.selectDetailSidenavActiveTab)
		this.gridLayout$ = this.store$.select(LayoutStoreSelectors.selectView)

		this.selectedItem$.pipe(takeUntil(this.destroy$)).subscribe(event => (this.selectedItems = event))
		this.isSearchActive$.pipe(takeUntil(this.destroy$)).subscribe(t => (this.isSearchActive = t))
		this.user$.pipe(takeUntil(this.destroy$)).subscribe(t => (this.user = t))
		this.sortField$.pipe(takeUntil(this.destroy$)).subscribe(t => (this.sortField = t))
		this.selectActiveProjectsFilter$.pipe(takeUntil(this.destroy$)).subscribe(e => (this.activeProjectFilter = e))
		this.selectMyProjectsFilter$.pipe(takeUntil(this.destroy$)).subscribe(e => (this.selectMyProjectsFilter = e))

		this.store$.dispatch(new LayoutStoreActions.SortFieldChange({ field: null }))
		// Selected project metadata (project view)
		// this.item$.pipe(takeUntil(this.destroy$)).subscribe(i => {
		//   const folderId = i ? i.folder_id : '';
		//   const name = i ? i.name : '';
		//   this.selectProjectFromSearch$ = this.store$.select(
		// SearchStoreSelectors.selectProjectFromSearch(folderId, name));
		// });
		this.tableCount$ = this.store$.select(LayoutStoreSelectors.selectTableCount)
		this.tableLimit$ = this.store$.select(LayoutStoreSelectors.selectTableLimit)
		this.tablePage$ = this.store$.select(LayoutStoreSelectors.selectTablePage)
	}

	ngOnDestroy(): void {
		this.destroy$.next(true)
	}

	dbClick($event, item, type) {
		$event.stopPropagation()
		const folder = item as Folder

		if (!folder.property) {
			return
		}

		if (type === 'folder') {
			let path = `projects/folders/${folder.folder_id}`

			const parentProject = getMetadataBase(folder.property, environment.metadata.parentProject.name)
			if (parentProject) {
				path = `projects/folders/${parentProject}`
			}

			this.store$.dispatch(new RouteActions.Go({ path: [path] }))
		} else {
			// download file
			this.store$.dispatch(new FileActions.DownloadRequestAction({ file: item as DamFile, multiple: false }))
		}
	}

	onFolderPathClick(folderId) {
		//if (this.isSearchFilesActive) {
		const path = `projects/folders/${folderId}`
		this.store$.dispatch(new RouteActions.Go({ path: [path] }))
		this.store$.dispatch(new LayoutStoreActions.ShowDetailSidenav({ visible: false }))
		this.store$.dispatch(new SearchStoreActions.ClearAction())
		//}
	}

	setMultipleSelection(items: any) {
		if (this.deviceService.isMobile && items[0] && items[0].folder_id) {
			//if device is mobile navigate with selection
			const path = `projects/folders/${items[0].folder_id}`
			this.store$.dispatch(new RouteActions.Go({ path: [path] }))
		}

		if (!items.length) {
			this.store$.dispatch(new LayoutStoreActions.ShowDetailSidenav({ visible: false }))
		}

		if (items.length === 1) {
			this.setSelectedItem(items[0])
		} else {
			if (this.isSearchActive) {
				this.store$.dispatch(new FolderStoreActions.SetSelectedFromSearchAction({ item: items }))
			} else {
				const itemIds = items.map(t => t.file_id || t.folder_id)
				this.store$.dispatch(new FolderStoreActions.SetSelectedAction({ item: itemIds }))
			}
		}
	}

	setSelectedItem(payload: any) {
		// selected item from search window
		if (payload.property && this.isSearchActive) {
			const projectMetadata = {
				projectId: payload.folder_id,
				metadata: payload.property,
			} as ProjectMetadata
			this.store$.dispatch(new FolderStoreActions.LoadProjectMetadataSuccessAction({ data: projectMetadata }))
			this.store$.dispatch(new FolderStoreActions.SetSelectedFromSearchAction({ item: payload }))
		} else {
			// selected item from repository window
			this.store$.dispatch(new FolderStoreActions.SetSelectedAction({ item: [payload.file_id || payload.folder_id] }))
		}

		this.selectSidenavActiveTab$
			.pipe(
				take(1),
				filter(t => t === 'activity'),
			)
			.subscribe(tab => {
				if (payload.file_id) {
					this.store$.dispatch(new FileActions.LoadAuditHistoryRequestAction({ fileId: payload.file_id }))
				}
			})
	}

	clearSelection() {
		this.store$.dispatch(new FolderStoreActions.SetSelectedAction({ item: null }))
	}

	showModal(modalId: string, model: any = null) {
		this.modalService.open(modalId, model)
	}

	closeModal(modalId: string): any {
		this.modalService.close(modalId)
	}

	onItemDownload(file) {
		this.store$.dispatch(new FileActions.DownloadRequestAction({ file }))
	}

	/**
	 * Context Menu Actions
	 */
	onContextAction(event) {
		const action = event.action as IContextMenuAction
		if (action.modalId) {
			event.items = this.selectedItems
			this.showModal(action.modalId, event)
			return
		}

		switch (action.type) {
			case 'DownloadAsset': {
				this.store$.dispatch(new FileActions.DownloadRequestAction({ file: event.payload }))
				break
			}
			case 'DownloadFolder': {
				this.breadcrumbs$.pipe(take(1)).subscribe(breadcrumbs => {
					const folder = {
						folder_id: event.payload.folder_id,
						folder_name: event.payload.name,
						repo_id: event.payload.repo_id,
						path: breadcrumbs.map(t => t.label).join('/'),
					}
					this.store$.dispatch(new DownloadFolderStoreActions.DownloadFolderRequestAction({ folder }))
				})
				break
			}
			case 'ViewDetails': {
				if (!event.payload) return
				this.setSelectedItem(event.payload)
				this.showDetailSidenav(true)
				break
			}
		}
	}

	expandCollapseUploadDialog() {
		this.store$.dispatch(new LayoutStoreActions.ToggleBottomSidenav())
	}

	trackByFile(index, item) {
		return item ? item.file_id : undefined
	}
	trackByFolder(index, item) {
		return item ? item.folder_id : undefined
	}

	sortBy(field) {
		this.store$.dispatch(new LayoutStoreActions.SortFieldChange({ field }))
	}

	customComparator(itemA: any, itemB: any, filter: string) {
		if (!filter) return itemA > itemB ? 1 : -1

		if (!itemA.property || !itemB.property) return

		let sortField = filter.split('-')
		if (sortField.length === 1) return false

		let index = itemA.property.findIndex(x => x.name == sortField[1])
		itemA = itemA.property[index].value

		index = itemB.property.findIndex(x => x.name == sortField[1])
		itemB = itemB.property[index].value

		return itemA > itemB ? 1 : -1
	}

	changeSortDirection() {
		this.store$.dispatch(new LayoutStoreActions.SortDirectionChange())
	}

	clearUploadFile(file: FileUpload) {
		this.store$.dispatch(new UploadStoreActions.ClearUploadListAction({ file }))
	}

	clearDoownloadFolder(folder: FolderDownload) {
		this.store$.dispatch(new DownloadFolderStoreActions.RemoveDownloadFolderRequestAction({ folder }))
	}

	clearAllUploads() {
		this.store$.dispatch(new UploadStoreActions.ClearAllUploadListAction())
	}

	clearAllDownloads() {
		this.store$.dispatch(new DownloadFolderStoreActions.ClearDownloadFoldersAllAction())
	}

	switchView(view) {
		this.store$.dispatch(new LayoutStoreActions.ViewChange({ view: view }))
	}

	onClearSearch() {
		this.store$.dispatch(new LayoutStoreActions.ShowDetailSidenav({ visible: false }))
		this.store$.dispatch(new SearchStoreActions.ClearAction())
	}

	toggleMyProjects(event) {
		this.store$.dispatch(new SearchStoreActions.SetFilterMyProjectsAction({ value: event.target.checked }))

		if (!this.activeProjectFilter) {
			const query = event.target.checked ? { 'property.value': { $regex: this.user.email } } : {}

			this.folderService.getAllProjectFromStorageCount(query).subscribe(count => {
				this.store$.dispatch(new LayoutStoreActions.TableChangePage({ page: 1, count }))
				this.store$.dispatch(new FolderStoreActions.LoadProjectFromStorageRequestAction({ setFolders: true }))
			})
		}
	}

	toggleActiveProjects(event) {
		this.cacheService.expire()
		this.store$.dispatch(new SearchStoreActions.SetFilterActiveProjectsAction({ value: event.target.checked }))
		this.activeProjectFilter = event.target.checked
		if (event.target.checked) {
			this.store$.dispatch(new FolderStoreActions.LoadProjectFromStorageRequestAction({ setFolders: true }))
		} else {
			// Get count for all projects pagination
			// hack

			const query = this.selectMyProjectsFilter ? { 'property.value': { $regex: this.user.email } } : {}

			this.folderService.getAllProjectFromStorageCount(query).subscribe(count => {
				this.store$.dispatch(new LayoutStoreActions.TableChangePage({ page: 1, count }))
				this.store$.dispatch(new FolderStoreActions.LoadProjectFromStorageRequestAction({ setFolders: true }))
			})
		}
	}

	changePage(event) {
		this.store$.dispatch(new LayoutStoreActions.TableChangePage({ page: event.pageIndex + 1, limit: event.pageSize }))
		this.store$.dispatch(new FolderStoreActions.LoadProjectFromStorageRequestAction({ setFolders: true }))
	}

	filterProjectsBy(type, event) {
		switch (type) {
			case 'L': {
				this.store$.dispatch(new SearchStoreActions.SetFilterLoSAction({ value: event.target.value }))
				break
			}
			case 'B': {
				this.store$.dispatch(new SearchStoreActions.SetFilterBusinessUnitAction({ value: event.target.value }))
				break
			}
		}
	}

	fileIsSelected(items: Array<any>, file: any) {
		return items && items.some(t => t.file_id === file.file_id)
	}

	folderIsSelected(items: Array<any>, folder: any) {
		return items && items.some(t => t.folder_id === folder.folder_id)
	}

	onSlideInOut(event) {
		this.isDetailSidenavOn = event.toState == 'in'
	}

	showDetailSidenav(visible) {
		this.store$.dispatch(new LayoutStoreActions.ShowDetailSidenav({ visible }))
	}

	onSidenavOpenTab(event) {
		if (event.tab === 'activity') {
			this.store$.dispatch(new LayoutStoreActions.SetSidenavActiveTab({ tab: 'activity' }))
			this.store$.dispatch(new FileActions.LoadAuditHistoryRequestAction({ fileId: event.fileId }))
		} else {
			this.store$.dispatch(new LayoutStoreActions.SetSidenavActiveTab({ tab: 'details' }))
		}
	}

	refresh() {
		this.cacheService.expire()
		this.store$.dispatch(new FolderStoreActions.LoadProjectFromStorageRequestAction({ setFolders: true }))
	}

	changeSidenavActiveTab(tab) {
		this.store$.dispatch(new LayoutStoreActions.SetActiveTabBottomSidenav({ tab }))
	}

	notifyDownload(checked: boolean, f: Partial<FolderDownload>) {
		const folder = { ...f, notify_user: checked }
		this.store$.dispatch(new DownloadFolderStoreActions.NotifyFolderDownload({ folder }))
	}
}
