import { Directive, HostListener, HostBinding, Output, EventEmitter, Input } from '@angular/core'
import { ProcessFileService } from '../services/process-file.service'

@Directive({
	selector: '[dndFile]',
})
export class DndDirective {
	@Input()
	dndDisabled: boolean

	@Output('dndFile')
	dndOnDragLeave: EventEmitter<any> = new EventEmitter<any>()

	constructor(private processFileService: ProcessFileService) {}

	@HostBinding('class.drop') showDragAndDropStyle = false

	@HostListener('dragover', ['$event']) onDragOver(evt) {
		evt.preventDefault()
		evt.stopPropagation()
		if (this.dndDisabled) {
			this.showDragAndDropStyle = false
			return
		}

		this.showDragAndDropStyle = true
	}

	@HostListener('dragleave', ['$event']) public onDragLeave(evt) {
		evt.preventDefault()
		evt.stopPropagation()
		this.showDragAndDropStyle = false
	}

	@HostListener('drop', ['$event']) public onDrop(evt) {
		evt.preventDefault()
		evt.stopPropagation()
		if (this.dndDisabled) {
			return
		}

		this.showDragAndDropStyle = false

		if (!evt.dataTransfer) {
			return
		}

		let { items } = evt.dataTransfer

		if (items && items.length && items[0].webkitGetAsEntry != null) {
			let entries = []
			for (let item of items) {
				let value = item.webkitGetAsEntry ? item.webkitGetAsEntry() : null
				if (value) {
					entries.push(value)
				}
			}

			if (entries.length) {
				this.processFileService.makedirFromDrag(entries).then(t => {
					this.dndOnDragLeave.emit(t)
				})
			}
		}
	}
}
