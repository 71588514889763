import { Component, HostListener, OnDestroy, OnInit } from '@angular/core'
import { Observable, of, combineLatest } from 'node_modules/rxjs'
import { UserStoreActions, UserStoreSelectors } from 'src/app/root-store/user-store'
import { RootStoreState, FolderStoreSelectors, ApmStoreActions } from 'src/app/root-store'
import { Store } from 'node_modules/@ngrx/store'
import { WorkfrontStoreActions, WorkfrontStoreSelectors } from 'src/app/root-store/workfront-store'
import { environment } from '../../../environments/environment'
import { ModalService, RepositoryService, RequestCacheService, FileService } from 'src/app/services'
import { switchMap, map, first, takeUntil, take, takeWhile } from 'rxjs/operators'
import { getMetadataBase } from 'src/app/common/helpers'
import { FolderActions } from 'src/app/root-store/folder-store/actions'
import { User, Project, Folder } from '@app/models'
import { SocialAuthService } from 'angularx-social-login'
import { ApiGeeService } from '@app/services/api-gee.service'

@Component({
	selector: 'dam-workfront',
	templateUrl: './workfront.component.html',
	styleUrls: ['./workfront.component.css'],
})
export class WorkfrontComponent implements OnInit {
	isLoading$: Observable<boolean>
	user$: Observable<User>
	projects$: Observable<Project[]>
	damProjects$: Observable<Folder[]>
	isDamProjectsLoaded$: Observable<boolean>
	selectOutdateProjects$: Observable<Folder[]>
	damProjects: Folder[]

	model: Project
	credentials = null

	constructor(
		private store$: Store<RootStoreState.State>,
		private modalService: ModalService,
		private repositoryService: RepositoryService,
		private fileService: FileService,
		private cache: RequestCacheService,
		private authService: SocialAuthService,
		private apiGeeService: ApiGeeService,
	) {
		if (sessionStorage.getItem('wfusr')) {
			this.credentials = {
				email: sessionStorage.getItem('wfusr'),
				password: sessionStorage.getItem('wfpass'),
			}
		}

		localStorage.setItem('pSource', 'workfront')
	}

	ngOnInit() {
		this.authService.authState.subscribe(user => {
			if (user !== null) {
				this.apiGeeService.googleAuthToken = user.authToken
				this.apiGeeService.userEmail = user.email
				this.apiGeeService.idToken = user.idToken
				this.apiGeeService.authenticate().subscribe(t => {
					this.store$.dispatch(new UserStoreActions.LoginSuccessAction({ user: { ...user, accessToken: t.access_token } }))
				})
			}
		})

		this.cache.expire()

		this.user$ = this.store$.select(UserStoreSelectors.selectUser)
		this.projects$ = this.store$.select(WorkfrontStoreSelectors.wfProjects)
		this.isLoading$ = this.store$.select(WorkfrontStoreSelectors.selectIsLoading)
		this.damProjects$ = this.store$.select(state => state.folders.folders)
		// this.selectOutdateProjects$ = this.store$.select(WorkfrontStoreSelectors.selectOutdateProjects);
		this.selectOutdateProjects$ = this.store$.select(t => t.workfront.sync_projects)
		this.isDamProjectsLoaded$ = this.store$.select(WorkfrontStoreSelectors.isDamProjectLoaded)

		this.store$.select(WorkfrontStoreSelectors.loginError).subscribe(t => {
			if (t) {
				this.credentials = null
				sessionStorage.removeItem('wfusr')
				sessionStorage.removeItem('wfpass')
			}
		})

		this.damProjects$.subscribe(t => {
			this.damProjects = t
		})

		combineLatest(this.damProjects$, this.projects$)
			.pipe(takeWhile(([dam, wf]) => !dam.length || !wf.length, true))
			.subscribe(([dam, wf]) => {
				if (!dam.length) {
					return
				}

				// get projects out sync
				const syncProjects = dam.filter(proj => {
					// return (
					// 	!wf.find(x => x.workfront_id == proj.property.find(x => x.name == environment.metadata.workfrontId.name).value) &&
					// 	environment.workfrontStatusFilter.find(x => x == proj.property.find(x => x.name == environment.metadata.status.name).value)
					// )

					if (!proj.property) {
						// console.log('Project without metadata:', proj)
						return false
					}

					const wfProject = wf.find(x => x.workfront_id == proj.property.find(x => x.name == environment.metadata.workfrontId.name).value)
					const projPropStatus = proj.property.find(x => x.name == environment.metadata.status.name)

					// if project no esta en wf y esta en un estado de sync se sync
					if (!wfProject && environment.workfrontStatusFilter.includes(projPropStatus.value)) {
						return true
					}

					// si tengo los dos projectos valido que esten en el mismo estado
					if (wfProject) {
						const wfPropStatus = wfProject.metadata.find(t => t.name == environment.metadata.status.name)
						return wfPropStatus.value != projPropStatus.value
					}

					return false
				})

				this.store$.dispatch(new WorkfrontStoreActions.AddSyncProjectsAction({ projects: syncProjects }))
			})

		if (this.credentials) {
			this.getProjects(this.credentials)
		}

		this.selectOutdateProjects$.pipe(takeWhile(t => !t.length, true)).subscribe(t => {
			if (t.length) {
				this.modalService.open('sync-projects')
			}
		})
	}

	getProjects(model) {
		if (!this.credentials) {
			this.credentials = model
		}
		this.store$.dispatch(new FolderActions.ClearProjectMetadataAction())
		this.store$.dispatch(new WorkfrontStoreActions.LoadWorkfrontProjects({ email: model.email, password: model.password }))
	}

	addProject(project) {
		this.store$.dispatch(new WorkfrontStoreActions.CreateProjectRequestAction({ project }))
	}

	// sync modal
	syncProjects(projects: Folder[]) {
		let fileProjects = this.damProjects.filter(x => projects.find(fp => fp.metadataFileId == x.metadataFileId))
		// Update project name
		// for (let file of fileProjects) {
		//   file.folder_name = file.folder_path.split('> ').pop();
		// }

		console.log('projects', projects)
		console.log('fileprojects', fileProjects)

		this.store$.dispatch(new WorkfrontStoreActions.SyncProjectsRequestAction({ projects: fileProjects }))
	}

	// validate
	syncProject(project: Project) {
		let fileProjects = this.damProjects.filter(
			x => x.property.find(x => x.name == environment.metadata.workfrontId.name).value == project.workfront_id,
		)

		// Update project name
		if (fileProjects.length) {
			fileProjects[0].name = project.name
			this.store$.dispatch(new WorkfrontStoreActions.SyncProjectsRequestAction({ projects: fileProjects }))
		}
	}

	closeModal() {
		// this.store$.dispatch(new WorkfrontStoreActions.ClearOutdateProjectAction());
		this.modalService.close('sync-projects')
	}

	openLinkProject(project) {
		try {
			const damProjectWithoutParent = this.damProjects.filter(x => {
				if (!x.property || !x.property.length) {
					return false
				}

				const parent = x.property && x.property.find(t => t.name.toLowerCase() === environment.metadata.parentProject.name.toLowerCase())
				const workfrontId = x.property && x.property.find(t => t.name.toLowerCase() === environment.metadata.workfrontId.name.toLowerCase())
				return (!parent || !parent.value) && workfrontId.value !== project.workfront_id
			})

			const damProject = this.damProjects.find(t => {
				return (
					t.property &&
					t.property.some(
						p => p.name.toLowerCase() === environment.metadata.workfrontId.name.toLowerCase() && p.value === project.workfront_id,
					)
				)
			})

			const isParent = this.isParentProject(project, this.damProjects)

			const modalCtx = {
				action: {
					type: 'link-project',
					parents: !isParent ? damProjectWithoutParent : [],
					project: {
						...project,
						file_id: damProject && damProject.metadataFileId,
						isRootProject: isParent,
					},
				},
			}

			this.modalService.open('link-project', modalCtx)
		} catch (err) {
			console.log(err)
		}
	}

	saveProjectLink(e) {
		return this.modalService
			.getContext()
			.pipe(
				first(),
				switchMap(ctx => {
					const damProject = this.damProjects.find(t => t.metadataFileId === ctx.model.action.project.file_id)
					const parentMetadata = damProject.property.find(
						t => t.name.toLowerCase() === environment.metadata.parentProject.name.toLowerCase(),
					)
					if (!parentMetadata) {
						return this.repositoryService.getMetadataSchema(e.repo_id).pipe(
							map(schema => {
								return {
									file_id: damProject.metadataFileId,
									file_properties: schema
										.filter(s => s.field_name.toLowerCase() === environment.metadata.parentProject.name.toLowerCase())
										.map(s => {
											return {
												is_required: s.is_required,
												metadata_id: s.unique_id,
												name: s.field_name,
												type: s.field_type,
												value: e.folder_id,
												user_action: 'new',
											}
										}),
								}
							}),
						)
					}

					return this.fileService.getFileDict(damProject.metadataFileId).pipe(
						map(dict => dict.file.property),
						map(schema => {
							return {
								file_id: damProject.metadataFileId,
								file_properties: schema
									.filter(s => s.name.toLowerCase() === environment.metadata.parentProject.name.toLowerCase())
									.map(s => {
										const result = {
											is_required: s.is_required,
											metadata_id: s.metadata_id,
											name: s.name,
											type: s.type,
											value: (e && e.folder_id) || '',
											user_action: 'update',
											unique_id: s.unique_id,
										}
										return result
									}),
							}
						}),
					)
				}),
			)
			.subscribe(fileUpdate => {
				this.store$.dispatch(new ApmStoreActions.LinkProjects({ data: fileUpdate })),
					this.store$.dispatch(new WorkfrontStoreActions.UpdateMetadataProjectRequestAction({ fileUpdate }))
			})
	}

	isParentProject(project: Project, damProjects: Folder[]) {
		const damProject = damProjects.find(t => {
			const workfrontId = getMetadataBase(t.property, environment.metadata.workfrontId.name)
			return project.workfront_id === workfrontId
		})

		return damProjects.some(t => {
			const parentMetadata = getMetadataBase(t.property, environment.metadata.parentProject.name)
			return parentMetadata === damProject.folder_id
		})
	}

	showModal(modalId: string, model: any = null) {
		this.modalService.open(modalId, model)
	}

	generateStorageProjectsFile() {
		this.modalService.close('confirmation-generate')
		this.store$.dispatch(new WorkfrontStoreActions.GenerateStorageProjectsFileAction())
	}
}
