<header class="modal-header">
  <h2 class="modal-title">
    Generate file projects
  </h2>
  <button class="modal-header-btn modal-close" type="button" (click)="cancel()">
    <img src="../../../assets/images/icons/close.svg" alt="Close" title="Close">
  </button>
</header>
<div class="modal-content">
  <form class="new-project-form form" (ngSubmit)="onSubmit.emit()" #frm="ngForm" novalidate>
    <div class="form-group">
      <h3>Are you sure you want to generate all projects?</h3>
    </div>
    <div class="form-actions modal-footer">
      <button class="form-btn modal-close" mat-button type="button" (click)="cancel()">CANCEL</button>
      <button class="form-btn" mat-button type="submit" [disabled]="!frm.form.valid">OK</button>
    </div>
  </form>
</div>
