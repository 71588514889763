import { Action } from '@ngrx/store'
import { SearchFileResult, SearchFolderResult, SearchMetadataCriteria, AuditHistory } from '@app/models'

export enum ActionTypes {
	SEARCH_REQUEST = '[Search] Search Request',
	SEARCH_FAILURE = '[Search] Search Failure',
	SEARCH_SUCCESS = '[Search] Search Success',

	SEARCH_FOLDER_REQUEST = '[Search] Search Folder Request',
	SEARCH_FOLDER_FAILURE = '[Search] Search Folder Failure',
	SEARCH_FOLDER_SUCCESS = '[Search] Search Folder Success',

	PROJECT_FILTER_REQUEST = '[Search] Project filter Request',
	PROJECT_FILTER_FAILURE = '[Search] Project filter Failure',
	PROJECT_FILTER_SUCCESS = '[Search] Project filter Success',

	CLEAR = '[Search] Clear',

	ADD_METADATA_CRITERIA = '[Search] Add metadata criteria',
	DELETE_METADATA_CRITERIA = '[Search] Delete metadata criteria',
	ADD_QUERYCONTENT = '[Search] Add query content to search',
	ADD_CONTEXT = '[Search] Set search context',

	SET_FILTER_MY_PROJECTS = '[Search] Set filter my projects',
	SET_FILTER_ACTIVE_PROJECTS = '[Search] Set filter active projects',
	SET_FILTER_LOS = '[Search] Set filter by LoS',
	SET_FILTER_BUSINESS_UNIT = '[Search] Set filter by Business Unit',

	SET_AUDIT_HISTORY_TO_SEARCH_FILE = '[Search] Set audit history to search file',

	SET_ENABLED = '[Search] Set enabled',
}

export class SearchRequestAction implements Action {
	readonly type = ActionTypes.SEARCH_REQUEST
}

export class SearchFailureAction implements Action {
	readonly type = ActionTypes.SEARCH_FAILURE
	constructor(public payload: { error: string }) {}
}

export class SearchSuccessAction implements Action {
	readonly type = ActionTypes.SEARCH_SUCCESS
	constructor(public payload: { items: SearchFileResult[] }) {}
}

export class SearchFolderRequestAction implements Action {
	readonly type = ActionTypes.SEARCH_FOLDER_REQUEST
}

export class SearchFolderFailureAction implements Action {
	readonly type = ActionTypes.SEARCH_FOLDER_FAILURE
	constructor(public payload: { error: string }) {}
}

export class SearchFolderSuccessAction implements Action {
	readonly type = ActionTypes.SEARCH_FOLDER_SUCCESS
	constructor(public payload: { items: SearchFolderResult[] }) {}
}

export class ProjectFilterRequestAction implements Action {
	readonly type = ActionTypes.PROJECT_FILTER_REQUEST
}

export class ProjectFilterFailureAction implements Action {
	readonly type = ActionTypes.PROJECT_FILTER_FAILURE
	constructor(public payload: { error: string }) {}
}

export class ProjectFilterSuccessAction implements Action {
	readonly type = ActionTypes.PROJECT_FILTER_SUCCESS
	constructor(public payload: { items: SearchFileResult[] }) {}
}

export class ClearAction implements Action {
	readonly type = ActionTypes.CLEAR
}

export class AddMetadataCriteriaAction implements Action {
	readonly type = ActionTypes.ADD_METADATA_CRITERIA
	constructor(public payload: { data: SearchMetadataCriteria }) {}
}

export class DeleteMetadataCriteriaAction implements Action {
	readonly type = ActionTypes.DELETE_METADATA_CRITERIA
	constructor(public payload: { metadataId: string }) {}
}

export class AddQueryContentAction implements Action {
	readonly type = ActionTypes.ADD_QUERYCONTENT
	constructor(public payload: { value: string }) {}
}

export class AddContextContentAction implements Action {
	readonly type = ActionTypes.ADD_CONTEXT
	// constructor(public payload: { repositoryId: string, folderId: string, type: 'project' | 'files' }) { }
	constructor(public payload: { repositoryId: string; folderId: string }) {}
}

export class SetFilterMyProjectsAction implements Action {
	readonly type = ActionTypes.SET_FILTER_MY_PROJECTS
	constructor(public payload: { value: boolean }) {}
}

export class SetFilterActiveProjectsAction implements Action {
	readonly type = ActionTypes.SET_FILTER_ACTIVE_PROJECTS
	constructor(public payload: { value: boolean }) {}
}

export class SetFilterLoSAction implements Action {
	readonly type = ActionTypes.SET_FILTER_LOS
	constructor(public payload: { value: string }) {}
}

export class SetFilterBusinessUnitAction implements Action {
	readonly type = ActionTypes.SET_FILTER_BUSINESS_UNIT
	constructor(public payload: { value: string }) {}
}

export class SetAuditHistoryToSearchFileAction implements Action {
	readonly type = ActionTypes.SET_AUDIT_HISTORY_TO_SEARCH_FILE
	constructor(public payload: { fildeId: string; auditHistory: Array<AuditHistory> }) {}
}

export class SetEnabled implements Action {
	readonly type = ActionTypes.SET_ENABLED
	constructor(public payload: { value: boolean }) {}
}

export type Actions =
	| SearchRequestAction
	| SearchFailureAction
	| SearchSuccessAction
	| SearchFolderRequestAction
	| SearchFolderFailureAction
	| SearchFolderSuccessAction
	| ProjectFilterRequestAction
	| ProjectFilterFailureAction
	| ProjectFilterSuccessAction
	| ClearAction
	| AddMetadataCriteriaAction
	| DeleteMetadataCriteriaAction
	| AddQueryContentAction
	| AddContextContentAction
	| SetFilterMyProjectsAction
	| SetFilterActiveProjectsAction
	| SetFilterBusinessUnitAction
	| SetFilterLoSAction
	| SetAuditHistoryToSearchFileAction
	| SetEnabled
