import { Injectable } from '@angular/core'
import { NavigationStart, Router } from '@angular/router'
import { Subject, Observable } from 'rxjs'
import { AlertType, Alert } from '../models/alert.model'

@Injectable({
	providedIn: 'root',
})
export class AlertToastService {
	private addSubject = new Subject<Alert>()
	private dismissSubject = new Subject<Alert>()
	private keepAfterRouteChange = true
	private autoDismissDelay = 5000

	constructor(private router: Router) {
		this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				if (this.keepAfterRouteChange) {
					this.keepAfterRouteChange = false
				} else {
					this.clear()
				}
			}
		})
	}

	getAlert(): Observable<Alert> {
		return this.addSubject.asObservable()
	}

	getAlertDismissed(): Observable<Alert> {
		return this.dismissSubject.asObservable()
	}

	success(message: string, keepAfterRouteChange = false, autoDismiss = true) {
		this.alert(AlertType.Success, message, keepAfterRouteChange, autoDismiss)
	}

	error(message: string, keepAfterRouteChange = false, autoDismiss = true) {
		this.alert(AlertType.Error, message, keepAfterRouteChange, autoDismiss)
	}

	info(message: string, keepAfterRouteChange = false, autoDismiss = true) {
		this.alert(AlertType.Info, message, keepAfterRouteChange, autoDismiss)
	}

	warn(message: string, keepAfterRouteChange = false, autoDismiss = true) {
		this.alert(AlertType.Warning, message, keepAfterRouteChange, autoDismiss)
	}

	alert(type: AlertType, message: string, keepAfterRouteChange = false, autoDismiss = true) {
		this.keepAfterRouteChange = keepAfterRouteChange
		const alert = <Alert>{ type: type, message: message }
		this.addSubject.next(alert)

		if (!autoDismiss) {
			return
		}

		setTimeout(() => {
			this.dismissSubject.next(alert)
		}, this.autoDismissDelay)
	}

	clear() {
		this.dismissSubject.next()
	}
}
