import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, OnDestroy } from '@angular/core'
import { IContextMenuAction } from '../../models/context-menu.model'
import { ContextMenuService } from '../../services/context-menu.service'
import { Observable, Subject } from '../../../../node_modules/rxjs'
import { Store } from '../../../../node_modules/@ngrx/store'
import { RootStoreState, FolderStoreSelectors, SearchStoreSelectors } from '../../root-store'
import { takeUntil } from 'rxjs/operators'
import { DamFile, Folder } from '@app/models'

@Component({
	selector: 'dam-folder-item',
	templateUrl: './folder-item.component.html',
	styleUrls: ['./folder-item.component.css'],
})
export class FolderItemComponent implements OnInit, OnDestroy {
	destroy$ = new Subject()
	contextMenuActions: IContextMenuAction[] = []
	selectedItems$: Observable<(DamFile | Folder)[]>

	@Input()
	layout: 'grid' | 'list' = 'grid'

	@Input()
	model: Folder

	@Output()
	onDragLeave: EventEmitter<any> = new EventEmitter()

	@Output()
	onContextAction: EventEmitter<any> = new EventEmitter()

	constructor(private contexMenuService: ContextMenuService, private store$: Store<RootStoreState.State>) {
		this.contextMenuActions = this.contexMenuService.getContextMenu('folder')
		this.store$
			.select(SearchStoreSelectors.isSearchActive)
			.pipe(takeUntil(this.destroy$))
			.subscribe(isSearchActive => {
				this.contextMenuActions = isSearchActive
					? this.contexMenuService.getContextMenu('search-folder')
					: this.contexMenuService.getContextMenu('folder')
			})
	}

	ngOnInit() {
		this.selectedItems$ = this.store$.select(FolderStoreSelectors.selectedItems)
	}

	ngOnDestroy(): void {
		this.destroy$.next(true)
	}

	dragLeave(event) {
		this.onDragLeave.emit({ type: 'folder', model: this.model, event: event })
	}

	onClickContextMenu(event) {
		this.onContextAction.emit({ action: event, payload: this.model })
	}
}
