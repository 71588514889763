import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { Observable, of } from 'rxjs'
import { Action, Store, select } from '@ngrx/store'
import { switchMap, map, catchError, withLatestFrom, tap } from 'rxjs/operators'
import { FolderService, RepositoryService } from '../../services'
import { LayoutStoreActions } from '../layout-store'
import { RootStoreState } from './../'
import * as FolderActions from '../folder-store/actions/folder.actions'
import * as featureSelectors from './selectors'
import * as featureActions from './actions'
import { getFolderPath } from '@app/common/helpers'
import { Folder } from '@app/models'

@Injectable()
export class SearchStoreEffects {
	constructor(
		private actions$: Actions,
		private store$: Store<RootStoreState.State>,
		private repositoryService: RepositoryService,
		private folderService: FolderService,
	) {}

	@Effect()
	searchRequestEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.SearchRequestAction>(featureActions.ActionTypes.SEARCH_REQUEST),
		withLatestFrom(this.store$.pipe(select(featureSelectors.selectSearchCriteria))),
		switchMap(([action, searchCriteria]) =>
			this.repositoryService.search(searchCriteria).pipe(
				map(t => t.files),
				map(items => new featureActions.SearchSuccessAction({ items })),
				catchError(error => [
					new LayoutStoreActions.ShowAlertToast({
						message: 'An error has occurred and the search cannot be completed.',
					}),
					new featureActions.SearchFailureAction({ error }),
				]),
			),
		),
	)

	@Effect()
	searchFolderRequestEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.SearchFolderRequestAction>(featureActions.ActionTypes.SEARCH_FOLDER_REQUEST),
		withLatestFrom(this.store$.pipe(select(featureSelectors.selectFolderSearchCriteria))),
		switchMap(([action, searchCriteria]) =>
			this.repositoryService.searchFolders(searchCriteria).pipe(
				map(t => t.folders),
				switchMap(folders => {
					const foldersName = folders.map(f => getFolderPath({ name: f.name, folder_path: f.folder_path }))
					// add metadata to folder
					return this.folderService.getProjectsByNameFromStorage(foldersName).pipe(
						map(storageProject => {
							return folders.map(folder => {
								const index = storageProject.findIndex(
									s => s.name === getFolderPath({ name: folder.name, folder_path: folder.folder_path }),
								)
								return {
									...folder,
									property: !!~index ? storageProject[index].property : [],
								}
							})
						}),
					)
				}),
				map(items => new featureActions.SearchFolderSuccessAction({ items })),
				catchError(error => [
					new LayoutStoreActions.ShowAlertToast({
						message: 'An error has occurred and the folder search cannot be completed.',
					}),
					new featureActions.SearchFolderFailureAction({ error }),
				]),
			),
		),
	)

	/***
	 * REFACTOR: No references to this effect found
	 */
	@Effect()
	loadMyProjectsEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.ProjectFilterRequestAction>(featureActions.ActionTypes.PROJECT_FILTER_REQUEST),
		withLatestFrom(this.store$.pipe(select(featureSelectors.selectSearchCriteria))),
		switchMap(([action, searchCriteria]) =>
			this.repositoryService.search(searchCriteria).pipe(
				map(t => t.files),
				switchMap(items => [
					new featureActions.ProjectFilterSuccessAction({ items }),
					new FolderActions.UpdateFolderMetadataFromSearch({ data: items }),
				]),
				catchError(error => [
					new LayoutStoreActions.ShowAlertToast({ message: 'An error has occurred loading projects.' }),
					new featureActions.ProjectFilterFailureAction({ error }),
				]),
			),
		),
	)
}
