import { Store, Action, select } from '@ngrx/store'
import { RootStoreState } from '..'
import { Injectable } from '@angular/core'
import { Effect, Actions, ofType, createEffect } from '@ngrx/effects'
import { Observable, of, timer } from 'rxjs'
import { map, catchError, mergeMap, withLatestFrom, tap, switchMap, exhaustMap, take, delay } from 'rxjs/operators'
import { DownloadFolderService } from 'src/app/services/download-folder.service'
import { LayoutStoreActions } from '../layout-store'
import { TabBottomSidenav } from 'src/app/models/sidenav.model'

import * as featureActions from './actions'
import * as featureSelectors from './selectors'
import { FolderDownload } from '@app/models'

@Injectable()
export class DownloadFolderStoreEffects {
	constructor(
		private store$: Store<RootStoreState.State>,
		private actions$: Actions,
		private downloadFolderService: DownloadFolderService,
	) {}

	@Effect()
	downloadRequestEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.DownloadFolderRequestAction>(featureActions.ActionTypes.DOWNLOAD_FOLDER_REQUEST),
		withLatestFrom(this.store$.pipe(select(featureSelectors.selectDownloadFolders))),
		mergeMap(([action, downloadFolders]) => {
			const index = downloadFolders.findIndex(p => p.folder_id === action.payload.folder.folder_id)

			if (index !== -1 && downloadFolders[index].completed) {
				return this.downloadFolderService.remove(downloadFolders[index]).pipe(
					mergeMap(() => {
						return this.downloadFolderService.download(action.payload.folder).pipe(
							mergeMap(folder => [
								new featureActions.DownloadFolderSuccessAction({ folder }),
								new LayoutStoreActions.SetBottomSidenav({ visible: true }),
								new LayoutStoreActions.SetActiveTabBottomSidenav({ tab: TabBottomSidenav.Download }),
							]),
							catchError(error => [
								new LayoutStoreActions.ShowAlertToast({
									message: 'An error has occurred and the download of the folder can not be completed.',
								}),
								new featureActions.DownloadFolderFailureAction({ error }),
							]),
						)
					}),
				)
			}

			if (index === -1) {
				return this.downloadFolderService.download(action.payload.folder).pipe(
					mergeMap(folder => [
						new featureActions.DownloadFolderSuccessAction({ folder }),
						new LayoutStoreActions.SetBottomSidenav({ visible: true }),
						new LayoutStoreActions.SetActiveTabBottomSidenav({ tab: TabBottomSidenav.Download }),
					]),
					catchError(error => [
						new LayoutStoreActions.ShowAlertToast({
							message: 'An error has occurred and the download of the folder can not be completed.',
						}),
						new featureActions.DownloadFolderFailureAction({ error }),
					]),
				)
			} else if (!downloadFolders[index].completed) {
				return [
					new LayoutStoreActions.ShowAlertToast({ message: 'This folder is already being downloaded' }),
					new featureActions.DownloadFolderSuccessAction({ folder: downloadFolders[index] }),
				] as Action[]
			}

			return of(new featureActions.DownloadFolderSuccessAction({ folder: downloadFolders[index] }))
		}),
	)

	@Effect()
	notifyDownloadFolderEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.DownloadFolderRequestAction>(featureActions.ActionTypes.DOWNLOAD_FOLDER_REQUEST),
		map(t => new LayoutStoreActions.ShowAlertToast({ message: 'Preparing download...', autoDismiss: true })),
	)

	@Effect()
	loadRequestEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.LoadRequestAction>(featureActions.ActionTypes.LOAD_REQUEST),
		mergeMap(action => {
			return this.downloadFolderService.load().pipe(
				map(folders => new featureActions.LoadSuccessAction({ folders })),
				catchError(error => [
					new LayoutStoreActions.ShowAlertToast({ message: 'An error occurred while loading folder downloads' }),
					new featureActions.LoadFailureAction({ error }),
				]),
			)
		}),
	)

	@Effect({ dispatch: false })
	synchronizeRequestEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.SynchronizeRequestAction>(featureActions.ActionTypes.SYNCHRONIZE_REQUEST),
		exhaustMap(action => {
			return timer(0, 10000).pipe(
				mergeMap(action => {
					return this.downloadFolderService.synchronize().pipe(
						map(folders => {
							let actions = []
							this.store$.dispatch(new featureActions.SynchronizeSuccessAction({ folders }))

							for (let folder of folders.filter(t => !t.downloaded && t.completed)) {
								this.store$.dispatch(new featureActions.DownloadZipFolderRequestAction({ folder }))
							}
							return actions
						}),
						catchError(error => {
							this.store$.dispatch(
								new LayoutStoreActions.ShowAlertToast({
									message: 'An error occurred while synchronizing folder downloads',
								}),
							)

							this.store$.dispatch(new featureActions.SynchronizeFailureAction({ error }))
							return of(error)
						}),
					)
				}),
				catchError(err => {
					return of(err)
				}),
			)
		}),
	)

	// synchronizeRequestEffect1$ = createEffect(() => this.actions$.pipe(
	// 	ofType<featureActions.SynchronizeRequestAction>(featureActions.ActionTypes.SYNCHRONIZE_REQUEST),

	// 	mergeMap(() => {
	// 		return timer(0, 1000).pipe(tt => {
	// 			console.log('timer 1')
	// 			return this.downloadFolderService.synchronize().pipe(
	// 				map(folders => {
	// 					console.log('ok')
	// 					return new featureActions.SynchronizeSuccessAction({ folders })
	// 				})
	// 			)
	// 		})
	// 	})
	// ))

	@Effect()
	downloadZipFolderEffect$ = this.actions$.pipe(
		ofType<featureActions.DownloadZipFolderRequestAction>(featureActions.ActionTypes.DOWNLOAD_ZIP_FOLDER_REQUEST),
		mergeMap(action => {
			let folder = action.payload.folder

			if (folder.download_link) {
				folder.downloaded = true
				return this.downloadFolderService.markFolderAsDownloaded(folder).pipe(
					tap(t => window.open(action.payload.folder.download_link, '_blank')),
					map(t => new featureActions.DownloadZipFolderSuccessAction({ folder })),
				)
			}

			return of({ type: '[Download] Zip Folder Request NoopAction' })
		}),
	)

	@Effect({ dispatch: false })
	notifyFolderDownloadEffect$ = this.actions$.pipe(
		ofType<featureActions.NotifyFolderDownload>(featureActions.ActionTypes.NOTIFY_FOLDER_DOWNLOAD),
		map(action => action.payload.folder),
		tap((action: Partial<FolderDownload>) => {
			this.downloadFolderService.notifyFolderDownload(action)
		}),
	)

	@Effect()
	removeRequestEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.RemoveDownloadFolderRequestAction>(featureActions.ActionTypes.REMOVE_DOWNLOAD_FOLDER_REQUEST),
		mergeMap(action => {
			return this.downloadFolderService.remove(action.payload.folder).pipe(
				map(folder => new featureActions.RemoveDownloadFolderSuccessAction({ folder })),
				catchError(error => [
					new LayoutStoreActions.ShowAlertToast({ message: 'An error has occurred while remove the download' }),
					new featureActions.RemoveDownloadFolderFailureAction({ error }),
				]),
			)
		}),
	)

	@Effect({ dispatch: false })
	clearAllRequestEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.ClearDownloadFoldersAllAction>(featureActions.ActionTypes.CLEAR_DOWNLOAD_FOLDERS_ALL),
		tap(() => {
			this.downloadFolderService.clearAll()
		}),
	)
}
