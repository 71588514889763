import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { FileUpload } from '@app/models'

export const featureAdapter: EntityAdapter<FileUpload> = createEntityAdapter<FileUpload>({
	selectId: model => model.id,
})

export interface State extends EntityState<FileUpload> {
	isMinimized?: boolean
	duplicateFiles: any
	duplicateFolders: any
	isLoading?: boolean
	maxConcurrentUploads: number
}

export const initialState: State = featureAdapter.getInitialState({
	isMinimized: true,
	duplicateFiles: null,
	duplicateFolders: null,
	isLoading: false,
	maxConcurrentUploads: 5,
})
