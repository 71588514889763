import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HeaderComponent } from './header/header.component'
import { BottomDialogComponent } from './bottom-dialog/bottom-dialog.component'
import { AssetGridComponent } from './asset-grid/asset-grid.component'
import { UploadDialogItemComponent } from './upload-dialog-item/upload-dialog-item.component'
import { AssetItemComponent } from './asset-item/asset-item.component'
import { AssetItemTypeComponent } from './asset-item-type/asset-item-type.component'
import { AssetGridHeaderComponent } from './asset-grid-header/asset-grid-header.component'
import { FolderItemComponent } from './folder-item/folder-item.component'
import { UserComponent } from './user/user.component'
import { LoadingComponent } from './loading/loading.component'
import { ActionsHeaderComponent } from './actions-header/actions-header.component'
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component'
import { ModalComponent } from './modal/modal.component'
import { ProjectItemComponent } from './project-item/project-item.component'
import { CreateFolderModalComponent } from './create-folder-modal/create-folder-modal.component'
import { CreateProjectModalComponent } from './create-project-modal/create-project-modal.component'
import { DeleteConfirmModalComponent } from './delete-confirm-modal/delete-confirm-modal.component'
import { RenameModalComponent } from './rename-modal/rename-modal.component'
import { MoveToModalComponent } from './move-to-modal/move-to-modal.component'
import { ContextMenuModule } from 'ngx-contextmenu'
import { NgxPopperModule } from 'ngx-popper'
import { RouterModule } from '@angular/router'
import { FormsModule } from '@angular/forms'
import { DetailSidebarComponent } from './detail-sidebar/detail-sidebar.component'
import { DndDirective } from '../directives/dnd.directive'
import { AlertToastComponent } from './alert-toast/alert-toast.component'
import { WFProjectsComponent } from './wf-projects/wf-projects.component'
import { AddProjectModalComponent } from './add-project-modal/add-project-modal.component'
import { WFLoginComponent } from './wf-login/wf-login.component'
import { SearchComponent } from './search/search.component'
import { SearchNoResultsComponent } from './search-no-results/search-no-results.component'
import { SearchResultsHeaderComponent } from './search-results-header/search-results-header.component'
import { AssetItemIconComponent } from './asset-item-icon/asset-item-icon.component'
import { WFSyncModal } from './wf-sync-modal/wf-sync-modal.component'
import { DndToSelectDirective } from '../directives/dnd-to-select.directive'
import { DndToSelectItemDirective } from '../directives/dnd-to-select-item.directive'
import { FileUploadDialogLaunchComponent } from './file-upload-dialog-launch/file-upload-dialog-launch.component'
import { FileSizePipe } from './../pipes/file-size.pipe'
import { FilterPipe } from './../pipes/filter.pipe'
import { FolderUploadDialogLaunchComponent } from './folder-upload-dialog-launch/folder-upload-dialog-launch.component'
import { DuplicateFileModalComponent } from './duplicate-file-modal/duplicate-file-modal.component'
import { DuplicateFolderModalComponent } from './duplicate-folder-modal/duplicate-folder-modal.component'
import { ReversePipe } from './../pipes/reverse.pipe'
import { AssetItemStatusComponent } from './asset-item-status/asset-item-status.component'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatButtonModule } from '@angular/material/button'
import { MatListModule } from '@angular/material/list'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatTabsModule } from '@angular/material/tabs'
import { WfLinkProjectModalComponent } from './wf-link-project-modal/wf-link-project-modal.component'
import { DownloadDialogItemComponent } from './download-dialog-item/download-dialog-item.component'
import { EmptyDialogItemComponent } from './empty-dialog-item/empty-dialog-item.component'
import { AlertModalComponent } from './alert-modal/alert-modal.component'
import { GenerateConfirmModalComponent } from './generate-confirm-modal/generate-confirm-modal.component'
import { FooterComponent } from './footer/footer.component'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatRadioModule } from '@angular/material/radio'
import { MatPaginatorModule } from '@angular/material/paginator'

@NgModule({
	imports: [
		CommonModule,
		ContextMenuModule.forRoot({ autoFocus: true }),
		NgxPopperModule,
		FormsModule,
		RouterModule,
		MatTooltipModule,
		MatButtonModule,
		MatListModule,
		MatInputModule,
		MatSelectModule,
		MatTabsModule,
		MatProgressBarModule,
		MatSlideToggleModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatPaginatorModule,
	],
	declarations: [
		HeaderComponent,
		BottomDialogComponent,
		UploadDialogItemComponent,
		AssetGridComponent,
		AssetItemComponent,
		AssetItemTypeComponent,
		AssetGridHeaderComponent,
		FolderItemComponent,
		UserComponent,
		LoadingComponent,
		ActionsHeaderComponent,
		BreadcrumbsComponent,
		ModalComponent,
		ProjectItemComponent,
		CreateFolderModalComponent,
		CreateProjectModalComponent,
		DeleteConfirmModalComponent,
		RenameModalComponent,
		MoveToModalComponent,
		DetailSidebarComponent,
		DndDirective,
		WFProjectsComponent,
		AlertToastComponent,
		AddProjectModalComponent,
		WFLoginComponent,
		WFSyncModal,
		SearchComponent,
		SearchNoResultsComponent,
		SearchResultsHeaderComponent,
		AssetItemIconComponent,
		DndToSelectDirective,
		DndToSelectItemDirective,
		FileUploadDialogLaunchComponent,
		DuplicateFileModalComponent,
		FileSizePipe,
		FilterPipe,
		ReversePipe,
		FolderUploadDialogLaunchComponent,
		DuplicateFolderModalComponent,
		AssetItemStatusComponent,
		WfLinkProjectModalComponent,
		DownloadDialogItemComponent,
		EmptyDialogItemComponent,
		AlertModalComponent,
		GenerateConfirmModalComponent,
		FooterComponent,
	],
	exports: [
		HeaderComponent,
		BottomDialogComponent,
		UploadDialogItemComponent,
		AssetGridComponent,
		AssetItemComponent,
		AssetItemTypeComponent,
		AssetGridHeaderComponent,
		FolderItemComponent,
		UserComponent,
		LoadingComponent,
		ActionsHeaderComponent,
		BreadcrumbsComponent,
		ModalComponent,
		ProjectItemComponent,
		CreateFolderModalComponent,
		CreateProjectModalComponent,
		DeleteConfirmModalComponent,
		RenameModalComponent,
		MoveToModalComponent,
		NgxPopperModule,
		ContextMenuModule,
		DetailSidebarComponent,
		AlertToastComponent,
		DndDirective,
		WFProjectsComponent,
		AddProjectModalComponent,
		WFLoginComponent,
		SearchComponent,
		SearchNoResultsComponent,
		SearchResultsHeaderComponent,
		AssetItemIconComponent,
		WFSyncModal,
		DndToSelectDirective,
		DndToSelectItemDirective,
		FileUploadDialogLaunchComponent,
		FolderUploadDialogLaunchComponent,
		DuplicateFileModalComponent,
		DuplicateFolderModalComponent,
		ReversePipe,
		AssetItemStatusComponent,
		WfLinkProjectModalComponent,
		MatTabsModule,
		DownloadDialogItemComponent,
		EmptyDialogItemComponent,
		AlertModalComponent,
		GenerateConfirmModalComponent,
		FooterComponent,
		MatAutocompleteModule,
		MatRadioModule,
		MatPaginatorModule,
	],
})
export class ComponentsModule {}
