import { BrowserModule } from '@angular/platform-browser'
import { NgModule, ErrorHandler } from '@angular/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { HttpClientModule } from '@angular/common/http'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login'
import { GoogleLoginProvider } from 'angularx-social-login'
import { AppComponent } from './app.component'
import { RootStoreModule } from './root-store/root-store.module'
import { GoogleInterceptor } from './interceptors/google-auth.interceptor'
import { ExplorerComponent } from './containers/explorer/explorer.component'
import { HomeComponent } from './containers/home/home.component'
import { RouterModule } from '@angular/router'
import { DamRoutes } from './root-store/route-store/routes'
import { StoreRouterConnectingModule, routerReducer, DefaultRouterStateSerializer } from '@ngrx/router-store'
import { environment } from '../environments/environment'
import { RouterEffects } from './root-store/route-store/effects'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { FolderListResolver } from './root-store/route-store/route-resolvers/folder-list.resolver'
import { AuthGuard } from './root-store/route-store/route-guards/auth.guard'
import { AdminGuard } from './root-store/route-store/route-guards/admin.guard'
import { PageNotFoundComponent } from './containers/page-not-found/page-not-found.component'
import { FolderService, RequestCacheService } from './services'
import { ScrollingModule } from '@angular/cdk/scrolling'
import { ComponentsModule } from './components/components.module'
import { MainComponent } from './containers/main/main.component'
import { AlertToastService } from './services/alert-toast.service'
import { WorkfrontComponent } from './containers/workfront/workfront.component'
import { DndToSelectService } from './services/dnd-to-select.service'
import { WorkfrontResolver } from './root-store/route-store/route-resolvers/workfront.resolver'
import { MainProjectComponent } from './containers/main-project/main-project.component'
import { MainLibraryComponent } from './containers/main-library/main-library.component'
import { MainGuidesComponent } from './containers/main-guides/main-guides.component'
import { ProjectExplorerComponent } from './containers/project-explorer/project-explorer.component'
import { ProjectResolver } from './root-store/route-store/route-resolvers/project.resolver'
import { CommonFolderResolver } from './root-store/route-store/route-resolvers/common-folder.resolver'
import { MainResolver } from './root-store/route-store/route-resolvers/main.resolver'
import { MainWorkfrontComponent } from './containers/main-workfront/main-workfront.component'
import { OrderPipe } from './pipes/order-by.pipe'
import { MatButtonModule } from '@angular/material/button'
import { ErrorComponent } from './containers/error/error.component'
import { ProjectMaintenanceComponent } from './containers/project-maintenance/project-maintenance'
import { ErrorsHandler } from './error/errors-handler'
import { RepositoryManageComponent } from './containers/repository-manage/repository-manage.component'
import { RepositoryManageResolver } from './root-store/route-store/route-resolvers/repository-manage.resolver'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { extModules } from './build-specifics'
import { ApmModule } from '@elastic/apm-rum-angular'

// let config = new SocialAuthServiceConfig([
// 	{
// 		id: GoogleLoginProvider.PROVIDER_ID,
// 		provider: new GoogleLoginProvider(environment.googleClientId),
// 	},
// ])

// export function provideConfig() {
// 	return config
// }

@NgModule({
	declarations: [
		AppComponent,
		ExplorerComponent,
		WorkfrontComponent,
		HomeComponent,
		PageNotFoundComponent,
		MainComponent,
		MainProjectComponent,
		MainLibraryComponent,
		MainGuidesComponent,
		ProjectExplorerComponent,
		MainWorkfrontComponent,
		OrderPipe,
		ErrorComponent,
		ProjectMaintenanceComponent,
		RepositoryManageComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		ApmModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		SocialLoginModule,
		RootStoreModule,
		MatButtonModule,
		RouterModule.forRoot(DamRoutes, { useHash: false, relativeLinkResolution: 'legacy' }),
		StoreModule.forRoot(
			{ router: routerReducer },
			{
				runtimeChecks: {
					strictStateImmutability: false,
					strictActionImmutability: false,
				},
			},
		),
		EffectsModule.forRoot([RouterEffects]),
		extModules,
		StoreRouterConnectingModule.forRoot({ serializer: DefaultRouterStateSerializer, stateKey: 'router' }),
		ScrollingModule,
		ComponentsModule,
	],
	providers: [
		{
			provide: 'SocialAuthServiceConfig',
			useValue: {
				autoLogin: true,
				providers: [
					{
						id: GoogleLoginProvider.PROVIDER_ID,
						provider: new GoogleLoginProvider(environment.googleClientId),
					},
				],
			} as SocialAuthServiceConfig,
		},
		{ provide: HTTP_INTERCEPTORS, useClass: GoogleInterceptor, multi: true },
		{
			provide: ErrorHandler,
			useClass: ErrorsHandler,
		},
		FolderListResolver,
		CommonFolderResolver,
		ProjectResolver,
		WorkfrontResolver,
		RepositoryManageResolver,
		MainResolver,
		AuthGuard,
		AdminGuard,
		FolderService,
		AlertToastService,
		DndToSelectService,
		RequestCacheService,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
