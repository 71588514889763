import { Actions, ActionTypes } from './actions'
import { initialState, State } from './state'
import { environment } from 'src/environments/environment'
import { SearchCriteria, SearchFolderCriteria } from '@app/models'
import { arrayUpdate } from '@app/common/extensions'

export function featureReducer(state = initialState, action: Actions): State {
	switch (action.type) {
		case ActionTypes.SEARCH_REQUEST:
		case ActionTypes.SEARCH_FOLDER_REQUEST: {
			return {
				...state,
				folders: [],
				files: [],
				isSearchFolderActive: false,
				isSearchFilesActive: false,
				isLoading: true,
				error: null,
			}
		}

		case ActionTypes.SEARCH_SUCCESS: {
			const result = action.payload.items || []
			return {
				...state,
				files: [...result],
				isSearchFilesActive: true,
				isLoading: !state.isSearchFolderActive,
				error: null,
			}
		}

		case ActionTypes.SEARCH_FOLDER_SUCCESS: {
			const result = action.payload.items || []
			return {
				...state,
				folders: [...result],
				isSearchFolderActive: true,
				isLoading: !state.isSearchFilesActive,
				error: null,
			}
		}

		case ActionTypes.SEARCH_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: action.payload.error,
			}
		}

		case ActionTypes.SEARCH_FOLDER_FAILURE: {
			return {
				...state,
				isLoading: false,
				error: action.payload.error,
			}
		}

		case ActionTypes.PROJECT_FILTER_SUCCESS: {
			const result = action.payload.items || []
			return {
				...state,
				projects: [...result],
				isLoading: false,
				error: null,
			}
		}

		case ActionTypes.CLEAR: {
			return {
				...state,
				files: [],
				folders: [],
				isSearchFilesActive: false,
				isSearchFolderActive: false,
				isLoading: false,
				error: null,
			}
		}

		case ActionTypes.ADD_CONTEXT: {
			const folders =
				action.payload.folderId && action.payload.folderId !== action.payload.repositoryId
					? [{ folder_id: action.payload.folderId, root_id: action.payload.repositoryId }]
					: []

			const newState: SearchCriteria = {
				...state.searchCriteria,
				search_criteria: [
					{
						...state.searchCriteria[0],
						folders: [...folders],
						repo_id: action.payload.repositoryId,
					},
				],
			}

			const newFolderCriteria: SearchFolderCriteria = {
				...state.searchFolderCriteria,
				search_criteria: [
					{
						...state.searchCriteria[0],
						folders: [
							{
								folder_id: folders.length ? folders[0].folder_id : action.payload.repositoryId,
								root_id: action.payload.repositoryId,
								root_label: environment.repoRootName,
							},
						],
						repo_id: action.payload.repositoryId,
					},
				],
			}

			return {
				...state,
				searchCriteria: newState,
				searchFolderCriteria: newFolderCriteria,
			}
		}

		// TODO Fabricio  Verirfy SearchCriteria model
		case ActionTypes.ADD_METADATA_CRITERIA: {
			const newState: SearchCriteria = {
				...state.searchCriteria,
				search_criteria: [
					{
						...state.searchCriteria[0],
						metadata_list: [...state.searchCriteria[0].metadata_list, action.payload.data],
					},
				],
			}

			return {
				...state,
				searchCriteria: newState,
			}
		}

		case ActionTypes.DELETE_METADATA_CRITERIA: {
			const newState: SearchCriteria = {
				...state.searchCriteria,
				search_criteria: [
					{
						...state.searchCriteria[0],
						metadata_list: [...state.searchCriteria[0].metadata_list.filter(t => t.metadata_id !== action.payload.metadataId)],
					},
				],
			}

			return {
				...state,
				searchCriteria: newState,
			}
		}

		case ActionTypes.ADD_QUERYCONTENT: {
			return {
				...state,
				searchCriteria: {
					...state.searchCriteria,
					query_content: action.payload.value,
				},
				searchFolderCriteria: {
					...state.searchFolderCriteria,
					query_content: action.payload.value,
				},
			}
		}

		case ActionTypes.SET_FILTER_MY_PROJECTS: {
			return {
				...state,
				filterMyProjects: action.payload.value,
			}
		}

		case ActionTypes.SET_FILTER_ACTIVE_PROJECTS: {
			return {
				...state,
				filterActiveProjects: action.payload.value,
			}
		}

		case ActionTypes.SET_FILTER_LOS: {
			return {
				...state,
				filterLoS: action.payload.value,
			}
		}

		case ActionTypes.SET_FILTER_BUSINESS_UNIT: {
			return {
				...state,
				filterBusinessUnit: action.payload.value,
			}
		}

		case ActionTypes.SET_AUDIT_HISTORY_TO_SEARCH_FILE: {
			const index = state.files.findIndex(t => t.file_id === action.payload.fildeId)

			if (index === -1) {
				return state
			}

			const newFile = {
				...state.files[index],
				auditHistory: [...action.payload.auditHistory],
			}

			return {
				...state,
				files: [...arrayUpdate(state.files, newFile, index)],
			}
		}

		case ActionTypes.SET_ENABLED: {
			return {
				...state,
				enabled: action.payload.value,
			}
		}

		default: {
			return state
		}
	}
}
