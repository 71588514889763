import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core'
import { ModalService } from '../../services'
import { Subject } from 'rxjs'

@Component({
	selector: 'dam-generate-confirm-modal',
	templateUrl: './generate-confirm-modal.component.html',
	styleUrls: ['./generate-confirm-modal.component.css'],
})
export class GenerateConfirmModalComponent implements OnInit, OnDestroy {
	@Output()
	onSubmit: EventEmitter<any> = new EventEmitter()

	@Output()
	onCancel: EventEmitter<any> = new EventEmitter()

	destroy$ = new Subject()

	constructor(private modalService: ModalService) {}

	ngOnInit() {}

	ngOnDestroy(): void {
		this.destroy$.next(true)
	}

	cancel() {
		this.modalService.close('confirmation-generate')
	}
}
