import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { featureReducer } from './reducer'
import { FolderStoreEffects, FileStoreEffects } from './effects'

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature('folders', featureReducer),
		EffectsModule.forFeature([FolderStoreEffects, FileStoreEffects]),
	],
	declarations: [],
})
export class FolderStoreModule {}
