<div class="alert-container">
    <div class="alert" *ngFor="let alert of alerts">
        <span class="alert-message">{{alert.message}}</span>
        <div class="alert-close" (click)="remove(alert)">
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="#000000" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
          </svg>
        </div>
    </div>
</div>

