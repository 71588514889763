<main class="main-content">
    <dam-loading *ngIf="isLoading$ | async"></dam-loading>
    <div class="asset-bank">
        <div class="container">
            <div class="container__actions" *ngIf="addUserVisible">
                <div class="actions__add">
                    <form>
                        <input type="text" [(ngModel)]="selectedUser" style="width: 37rem;" placeholder="Search user"
                            [formControl]="control" [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                            <mat-option *ngFor="let user of filteredUsers$ | async" [value]="user">
                                {{user.email}} - {{user.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </form>

                    <mat-radio-group [(ngModel)]="newUserPermissions">
                        <mat-radio-button value="fullAccess">Full access</mat-radio-button>
                        <mat-radio-button value="editors">Editor</mat-radio-button>
                        <mat-radio-button value="viewers">Viewer</mat-radio-button>
                    </mat-radio-group>

                    <button class="mat-button" (click)="add()">Add</button>


                </div>

                <h2>New Users</h2>
                <div class="asset-list">
                    <div class="asset-list-header">
                        <span class="asset-list-header-item">Name</span>
                        <span class="asset-list-header-item">Email</span>
                        <span class="asset-list-header-item">Rol</span>
                    </div>
                    <div class="asset-item-container">
                        <div class="asset-item" *ngFor="let item of newUsers | keyvalue">
                            <div class="asset-item-name">{{item.value.user_display_name}}</div>
                            <div>{{item.value.user_email}}</div>
                            <div>{{item.value.permission_type}}</div>
                        </div>
                    </div>
                </div>
                <button class="mat-button" [disabled]="!newUsers.size" (click)="save()">Update repository</button>
                <div class="actions__save">

                </div>




            </div>

            <h2>Admins</h2>
            <div class="asset-list">
                <div class="asset-list-header">
                    <span class="asset-list-header-item">Name</span>
                    <span class="asset-list-header-item">Email</span>
                </div>
                <div class="asset-item-container">
                    <div class="asset-item" *ngFor="let item of admins$ | async">
                        <div class="asset-item-name">{{item.user_display_name}}</div>
                        <div>{{item.user_email}}</div>
                    </div>
                </div>
            </div>

            <h2>Full Access</h2>
            <div class="asset-list">
                <div class="asset-list-header">
                    <span class="asset-list-header-item">Name</span>
                    <span class="asset-list-header-item">Email</span>
                </div>
                <div class="asset-item-container">
                    <div class="asset-item" *ngFor="let item of fullAccess$ | async">
                        <div class="asset-item-name">{{item.user_display_name}}</div>
                        <div>{{item.user_email}}</div>
                    </div>
                </div>
            </div>

            <h2>Editors</h2>
            <div class="asset-list">
                <div class="asset-list-header">
                    <span class="asset-list-header-item">Name</span>
                    <span class="asset-list-header-item">Email</span>
                </div>
                <div class="asset-item-container">
                    <div class="asset-item" *ngFor="let item of editors$ | async">
                        <div class="asset-item-name">{{item.user_display_name}}</div>
                        <div>{{item.user_email}}</div>
                    </div>
                </div>
            </div>

            <h2>Viewers</h2>
            <div class="asset-list">
                <div class="asset-list-header">
                    <span class="asset-list-header-item">Name</span>
                    <span class="asset-list-header-item">Email</span>
                </div>
                <div class="asset-item-container">
                    <div class="asset-item" *ngFor="let item of viewers$ | async">
                        <div class="asset-item-name">{{item.user_display_name}}</div>
                        <div>{{item.user_email}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>