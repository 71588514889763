import { Component, Output, EventEmitter, AfterViewInit } from '@angular/core'

@Component({
	selector: 'dam-folder-upload-dialog-launch',
	templateUrl: './folder-upload-dialog-launch.component.html',
	styleUrls: ['./folder-upload-dialog-launch.component.css'],
})
export class FolderUploadDialogLaunchComponent implements AfterViewInit {
	constructor() {}

	@Output()
	onFileUpload = new EventEmitter()

	ngOnInit() {}

	ngAfterViewInit(): void {}

	onFileChange(event) {
		this.onFileUpload.emit(event.target.files)
		event.target.value = ''
	}
}
