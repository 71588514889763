<main class="main-content" [class.sidebar]="isDetailSidenavOn">

  <div class="asset-bank" id="asset-container">
    <div class="container" (damDndToSelect)="setMultipleSelection($event)">
      <dam-asset-grid-header (switchView)="switchView($event)" [hidden]="(isSearchActive$ | async)">
        <dam-actions-header>
          <div class="project-navigation-links">
            <div>
              <button class="navigation-view-refresh" mat-button (click)="refresh()">
                <img src="../../../assets/images/icons/refresh-1.svg" alt=""> REFRESH CACHE
              </button>
            </div>
            <div class="navigation-item status">
              <label class="navigation-item-switch" [class.disabled]="damContext.folder_type !== 'repository'">
                <div class="switch">
                  <input type="checkbox" [checked]="selectActiveProjectsFilter$ | async" (change)="toggleActiveProjects($event)"
                    [disabled]="damContext.folder_type !== 'repository'">
                  <span class="slider red"></span>
                </div>
                <div class="asset-item-status">
                  <span class="status">CURRENT</span>
                </div>
              </label>
            </div>
            <div class="navigation-item my-project">
              <label class="navigation-item-switch" [class.disabled]="damContext.folder_type !== 'repository'">
                <div class="switch">
                  <input type="checkbox" [checked]="selectMyProjectsFilter$ | async" (change)="toggleMyProjects($event)"
                    [disabled]="damContext.folder_type !== 'repository'">
                  <span class="slider orange"></span>
                </div>
                <img src="../../../assets/images/star.png" alt="">
              </label>
            </div>
          </div>
        </dam-actions-header>

      </dam-asset-grid-header>

      <dam-search-results-header [hidden]="!(isSearchActive$ | async)" (clear)="onClearSearch();"></dam-search-results-header>
      <dam-search-no-results [hidden]="!((isSearchActive$ | async) && (!(files$ | async)?.length && !(folders$ | async)?.length))">
      </dam-search-no-results>

      <div class="assets-container-dropzone">
        <div class="assets-child-dropzone" [contextMenu]="contextMenu">

          <dam-asset-grid>
            <div [class.asset-grid]="(gridLayout$ | async) == 'grid'" [class.asset-list.project-folder-list]="(gridLayout$ | async) == 'list'"
              [class.project-folder-list]="(gridLayout$ | async) == 'list' && !(isSearchActive$ | async)">
              <div class="asset-list-header" [hidden]="(gridLayout$ | async) == 'grid'" *ngIf="!(isSearchActive$ | async)">
                <span class="asset-list-header-item" (click)="sortBy('name')">NAME <span class="arrow" *ngIf="(sortField$ | async) == 'name'"
                    [ngClass]="(sortDirection$ | async)? 'down':'up'"></span></span>
                <span class="asset-list-header-item" (click)="sortBy('property-Reference number')">REFERENCE
                  NUMBER
                  <span class="arrow" *ngIf="(sortField$ | async) == 'property-Reference number'" [ngClass]="(sortDirection$ | async)? 'down':'up'"></span></span>
                <span class="asset-list-header-item" (click)="sortBy('property-Deadline')">DEADLINE <span
                    class="arrow" *ngIf="(sortField$ | async) == 'property-Deadline'" [ngClass]="(sortDirection$ | async)? 'down':'up'"></span></span>
                <span class="asset-list-header-item status" (click)="sortBy('property-Status')">STATUS <span class="arrow" *ngIf="(sortField$ | async) == 'property-Status'"
                    [ngClass]="(sortDirection$ | async)? 'down':'up'"></span></span>
                <span class="asset-list-header-item my-project" (click)="sortBy('myProject')">MY PROJECT <span class="arrow"
                    *ngIf="(sortField$ | async) == 'myProject'" [ngClass]="(sortDirection$ | async)? 'down':'up'"></span></span>
              </div>

              <div class="asset-list-header" [hidden]="(gridLayout$ | async) == 'grid'" *ngIf="(isSearchActive$ | async) && ((files$ | async)?.length || (folders$ | async)?.length)">
                <span class="asset-list-header-item" (click)="sortBy('name')">NAME <span class="arrow" *ngIf="(sortField$ | async) == 'name'"
                    [ngClass]="(sortDirection$ | async)? 'down':'up'"></span></span>
                <span class="asset-list-header-item" (click)="sortBy('createdBy')">OWNER <span class="arrow" *ngIf="(sortField$ | async) == 'createdBy'"
                    [ngClass]="(sortDirection$ | async)? 'down':'up'"></span></span>
                <span class="asset-list-header-item" (click)="sortBy('createdDate')">CREATED DATE <span class="arrow"
                    *ngIf="(sortField$ | async) == 'createdDate'" [ngClass]="(sortDirection$ | async)? 'down':'up'"></span></span>
                <span class="asset-list-header-item" (click)="sortBy('size')">SIZE <span class="arrow" *ngIf="(sortField$ | async) == 'size'"
                    [ngClass]="(sortDirection$ | async)? 'down':'up'"></span></span>
              </div>

              <ng-container *ngIf="!(isSearchActive$ | async) && (selectActiveProjectsFilter$ | async)">
              
                <dam-project-item @listAnimation class="asset-item-container folderRow asset" 
                  *ngFor="let folder of (folders$ | async) | orderBy: (sortField$ | async) : (sortDirection$ | async) : true : customComparator; trackBy: trackByFolder"
                  [damDndToSelectItem]="folder" [model]="folder" id="{{folder.folder_id}}" 
                  (dblclick)="dbClick($event, folder, 'folder')" (onContextAction)="onContextAction($event)" [layout]="gridLayout$ | async">
                </dam-project-item>
                 
                
              </ng-container>

              <ng-container *ngIf="!(isSearchActive$ | async) && !(selectActiveProjectsFilter$ | async)">
                <dam-project-item @listAnimation class="asset-item-container folderRow asset" 
                  *ngFor="let folder of (folders$ | async); trackBy: trackByFolder"
                  [damDndToSelectItem]="folder" [model]="folder" id="{{folder.folder_id}}" 
                  (dblclick)="dbClick($event, folder, 'folder')" (onContextAction)="onContextAction($event)" [layout]="gridLayout$ | async">
                </dam-project-item>
              </ng-container>
              
              <ng-container *ngIf="isSearchActive$ | async">

                <dam-folder-item @listAnimation class="asset-item-container folderRow asset explorer"
                  id="{{folder.folder_id}}"
                  *ngFor="let folder of folders$ | async | orderBy: 'name' : (sortField$ | async) == 'name' ? (sortDirection$ | async) : null : true; trackBy: trackByFolder"
                  [damDndToSelectItem]="folder" [model]="folder" 
                  (dblclick)="dbClick($event, folder, 'folder')" (onContextAction)="onContextAction($event)"
                  [class.asset-item-selected]="folderIsSelected(selectedItem$ | async, folder)"
                  [layout]="gridLayout$ | async">
                </dam-folder-item>


                <dam-asset-item class="fileRow asset" id="{{item.file_id}}" @listAnimation *ngFor="let item of files$ | async | orderBy: (sortField$ | async) : (sortDirection$ | async) : true; trackBy: trackByFile"
                  [damDndToSelectItem]="item" [model]="item" (dblclick)="dbClick($event, item, 'asset')"
                  (onContextAction)="onContextAction($event)" (onSelect)="setSelectedItem($event)" (onDownload)="onItemDownload($event)"
                  [class.asset-item-selected]="fileIsSelected(selectedItem$ | async, item)" [layout]="gridLayout$ | async">
                </dam-asset-item>
              </ng-container>
            </div>
          </dam-asset-grid>
          <mat-paginator *ngIf="!(isLoading$ | async) && !(isSearchActive$ | async) && !(selectActiveProjectsFilter$ | async)" [pageIndex]="(tablePage$ | async) - 1" (page)="changePage($event)" [length]="tableCount$ | async" [pageSize]="tableLimit$ | async" [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
        </div>
      </div>
    </div>
  </div>

  <!-- DETAIL SIDEBAR -->
  <dam-detail-sidebar *ngIf="(isSearchActive$ | async)" [@slideInOut]="(showDetailSidenav$ | async) ? 'in' : 'out'"
    (@slideInOut.done)="onSlideInOut($event)" (onVisible)="showDetailSidenav($event)" [type]="'folder'" [model]="item$ | async"
    [details]="projectMetadata$ | async" [activities]="(selectedItemAuditHistory$ | async)" [isLoading]="projectMetadataIsLoading$ | async"
    [isActivityLoading]="isAuditHistoryLoading$ | async" [activeTab]="selectSidenavActiveTab$ | async" (onOpenTab)="onSidenavOpenTab($event)"
    (onFolderPathClick)="onFolderPathClick($event)">
  </dam-detail-sidebar>
  
  <dam-detail-sidebar *ngIf="!(isSearchActive$ | async)" [@slideInOut]="(showDetailSidenav$ | async) ? 'in' : 'out'"
    (@slideInOut.done)="onSlideInOut($event)" (onVisible)="showDetailSidenav($event)" [isLoading]="projectMetadataIsLoading$ | async"
    [activeTab]="selectSidenavActiveTab$ | async" [type]="'project'" [details]="item$ | async" (onFolderPathClick)="onFolderPathClick($event)">
  </dam-detail-sidebar>

</main>

<dam-loading *ngIf="isLoading$ | async"></dam-loading>

<dam-footer></dam-footer>

<dam-bottom-dialog [isMinimized]="bottomSidenavIsMinimized$ | async" (onExpandCollapseClick)="expandCollapseUploadDialog()">

  <button type="button" class="bottom-dialog-list-clear" (click)="clearAllUploads()" [hidden]="!(uploadFiles$ | async)?.length || ((bottomSidenavActiveTab$ | async) == 1)">
    Clear all
  </button>

  <button type="button" class="bottom-dialog-list-clear" (click)="clearAllDownloads()" [hidden]="!(downloadFolders$ | async)?.length || ((bottomSidenavActiveTab$ | async) == 0)">
    Clear all
  </button>

  <mat-tab-group dynamicHeight [selectedIndex]="bottomSidenavActiveTab$ | async" (selectedIndexChange)="changeSidenavActiveTab($event)">
    <mat-tab label="Upload files">
      <dam-upload-dialog-item *ngFor="let item of uploadFiles$ | async | orderBy: 'status': true; trackBy: trackByFile" [name]="item.file_name"
        [foldertree]="item.folder_tree"
        [size]="item.total" 
        [loaded]="item.loaded" [progress]="item.progress" [completed]="item.completed" (onClear)="clearUploadFile(item)">
      </dam-upload-dialog-item>
      <dam-empty-dialog-item *ngIf="!(uploadFiles$ | async)?.length" message="Files or folders you upload appear here">
      </dam-empty-dialog-item>
    </mat-tab>

    <mat-tab label="Download folders">
      <dam-download-dialog-item *ngFor="let item of downloadFolders$ | async | reverse; trackBy: trackByFolder" [name]="item.folder_name"
        [url]="item.download_link" [path]="item.path" [description]="item.description" [completed]="item.completed"
        [statistics]="item.statistics" [notifyMe]="item.notify_user" (onClear)="clearDoownloadFolder(item)"
        (onNotifyMe)="notifyDownload($event, item)">
      </dam-download-dialog-item>
      <dam-empty-dialog-item *ngIf="!(downloadFolders$ | async)?.length" message="Folders you download appear here">
      </dam-empty-dialog-item>

    </mat-tab>
  </mat-tab-group>
</dam-bottom-dialog>


<!-- Context Menu -->
<context-menu #contextMenu>
  <ng-template contextMenuItem *ngFor="let action of contextMenuActions" let-item [visible]="true" [enabled]="true"
    (execute)="onContextAction({ action: action })">
    <div class="menu-item">
      <img class="icon" src="./../../../assets/images/icons/{{action.icon}}.svg" alt=""> {{action.displayName}}
    </div>
  </ng-template>
</context-menu>