<header class="modal-header">
  <h2 class="modal-title">
    Project link
  </h2>
  <button class="modal-header-btn modal-close" type="button" (click)="cancel()">
    <img src="../../../assets/images/icons/close.svg" alt="Close" title="Close">
  </button>
</header>
<div class="modal-content">

  <div class="new-project-form form">

    <div class="modal-search-container">
      <mat-form-field>
        <input matInput placeholder="Search project" [(ngModel)]="searchText">
      </mat-form-field>
    </div>

    <label *ngIf="model?.project?.isRootProject">
      This project contains links from other projects.
    </label>

    <mat-selection-list class="list-fixed" [(ngModel)]="selectedProject" (selectionChange)="handleSelection($event)">
      <mat-list-option 
        *ngFor="let p of model?.parents | filter: searchText: 'name'"
        [value]="p" 
        >{{p.name}}</mat-list-option>
    </mat-selection-list>
    <br>
    
    <div class="form-actions modal-footer">
      <button class="form-btn modal-close" type="button" (click)="cancel()">CANCEL</button>
      <button class="form-btn" type="button" (click)="save()">SAVE</button>
    </div>
  </div>
</div>