import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core'
import { DamFile, IFolderTree } from '@app/models'

@Component({
	selector: 'dam-upload-dialog-item',
	templateUrl: './upload-dialog-item.component.html',
	styleUrls: ['./upload-dialog-item.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadDialogItemComponent implements OnInit {
	@Input()
	progress: number = 0

	name: string
	@Input('name')
	set setName(value) {
		this.name = decodeURI(value)
	}

	folderTree: string
	@Input('foldertree')
	set setFolderTree(value: IFolderTree[]) {
		this.folderTree = value.map(t => t.folder_name).join('/')
	}

	@Input()
	size: number

	@Input()
	loaded: number

	@Input()
	completed: boolean

	@Output()
	onClear: EventEmitter<DamFile> = new EventEmitter()

	constructor() {}

	ngOnInit() {}
}
