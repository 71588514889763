import { Routes } from '@angular/router'
import { ExplorerComponent } from '@app/containers/explorer/explorer.component'
import { HomeComponent } from '@app/containers/home/home.component'
import { AuthGuard } from './route-guards/auth.guard'
import { PageNotFoundComponent } from '../../containers/page-not-found/page-not-found.component'
import { WorkfrontComponent } from '@app/containers/workfront/workfront.component'
import { WorkfrontResolver } from '@app/root-store/route-store/route-resolvers/workfront.resolver'
import { MainComponent } from '@app/containers/main/main.component'
import { MainProjectComponent } from '@app/containers/main-project/main-project.component'
import { ProjectExplorerComponent } from '@app/containers/project-explorer/project-explorer.component'
import { ProjectResolver } from './route-resolvers/project.resolver'
import { MainGuidesComponent } from '@app/containers/main-guides/main-guides.component'
import { CommonFolderResolver } from './route-resolvers/common-folder.resolver'
import { MainResolver } from './route-resolvers/main.resolver'
import { ErrorComponent } from '@app/containers/error/error.component'
import { ProjectMaintenanceComponent } from '@app/containers/project-maintenance/project-maintenance'
import { RepositoryManageComponent } from '@app/containers/repository-manage/repository-manage.component'
import { RepositoryManageResolver } from './route-resolvers/repository-manage.resolver'

export const DamRoutes: Routes = [
	{
		path: '',
		canActivate: [AuthGuard],
		component: MainComponent,
		children: [
			{ path: '', redirectTo: '/projects', pathMatch: 'full' },
			{
				path: 'projects',
				component: MainProjectComponent,
				resolve: {
					data: MainResolver,
				},
				children: [
					{
						path: '',
						component: ProjectExplorerComponent,
						resolve: {
							data: ProjectResolver,
						},
					},
					{
						path: 'folders/:id',
						component: ExplorerComponent,
						resolve: {
							data: CommonFolderResolver,
						},
					},
				],
			},
			{
				path: 'guidelines',
				component: MainGuidesComponent,
				resolve: {
					data: MainResolver,
				},
				children: [
					{
						path: '',
						component: ExplorerComponent,
						resolve: {
							data: CommonFolderResolver,
						},
					},
					{
						path: 'folders/:id',
						component: ExplorerComponent,
						resolve: {
							data: CommonFolderResolver,
						},
					},
				],
			},
			{
				path: 'workfront',
				component: WorkfrontComponent,
				resolve: {
					data: WorkfrontResolver,
				},
			},
			{
				path: 'users',
				component: RepositoryManageComponent,
				resolve: {
					data: RepositoryManageResolver,
				},
			},
			{
				path: 'error',
				component: ErrorComponent,
			},
		],
	},

	{ path: 'login', component: HomeComponent },
	{ path: 'maintenance', component: ProjectMaintenanceComponent, canActivate: [AuthGuard] },
	{ path: '**', redirectTo: '/login', pathMatch: 'full' },
]
