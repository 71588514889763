import { Component, OnInit, Output, EventEmitter } from '@angular/core'

@Component({
	selector: 'dam-search-results-header',
	templateUrl: './search-results-header.component.html',
	styleUrls: ['./search-results-header.component.css'],
})
export class SearchResultsHeaderComponent implements OnInit {
	@Output() clear: EventEmitter<any> = new EventEmitter()
	constructor() {}

	ngOnInit() {}

	onClear() {
		this.clear.emit()
	}
}
