import { Action } from '@ngrx/store'
import { Project, FolderActionResult, Folder, FileUpdate, FolderBase, UpdateStorageProject, SyncFolder } from '@app/models'

export enum ActionTypes {
	CLEAR_OUTDATED_PROJECTS_REQUEST = '[Workfront] Clear outdated projects',

	LOAD_WF_PROJECTS = '[Workfront][Load] Load wf projects',
	LOAD_WF_PROJECTS_SUCCESS = '[Workfront][Load] Load wf projects success',
	LOAD_WF_PROJECTS_FAILURE = '[Workfront][Load] Load wf projects failure',

	CREATE_PROJECT_REQUEST = '[Workfront] Create Project Request',
	CREATE_PROJECT_FAILURE = '[Workfront] Create Project Failure',
	CREATE_PROJECT_SUCCESS = '[Workfront] Create Project Success',

	// LOAD_DAM_PROJECTS_METADATA = '[Workfront] Load DAM projects metadata',

	SYNC_ADD_PROJECTS_REQUEST = '[Workfront] Load projects to sync',
	SYNC_PROJECTS_REQUEST = '[Workfront] Sync projects',
	SYNC_PROJECTS_FAILURE = '[Workfront] Sync projects Failure',
	SYNC_PROJECTS_SUCCESS = '[Workfront] Sync projects Success',
	SYNC_PROJECT_REQUEST = '[Workfront] Sync project',
	SYNC_PROJECT_FAILURE = '[Workfront] Sync project Failure',
	SYNC_PROJECT_SUCCESS = '[Workfront] Sync project Success',
	SYNC_PROJECT_FIX_METADATA_REQUEST = '[Workfront] Sync fix project metadata',

	UPDATE_METADATA_PROJECT_REQUEST = '[Workfront] Update Metadata Project Request',
	UPDATE_METADATA_PROJECT_FAILURE = '[Workfront] Update Metadata Project Failure',
	UPDATE_METADATA_PROJECT_SUCCESS = '[Workfront] Update Metadata Project Success',

	UPDATE_PROJECT_NAME_REQUEST = '[Workfront] Update Project Name Request',
	UPDATE_PROJECT_NAME_FAILURE = '[Workfront] Update Project Name Failure',
	UPDATE_PROJECT_NAME_SUCCESS = '[Workfront] Update Project Name Success',

	GENERATE_STORAGE_PROJECTS_FILE_REQUEST = '[Workfront] Generate storage projects file',
	GENERATE_STORAGE_PROJECTS_FILE_FAILURE = '[Workfront] Generate storage projects file Failure',
	GENERATE_STORAGE_PROJECTS_FILE_SUCCESS = '[Workfront] Generate storage projects file Success',

	ADD_STORAGE_PROJECT_REQUEST = '[Workfront] Add storage project file',
	ADD_STORAGE_PROJECT_FAILURE = '[Workfront] Add storage project file Failure',
	ADD_STORAGE_PROJECT_SUCCESS = '[Workfront] Add storage project file Success',

	UPDATE_STORAGE_PROJECTS_REQUEST = '[Workfront] Update storage project file',
	UPDATE_STORAGE_PROJECTS_FAILURE = '[Workfront] Update storage project file Failure',
	UPDATE_STORAGE_PROJECTS_SUCCESS = '[Workfront] Update storage project file Success',
}

export class ClearOutdateProjectAction implements Action {
	readonly type = ActionTypes.CLEAR_OUTDATED_PROJECTS_REQUEST
}

export class LoadWorkfrontProjects implements Action {
	readonly type = ActionTypes.LOAD_WF_PROJECTS
	constructor(public payload: { email: string; password: string }) {}
}

export class LoadWorkfrontProjectsSuccess implements Action {
	readonly type = ActionTypes.LOAD_WF_PROJECTS_SUCCESS
	constructor(public payload: { data: Project[] }) {}
}

export class LoadWorkfrontProjectsFailure implements Action {
	readonly type = ActionTypes.LOAD_WF_PROJECTS_FAILURE
	constructor(public payload: { error: string }) {}
}

export class CreateProjectRequestAction implements Action {
	readonly type = ActionTypes.CREATE_PROJECT_REQUEST
	constructor(public payload: { project: Project }) {}
}

export class CreateProjectSuccessAction implements Action {
	readonly type = ActionTypes.CREATE_PROJECT_SUCCESS
	constructor(public payload: { folder: FolderActionResult; wf_id: string }) {}
}

export class CreateProjectFailureAction implements Action {
	readonly type = ActionTypes.CREATE_PROJECT_FAILURE
	constructor(public payload: { error: string }) {}
}

// export class LoadDamProjectsMetadata implements Action {
// 	readonly type = ActionTypes.LOAD_DAM_PROJECTS_METADATA;
// 	constructor(public payload: { items: any[] }) {}
// }

export class AddSyncProjectsAction implements Action {
	readonly type = ActionTypes.SYNC_ADD_PROJECTS_REQUEST
	constructor(public payload: { projects: SyncFolder[] }) {}
}

export class SyncProjectFixMetadataRequestAction implements Action {
	readonly type = ActionTypes.SYNC_PROJECT_FIX_METADATA_REQUEST
	constructor(public payload: { project: SyncFolder }) {}
}

export class SyncProjectRequestAction implements Action {
	readonly type = ActionTypes.SYNC_PROJECT_REQUEST
	constructor(public payload: { project: SyncFolder }) {}
}

export class SyncProjectSuccessAction implements Action {
	readonly type = ActionTypes.SYNC_PROJECT_SUCCESS
	constructor(public payload: { project: SyncFolder }) {}
}

export class SyncProjectFailureAction implements Action {
	readonly type = ActionTypes.SYNC_PROJECT_FAILURE
	constructor(public payload: { project: SyncFolder }) {}
}

export class SyncProjectsRequestAction implements Action {
	readonly type = ActionTypes.SYNC_PROJECTS_REQUEST
	constructor(public payload: { projects: Folder[] }) {}
}

export class SyncProjectsSuccessAction implements Action {
	readonly type = ActionTypes.SYNC_PROJECTS_SUCCESS
}

export class SyncProjectsFailureAction implements Action {
	readonly type = ActionTypes.SYNC_PROJECTS_FAILURE
}

export class UpdateMetadataProjectRequestAction implements Action {
	readonly type = ActionTypes.UPDATE_METADATA_PROJECT_REQUEST
	constructor(public payload: { project?: SyncFolder; fileUpdate: FileUpdate }) {}
}

export class UpdateProjectNameRequestAction implements Action {
	readonly type = ActionTypes.UPDATE_PROJECT_NAME_REQUEST
	constructor(public payload: { folder: FolderBase }) {}
}

export class UpdateProjectNameSuccessAction implements Action {
	readonly type = ActionTypes.UPDATE_PROJECT_NAME_SUCCESS
}

export class UpdateProjectNameFailureAction implements Action {
	readonly type = ActionTypes.UPDATE_PROJECT_NAME_FAILURE
	constructor(public payload: { error: string }) {}
}

export class UpdateMetadataProjectSuccessAction implements Action {
	readonly type = ActionTypes.UPDATE_METADATA_PROJECT_SUCCESS
}

export class GenerateStorageProjectsFileAction implements Action {
	readonly type = ActionTypes.GENERATE_STORAGE_PROJECTS_FILE_REQUEST
}

export class GenerateStorageProjectsFileFailureAction implements Action {
	readonly type = ActionTypes.GENERATE_STORAGE_PROJECTS_FILE_FAILURE
	constructor(public payload: { error: string }) {}
}

export class GenerateStorageProjectsFileSuccessAction implements Action {
	readonly type = ActionTypes.GENERATE_STORAGE_PROJECTS_FILE_SUCCESS
}

export class AddStorageProjectAction implements Action {
	readonly type = ActionTypes.ADD_STORAGE_PROJECT_REQUEST
	constructor(public payload: { folder: any; wf_id: string }) {}
}

export class AddStorageProjectFailureAction implements Action {
	readonly type = ActionTypes.ADD_STORAGE_PROJECT_FAILURE
	constructor(public payload: { error: string }) {}
}

export class AddStorageProjectSuccessAction implements Action {
	readonly type = ActionTypes.ADD_STORAGE_PROJECT_SUCCESS
}

export class UpdateStorageProjectsAction implements Action {
	readonly type = ActionTypes.UPDATE_STORAGE_PROJECTS_REQUEST
	constructor(public payload: { project: SyncFolder; updateStorageProject: UpdateStorageProject }) {}
}

export class UpdateStorageProjectsFailureAction implements Action {
	readonly type = ActionTypes.UPDATE_STORAGE_PROJECTS_FAILURE
	constructor(public payload: { error: string }) {}
}

export class UpdateStorageProjectSuccessAction implements Action {
	readonly type = ActionTypes.UPDATE_STORAGE_PROJECTS_SUCCESS
}

export type Actions =
	| ClearOutdateProjectAction
	| LoadWorkfrontProjects
	| LoadWorkfrontProjectsSuccess
	| LoadWorkfrontProjectsFailure
	| CreateProjectRequestAction
	| CreateProjectSuccessAction
	| CreateProjectFailureAction
	// | LoadDamProjectsMetadata
	| AddSyncProjectsAction
	| SyncProjectRequestAction
	| SyncProjectSuccessAction
	| SyncProjectFailureAction
	| SyncProjectFixMetadataRequestAction
	| SyncProjectsRequestAction
	| SyncProjectsSuccessAction
	| SyncProjectsFailureAction
	| UpdateMetadataProjectRequestAction
	| UpdateMetadataProjectSuccessAction
	| UpdateProjectNameRequestAction
	| UpdateProjectNameSuccessAction
	| UpdateProjectNameFailureAction
	| GenerateStorageProjectsFileAction
	| GenerateStorageProjectsFileFailureAction
	| GenerateStorageProjectsFileSuccessAction
	| AddStorageProjectAction
	| AddStorageProjectFailureAction
	| AddStorageProjectSuccessAction
	| UpdateStorageProjectsAction
	| UpdateStorageProjectsFailureAction
	| UpdateStorageProjectSuccessAction
