import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { featureReducer } from './reducer'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { DownloadFolderStoreEffects } from './effects'

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature('folderDownloads', featureReducer),
		EffectsModule.forFeature([DownloadFolderStoreEffects]),
	],
	declarations: [],
})
export class DownloadStoreModule {}
