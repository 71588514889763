import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { delay, map, retryWhen, take } from 'rxjs/operators'
import { Observable, Subject } from 'rxjs'
import { RequestCacheService } from './request-cache.service'
import {
	FileLink,
	FileLinkWrapper,
	FileUploadStep1,
	FileUploadStep1Result,
	FileUploadStep3,
	DamFile,
	FileDeleteMulti,
	FileInfo,
	AuditHistory,
	Metadata,
	MoveItems,
	FileVersion,
	FileReplaceStep1,
	FileReplaceStep3,
} from '@app/models'
import { GoogleAnalyticsService } from './google-analytics.service'

@Injectable({
	providedIn: 'root',
})
export class FileService {
	constructor(
		private http: HttpClient,
		private cacheService: RequestCacheService,
		private googleAnalyticsService: GoogleAnalyticsService,
	) {}

	getFileLink(fileId: string): Observable<FileLink> {
		return this.http.get<FileLinkWrapper>(`${environment.apiBaseUrl}${environment.fileLinkApiUrl}?file_id=${fileId}&role=reader`).pipe(
			map(t => {
				return { ...t.access, file_id: fileId }
			}),
		)
	}

	uploadStep1(payload: FileUploadStep1, useFolderTree: boolean = true): Observable<FileUploadStep1Result> {
		let fileMetadata = { ...payload }
		this.cacheService.expire(`${fileMetadata.repo_id}-${fileMetadata.folder_id}`)
		fileMetadata.file_properties =
			environment.metadataFileName === fileMetadata.file_name
				? fileMetadata.file_properties
				: this.filterFileMetadata(fileMetadata.file_properties)

		fileMetadata.file_properties = fileMetadata.file_properties.map((t: any) => {
			delete t._id
			delete t.unique_id
			return t
		})

		if (useFolderTree) {
			delete fileMetadata.id
			delete fileMetadata.folder_id
		}

		//fileMetadata.file_name = encodeURI(fileMetadata.file_name)
		return this.http.post<FileUploadStep1Result>(`${environment.uploadBaseUrl}${environment.fileUploadStep1}`, fileMetadata)
	}

	uploadStep2(uploadUrl: string, file: File, reportProgress: boolean): Observable<any> {
		const httpHeaders = new HttpHeaders().append('Content-Type', file.type)
		const req = new HttpRequest('PUT', uploadUrl, file, { reportProgress: reportProgress, headers: httpHeaders })
		return this.http.request(req)
	}

	uploadStep3(payload: FileUploadStep3, useFolderTree: boolean = true) {
		let { folder_tree, ...fileMetadata } = { ...payload }

		// expire folder cache
		this.cacheService.expire(`${fileMetadata.repo_id}-${fileMetadata.folder_id}`)

		// fileMetadata.file_properties =
		// 	environment.metadataFileName === fileMetadata.file_name
		// 		? fileMetadata.file_properties
		// 		: this.filterFileMetadata(fileMetadata.file_properties)

		fileMetadata.file_properties = fileMetadata.file_properties.map((t: any) => {
			delete t.unique_id
			return t
		})

		if (useFolderTree) {
			delete fileMetadata.id
			delete fileMetadata.folder_id
		}

		// GA file upload track
		this.googleAnalyticsService.trackEvent('upload', this.getFileExtension(fileMetadata.file_name), fileMetadata.file_name)

		return this.http.post(`${environment.uploadBaseUrl}${environment.fileUploadStep3}`, fileMetadata)
	}

	update(file: DamFile) {
		// expire folder cache
		const cacheKey = `${file.repo_id}-${file.folder_id}`
		this.cacheService.expire(cacheKey)
		const payload = { ...file }

		delete payload.folder_id
		delete payload.repo_id

		return this.http.put(`${environment.apiBaseUrl}${environment.fileUpdateApiUrl}`, payload)
	}

	delete(fileDelete: FileDeleteMulti, folderId: string): Observable<any> {
		// expire folder cache
		const cacheKey = `${fileDelete.repo_id}-${folderId}`
		this.cacheService.expire(cacheKey)
		this.cacheService.deleteItemTree(fileDelete.folders)
		return this.http.post(`${environment.apiBaseUrl}${environment.fileDeleteApiUrl}`, fileDelete)
	}

	getFile(file: DamFile): Observable<any> {
		return this.http.get<any>(`${environment.downloadApiBaseUrl}${environment.fileGetUrl}?file_id=${file.file_id}`).pipe(
			map(t => {
				return { url: t.download_url, file: file }
			}),
		)
	}

	getFileInfo(repoId: string, fileId: string): Observable<FileInfo> {
		const cacheKey = `getFileInfo-${repoId}-${fileId}`
		const subject = new Subject<FileInfo>()
		this.cacheService.get<FileInfo>(cacheKey).then(item => {
			if (item) {
				subject.next(item)
				subject.complete()
			} else {
				this.http
					.get<FileInfo>(`${environment.apiBaseUrl}${environment.fileGetInfoUrl}?file_id=${fileId}&meta_info=true`)
					.pipe(
						map((r: any) => {
							if (r?.status === 'failed') {
								throw r
							}
							return r
						}),
						retryWhen(error => {
							return error.pipe(delay(5000), take(10))
						}),
					)
					.subscribe(t => {
						subject.next(t)
						subject.complete()
						this.cacheService.put(cacheKey, t)
					})
			}
		})

		return subject.asObservable()
	}

	getAuditHistory(fileId: string): Observable<Array<AuditHistory>> {
		return this.http
			.get<any>(`${environment.apiBaseUrl}${environment.fileAuditHistory}?file_id=${fileId}`)
			.pipe(map(t => (t.file_history ? t.file_history.filter(h => h.action !== 'Viewed') : [])))
	}

	private filterFileMetadata(fileMetadata: Array<Metadata>) {
		return fileMetadata.filter(t => !environment.metadataToRemoveInFiles.some(m => t.name === m))
	}

	move(files: MoveItems) {
		const payload = { ...files }
		// expire folder cache
		const sourceCacheKey = `${payload.repo_id}-${payload.source_folder_id}`
		this.cacheService.expire(sourceCacheKey)
		const cacheKey = `${payload.repo_id}-${payload.folder_id}`
		this.cacheService.expire(cacheKey)

		for (let folder of payload.folders) {
			const partialTreeCacheKey = `tree-${payload.repo_id}-${folder.folder_id}`
			this.cacheService.expire(partialTreeCacheKey)
		}

		this.cacheService.moveItemTree(payload)

		delete payload.source_folder_id

		return this.http.post(`${environment.apiBaseUrl}${environment.fileMoveApiUrl}`, payload)
	}

	versionate(version: FileVersion) {
		return this.http.post<FileVersion>(`${environment.apiBaseUrl}${environment.fileVersionate}`, version)
	}

	getFileVersions(file_id: string) {
		return this.http.get<any>(`${environment.apiBaseUrl}${environment.fileVersions}?file_id=${file_id}`)
	}

	replaceFileStep1(model: FileReplaceStep1) {
		const payload = { ...model }
		delete payload.id
		return this.http.post<any>(`${environment.uploadBaseUrl}${environment.fileUploadStep1}`, payload)
	}

	replaceFileStep2(url: string, file: File): Observable<any> {
		const httpHeaders = new HttpHeaders().append('Content-Type', file.type)
		const req = new HttpRequest('PUT', url, file, { reportProgress: true, headers: httpHeaders })
		return this.http.request(req)
		// return this.http.put<any>(url, file);
	}

	replaceFileStep3(payload: FileReplaceStep3) {
		return this.http.post<any>(`${environment.uploadBaseUrl}${environment.fileUploadStep3}`, payload)
	}

	getFileDict(file_id: string) {
		return this.http.get<any>(`${environment.apiBaseUrl}${environment.fileDictUrl}?file_id=${file_id}`)
	}

	private getFileExtension(path) {
		return path.match(/(?:.+..+[^\/]+$)/gi) != null ? path.split('.').slice(-1) : null
	}
}
