import { Injectable, InjectionToken, Inject, Optional } from '@angular/core'
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects'
import { Action, Store, select } from '@ngrx/store'
import { Observable, from, of, combineLatest, concat } from 'rxjs'
import { catchError, map, switchMap, mergeMap, withLatestFrom, filter, merge, tap, concatMap, concatAll, concatMapTo } from 'rxjs/operators'
import { environment } from '../../../../environments/environment'
import { FolderService, FileService, RepositoryService, WorkfrontService } from '../../../services'
import { LayoutStoreActions } from '../../layout-store'
import { RootStoreState } from './../../'
import * as featureActions from './../actions/folder.actions'
import * as featureSelectors from './../selectors'
import * as searchSelectors from '@app/root-store/search-store/selectors'
import { FolderWrapper, SearchCriteria, DamFile, ProjectMetadata } from '@app/models'
import { mapSortFromState } from '@app/common/helpers'
import { selectUser } from '@app/root-store/user-store/selectors'

@Injectable()
export class FolderStoreEffects {
	constructor(
		private folderService: FolderService,
		private fileService: FileService,
		private repositoryService: RepositoryService,
		private actions$: Actions,
		private store$: Store<RootStoreState.State>,
		private workfrontService: WorkfrontService,
	) {}

	loadRequestEffect$: Observable<Action> = createEffect(() => {
		return this.actions$.pipe(
			ofType<featureActions.LoadRequestAction>(featureActions.ActionTypes.LOAD_REQUEST),
			switchMap(action =>
				this.folderService.getFolder(action.payload.repositoryId, action.payload.folderId).pipe(
					switchMap(rootFolder => [new featureActions.LoadSuccessAction({ rootFolder })]),
					catchError(error => [
						new LayoutStoreActions.ShowAlertToast({ message: 'An error has occurred loading folders.' }),
						new featureActions.LoadFailureAction({ error }),
					]),
				),
			),
		)
	})

	@Effect()
	loadProjectsFromStorageEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.LoadProjectFromStorageRequestAction>(featureActions.ActionTypes.LOAD_PROJECT_FROM_STORAGE_REQUEST),
		withLatestFrom(this.store$.pipe(select(selectUser))),
		withLatestFrom(this.store$.pipe(select(searchSelectors.selectActiveProjectsFilter))),
		withLatestFrom(this.store$.pipe(select(searchSelectors.selectMyProjectsFilter))),
		withLatestFrom(this.store$.pipe(select(s => s.layout))),
		switchMap(([[[[action, user], activeProjectFilter], myProjectFilter], layoutStore]) => {
			if (!activeProjectFilter && !action.payload.disablePagination) {
				const { sortField, sortDirection } = mapSortFromState(layoutStore)
				return this.folderService
					.getAllProjectFromStorage(layoutStore.tablePage, layoutStore.tableLimit, sortField, sortDirection, myProjectFilter, user)
					.pipe(
						map(
							(rootFolder: FolderWrapper) =>
								new featureActions.LoadProjectFromStorageSuccessAction({
									rootFolder: rootFolder,
									setFolders: action.payload.setFolders,
								}),
						),
						catchError(error => [
							new LayoutStoreActions.ShowAlertToast({ message: 'An error has occurred loading folders.' }),
							new featureActions.LoadProjectFromStorageFailureAction({ error }),
						]),
					)
			}

			return this.folderService.getProjectFromStorage(activeProjectFilter).pipe(
				map(
					(rootFolder: FolderWrapper) =>
						new featureActions.LoadProjectFromStorageSuccessAction({
							rootFolder: rootFolder,
							setFolders: action.payload.setFolders,
						}),
				),
				catchError(error => [
					new LayoutStoreActions.ShowAlertToast({ message: 'An error has occurred loading folders.' }),
					new featureActions.LoadProjectFromStorageFailureAction({ error }),
				]),
			)
		}),
	)

	@Effect()
	loadProjectsPageFromStorageEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.LoadProjectPageAction>(featureActions.ActionTypes.LOAD_PROJECT_PAGE_FROM_STORAGE_REQUEST),
		withLatestFrom(this.store$.pipe(select(s => s.layout))),
		switchMap(([action, layoutStore]) => {
			const { sortField, sortDirection } = mapSortFromState(layoutStore)

			return this.folderService.getAllProjectFromStorage(layoutStore.tablePage, layoutStore.tableLimit, sortField, sortDirection).pipe(
				map(
					(rootFolder: FolderWrapper) =>
						new featureActions.LoadProjectFromStorageSuccessAction({
							rootFolder: rootFolder,
							setFolders: true,
						}),
				),
				catchError(error => [
					new LayoutStoreActions.ShowAlertToast({ message: 'An error has occurred loading folders.' }),
					new featureActions.LoadProjectFromStorageFailureAction({ error }),
				]),
			)
		}),
	)

	@Effect()
	loadRequestSuccessEffect$ = this.actions$.pipe(
		ofType<featureActions.LoadSuccessAction>(featureActions.ActionTypes.LOAD_SUCCESS),
		mergeMap(action => {
			if (action.payload.rootFolder.folder_id || !action.payload.rootFolder.folders) {
				return of({ type: '[Folder] Load Success NoopAction' })
			}
			// load all metadata
			const f = action.payload.rootFolder.folders.map(
				t => new featureActions.LoadProjectMetadataRequestAction({ repositoryId: t.repo_id, folderId: t.folder_id }),
			)

			return f
		}),
	)

	/// TODO verify this action
	@Effect()
	createFolderEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.CreateRequestAction>(featureActions.ActionTypes.CREATE_REQUEST),
		switchMap(action =>
			this.folderService.create(action.payload.folder).pipe(
				switchMap(folder => [
					new featureActions.CreateSuccessAction({ folder }),
					new featureActions.FolderStateChangeAction({ folderId: action.payload.folder.parent_id, isEmpty: false }),
				]),
				catchError(error => [
					new LayoutStoreActions.ShowAlertToast({ message: 'An error has occurred while creating the folder.' }),
					new featureActions.CreateFailureAction({ error }),
				]),
			),
		),
	)

	@Effect()
	updateProjectEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.UpdateRequestAction>(featureActions.ActionTypes.UPDATE_REQUEST),
		withLatestFrom(this.store$.pipe(select(selectUser))),
		switchMap(([action, user]) =>
			this.folderService.update(action.payload.folder, user).pipe(
				map(folder => {
					return new featureActions.UpdateSuccessAction({ folder: action.payload.folder })
				}),
				catchError(error => [
					new LayoutStoreActions.ShowAlertToast({ message: 'An error has occurred while updating the folder.' }),
					new featureActions.UpdateFailureAction({ error }),
				]),
			),
		),
	)

	/***
	 * REFACTOR: No references to this effect found
	 */
	@Effect()
	loadFileLinkRequestEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.LoadFileLinkRequestAction>(featureActions.ActionTypes.LOAD_FILE_LINK_REQUEST),
		mergeMap(file =>
			from(file.payload.files || []).pipe(
				mergeMap(file =>
					this.fileService.getFileLink(file.file_id).pipe(
						map(file => new featureActions.LoadFileLinkSuccessAction({ file })),
						catchError(error => of(new featureActions.LoadFileLinkFailureAction({ error }))),
					),
				),
			),
		),
	)

	/**
	 * Load files metadata from the search  (set files details, owner, seize, created datte)
	 */
	@Effect()
	loadFilesMetadataRequestEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.LoadFileMetadataRequestAction>(featureActions.ActionTypes.LOAD_FILES_METADATA_REQUEST),
		switchMap((ctx: featureActions.LoadFileMetadataRequestAction) => {
			const searchCriteria: SearchCriteria = {
				search_criteria: [
					{
						folders: [
							{
								folder_id: ctx.payload.folderId,
								root_id: ctx.payload.repositoryId,
							},
						],
						repo_id: ctx.payload.repositoryId,
					},
				],
				query_content: '',
				include_subfolders: false,
				search_all: false,
				limit: 1000,
			}

			return this.repositoryService.search(searchCriteria).pipe(
				map(data => new featureActions.LoadFileMetadataSuccessAction({ data })),
				catchError(error => [
					new LayoutStoreActions.ShowAlertToast({ message: 'An error has occurred loading folder metadata.' }),
					new featureActions.LoadFileMetadataFailureAction({ error }),
				]),
			)
		}),
	)

	@Effect()
	loadFileUploadedRequestEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.UploadFileFolderSuccessAction>(featureActions.ActionTypes.UPLOAD_INSIDE_FOLDER_SUCCESS),
		withLatestFrom(this.store$.pipe(select(featureSelectors.selectCurrentFolder))),
		mergeMap(([ctx, store]) => {
			// TODO solve where to load the image file
			if (ctx.payload.model.folder_id === store.folder_id) {
				const file: DamFile = {
					file_id: ctx.payload.model.file_id,
					icon: '',
					isBusy: false,
					isFavorite: false,
					isTemplate: false,
					name: ctx.payload.model.file_name,
					native: false,
					status: 'Active',
					storageType: '',
					user_permission: [],
				}
				return of(new featureActions.AddFileSuccessAction({ file }))
			}
			return of({ type: '[Folder] Upload File Folder Success NoopAction' })
		}),
	)

	/**
	 * Based on the current folder (payload.folderId),
	 * find the project in the tree and get the metadata from the endpoint of FileInfo
	 */
	@Effect()
	loadProjectMetadataEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.LoadProjectMetadataRequestAction>(featureActions.ActionTypes.LOAD_PROJECT_METADATA_REQUEST),
		mergeMap(action => {
			return this.folderService.findProject(action.payload.repositoryId, action.payload.folderId).pipe(
				mergeMap(project => {
					if (!project.folder_id) {
						return [
							new LayoutStoreActions.ShowAlertToast({ message: 'An error has occurred loading project metadata.' }),
							new featureActions.LoadProjectMetadataFailureAction({ error: '[LoadProjectMetadataRequestAction] Load error' }),
						]
					}

					// replace call to SDM for call to api mongo
					return this.folderService.getProjectMetadataFromStorage(project.folder_id).pipe(
						map((data: any) => {
							const file = data.files[0]
							const projectMetadata = {
								metadataFileId: file.file_id,
								projectId: project.folder_id,
								metadata: file.property,
							} as ProjectMetadata
							return new featureActions.LoadProjectMetadataSuccessAction({ data: projectMetadata })
						}),
						catchError(error => [
							new LayoutStoreActions.ShowAlertToast({
								message: 'An error has occurred loading project metadata.',
							}),
							new featureActions.LoadProjectMetadataFailureAction({ error: '[LoadProjectMetadataRequestAction][catch] Load error' }),
						]),
					)
				}),
			)
		}),
		catchError(error => [
			new LayoutStoreActions.ShowAlertToast({ message: 'An error has occurred loading project metadata.' }),
			new featureActions.LoadProjectMetadataFailureAction({ error: '[LoadProjectMetadataRequestAction][catch1] Load error' }),
		]),
	)

	@Effect({ dispatch: false })
	changeFolderStateEffect$ = this.actions$.pipe(
		ofType<featureActions.FolderStateChangeAction>(featureActions.ActionTypes.FOLDER_STATE_CHANGE),
		withLatestFrom(this.store$),
		mergeMap(([ctx, store]) =>
			this.folderService.findProject(environment.projectRepositoryId, ctx.payload.folderId).pipe(map(proj => [ctx, store, proj])),
		),
		tap(([ctx, store, project]) => {
			let folderStateChange = this.folderService.updateFolderStateCache(
				{
					folder_id: project.folder_id,
					isEmptyFolder: ctx.payload.isEmpty,
				},
				store.folders.projects,
			)

			if (!folderStateChange) {
				return
			}

			if (ctx.payload.verify) {
				this.folderService
					.getFolder(environment.projectRepositoryId, project.folder_id, false)
					.pipe(
						switchMap(folder => {
							const isEmpty = folder.files.length < 2 && !folder.folders.length
							return this.workfrontService.updateStorageProjectStatus({ folder_id: project.folder_id, isEmptyFolder: isEmpty })
						}),
					)
					.subscribe()
			} else {
				this.workfrontService.updateStorageProjectStatus({ folder_id: project.folder_id, isEmptyFolder: ctx.payload.isEmpty }).subscribe()
			}
		}),
	)
}
