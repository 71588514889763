export interface IContextMenuAction {
	type: string
	displayName: string
	icon: string
	modalId: string
	multiple: boolean
}

export const ContextMenuMoveTo: IContextMenuAction = {
	type: 'MoveTo',
	displayName: 'Move to...',
	icon: 'folder-move',
	modalId: 'move-folder',
	multiple: true,
}

export const ContextMenuRename: IContextMenuAction = {
	type: 'Rename',
	displayName: 'Rename...',
	icon: 'folder-edit',
	modalId: 'rename-resource',
	multiple: false,
}

export const ContextMenuDelete: IContextMenuAction = {
	type: 'Delete',
	displayName: 'Delete',
	icon: 'delete',
	modalId: 'confirmation-delete',
	multiple: true,
}

export const ContextMenuNewProject: IContextMenuAction = {
	type: 'NewProject',
	displayName: 'New Project...',
	icon: 'folder-create',
	modalId: 'create-project',
	multiple: false,
}

export const ContextMenuNewFolder: IContextMenuAction = {
	type: 'NewFolder',
	displayName: 'New Folder...',
	icon: 'folder-create',
	modalId: 'create-folder',
	multiple: false,
}

export const ContextMenuDownload: IContextMenuAction = {
	type: 'DownloadAsset',
	displayName: 'Download',
	icon: 'download',
	modalId: '',
	multiple: true,
}

export const ContextMenuDownloadFolder: IContextMenuAction = {
	type: 'DownloadFolder',
	displayName: 'Download',
	icon: 'download',
	modalId: '',
	multiple: false,
}

export const ContextMenuViewDetails: IContextMenuAction = {
	type: 'ViewDetails',
	displayName: 'View details',
	icon: 'info',
	modalId: '',
	multiple: false,
}
