import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core'
import { Subject } from 'rxjs'
import { FolderService, ModalService } from '../../services'
import { Observable } from 'rxjs'
import { takeUntil, filter, switchMap, first, map } from 'rxjs/operators'
import { FolderBase, Breadcrumb, ContextMenuMoveTo } from '@app/models'

@Component({
	selector: 'dam-move-to-modal',
	templateUrl: './move-to-modal.component.html',
	styleUrls: ['./move-to-modal.component.css'],
})
export class MoveToModalComponent implements OnInit, OnDestroy {
	@Output()
	onSubmit: EventEmitter<any> = new EventEmitter()

	@Output()
	onCancel: EventEmitter<any> = new EventEmitter()

	@Input()
	repositoryId: string

	@Input()
	folderId: string

	@Input()
	breadcrumbs$: Observable<Breadcrumb[]>

	@Input()
	currentFolderId: string

	destroy$ = new Subject()
	currentFolder: any = {}
	parentId: string
	modalId: string
	selectedFolders: Array<FolderBase> = []
	loading: boolean = true

	constructor(private modalService: ModalService, private folderService: FolderService) {}

	ngOnInit() {
		this.modalService
			.getContext()
			.pipe(
				takeUntil(this.destroy$),
				filter(ctx => ctx.model && ctx.model.action.type === ContextMenuMoveTo.type),
				switchMap(ctx => this.folderService.find(this.repositoryId, this.folderId).pipe(map(folder => [ctx, folder]))),
			)
			.subscribe(([ctx, folder]) => {
				this.modalId = ctx.model.action.modalId
				this.currentFolder = folder
				this.currentFolderId = folder.data.folder_id
				this.selectedFolders = ctx.model.items.filter(t => !t.file_id)
				this.setParent()
				if (this.currentFolder && this.currentFolder.children) {
					this.disabledSelectedFolder(this.currentFolder.children)
				}

				this.loading = false
			})
	}

	navigateTo(folderId: string) {
		this.loading = true
		this.folderService
			.find(this.repositoryId, folderId)
			.pipe(
				filter(t => t),
				first(),
			)
			.subscribe(t => {
				this.currentFolder = t
				this.setParent()
				if (this.currentFolder && this.currentFolder.children) {
					this.disabledSelectedFolder(this.currentFolder.children)
				}
				this.loading = false
			})
	}

	setParent() {
		if (this.currentFolder.data.level == 1) {
			this.parentId = this.currentFolder.data.repo_id
		} else if (this.currentFolder.data.level > 1) {
			this.parentId = this.currentFolder.data.parent_id
		} else {
			this.parentId = null
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next(true)
	}

	moveTo() {
		if (this.currentFolder.data.folder_id === this.currentFolderId) {
			this.modalService.close(this.modalId)
			return
		}
		this.onSubmit.emit(this.currentFolder.data)
	}

	disabledSelectedFolder(folders) {
		for (let folder of folders) {
			folder.disabled = this.selectedFolders.some(t => folder.data.folder_id === t.folder_id)
			folder.displayError = `Cannot move the folder "${folder.label}" onto itself`
		}
	}
}
