import { Action } from '@ngrx/store'
import { Folder } from '@app/models'

export enum ActionTypes {
	START_UPLOAD = '[APM] Start upload',
	LINK_PROJECT = '[APM] Link WF Projects',
}

export class StartUpload implements Action {
	readonly type = ActionTypes.START_UPLOAD
	constructor(public payload: { folder: Partial<Folder>; data: any }) {}
}

export class LinkProjects implements Action {
	readonly type = ActionTypes.START_UPLOAD
	constructor(public payload: { data: any }) {}
}

export type Actions = StartUpload | LinkProjects
