import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { RootStoreState } from 'src/app/root-store'
import { DamFile } from '@app/models'

@Component({
	selector: 'dam-asset-item-status',
	templateUrl: './asset-item-status.component.html',
	styleUrls: ['./asset-item-status.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetItemStatusComponent implements OnInit {
	file: DamFile
	fileStatusIcon: string
	fileStatusTitle: string

	@Input('file')
	set setFile(value) {
		this.file = value
		this.loadFileStatusIcon(this.file)
		// this.store$.pipe(select(UploadStoreSelectors.selectAssetById(), this.file.file_id)).subscribe(fileUpload => {
		//   this.loadFileStatusIcon(this.file, fileUpload);
		// });
	}

	constructor(private store$: Store<RootStoreState.State>) {}

	ngOnInit() {}

	loadFileStatusIcon(file: DamFile) {
		switch (file.status) {
			case 'Inactive': {
				this.fileStatusIcon = './../../../assets/images/icons/error-upload.svg'
				this.fileStatusTitle = 'Inactive'
				break
			}
			case 'Upload_Failed': {
				this.fileStatusIcon = './../../../assets/images/icons/error-upload.svg'
				this.fileStatusTitle = 'Upload failed'
				break
			}
			case 'Upload_InProgress': {
				this.fileStatusIcon = './../../../assets/images/icons/progress-upload.svg'
				this.fileStatusTitle = 'Upload in progress'
				break
			}
			default: {
				this.fileStatusIcon = ''
				this.fileStatusTitle = ''
			}
		}
	}
}
