import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Store } from '@ngrx/store'
import { RootStoreState } from '../..'
import { mapRepositoryNameToId, RepositoryTypes } from '../../../common/helpers'
import { SearchStoreActions } from '../../search-store'

@Injectable()
export class WorkfrontResolver {
	constructor(private store$: Store<RootStoreState.State>) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const rootFolder = route.paramMap.get('rootFolder')
		const repositoryId = mapRepositoryNameToId(rootFolder as RepositoryTypes)
		this.store$.dispatch(new SearchStoreActions.SetEnabled({ value: false }))
		// if (sessionStorage.getItem('wfusr')) {
		//     // this.store$.dispatch(new SearchStoreActions.AddContextContentAction({
		//     //     folderId: repositoryId,
		//     //     repositoryId: repositoryId
		//     // }));

		//     this.store$.dispatch(new FolderStoreActions.ClearProjectMetadataAction());
		//     // this.store$.dispatch(new SearchStoreActions.AddQueryContentAction({ value: environment.metadataFileName }));
		//     // this.store$.dispatch(new SearchStoreActions.ProjectFilterRequestAction());

		//     this.store$.dispatch(new FolderStoreActions.LoadRequestAction({ repositoryId: repositoryId, folderId: null }));
		// }
	}
}
