import { Component, OnInit, OnDestroy } from '@angular/core'
import { ModalService } from 'src/app/services'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { environment } from 'src/environments/environment'
import { FolderStoreActions, RootStoreState, UploadStoreActions, FolderStoreSelectors } from 'src/app/root-store'
import { Store } from '@ngrx/store'
import { ProcessFileService } from 'src/app/services/process-file.service'
import { DamContext, FolderCreate } from '@app/models'

@Component({
	selector: 'dam-main-guides',
	templateUrl: './main-guides.component.html',
	styleUrls: ['./main-guides.component.css'],
	providers: [ModalService],
})
export class MainGuidesComponent implements OnInit, OnDestroy {
	damContext: DamContext
	destroy$ = new Subject()

	constructor(
		private store$: Store<RootStoreState.State>,
		private modalService: ModalService,
		private processFileService: ProcessFileService,
	) {}

	ngOnInit() {
		this.store$
			.select(FolderStoreSelectors.selectCurrentFolder)
			.pipe(takeUntil(this.destroy$))
			.subscribe(t => {
				this.damContext = {
					folder_type: 'folder',
					folder_id: t.folder_id || environment.guideRepositoryId,
					repo_id: environment.guideRepositoryId,
				}
			})
	}

	ngOnDestroy(): void {
		this.destroy$.next(true)
	}

	showModal(modalId: string, model: any = null) {
		this.modalService.open(modalId, model)
	}

	closeModal(modalId: string): any {
		this.modalService.close(modalId)
	}

	createFolder(folder: FolderCreate) {
		folder.repo_id = this.damContext.repo_id
		folder.parent_id = this.damContext.folder_id || null
		this.store$.dispatch(new FolderStoreActions.CreateRequestAction({ folder }))
	}

	uploadFile(files) {
		if (this.damContext.folder_type !== 'folder') {
			return
		}

		const data = this.processFileService.makedirFromInput(files)

		this.store$.dispatch(
			new UploadStoreActions.VerifyUploadRequestAction({
				folder: { folder_id: this.damContext.folder_id, repo_id: this.damContext.repo_id },
				data,
			}),
		)
	}
}
