import { Injectable } from '@angular/core'
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Store } from '@ngrx/store'
import { FolderStoreActions } from './../../folder-store'
import { RootStoreState, LayoutStoreActions } from '../..'
import { SearchStoreActions } from '../../search-store'
import { environment } from '../../../../environments/environment'
import { BreadcrumbStoreActions } from '../../breadcrumb-store'
import { DamContext } from '@app/models'
import { RequestCacheService } from '@app/services'

@Injectable()
export class ProjectResolver implements Resolve<DamContext> {
	constructor(private store$: Store<RootStoreState.State>, private cache: RequestCacheService) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		this.store$.dispatch(new FolderStoreActions.ClearProjectMetadataAction())

		this.store$.dispatch(new BreadcrumbStoreActions.ClearAction())
		this.store$.dispatch(new LayoutStoreActions.SetSidenavActiveTab({ tab: 'details' }))

		this.store$.dispatch(new FolderStoreActions.LoadProjectFromStorageRequestAction({ setFolders: true }))
		this.store$.dispatch(new LayoutStoreActions.ShowDetailSidenav({ visible: false }))
		this.store$.dispatch(new SearchStoreActions.SetEnabled({ value: true }))

		const repositoryId = environment.projectRepositoryId

		return {
			folder_id: null,
			repo_id: repositoryId,
			folder_type: 'repository',
			tab: 'projects',
		} as DamContext
	}
}
