import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { map, catchError } from 'rxjs/operators'
import { Observable } from 'rxjs'
import * as moment from 'moment'
import { Project } from '../models/project.model'
import { Folder } from '../models/folder.model'
import { Metadata } from '../models/metadata.model'
import { FileUpdate } from '../models/file.model'

@Injectable({ providedIn: 'root' })
export class WorkfrontService {
	constructor(private http: HttpClient) {}

	getProjects(user: string, password: string): Observable<Project[]> {
		const payload = {
			username: user,
			password: password,
		}

		return this.http.post(`${environment.workfrontApiUrl}projects/get`, payload).pipe(
			map<any, Project[]>(projects => {
				return projects.data.map(x => this.workfrontMapping(x))
			}),
			catchError(err => {
				throw err
			}),
		)
	}

	getProjectsByID(user: string, password: string, projects: Folder[]): Observable<Project[]> {
		const ids = projects.map(x => x.property.find(x => x.name == environment.metadata.workfrontId.name).value).join(',')

		const payload = {
			username: user,
			password: password,
			id: ids,
		}
		return this.http.post(`${environment.workfrontApiUrl}projects/byid`, payload).pipe(
			map<any, Project[]>(projects => {
				return projects.data.map(x => this.workfrontMapping(x))
			}),
		)
	}

	createFileUpdate(fileId: string, schema: Metadata[], wfProject: Project): FileUpdate {
		return {
			file_id: fileId,

			file_properties: schema.map(property => {
				if (property.type == 'Date') {
					property.value = new Date(property.value).toISOString()
				}
				if (property.name == environment.metadata.status.name) {
					property.value = wfProject.metadata.find(x => x.name == environment.metadata.status.name).value
				}
				if (property.name == environment.metadata.projectUsers.name) {
					property.value = wfProject.metadata.find(x => x.name == environment.metadata.projectUsers.name).value
				}
				property.user_action = 'update'
				return property
			}),
		} as FileUpdate
	}

	workfrontMapping(context) {
		// hack to fix fail generate metadata file
		let projectUserString = JSON.stringify(
			context.projectUsers.map(x => {
				return {
					name: x.user.name,
					username: x.user.username,
				}
			}),
		)
		if (projectUserString) {
			projectUserString = projectUserString
				.split('ó')
				.join('o')
				.split('é')
				.join('e')
				.split('’')
				.join("'")
		}

		return {
			name: context.name,
			workfront_id: context.ID || '',
			description: '',
			metadata: [
				{
					...environment.metadata.workfrontId,
					value: context.ID || '',
				},
				{
					...environment.metadata.los,
					value: context['DE:_functional_group'] || '',
				},
				{
					...environment.metadata.businessUnit,
					value: context['DE:_sub_functional_group'] || '',
				},
				{
					...environment.metadata.entryDate,
					value: context.entryDate
						? moment(context.entryDate, 'YYYY-MM-DDTHH:mm:ssZ')
								.toDate()
								.toISOString()
						: '',
				},
				{
					...environment.metadata.deadline,
					value: context.plannedCompletionDate
						? moment(context.plannedCompletionDate, 'YYYY-MM-DDTHH:mm:ssZ')
								.toDate()
								.toISOString()
						: '',
				},
				{
					...environment.metadata.homeGroup,
					value: context.owner ? context.owner.homeGroup.name : '',
				},
				{
					...environment.metadata.projectUsers,
					value: projectUserString,
				},
				{
					...environment.metadata.referenceNumber,
					value: context.referenceNumber ? context.referenceNumber.toString() : '',
				},
				{
					...environment.metadata.sponsorName,
					value: context.sponsor ? context.sponsor.name : '',
				},
				{
					...environment.metadata.status,
					value: context['status'] || '',
				},
				{
					...environment.metadata.parentProject,
				},
				{
					...environment.metadata.childProject,
				},
			],
		}
	}

	generateStorageProjectsFile(folders) {
		return this.http.post(`${environment.workfrontApiUrl}${environment.workfrontApiCreateStorageProjects}`, folders)
	}

	addStorageProjects(folder) {
		return this.http.post(`${environment.workfrontApiUrl}${environment.workfrontApiGetStorageProjects}`, folder)
	}

	updateStorageProjects(folders) {
		return this.http.put(`${environment.workfrontApiUrl}${environment.workfrontApiGetStorageProjects}`, folders)
	}
	updateStorageProjectStatus(project) {
		return this.http.put(`${environment.workfrontApiUrl}${environment.workfrontApiGetStorageProjects}/updateProjectStatus`, project)
	}
}
