/**
 *  array update
 */
export const arrayUpdate = (source: any[], newItem: any, index: number) => {
	return source.map((item, i) => {
		if (index !== i) {
			return item
		}

		return {
			...item,
			...newItem,
		}
	})
}
