<ng-container [ngSwitch]="layout">

  <div class="asset-item asset-project" (dndFile)="dragLeave($event)" [contextMenu]="folderContextMenu" *ngSwitchCase="'grid'">
    <!-- Drag & drop indicator shown when "asset-project" has the class "drop" -->
    <div class="project-drop">
      <div class="project-drop-border">
        <span class="project-drop-text">
          Drop it like it's hot
        </span>
      </div>
    </div>

    <!-- Project's logo & truncated title -->
    <div class="project-title folder">
      <div class="asset-item-header">
        <img src="./../../../assets/images/icons/folder.svg" alt="folder">
        <h5 class="asset-item-title">
          {{model.name}}
        </h5>
      </div>
    </div>
    <dam-loading [internal]="true" [small]="true" *ngIf="model?.isBusy"></dam-loading>
  </div>

  <div class="asset-item" (dndFile)="dragLeave($event)" [contextMenu]="folderContextMenu" *ngSwitchCase="'list'">
    <div class="project-drop">
      <div class="project-drop-border">
        <span class="project-drop-text">
          Drop it like it's hot
        </span>
      </div>
    </div>

    <div class="asset-item-name text-ellipsis" [title]="model.name">
      <div class="asset-item-icon">
        <img src="./../../../assets/images/icons/folder.svg" alt="folder">
      </div>
      <span class="asset-list-item-title text-ellipsis">
        {{model.name}}
      </span>
    </div>
    <div class="asset-item-owner text-ellipsis">
      -
    </div>
    <div class="asset-item-update text-ellipsis">
      -
    </div>
    <div class="asset-item-size text-ellipsis">
      -
    </div>
    <div class="asset-item-actions text-ellipsis">
      <dam-asset-item-status [file]="model" *ngIf="!model?.isBusy"></dam-asset-item-status>
      <div [hidden]="model?.isBusy">
        <button *ngFor="let action of contextMenuActions" (click)="onClickContextMenu(action)" class="button"
          matTooltip="{{action.displayName}}">
          <img alt="download" src="../../../assets/images/icons/{{action.icon}}.svg">
        </button>
      </div>
      <div class="loader-wrapper">
        <div class="loader" *ngIf="model?.isBusy">Loading...</div>
      </div>
    </div>
  </div>
</ng-container>

<!-- Context Menu -->
<context-menu #folderContextMenu>
  <ng-template contextMenuItem *ngFor="let action of contextMenuActions" let-item [visible]="(selectedItems$ | async)?.length > 1 ? action.multiple : true"
    [enabled]="true" (execute)="onClickContextMenu(action)">
    <div class="menu-item">
      <img class="icon" src="./../../../assets/images/icons/{{action.icon}}.svg" alt=""> {{action.displayName}}
    </div>
  </ng-template>
</context-menu>