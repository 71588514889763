import { Action } from '@ngrx/store'
import { FolderDownload } from '@app/models'

export enum ActionTypes {
	LOAD_REQUEST = '[DownloadFolder] Load Request',
	LOAD_FAILURE = '[DownloadFolder] Load Failure',
	LOAD_SUCCESS = '[DownloadFolder] Load Success',

	DOWNLOAD_FOLDER_REQUEST = '[DownloadFolder] Download Request',
	DOWNLOAD_FOLDER_FAILURE = '[DownloadFolder] Download Failure',
	DOWNLOAD_FOLDER_SUCCESS = '[DownloadFolder] Download Success',

	DOWNLOAD_ZIP_FOLDER_REQUEST = '[DownloadFolder] Download zip folder request',
	DOWNLOAD_ZIP_FOLDER_SUCCESS = '[DownloadFolder] Download zip folder success',

	NOTIFY_FOLDER_DOWNLOAD = '[DownloadFolder] Notify folder download',

	SYNCHRONIZE_REQUEST = '[DownloadFolder] Synchronize Request',
	SYNCHRONIZE_FAILURE = '[DownloadFolder] Synchronize Failure',
	SYNCHRONIZE_SUCCESS = '[DownloadFolder] Synchronize Success',

	REMOVE_DOWNLOAD_FOLDER_REQUEST = '[DownloadFolder] Remove Download Folder Request',
	REMOVE_DOWNLOAD_FOLDER_FAILURE = '[DownloadFolder] Remove Download Folder Failure',
	REMOVE_DOWNLOAD_FOLDER_SUCCESS = '[DownloadFolder] Remove Download Folder Success',

	CLEAR_DOWNLOAD_FOLDERS_ALL = '[DownloadFolder] Clear Download Folders',
	// CLEAR_DOWNLOAD_FOLDERS_ALL_FAILURE = '[DownloadFolder] Clear Download Folders Failure',
	// CLEAR_DOWNLOAD_FOLDERS_ALL_SUCCESS = '[DownloadFolder] Clear Download Folders Success',
}

export class LoadRequestAction implements Action {
	readonly type = ActionTypes.LOAD_REQUEST
}

export class LoadFailureAction implements Action {
	readonly type = ActionTypes.LOAD_FAILURE
	constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
	readonly type = ActionTypes.LOAD_SUCCESS
	constructor(public payload: { folders: Partial<FolderDownload>[] }) {}
}

export class DownloadFolderRequestAction implements Action {
	readonly type = ActionTypes.DOWNLOAD_FOLDER_REQUEST
	constructor(public payload: { folder: Partial<FolderDownload> }) {}
}

export class DownloadFolderFailureAction implements Action {
	readonly type = ActionTypes.DOWNLOAD_FOLDER_FAILURE
	constructor(public payload: { error: string }) {}
}

export class DownloadFolderSuccessAction implements Action {
	readonly type = ActionTypes.DOWNLOAD_FOLDER_SUCCESS
	constructor(public payload: { folder: Partial<FolderDownload> }) {}
}

export class DownloadZipFolderRequestAction implements Action {
	readonly type = ActionTypes.DOWNLOAD_ZIP_FOLDER_REQUEST
	constructor(public payload: { folder: Partial<FolderDownload> }) {}
}

export class DownloadZipFolderSuccessAction implements Action {
	readonly type = ActionTypes.DOWNLOAD_ZIP_FOLDER_SUCCESS
	constructor(public payload: { folder: Partial<FolderDownload> }) {}
}

export class NotifyFolderDownload implements Action {
	readonly type = ActionTypes.NOTIFY_FOLDER_DOWNLOAD
	constructor(public payload: { folder: Partial<FolderDownload> }) {}
}

export class SynchronizeRequestAction implements Action {
	readonly type = ActionTypes.SYNCHRONIZE_REQUEST
}

export class SynchronizeFailureAction implements Action {
	readonly type = ActionTypes.SYNCHRONIZE_FAILURE
	constructor(public payload: { error: string }) {}
}

export class SynchronizeSuccessAction implements Action {
	readonly type = ActionTypes.SYNCHRONIZE_SUCCESS
	constructor(public payload: { folders: Partial<FolderDownload>[] }) {}
}

export class RemoveDownloadFolderRequestAction implements Action {
	readonly type = ActionTypes.REMOVE_DOWNLOAD_FOLDER_REQUEST
	constructor(public payload: { folder: FolderDownload }) {}
}

export class RemoveDownloadFolderFailureAction implements Action {
	readonly type = ActionTypes.REMOVE_DOWNLOAD_FOLDER_FAILURE
	constructor(public payload: { error: string }) {}
}

export class RemoveDownloadFolderSuccessAction implements Action {
	readonly type = ActionTypes.REMOVE_DOWNLOAD_FOLDER_SUCCESS
	constructor(public payload: { folder: Partial<FolderDownload> }) {}
}
export class ClearDownloadFoldersAllAction implements Action {
	readonly type = ActionTypes.CLEAR_DOWNLOAD_FOLDERS_ALL
}

export type Actions =
	| LoadRequestAction
	| LoadFailureAction
	| LoadSuccessAction
	| DownloadFolderRequestAction
	| DownloadFolderFailureAction
	| DownloadFolderSuccessAction
	| DownloadZipFolderRequestAction
	| DownloadZipFolderSuccessAction
	| NotifyFolderDownload
	| SynchronizeRequestAction
	| SynchronizeFailureAction
	| SynchronizeSuccessAction
	| RemoveDownloadFolderRequestAction
	| RemoveDownloadFolderFailureAction
	| RemoveDownloadFolderSuccessAction
	| ClearDownloadFoldersAllAction
