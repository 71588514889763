import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { FolderDownload } from '@app/models'

export const featureAdapter: EntityAdapter<Partial<FolderDownload>> = createEntityAdapter<Partial<FolderDownload>>({
	selectId: model => model.folder_id,
})

export interface State extends EntityState<Partial<FolderDownload>> {}

export const initialState: State = featureAdapter.getInitialState({})
