import { Component, OnInit, Output, EventEmitter, OnDestroy, Input } from '@angular/core'
import { ModalService } from '../../services'
import { filter, map, takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { ContextMenuDelete } from '@app/models'

@Component({
	selector: 'dam-delete-confirm-modal',
	templateUrl: './delete-confirm-modal.component.html',
	styleUrls: ['./delete-confirm-modal.component.css'],
})
export class DeleteConfirmModalComponent implements OnInit, OnDestroy {
	@Output()
	onSubmit: EventEmitter<any> = new EventEmitter()

	@Output()
	onCancel: EventEmitter<any> = new EventEmitter()

	destroy$ = new Subject()
	name: string
	type: 'folder' | 'file' | 'multiple' = 'folder'

	constructor(private modalService: ModalService) {
		this.modalService
			.getContext()
			.pipe(
				takeUntil(this.destroy$),
				filter(ctx => ctx.model && ctx.model.action.type === ContextMenuDelete.type),
				map(ctx => ctx.model),
			)
			.subscribe(context => {
				if (context.items.length == 1) {
					this.name = context.payload.name
					this.type = context.payload.file_id ? 'file' : 'folder'
				} else {
					this.name = context.items.length
					this.type = 'multiple'
				}
			})
	}

	ngOnInit() {}

	ngOnDestroy(): void {
		this.destroy$.next(true)
	}
}
