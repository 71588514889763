import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core'
import { Breadcrumb } from '@app/models'

@Component({
	selector: 'dam-breadcrumbs',
	templateUrl: './breadcrumbs.component.html',
	styleUrls: ['./breadcrumbs.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent implements OnInit {
	data: Breadcrumb[]

	@Input('data')
	set setData(data) {
		this.data = data
	}

	constructor() {}

	ngOnInit() {
		// console.log('prev',this.data)
		// this.data = this.data.slice(0, 1)
		// console.log('post',this.data)
	}
}
