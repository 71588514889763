import { Injectable } from '@angular/core'
import { Store, Action } from '@ngrx/store'
import { RootStoreState } from '..'
import { RepositoryService } from '@app/services'
import { Effect, ofType, Actions } from '@ngrx/effects'
import { Observable, of } from 'rxjs'
import * as featureActions from './actions'
import { switchMap, map, catchError } from 'rxjs/operators'
import { environment } from '@environment/environment'

@Injectable()
export class RepositoryStoreEffects {
	constructor(private actions$: Actions, private repositoryService: RepositoryService) {}

	@Effect()
	repositoryRequestEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.RepoRequestAction>(featureActions.ActionTypes.REPO_REQUEST),
		switchMap(t =>
			this.repositoryService.get(environment.projectRepositoryId).pipe(
				map(repo => new featureActions.RepoSuccessAction(repo)),
				catchError(error => of(new featureActions.RepoFailureAction(error))),
			),
		),
	)

	@Effect()
	repositoryUpdateEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.UpdateRequestAction>(featureActions.ActionTypes.UPDATE_REPO_REQUEST),
		switchMap(t =>
			this.repositoryService.update(t.payload).pipe(
				map(repo => new featureActions.UpdateSuccessAction(t.payload)),
				catchError(error => of(new featureActions.UpdateFailureAction(error))),
			),
		),
	)
}
