import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class ModalService {
	private modals: any[] = []
	private activeModal: any

	private context: BehaviorSubject<any> = new BehaviorSubject({})

	add(modal: any) {
		this.modals.push(modal)
	}

	remove(id: string) {
		this.modals = this.modals.filter(x => x.id !== id)
	}

	removeAll() {
		this.modals = []
		this.activeModal = null
	}

	open(id: string, model?: any) {
		this.activeModal = { id: id, model: model }
		this.context.next(this.activeModal)
		const modal: any = this.modals.filter(x => x.id === id)[0]
		modal.open()
	}

	getContext() {
		return this.context.asObservable()
	}

	close(id: string) {
		let modal: any = this.modals.filter(x => x.id === id)[0]
		this.activeModal = null
		modal.close()
	}
}
