import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { EffectsModule } from '@ngrx/effects'
import { GoogleAnalyticsEffects } from '../google-analytics-store/effects'

@NgModule({
	imports: [CommonModule, EffectsModule.forFeature([GoogleAnalyticsEffects])],
	declarations: [],
})
export class GoogleAnalyticsStoreModule {}
