import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { UserStoreModule } from './user-store/user-store.module'
import { FolderStoreModule } from './folder-store/folder-store.module'
import { BreadcrumbStoreModule } from './breadcrumb-store/breadcrumb-store.module'
import { UploadStoreModule } from './upload-store/upload-store.module'
import { LayoutStoreModule } from './layout-store/layout-store.module'
import { WorkfrontStoreModule } from './workfront-store'
import { SearchStoreModule } from './search-store/search-store.module'
import { GoogleAnalyticsStoreModule } from './google-analytics-store/google-analytics-store.module'
import { DownloadStoreModule } from './download-store/download-store.module'
import { ApmStoreModule } from './apm-store'
import { RepositoryStoreModule } from './repository-store/repository-store.module'

@NgModule({
	imports: [
		CommonModule,
		UserStoreModule,
		UploadStoreModule,
		StoreModule.forRoot({}),
		EffectsModule.forRoot([]),
		FolderStoreModule,
		BreadcrumbStoreModule,
		LayoutStoreModule,
		WorkfrontStoreModule,
		SearchStoreModule,
		GoogleAnalyticsStoreModule,
		DownloadStoreModule,
		ApmStoreModule,
		RepositoryStoreModule,
	],
	declarations: [],
})
export class RootStoreModule {}
