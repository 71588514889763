import { Actions, ActionTypes } from './actions'
import { initialState, State } from './state'
import { environment } from '../../../environments/environment'
import { arrayUpdate } from '@app/common/extensions'

export function featureReducer(state = initialState, action: Actions): State {
	switch (action.type) {
		/***
		 * Workfront actions
		 */
		case ActionTypes.LOAD_WF_PROJECTS: {
			return {
				...state,
				user: action.payload.email,
				password: action.payload.password,
				isLoading: true,
			}
		}
		case ActionTypes.LOAD_WF_PROJECTS_SUCCESS: {
			return {
				...state,
				wf_projects: action.payload.data,
				// .map(x => {
				//     if (state.dam_projects.find(proj => proj.property.find(x => x.name.toLowerCase() == environment.metadata.workfrontId.name.toLowerCase()).value == x.workfront_id))
				//         x.created = true;
				//     return x;
				// }),
				// outdatedProjects: state.dam_projects.filter(proj =>
				//     !action.payload.data.find(x => x.workfront_id == proj.property.find(x => x.name == environment.metadata.workfrontId.name).value)
				//     && environment.workfrontStatusFilter.find(x => x == proj.property.find(x => x.name == environment.metadata.status.name).value)
				// ),
				outdatedProjects: false,
				isLoading: false,
			}
		}
		case ActionTypes.LOAD_WF_PROJECTS_FAILURE: {
			return {
				...state,
				loginError: action.payload.error,
				isLoading: false,
			}
		}
		/**
		 * Project actions
		 */
		case ActionTypes.CREATE_PROJECT_REQUEST: {
			const index = state.wf_projects.findIndex(t => t.workfront_id === action.payload.project.workfront_id)
			if (index === -1) {
				return state
			}

			const oldItem = state.wf_projects[index]
			const newItem = {
				...oldItem,
				isBusy: true,
			}

			return {
				...state,
				wf_projects: arrayUpdate(state.wf_projects, newItem, index),
			}
		}
		case ActionTypes.CREATE_PROJECT_SUCCESS: {
			const index = state.wf_projects.findIndex(t => t.workfront_id === action.payload.wf_id)
			if (index === -1) {
				return state
			}

			const oldItem = state.wf_projects[index]
			const newItem = {
				...oldItem,
				isBusy: false,
				created: true,
			}

			return {
				...state,
				wf_projects: arrayUpdate(state.wf_projects, newItem, index),
				isLoading: false,
			}
		}
		// case ActionTypes.LOAD_DAM_PROJECTS_METADATA: {
		// 	if (!action.payload.items) {
		// 		return state;
		// 	}

		// 	let dam_projects = action.payload.items.filter(x => {
		// 		return x.property.find(x => x.name == environment.metadata.workfrontId.name);
		// 	});

		// 	return {
		// 		...state,
		// 		dam_projects: dam_projects,
		// 	};
		// }
		case ActionTypes.SYNC_ADD_PROJECTS_REQUEST: {
			return {
				...state,
				sync_projects: action.payload.projects,
			}
		}
		case ActionTypes.SYNC_PROJECTS_REQUEST: {
			if (!action.payload.projects) {
				return state
			}
			return {
				...state,
				// isLoading: true,
			}
		}
		case ActionTypes.SYNC_PROJECTS_SUCCESS: {
			return {
				...state,
				outdatedProjects: true,
				isLoading: false,
			}
		}
		case ActionTypes.SYNC_PROJECTS_FAILURE: {
			return {
				...state,
				outdatedProjects: true,
				isLoading: false,
			}
		}

		case ActionTypes.SYNC_PROJECT_REQUEST: {
			const index = state.sync_projects.findIndex(t => t.folder_id == action.payload.project.folder_id)
			if (index === -1) return state

			const oldItem = state.sync_projects[index]
			const newItem = {
				...oldItem,
				isBusy: true,
			}

			return {
				...state,
				sync_projects: arrayUpdate(state.sync_projects, newItem, index),
			}
		}

		case ActionTypes.SYNC_PROJECT_SUCCESS: {
			const index = state.sync_projects.findIndex(t => t.folder_id == action.payload.project.folder_id)
			if (index === -1) return state

			const oldItem = state.sync_projects[index]
			const newItem = {
				...oldItem,
				isBusy: false,
				syncCompleted: true,
				error: null,
			}

			return {
				...state,
				sync_projects: arrayUpdate(state.sync_projects, newItem, index),
			}
		}

		case ActionTypes.SYNC_PROJECT_FAILURE: {
			const index = state.sync_projects.findIndex(t => t.folder_id == action.payload.project.folder_id)
			if (index === -1) return state

			let errorMessage = ''
			switch (action.payload.project.error.code) {
				case '1': {
					errorMessage = 'Invalid workfront ID'
					break
				}
				case '2': {
					errorMessage = 'Metadata id not found'
					break
				}
				case '2.1': {
					errorMessage = 'Upload metadata failed'
					break
				}
				case '500': {
					errorMessage = 'Internal server error'
				}
				case '600': {
					errorMessage = 'SDM Server error'
				}
			}

			const oldItem = state.sync_projects[index]
			const newItem = {
				...oldItem,
				isBusy: false,
				syncCompleted: true,
				error: {
					...action.payload.project.error,
					message: errorMessage,
				},
			}

			return {
				...state,
				sync_projects: arrayUpdate(state.sync_projects, newItem, index),
			}
		}

		case ActionTypes.SYNC_PROJECT_FIX_METADATA_REQUEST: {
			const index = state.sync_projects.findIndex(t => t.folder_id == action.payload.project.folder_id)
			if (index === -1) return state

			let errorMessage = ''
			switch (action.payload.project.error.code) {
				case '1': {
					errorMessage = 'Invalid workfront ID'
					break
				}
				case '2': {
					errorMessage = 'Fixing metadata file...'
					break
				}
			}

			const oldItem = state.sync_projects[index]

			const newItem = {
				...oldItem,
				isBusy: true,
				syncCompleted: false,
				error: {
					...action.payload.project?.error,
					count: action.payload.project?.error?.count ? action.payload.project.error.count++ : 1,
					message: errorMessage,
				},
			}

			return {
				...state,
				sync_projects: arrayUpdate(state.sync_projects, newItem, index),
			}
		}

		case ActionTypes.CLEAR_OUTDATED_PROJECTS_REQUEST: {
			return {
				...state,
				outdatedProjects: true,
			}
		}

		case ActionTypes.GENERATE_STORAGE_PROJECTS_FILE_REQUEST: {
			return {
				...state,
				isLoading: true,
			}
		}

		case ActionTypes.GENERATE_STORAGE_PROJECTS_FILE_FAILURE: {
			return {
				...state,
				isLoading: false,
			}
		}

		case ActionTypes.GENERATE_STORAGE_PROJECTS_FILE_SUCCESS: {
			return {
				...state,
				isLoading: false,
			}
		}

		default: {
			return state
		}
	}
}
