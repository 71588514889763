import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store'
import { State } from './state'
import { selectFolderState } from './../folder-store/selectors'
import { environment } from 'src/environments/environment'

export const selectWorkfrontState: MemoizedSelector<object, State> = createFeatureSelector<State>('workfront')
export const wfProjects = createSelector(selectWorkfrontState, (state: State) => state.wf_projects)
export const damProjects = createSelector(selectWorkfrontState, (state: State) => state.dam_projects)
export const selectIsLoading = createSelector(selectWorkfrontState, (state: State) => state.isLoading)
export const loginError = createSelector(selectWorkfrontState, (state: State) => state.loginError)
export const getUserInfo = createSelector(selectWorkfrontState, state => {
	return { user: state.user, password: state.password }
})

export const isDamProjectLoaded = createSelector(selectFolderState, state => {
	return state.folders.some(t => !t.metadataLoaded) || state.isLoading
})

export const selectOutdateProjects = createSelector(selectWorkfrontState, selectFolderState, (state: State, folderState) => {
	return folderState.folders.filter(proj => {
		if (!proj.property || state.outdatedProjects) {
			return false
		}

		return (
			!state.wf_projects.find(x => x.workfront_id == proj.property.find(x => x.name == environment.metadata.workfrontId.name).value) &&
			environment.workfrontStatusFilter.find(x => x == proj.property.find(x => x.name == environment.metadata.status.name).value)
			// if the project exists in wf list, verify the name
			// los unicos proyectos que se puede actualizar el nombre son los
			// state.wf_projects.find(x => x.workfront_id == proj.property.find(x => x.name == environment.metadata.workfrontId.name).value)

			//// falta verificar que el nombre sea igual
		)
	})
})
