<nav aria-label="breadcrumb">
    <ol class="breadcrumbs">
        <li class="breadcrumb" *ngFor="let bc of data; let i = index" [ngClass]="{' active': i == (data.length-1)}">
            <ng-container *ngIf="i == 0">
                <a class="breadcrumb-link" routerLink="{{bc.url}}"><img src="../../../assets/images/icons/home.svg" alt="Home"></a>
            </ng-container>
            <ng-container *ngIf="i > 0 && i != (data.length - 1)">
                &#47;
                <a class="breadcrumb-link" routerLink="{{bc.url}}">{{bc.label}}</a>
            </ng-container>
            <ng-container *ngIf="i == (data.length - 1)">
                {{bc.label}}
            </ng-container>
        </li>
    </ol>
</nav>
