import { Injectable } from '@angular/core'
import { Router, CanActivate, ActivatedRoute } from '@angular/router'
import { GoogleLoginProvider, SocialAuthService } from 'angularx-social-login'
import { SocialUser } from 'angularx-social-login'
import { Observable, of } from 'rxjs'
import { withLatestFrom, mergeMap, map, switchMap, catchError, take } from 'rxjs/operators'
import { select, Store } from '@ngrx/store'
import { RootStoreState, UserStoreSelectors, UserStoreActions, RouteActions } from '../..'
import { UserService } from '../../../services/user.service'
import { ApiGeeService } from '@app/services/api-gee.service'

declare const window: any

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(
		private store$: Store<RootStoreState.State>,
		private authService: SocialAuthService,
		private router: Router,
		private userService: UserService,
		private route: ActivatedRoute,
		private apiGeeService: ApiGeeService,
	) {}

	canActivate(): Observable<boolean> {
		return this.authService.authState.pipe(
			switchMap(user => {
				if (user) {
					return of(true)
				}

				setTimeout(() => {
					this.authService.signIn(GoogleLoginProvider.PROVIDER_ID, {
						ux_mode: 'redirect',
						redirect_uri: window.location.origin,
					})
				}, 0)

				return of(false)
			}),
		)
	}
}
