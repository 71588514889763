<ng-container [ngSwitch]="layout">

  <div class="asset-item asset-project asset-project-temp-icon" [contextMenu]="projectContextMenu" *ngSwitchCase="'grid'">
    <!-- Details of the project visible on hover -->
    <div class="project-hover">
      <span class="project-status" [hidden]="!status">
        {{workfrontStatusDictionary[status?.value] || status?.value || '' }}
      </span>
      <div class="project-hover-header">
        <h5 class="asset-item-title">
          {{model.name}}
        </h5>
      </div>
      <hr class="project-hover-line">
      <div class="project-hover-footer">

        <div class="project-hover-info-line">
          <div class="deadline" [hidden]="!deadline">
            <span class="porject-hover-info-title">
              C3 deadline:
            </span>
            <span class="project-hover-info-value">
              {{deadline?.value | date:'short'}}
            </span>
          </div>
          <div class="workfront-link" [hidden]="!workfrontId">
            <a href="{{wfUrl}}{{workfrontId?.value}}" target="_blank" rel="noopener noreferrer">
              <img src="./../../../assets/images/icons/workfront.svg" alt="Open in workfront"> Open in C3
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Drag & drop indicator shown when "asset-project" has the class "drop" -->
    <div class="project-drop">
      <div class="project-drop-border">
        <img class="project-drop-img" src="../../../assets/images/icons/upload.svg" alt="Drop to add">
        <span class="project-drop-text">
          Drop it like it's hot
        </span>
      </div>
    </div>

    <!-- Project's logo & truncated title -->
    <div class="project-title">
      <span class="project-status" [hidden]="!status">
        {{workfrontStatusDictionary[status?.value] || status?.value || '' }}
      </span>
      <div class="asset-item-img">
        <img class="asset-item-asset" src="../assets/images/icons/project-folder.svg" alt="Asset Image">
      </div>
      <div class="asset-item-header">
        <h5 class="asset-item-title">
          {{model.name}}
        </h5>
      </div>
    </div>
  </div>

  <div class="asset-item" [class.disabled]="!model.property" [contextMenu]="projectContextMenu" *ngSwitchCase="'list'">
    <div class="asset-item-name" [title]="model.name">
      <div class="asset-item-icon">
        <img [src]="itemIcon" alt="folder" *ngIf="this.model.property">
      </div>
      <span class="asset-list-item-title project-name">
        {{model.name}}
      </span>
    </div>
    <div class="asset-item-name">
      <span class="asset-list-item-title">
        {{reference?.value}}
      </span>
    </div>
    <div class="asset-item-name">
      <span class="asset-list-item-title">
        {{deadline?.value | date:'MMMM dd, yyyy'}}
      </span>
    </div>
    <div class="asset-item-name status">
      <span class="asset-list-item-title">
        <span class="project-status" [ngClass]="status?.value.toLowerCase()" [hidden]="!status" >
          {{workfrontStatusDictionary[status?.value] || status?.value || '' }}
        </span>
      </span>
    </div>
    <div class="asset-item-name my-project">
      <span class="asset-list-item-title" *ngIf="model.myProject">
        <img src="../../../assets/images/star.png" alt="">
      </span>
    </div>
    <div class="asset-item-actions text-ellipsis">
        <dam-asset-item-status [file]="model" *ngIf="!model?.isBusy"></dam-asset-item-status>
          <button *ngFor="let action of contextMenuActions" (click)="onClickContextMenu(action)" class="button" matTooltip="{{action.displayName}}">
            <img src="../../../assets/images/icons/{{action.icon}}.svg">
          </button>
      </div>
  </div>
</ng-container>

<!-- Context Menu -->
<context-menu #projectContextMenu>
  <ng-template contextMenuItem *ngFor="let action of contextMenuActions" let-item [visible]="true" [enabled]="true"
    (execute)="onClickContextMenu(action)">
    <div class="menu-item">
      <img class="icon" src="./../../../assets/images/icons/{{action.icon}}.svg" alt=""> {{action.displayName}}
    </div>
  </ng-template>
</context-menu>