import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { Action } from '@ngrx/store'
import { Observable, from, of } from 'rxjs'
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators'
import { FileService } from '../../../services/file.service'
import { LayoutStoreActions } from '../../layout-store'

import * as folderActions from './../actions/folder.actions'
import * as searchStoreActions from '../../search-store/actions'

import * as featureActions from './../actions/file.actions'
import { FolderService } from '../../../services/folder.service'

@Injectable()
export class FileStoreEffects {
	constructor(private fileService: FileService, private actions$: Actions, private folderService: FolderService) {}

	/***
	 * REFACTOR: No references to this effect found
	 */
	@Effect()
	loadFileLinkRequestEffect$: Observable<Action> = this.actions$.pipe(
		ofType<folderActions.LoadFileLinkRequestAction>(folderActions.ActionTypes.LOAD_FILE_LINK_REQUEST),
		mergeMap(file =>
			from(file.payload.files || []).pipe(
				mergeMap(file =>
					this.fileService.getFileLink(file.file_id).pipe(
						map(file => new folderActions.LoadFileLinkSuccessAction({ file })),
						catchError(error => of(new folderActions.LoadFileLinkFailureAction({ error }))),
					),
				),
			),
		),
	)

	@Effect()
	updateProjectEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.UpdateRequestAction>(featureActions.ActionTypes.FILE_UPDATE_REQUEST),
		switchMap(action =>
			this.fileService.update(action.payload.file).pipe(
				map(folder => new featureActions.UpdateSuccessAction({ file: action.payload.file })),
				catchError(error => [
					new LayoutStoreActions.ShowAlertToast({ message: 'An error has occurred while updating the file.' }),
					new featureActions.UpdateFailureAction({ error }),
				]),
			),
		),
	)

	@Effect()
	deleteFileEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.DeleteRequestAction>(featureActions.ActionTypes.FILE_DELETE_REQUEST),
		switchMap(action =>
			this.fileService.delete(action.payload.assets, action.payload.folderId).pipe(
				switchMap(folder => [
					new featureActions.DeleteSuccessAction({ assets: action.payload.assets }),
					new folderActions.FolderStateChangeAction({ folderId: action.payload.folderId, verify: true }),
				]),
				catchError(error => [
					new LayoutStoreActions.ShowAlertToast({ message: 'An error has occurred deleting the file.' }),
					new featureActions.DeleteFailureAction({ error }),
				]),
			),
		),
	)

	@Effect()
	notifyDeleteFileEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.DeleteSuccessAction>(featureActions.ActionTypes.FILE_DELETE_SUCCESS),
		map(t => {
			const items = t.payload.assets.files.length + t.payload.assets.folders.length
			const itemsLabel = items === 1 ? 'item' : 'items'
			return new LayoutStoreActions.ShowAlertToast({ message: `Removed ${items} ${itemsLabel}.` })
		}),
	)

	@Effect()
	downloadFileEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.DownloadRequestAction>(featureActions.ActionTypes.FILE_DOWNLOAD_REQUEST),
		mergeMap(action =>
			this.fileService.getFile(action.payload.file).pipe(
				mergeMap(t => {
					// Opens in a new tab to start the download file.
					// In single download
					// if (action.payload.multiple) {
					window.open(t.url, '_blank')
					// } else {
					//     var a = document.createElement('a');
					//     a.download = t.file.name;
					//     a.href = t.url;
					//     a.dispatchEvent(new MouseEvent('click'));
					// }

					return of(new featureActions.DownloadSuccessAction({ file: t.file }))
				}),
				catchError(error => of(new featureActions.DownloadFailureAction({ error }))),
			),
		),
	)

	@Effect()
	notifyDownloadFileEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.DownloadRequestAction>(featureActions.ActionTypes.FILE_DOWNLOAD_REQUEST),
		map(t => new LayoutStoreActions.ShowAlertToast({ message: 'Preparing download...', autoDismiss: true })),
	)

	@Effect()
	loadFileAuditHistoryEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.LoadAuditHistoryRequestAction>(featureActions.ActionTypes.LOAD_AUDIT_HISTORY_REQUEST),
		switchMap(action =>
			this.fileService.getAuditHistory(action.payload.fileId).pipe(
				switchMap(auditHistory => [
					new featureActions.LoadAuditHistorySuccessAction({ fildeId: action.payload.fileId, auditHistory }),
					new searchStoreActions.SetAuditHistoryToSearchFileAction({ fildeId: action.payload.fileId, auditHistory }),
				]),
				catchError(error => [
					new LayoutStoreActions.ShowAlertToast({
						message: 'An error has occurred loading the audit history of the file.',
					}),
					new featureActions.LoadAuditHistoryFailureAction({ error }),
				]),
			),
		),
	)

	/**
	 * Move file feature
	 */
	@Effect()
	moveToEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.MoveToRequestAction>(featureActions.ActionTypes.MOVE_TO_REQUEST),
		switchMap(action =>
			this.fileService.move(action.payload.files).pipe(
				switchMap(files => {
					return [
						new featureActions.MoveToRequestSuccessAction({ files: action.payload.files }),
						new LayoutStoreActions.ShowAlertToast({ message: 'File/s has been moved successfully' }),
						new folderActions.FolderStateChangeAction({ folderId: action.payload.files.source_folder_id, verify: true }),
					]
				}),
				catchError(error => [
					new LayoutStoreActions.ShowAlertToast({ message: 'An error has occurred while moving the file.' }),
					new featureActions.MoveToRequestFailureAction({ error }),
				]),
			),
		),
	)
}
