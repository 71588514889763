import { Project, DamFile, Folder, SyncFolder } from '@app/models'

export interface State {
	user: string
	password: string
	isLoading: boolean
	wf_projects: Project[]
	dam_projects: DamFile[]
	outdatedProjects: boolean
	sync_projects: SyncFolder[]
	loginError: string
}

export const initialState: State = {
	user: '',
	password: '',
	isLoading: false,
	wf_projects: [],
	dam_projects: [],
	outdatedProjects: false,
	sync_projects: [],
	loginError: null,
}
