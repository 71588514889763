import { State, initialState } from './state'
import { Actions, ActionTypes } from './actions'

export function featureReducer(state = initialState, action: Actions): State {
	switch (action.type) {
		case ActionTypes.REPO_REQUEST: {
			return {
				...state,
				isBusy: true,
			}
		}
		case ActionTypes.REPO_SUCCESS: {
			return {
				...action.payload,
				isBusy: false,
			}
		}
		case ActionTypes.REPO_FAILURE: {
			return {
				...state,
				isBusy: false,
			}
		}
		case ActionTypes.UPDATE_REPO_REQUEST: {
			return {
				...state,
				isBusy: true,
			}
		}
		case ActionTypes.UPDATE_REPO_FAILURE:
		case ActionTypes.UPDATE_REPO_SUCCESS: {
			return {
				...state,
				isBusy: false,
			}
		}
		default: {
			return state
		}
	}
}
