import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { UploadStoreEffects } from './effects'
import { featureReducer } from './reducer'

@NgModule({
	imports: [CommonModule, StoreModule.forFeature('fileManager', featureReducer), EffectsModule.forFeature([UploadStoreEffects])],
	declarations: [],
})
export class UploadStoreModule {}
