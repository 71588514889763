import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core'
import { ContextMenuService } from '../../services/context-menu.service'
import { environment } from '../../../environments/environment'
import { Store } from '@ngrx/store'
import { RootStoreState } from '../../root-store'
import { IContextMenuAction, Metadata, Folder } from '@app/models'

@Component({
	selector: 'dam-project-item',
	templateUrl: './project-item.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectItemComponent implements OnInit {
	@Input()
	layout: 'grid' | 'list' = 'grid'

	contextMenuActions: IContextMenuAction[] = []
	wfUrl = environment.workfrontSiteUrl + '/project/view?ID='
	workfrontId: Metadata
	deadline: Metadata
	reference: Metadata
	status: Metadata
	workfrontStatusDictionary = environment.workfrontStatus
	itemIcon: string

	@Input('model') set setModel(value) {
		this.model = value

		if (this.model.property) {
			this.workfrontId = this.model.property.find(t => t.name.toLowerCase() === environment.metadata.workfrontId.name.toLowerCase())
			this.deadline = this.model.property.find(t => t.name.toLowerCase() === environment.metadata.deadline.name.toLowerCase())
			this.status = this.model.property.find(t => t.name.toLowerCase() === environment.metadata.status.name.toLowerCase())
			this.reference = this.model.property.find(
				t => t.name.toLowerCase().toLowerCase() === environment.metadata.referenceNumber.name.toLowerCase(),
			)

			// ///
			// if (new Date(this.deadline.value).toString() != (this.model as any).deadLine.toString()) {
			// 	console.log('DEADLINE UI', this.deadline.value)
			// 	console.log('DEADLINE FIELD', (this.model as any).deadLine)
			// }
			// ////
			const parentProject = this.model.property.find(t => t.name.toLowerCase() === environment.metadata.parentProject.name.toLowerCase())
			this.itemIcon =
				parentProject && parentProject.value
					? './../../../assets/images/icons/link-variant.svg'
					: this.model.isEmptyFolder
					? './../../../assets/images/icons/folder-empty.svg'
					: './../../../assets/images/icons/folder.svg'
		}
	}

	model: Folder

	@Output()
	onDragLeave: EventEmitter<any> = new EventEmitter()

	@Output()
	onContextAction: EventEmitter<any> = new EventEmitter()

	constructor(private contexMenuService: ContextMenuService, private store$: Store<RootStoreState.State>) {
		this.contextMenuActions = this.contexMenuService.getContextMenu('project')
	}

	ngOnInit() {}

	dragLeave(event) {
		const param = {
			type: 'project',
			model: this.model,
			event: event,
		}

		this.onDragLeave.emit(param)
	}

	onClickContextMenu(event) {
		this.onContextAction.emit({ action: event, payload: this.model })
	}
}
