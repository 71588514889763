import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core'
import { Store } from '@ngrx/store'
import { RootStoreState, LayoutStoreSelectors } from 'src/app/root-store'
import { Observable } from 'rxjs'

@Component({
	selector: 'dam-asset-grid-header',
	templateUrl: './asset-grid-header.component.html',
	styleUrls: ['./asset-grid-header.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetGridHeaderComponent implements OnInit {
	view$: Observable<string>
	@Output()
	switchView: EventEmitter<any> = new EventEmitter()

	constructor(private store$: Store<RootStoreState.State>) {
		this.view$ = this.store$.select(LayoutStoreSelectors.selectView)
	}

	ngOnInit() {}

	onSwitchView(view) {
		this.switchView.emit(view)
	}
}
