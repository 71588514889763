<div class="detail-sidebar">
  <header class="detail-sidebar-header">
    <div class="detail-sidebar-thumb">
      <!-- TODO: Display icon or thumbnail -->
      <img class="detail-sidebar-img" *ngIf="!model?.file_id" src="../../../assets/images/icons/folder.svg" alt="Item icon">
      <dam-asset-item-icon *ngIf="model?.file_id" [name]="model.name"></dam-asset-item-icon>
    </div>
    <h2 class="detail-sidebar-title">
      {{model?.name}}
    </h2>
    <button type="button" class="detail-sidebar-close" aria-label="Close" (click)="closeSidebar()" matTooltip="Hide details">
      <span aria-hidden="true">&times;</span>
    </button>
  </header>
  <div class="detail-sidebar-tabs">
    <button type="button" class="detail-sidebar-tab" [ngClass]="{ 'active': activeTab === 'details' }" (click)="openTab('details')">
      Details
    </button>
    <button type="button" class="detail-sidebar-tab" [disabled]="!model?.file_id" [style.cursor]="!model?.file_id ? 'default' : 'pointer'"
      [class.active]="activeTab === 'activity'" (click)="openTab('activity')">
      Activity
    </button>
  </div>

  <div class="detail-sidebar-details" *ngIf="activeTab === 'details'">

    <div class="detail-sidebar-activity-empty" *ngIf="!details?.length && !isLoading">
      No details found!
    </div>

    <div class="detail-sidebar-activity-empty" *ngIf="isLoading">
      Loading...
    </div>

    <ng-container *ngIf="details?.length">
      <div class="detail-sidebar-details-item" *ngFor="let detail of details" [ngSwitch]="detail.name.toLowerCase()">
        <div *ngSwitchCase="metadataWorkfrontId" class="workfront-link">
          <a href="{{wfUrl}}{{detail.value}}" target="_blank" rel="noopener noreferrer">
            <img src="./../../../assets/images/icons/workfront.svg" alt="Open in workfront">
          </a>
        </div>
        <div *ngSwitchCase="metadataParentProject" [hidden]="!detail.value">
          <h4 class="detail-sidebar-details-item-title">
            {{ detail.name }}
          </h4>
          <span class="detail-sidebar-details-item-value">
            <a (click)="navigateToFilePath(detail.value)">
              Go to parent project
            </a>
          </span>
        </div>
        <div *ngSwitchDefault>
          <h4 class="detail-sidebar-details-item-title">
            {{ detail.name }}
          </h4>
          <span class="detail-sidebar-details-item-value">
            {{ (detail.name == 'Deadline' || detail.name == 'Entry date') ? (detail.value | date:'MMMM dd, yyyy') : detail.value || 'N/A' }}
          </span>
        </div>
      </div>
      <div class="detail-sidebar-details-item" [hidden]="!model?.createdBy">
        <h4 class="detail-sidebar-details-item-title">
          Created by
        </h4>
        <span class="detail-sidebar-details-item-value">
          {{model?.createdBy}}
        </span>
      </div>
      <div class="detail-sidebar-details-item" [hidden]="!model?.createdOn">
        <h4 class="detail-sidebar-details-item-title">
          Created on
        </h4>
        <span class="detail-sidebar-details-item-value">
          {{model?.createdOn}}
        </span>
      </div>
      <div class="detail-sidebar-details-item" [hidden]="!model?.size || !model?.file_id">
        <h4 class="detail-sidebar-details-item-title">
          File size
        </h4>
        <span class="detail-sidebar-details-item-value">
          <!--{{model?.size && (model?.size / 1024).toFixed(2)}} KB-->
          {{model?.size | fileSize}}
        </span>
      </div>
      <div [hidden]="!model?.folder_path" class="detail-sidebar-details-item">
        <h4 class="detail-sidebar-details-item-title">
          Location
        </h4>
        <span class="detail-sidebar-details-item-value">
          <a (click)="navigateToFilePath(model?.folder_id)">
            {{model?.folder_path == repoRootName ? model?.name : model?.folder_path || ''}}
          </a>
        </span>
      </div>
    </ng-container>
  </div>

  <div class="detail-sidebar-activity" *ngIf="activeTab === 'activity'">
    <div class="detail-sidebar-activity-empty" *ngIf="!activities?.length && !isActivityLoading">
      No activities found!
    </div>
    <div class="detail-sidebar-activity-empty" *ngIf="isActivityLoading">
      <dam-loading [internal]="true"></dam-loading>
    </div>
    <ng-container *ngIf="activities?.length">
      <div class="detail-sidebar-activity-event" *ngFor="let activity of activities">
        <h4 class="detail-sidebar-activity-event-date">
          {{ activity.actedOn }}
        </h4>
        <span class="detail-sidebar-activity-event-user">
          {{ activity.action_by }}
        </span>
        /
        <span class="detail-sidebar-activity-event-action">
          {{ activity.action }}
          <!-- <ng-container *ngIf="activity.items.length==1">this item</ng-container> -->
          <!-- <ng-container *ngIf="activity.items.length > 1">these items</ng-container>: -->
        </span>
        <!-- <ul class="detail-sidebar-activity-event-items">
          <li class="detail-sidebar-activity-event-item" *ngFor="let item of activities">
            <img class="detail-sidebar-activity-event-item-icon" src="../../../assets/images/icons/folder.svg"> {{ item.name
            }}
          </li>
        </ul> -->
      </div>
    </ng-container>
  </div>
</div>