import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity'
import { Breadcrumb } from '@app/models'

export const featureAdapter: EntityAdapter<Breadcrumb> = createEntityAdapter<Breadcrumb>()

export interface State extends EntityState<Breadcrumb> {
	isLoading?: boolean
	error?: any
}

export const initialState: State = featureAdapter.getInitialState({
	isLoading: false,
	error: null,
})
