<main class="main-content" [class.sidebar]="isDetailSidenavOn">

  <div class="asset-bank" id="asset-container">
    <div class="container" (damDndToSelect)="setMultipleSelection($event)">
      <dam-breadcrumbs [hidden]="isSearchActive$ | async" [data]="breadcrumbs$ | async"></dam-breadcrumbs>
      <dam-asset-grid-header (sort)="sortBy($event)" [hidden]="(isSearchActive$ | async)">
        <button class="navigation-view-refresh" mat-button (click)="refresh()">
          <img src="../../../assets/images/icons/refresh-1.svg" alt=""> REFRESH CACHE
        </button>
        <button class="navigation-action" mat-button (click)="showModal('create-folder')">
          <img src="../../../assets/images/icons/folder-create.svg" alt=""> NEW FOLDER
        </button>
        <dam-file-upload-dialog-launch (onFileUpload)="uploadFile($event)"></dam-file-upload-dialog-launch>
        <dam-folder-upload-dialog-launch (onFileUpload)="uploadFile($event);"></dam-folder-upload-dialog-launch>
      </dam-asset-grid-header>

      <dam-search-results-header [hidden]="!(isSearchActive$ | async)" (clear)="onClearSearch()">
      </dam-search-results-header>
      <dam-search-no-results
        [hidden]="!((isSearchActive$ | async) && (!(files$ | async)?.length && !(folders$ | async)?.length))">
      </dam-search-no-results>

      <div class="assets-container-dropzone">
        <div class="assets-child-dropzone" (dndFile)="onDragLeave($event)"
          [dndDisabled]="damContext.folder_type == 'repository' || (isSearchActive$ | async)"
          [contextMenu]="contextMenu">

          <dam-asset-grid>
            <div [class.asset-grid]="(gridLayout$ | async) == 'grid'"
              [class.asset-list]="(gridLayout$ | async) == 'list'"
              [class.explorer-list]="(gridLayout$ | async) == 'list'">
              <div class="asset-list-header" [hidden]="(gridLayout$ | async) == 'grid'"
                *ngIf="!((isSearchActive$ | async) && !(files$ | async)?.length && !(folders$ | async)?.length)">
                <span class="asset-list-header-item" (click)="sortBy('name')">NAME
                  <span class="arrow" *ngIf="(sortField$ | async) == 'name'"
                    [ngClass]="(sortDirection$ | async)? 'down':'up'"></span>
                </span>
                <ng-container *ngIf="damContext.folder_type !== 'repository'">
                  <span class="asset-list-header-item" (click)="sortBy('createdBy')">OWNER
                    <span class="arrow" *ngIf="(sortField$ | async) == 'createdBy'"
                      [ngClass]="(sortDirection$ | async)? 'down':'up'"></span>
                  </span>
                  <span class="asset-list-header-item" (click)="sortBy('createdOn')">CREATED DATE
                    <span class="arrow" *ngIf="(sortField$ | async) == 'createdOn'"
                      [ngClass]="(sortDirection$ | async)? 'down':'up'"></span>
                  </span>
                  <span class="asset-list-header-item" (click)="sortBy('size')">SIZE <span class="arrow"
                      *ngIf="(sortField$ | async) == 'size'"
                      [ngClass]="(sortDirection$ | async)? 'down':'up'"></span></span>
                </ng-container>
              </div>

              <ng-container>
                <dam-folder-item @listAnimation class="asset-item-container folderRow asset explorer"
                  id="{{folder.folder_id}}"
                  *ngFor="let folder of folders$ | async | orderBy: 'name' : (sortField$ | async) == 'name' ? (sortDirection$ | async) : null : true; trackBy: trackByFolder"
                  [damDndToSelectItem]="folder" [model]="folder" (onDragLeave)="onDragLeave($event, true)"
                  (dblclick)="dbClick($event, folder, 'folder')" (onContextAction)="onContextAction($event)"
                  [class.asset-item-selected]="folderIsSelected(selectedItem$ | async, folder)"
                  [layout]="gridLayout$ | async">
                </dam-folder-item>

                <dam-asset-item class="fileRow asset explorer" id="{{item.file_id}}" @listAnimation
                  *ngFor="let item of files$ | async | orderBy: (sortField$ | async) : (sortDirection$ | async) : true; trackBy: trackByFile"
                  [damDndToSelectItem]="item" [model]="item" (dblclick)="dbClick($event, item, 'asset')"
                  (onContextAction)="onContextAction($event)" (onSelect)="setSelectedItem($event)"
                  [class.asset-item-selected]="fileIsSelected(selectedItem$ | async, item)"
                  [layout]="gridLayout$ | async">
                </dam-asset-item>
              </ng-container>
            </div>
          </dam-asset-grid>

          <div class="asset-grid-empty-container">
            <div class="asset-grid-empty"
              *ngIf="damContext.folder_type !== 'repository' && !(isLoading$ | async) && !(folders$ | async)?.length && !(files$ | async)?.length && !(isSearchActive$ | async)">
              <img src="../../../assets/images/empty-folder.svg" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- DETAIL SIDEBAR -->
  <dam-detail-sidebar [@slideInOut]="(showDetailSidenav$ | async) ? 'in' : 'out'"
    (@slideInOut.done)="onSlideInOut($event)" (onVisible)="showDetailSidenav($event)" [type]="'folder'"
    [model]="item$ | async" [details]="projectMetadata$ | async" [activities]="(selectedItemAuditHistory$ | async)"
    [isLoading]="projectMetadataIsLoading$ | async" [isActivityLoading]="isAuditHistoryLoading$ | async"
    [activeTab]="selectSidenavActiveTab$ | async" (onOpenTab)="onSidenavOpenTab($event)"
    (onFolderPathClick)="onFolderPathClick($event)">
  </dam-detail-sidebar>
</main>

<dam-loading *ngIf="isLoading$ | async"></dam-loading>

<dam-footer></dam-footer>

<dam-bottom-dialog [isMinimized]="bottomSidenavIsMinimized$ | async"
  (onExpandCollapseClick)="expandCollapseUploadDialog()">

  <button type="button" class="bottom-dialog-list-clear" (click)="clearAllUploads()"
    [hidden]="!(uploadFiles$ | async)?.length || ((bottomSidenavActiveTab$ | async) == 1)">
    Clear all
  </button>

  <button type="button" class="bottom-dialog-list-clear" (click)="clearAllDownloads()"
    [hidden]="!(downloadFolders$ | async)?.length || ((bottomSidenavActiveTab$ | async) == 0)">
    Clear all
  </button>

  <mat-tab-group [selectedIndex]="this.bottomSidenavActiveTab$ | async" (selectedIndexChange)="changeSidenavActiveTab($event)">
    <mat-tab label="Upload files">
      <ng-template matTabContent>
        <dam-upload-dialog-item
          *ngFor="let item of uploadFiles$ | async | orderBy: 'status': true; trackBy: trackByFile"
          [name]="item.file_name" [size]="item.total" [loaded]="item.loaded" [progress]="item.progress"
          [foldertree]="item.folder_tree" [completed]="item.completed" (onClear)="clearUploadFile(item)">
        </dam-upload-dialog-item>
        <dam-empty-dialog-item *ngIf="!(uploadFiles$ | async)?.length"
          message="Files or folders you upload appear here">
        </dam-empty-dialog-item>
      </ng-template>
    </mat-tab>

    <mat-tab label="Download folders">
      <dam-download-dialog-item *ngFor="let item of downloadFolders$ | async | reverse; trackBy: trackByFolder"
        [name]="item.folder_name" [url]="item.download_link" [path]="item.path" [description]="item.description"
        [completed]="item.completed" [statistics]="item.statistics" [notifyMe]="item.notify_user"
        (onClear)="clearDoownloadFolder(item)" (onNotifyMe)="notifyDownload($event, item)">
      </dam-download-dialog-item>
      <dam-empty-dialog-item *ngIf="!(downloadFolders$ | async)?.length" message="Folders you download appear here">
      </dam-empty-dialog-item>
    </mat-tab>
  </mat-tab-group>
</dam-bottom-dialog>

<!-- Context Menu -->
<context-menu #contextMenu>
  <ng-template contextMenuItem *ngFor="let action of contextMenuActions" let-item [visible]="true" [enabled]="true"
    (execute)="onContextAction({ action: action })">
    <div class="menu-item">
      <img class="icon" src="./../../../assets/images/icons/{{action.icon}}.svg" alt=""> {{action.displayName}}
    </div>
  </ng-template>
</context-menu>

<dam-modal id="create-folder">
  <dam-create-folder-modal (onSubmit)="createFolder($event); closeModal('create-folder');"
    (onCancel)="closeModal('create-folder')" [folders]="folders$ | async"></dam-create-folder-modal>
</dam-modal>

<dam-modal id="confirmation-delete">
  <dam-delete-confirm-modal (onSubmit)="submitDeleteAction()" (onCancel)="closeModal('confirmation-delete')">
  </dam-delete-confirm-modal>
</dam-modal>

<dam-modal id="rename-resource">
  <dam-rename-modal (onSubmit)="submitRenameAction($event)" (onCancel)="closeModal('rename-resource')">
  </dam-rename-modal>
</dam-modal>

<dam-modal id="move-folder">
  <dam-move-to-modal [breadcrumbs$]="breadcrumbs$" [repositoryId]="damContext.repo_id" [folderId]="damContext.folder_id"
    (onSubmit)="submitMoveAction($event)" (onCancel)="closeModal('move-folder')">
  </dam-move-to-modal>
</dam-modal>

<dam-modal id="duplicate-file">
  <dam-duplicate-file-modal (onRename)="renameDuplicateAction()" (onReplace)="replaceDuplicateAction()"
    (onCancel)="cancelDuplicateAction()"></dam-duplicate-file-modal>
</dam-modal>

<dam-modal id="duplicate-folder">
  <dam-duplicate-folder-modal (onRename)="renameDuplicateAction()" (onReplace)="replaceDuplicateAction()"
    (onCancel)="cancelDuplicateAction()"></dam-duplicate-folder-modal>
</dam-modal>
<!--<dam-duplicate-file-modal (onSubmit)="submitMoveAction($event)" (onCancel)="closeModal('move-folder')"></dam-duplicate-file-modal>-->

<dam-modal id="load-folder-error">
  <dam-alert-modal (onOk)="loadFolderError()">
    <label class="title">Folder path error</label>
    <div class="message">
      <label>An error has occurred while loading the folder.</label>
      <br>
      <label>Click OK to continue.</label>
    </div>
  </dam-alert-modal>
</dam-modal>