<dam-header (onSearch)="onSearch()" (onSearchInputChange)="onSearchInputChange($event)" (onClearSearch)="onClearSearch()" (onSearchInputError)="onSearchInputError()" [isSearchEnabled]="isSearchEnabled$ | async">
  <ul class="navigation-links dam-header-actions">
    <!-- <li class="navigation-item">
      <a routerLink="/projects"  routerLinkActive="active" class="navigation-link">Projects</a>
    </li> -->
    <!-- <li class="navigation-item">
      <a routerLink="/guidelines" routerLinkActive="active" class="navigation-link">Guidelines</a>
    </li> -->
  </ul>

  <div class="navigation-actions dam-header-right-actions">
    <dam-user [user]="user$ | async"></dam-user>
  </div>
</dam-header>
<router-outlet></router-outlet>