import { Action } from '@ngrx/store'
import { UserPermissions } from '@app/models/permission.enum'
import { User } from '@app/models'
import { AuthenticationResponse } from '@app/models/api-gee.model'

export enum ActionTypes {
	LOGIN_REQUEST = '[User] Login Request',
	LOGIN_SUCCESS = '[User] Login Success',
	LOGOUT_SUCCESS = '[User] Logout Success',
	LOGIN_FAILURE = '[User] Login Failure',
	LOAD_PERMISSIONS_REQUEST = '[User] Load permissions Request',
	LOAD_PERMISSIONS_SUCCESS = '[User] Load permissions Success',
	LOAD_PERMISSIONS_FAILURE = '[User] Load permissions Failure',
}

export class LoginRequestAction implements Action {
	readonly type = ActionTypes.LOGIN_REQUEST
}

export class LogoutSuccessAction implements Action {
	readonly type = ActionTypes.LOGOUT_SUCCESS
	constructor(public payload: { user: User }) {}
}

export class LoginSuccessAction implements Action {
	readonly type = ActionTypes.LOGIN_SUCCESS
	constructor(public payload: { user: User }) {}
}

export class LoginFailureAction implements Action {
	readonly type = ActionTypes.LOGIN_FAILURE
	constructor(public payload: { error: string }) {}
}

export class LoadPermissionsRequestAction implements Action {
	readonly type = ActionTypes.LOAD_PERMISSIONS_REQUEST
}

export class LoadPermissionsSuccessAction implements Action {
	readonly type = ActionTypes.LOAD_PERMISSIONS_SUCCESS
	constructor(public payload: { value: UserPermissions }) {}
}

export class LoadPermissionsFailureAction implements Action {
	readonly type = ActionTypes.LOAD_PERMISSIONS_FAILURE
	constructor(public payload: { error: string }) {}
}

export type Actions =
	| LoginRequestAction
	| LoginSuccessAction
	| LoginFailureAction
	| LogoutSuccessAction
	| LoadPermissionsRequestAction
	| LoadPermissionsFailureAction
	| LoadPermissionsSuccessAction
