<header class="main-header">
  <nav class="main-navigation container" style="margin: 0; display: block; ">
    <img class="main-logo" src="./../../../assets/images/logo.svg" alt="pwc">
  </nav>
</header>

<div style="display:flex; align-items: center; justify-content: center; height: 200px">
  <a style="cursor: pointer;" (click)="signInWithGoogle()">
    <div style="float: left; margin-right: 10px; margin-top: -2px">
      <img width="20px" alt="Google &quot;G&quot; Logo" src="./../../../assets/images/logo-google.png"
      />
    </div>
    Login with Google
  </a>
</div>



