import { Component, OnInit } from '@angular/core'
import { FolderService, RequestCacheService, FileService, WorkfrontService, ProjectService } from '@app/services'
import { environment } from '@environment/environment'
import { switchMap, mergeMap, map, filter, tap, concatMap, concatAll, toArray, bufferCount, delay } from 'rxjs/operators'
import { from, merge, forkJoin, concat, Observable, of } from 'rxjs'
import { Folder, FolderWrapper, FileDeleteMulti, Project, UpdateStorageProject } from '@app/models'
import { HttpParams, HttpClient } from '@angular/common/http'
import { SocialAuthService } from 'angularx-social-login'
import { ApiGeeService } from '@app/services/api-gee.service'
import { RootStoreState, UserStoreActions } from '@app/root-store'
import { Store } from '@ngrx/store'

@Component({
	selector: 'dam-project-maintenance',
	templateUrl: './project-maintenance.html',
	styleUrls: ['./project-maintenance.css'],
})
export class ProjectMaintenanceComponent implements OnInit {
	message: string
	projectsToFix: any[] = []
	projectsToFixWithoutMetadata: any[] = []
	projects: FolderWrapper
	count = 0
	folders: FolderWrapper
	folder: FolderWrapper
	folderId: string
	projectName: string

	constructor(
		private readonly folderService: FolderService,
		private fileService: FileService,
		private cacheService: RequestCacheService,
		private http: HttpClient,
		private workfrontService: WorkfrontService,
		private projectService: ProjectService,
		private authService: SocialAuthService,
		private apiGeeService: ApiGeeService,
		private store$: Store<RootStoreState.State>,
	) {}

	ngOnDestroy(): void {}

	async ngOnInit() {
		this.authService.authState.subscribe(user => {
			if (user !== null) {
				this.apiGeeService.googleAuthToken = user.authToken
				this.apiGeeService.userEmail = user.email
				this.apiGeeService.idToken = user.idToken
				this.apiGeeService.authenticate().subscribe(t => {
					this.store$.dispatch(new UserStoreActions.LoginSuccessAction({ user: { ...user, accessToken: t.access_token } }))
				})
			}
		})
	}

	fixDuplicatedMetadataFiles() {
		let filesToDelete = []
		for (let p of this.projectsToFix) {
			let metadatafiles = p.files.filter(t => t.name == 'dam.metadata.txt')
			metadatafiles.shift()
			filesToDelete.push(...metadatafiles)
		}
		const payload = { files: filesToDelete, repo_id: environment.projectRepositoryId } as FileDeleteMulti

		this.message = `request:: 
						${JSON.stringify(payload)}
						`

		this.fileService.delete(payload, '').subscribe()
	}

	async findDuplicatedMetadataFiles() {
		await this.cacheService.expire(environment.projectRepositoryId + '-}')
		this.message = 'loading..'
		this.projects = await this.folderService.getFolder(environment.projectRepositoryId).toPromise()

		console.log(this.projects)

		from(this.projects.folders)
			.pipe(
				map(p => {
					let param = new HttpParams().set('repo_id', environment.projectRepositoryId)
					param = param.append('folder_id', p.folder_id)
					console.log('GET:' + p.folder_id)
					return this.http.get<FolderWrapper>(`${environment.apiBaseUrl}${environment.folderApiUrl}`, {
						params: param,
					})
				}),
				bufferCount(5),
				concatMap(p => forkJoin(p)),
				delay(500),
				toArray(),
			)
			.subscribe(result => {
				console.log(result)
				this.message = 'finish'
				Array.prototype.forEach.call(result, r => {
					const metadataFiles = (r.files && r.files.filter(t => t.name == 'dam.metadata.txt')) || []
					if (metadataFiles.length > 1) {
						let pro = this.projects.folders.find(t => t.folder_id == metadataFiles[0].folder_id)
						this.projectsToFix.push({ project: pro, files: metadataFiles })
					}
				})
			})
	}

	async findProjectsWithoutMetadata() {
		await this.cacheService.expire(environment.projectRepositoryId + '-}')
		this.message = 'loading..'
		this.projects = await this.folderService.getFolder(environment.projectRepositoryId, null, false).toPromise()

		console.log(this.projects)

		from(this.projects.folders)
			.pipe(
				map(p => {
					let param = new HttpParams().set('repo_id', environment.projectRepositoryId)
					param = param.append('folder_id', p.folder_id)
					console.log('GET:' + p.folder_id)
					return this.http
						.get<FolderWrapper>(`${environment.apiBaseUrl}${environment.folderApiUrl}`, {
							params: param,
						})
						.pipe(
							map(r => {
								return { project: p, child: r }
							}),
						)
				}),
				bufferCount(5),
				concatMap(p => forkJoin(p)),
				delay(500),
				toArray(),
			)
			.subscribe(result => {
				console.log(result)
				this.message = 'finish'
				Array.prototype.forEach.call(result, r => {
					const metadataFiles = (r.child.files && r.child.files.filter(t => t.name == 'dam.metadata.txt')) || []
					if (!metadataFiles || metadataFiles.length == 0) {
						this.projectsToFixWithoutMetadata.push({ project: r.project, files: null })
					}
				})
			})
	}

	getFolders() {
		this.folderService.getFolder(environment.projectRepositoryId, null, false).subscribe(t => {
			this.folders = t
		})
	}

	getFoldersOldApi() {
		this.folderService.getFolderOldApi(environment.projectRepositoryId, null, false).subscribe(t => {
			this.folders = t
		})
	}

	getFolder(folder_id, projectName) {
		this.folder = null
		this.projectName = projectName
		this.folderService.getFolder(environment.projectRepositoryId, folder_id, false).subscribe(t => {
			this.folder = t
		})
	}

	folderIdChange(e) {
		this.folderId = e.target.value
	}

	removeFolder(folder_id: string, name: string, parent_id: string) {
		if (confirm(`Would you like to delete the folder ${name}?`)) {
			const payload = {
				files: [],
				folders: [{ folder_id }],
				repo_id: environment.projectRepositoryId,
			}

			this.fileService.delete(payload, parent_id).subscribe(t => {
				alert('Folder deleted')
			})
		}
	}

	removeFile(file_id: string, name: string, parent_id: string) {
		if (confirm(`Would you like to delete the file ${name}?`)) {
			const payload = {
				files: [{ file_id }],
				folders: [],
				repo_id: environment.projectRepositoryId,
			} as FileDeleteMulti

			this.fileService.delete(payload, parent_id).subscribe(t => {
				alert('File deleted')
			})
		}
	}

	addMetadataFile(user, password, projectName, folderId) {
		if (!user || !password) {
			alert('Invalid credentials')
		}

		this.workfrontService.getProjects(user, password).subscribe(t => {
			const workfrontProjects = t
			const workfrontProject = workfrontProjects.find(t => t.name === projectName)

			if (!workfrontProject) {
				alert('Workfront project not found')
				return
			}

			this.message = 'Uploading metadata file...'

			this.projectService.uploadMetadataFile(environment.projectRepositoryId, folderId, workfrontProject.metadata).subscribe(j => {
				this.message = ''
				alert('Project updated.')
			})
		})
	}

	addMetadataFileFromWfId(user, password, wfId, folderId) {
		if (!user || !password) {
			alert('Invalid credentials')
		}

		const payload = {
			username: user,
			password: password,
			id: wfId,
		}

		this.http
			.post(`${environment.workfrontApiUrl}projects/byid`, payload)
			.pipe(
				map((projects: any) => {
					return projects.data.map(x => this.workfrontService.workfrontMapping(x))
				}),
			)
			.subscribe(workfrontProjects => {
				if (!workfrontProjects.length) {
					alert('Workfront project not found')
					return
				}

				let workfrontProject = workfrontProjects[0]

				this.message = 'Uploading metadata file...'

				this.projectService.uploadMetadataFile(environment.projectRepositoryId, folderId, workfrontProject.metadata).subscribe(j => {
					this.message = ''
					alert('Project updated.')
				})

				this.workfrontService
					.updateStorageProjects([
						{
							folder_id: folderId,
							name: workfrontProject.name,
							property: workfrontProject.metadata,
						} as UpdateStorageProject,
					])
					.subscribe(j => {
						alert('Storage updated')
					})
			})
	}

	updateMetadataFromWf(user, password, folderId, metadataFileId, workfrontId) {
		if (!user || !password) {
			alert('Invalid credentials')
		}

		const payload = {
			username: user,
			password: password,
			id: workfrontId,
		}

		this.http
			.post(`${environment.workfrontApiUrl}projects/byid`, payload)
			.pipe(
				map((projects: any) => {
					return projects.data.map(x => this.workfrontService.workfrontMapping(x))
				}),
			)
			.subscribe(workfrontProjects => {
				if (!workfrontProjects.length) {
					alert('Workfront project not found')
					return
				}

				let workfrontProject = workfrontProjects[0]

				this.message = 'Uploading metadata file...'

				const fileUpload = {
					file_id: metadataFileId,
					file_properties: workfrontProject.metadata,
				}

				this.projectService.updateProjectMetadata(fileUpload).subscribe(j => {
					this.message = ''
					alert('Project updated.')
				})

				this.workfrontService
					.updateStorageProjects([
						{
							folder_id: folderId,
							name: workfrontProject.name,
							property: workfrontProject.metadata,
						} as UpdateStorageProject,
					])
					.subscribe(j => {
						alert('Storage updated')
					})
			})
	}

	findProjectsWithDuplicateName() {
		this.folderService.getFolder(environment.projectRepositoryId, null, false).subscribe(t => {
			t.folders = this.findDuplicates(t.folders)
			this.folders = t
		})
	}

	findDuplicates(arr) {
		let sorted_arr = arr.slice().sort((a, b) => {
			if (a.name < b.name) {
				return -1
			}
			if (a.name > b.name) {
				return 1
			}
			return 0
		})

		let results = []
		for (let i = 0; i < sorted_arr.length - 1; i++) {
			if (sorted_arr[i + 1] == sorted_arr[i]) {
				results.push(sorted_arr[i])
			}
		}
		console.log(results)
		return results
	}
}
