import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { EffectsModule } from '@ngrx/effects'
import { ApmEffects } from './effects'

@NgModule({
	imports: [CommonModule, EffectsModule.forFeature([ApmEffects])],
	declarations: [],
})
export class ApmStoreModule {}
