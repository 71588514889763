import { Injectable } from '@angular/core'

declare const gtag: Function

@Injectable({
	providedIn: 'root',
})
export class GoogleAnalyticsService {
	constructor() {}

	trackEvent(event: string, category: string, label?: string) {
		gtag('event', event, {
			event_category: category,
			event_label: label || '',
		})
	}

	trackPageView(url: string, title: string = 'Creative DAM') {
		console.log('trackPageView', url)
		gtag('event', 'page_view', {
			page_location: url,
			page_title: title,
		})
	}
}
