<header class="main-header">
  <nav class="main-navigation">
    <a routerLink="/projects">
      <img class="main-logo" src="./../../../assets/images/logo.svg" alt="pwc">
    </a>
    <span class="main-header__divider" role="separator"></span>
    <a href="/projects" class="main-header__subbrand">Creative DAM</a>

    <ng-content select=".dam-header-actions"></ng-content>

    <dam-search *ngIf="isSearchEnabled" class="navigation-search" (onSearch)="onHeaderSearch()" [placeholder]="'Search for a project...'"
      (onSearchInputChange)="onHeaderSearchInputChange($event)" (onClearSearch)="onHeaderClearSearch()" (onSearchInputError)="onHeaderSearchInputError()">
    </dam-search>

    <ng-content select=".dam-header-right-actions"></ng-content>
  </nav>

  <nav class="context-navigation">
    <div class="container">
      <ng-content select=".dam-sub-header-actions"></ng-content>
    </div>
  </nav>
</header>