<div class="modal-wrapper">
  <div class="modal">
    <header class="modal-header">
      <h2 class="modal-title">
        Out of sync projects
      </h2>
      <button class="modal-header-btn modal-close" type="button" (click)="cancel()">
        <img src="../../../assets/images/icons/close.svg" alt="Close" title="Close">
      </button>
    </header>
    <div class="modal-content">
      <p>The status of the following projects has been updated in C3 and is out of sync with their corresponding DAM
        project
        folder. </p>
      <p>Click on the "sync" button to update the DAM project folder's metadata.</p>
      <div class="asset-list">
        <div class="modal-list-header">
          <span class="asset-list-header-item">Reference #</span>
          <span class="asset-list-header-item">Project Name</span>
        </div>
        <div class="asset-item-container">
          <div class="asset-item-modal" *ngFor="let project of outdatedPorjects" [class.new]="!project.created">
            <div class="asset-item-name">
              <a href="{{wfUrl}}{{getID(project)}}" target="_blank" rel="noopener noreferrer">
                {{getReferenceNumber(project)}}
              </a>
            </div>
            <div class="asset-item-name">
              <a href="{{wfUrl}}{{getID(project)}}" target="_blank" rel="noopener noreferrer">
                <!-- {{getProjectFolderName(project.folder_path)}} -->
                {{project.name}}
              </a>
              
              <span class="asset-item-name__error" *ngIf="project.error">{{project?.error?.message}}</span>
            </div>
            <div class="asset-item-modal__actions">
              <button *ngIf="!project.isBusy && !project.syncCompleted" class="form-btn modal-btn-primary" mat-button type="button" (click)="submit(project)">
                SYNC
              </button>
              <dam-loading [internal]="true" [small]="true" [offset]="false" *ngIf="project.isBusy && !project.syncCompleted"></dam-loading>
              <img *ngIf="project.syncCompleted && !project.error" src="../../../assets/images/icons/tick-success.svg" />
              <img *ngIf="project.syncCompleted && project.error" src="../../../assets/images/icons/error-upload.svg" />
            </div>
          </div>
        </div>
      </div>
      <div class="form form-actions modal-footer" *ngIf="!syncCompleted">
        <button class="form-btn modal-close" mat-button type="button" (click)="cancel()">CANCEL</button>
        <button class="form-btn modal-btn-primary" mat-button type="button" (click)="submitAll()">SYNC ALL</button>
      </div>
      <div class="form form-actions modal-footer" *ngIf="syncCompleted">
        <button class="form-btn modal-close" mat-button type="button" (click)="cancel()">CLOSE</button>
      </div>
    </div>
  </div>
</div>