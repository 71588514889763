import { Action } from '@ngrx/store'
import { DamFile, AuditHistory, MoveItems, FileDeleteMulti } from '@app/models'

export enum ActionTypes {
	FILE_DELETE_REQUEST = '[File] Delete Request',
	FILE_DELETE_FAILURE = '[File] Delete Failure',
	FILE_DELETE_SUCCESS = '[File] Delete Success',

	FILE_UPDATE_REQUEST = '[File] Update Request',
	FILE_UPDATE_FAILURE = '[File] Update Failure',
	FILE_UPDATE_SUCCESS = '[File] Update Success',

	FILE_DOWNLOAD_REQUEST = '[File] Download Request',
	FILE_DOWNLOAD_SUCCESS = '[File] Download Success',
	FILE_DOWNLOAD_FAILURE = '[File] Download Failure',

	MOVE_TO_REQUEST = '[File] Move to Request',
	MOVE_TO_FAILURE = '[File] Move to Failure',
	MOVE_TO_SUCCESS = '[File] Move to Success',

	LOAD_AUDIT_HISTORY_REQUEST = '[File] Load audit history Request',
	LOAD_AUDIT_HISTORY_FAILURE = '[File] Load audit history Failure',
	LOAD_AUDIT_HISTORY_SUCCESS = '[File] Load audit history Success',
}

export class DeleteRequestAction implements Action {
	readonly type = ActionTypes.FILE_DELETE_REQUEST
	constructor(public payload: { assets: FileDeleteMulti; folderId: string }) {}
}

export class DeleteSuccessAction implements Action {
	readonly type = ActionTypes.FILE_DELETE_SUCCESS
	constructor(public payload: { assets: FileDeleteMulti }) {}
}

export class DeleteFailureAction implements Action {
	readonly type = ActionTypes.FILE_DELETE_FAILURE
	constructor(public payload: { error: string }) {}
}

export class UpdateRequestAction implements Action {
	readonly type = ActionTypes.FILE_UPDATE_REQUEST
	constructor(public payload: { file: any }) {}
}

export class UpdateSuccessAction implements Action {
	readonly type = ActionTypes.FILE_UPDATE_SUCCESS
	constructor(public payload: { file: DamFile }) {}
}

export class UpdateFailureAction implements Action {
	readonly type = ActionTypes.FILE_UPDATE_FAILURE
	constructor(public payload: { error: string }) {}
}

export class DownloadRequestAction implements Action {
	readonly type = ActionTypes.FILE_DOWNLOAD_REQUEST
	constructor(public payload: { file: DamFile; multiple?: boolean }) {}
}

export class DownloadSuccessAction implements Action {
	readonly type = ActionTypes.FILE_DOWNLOAD_SUCCESS
	constructor(public payload: { file: DamFile }) {}
}

export class DownloadFailureAction implements Action {
	readonly type = ActionTypes.FILE_DOWNLOAD_FAILURE
	constructor(public payload: { error: string }) {}
}

export class LoadAuditHistoryRequestAction implements Action {
	readonly type = ActionTypes.LOAD_AUDIT_HISTORY_REQUEST
	constructor(public payload: { fileId: string }) {}
}

export class LoadAuditHistoryFailureAction implements Action {
	readonly type = ActionTypes.LOAD_AUDIT_HISTORY_FAILURE
	constructor(public payload: { error: string }) {}
}

export class LoadAuditHistorySuccessAction implements Action {
	readonly type = ActionTypes.LOAD_AUDIT_HISTORY_SUCCESS
	constructor(public payload: { fildeId: string; auditHistory: Array<AuditHistory> }) {}
}

export class MoveToRequestAction implements Action {
	readonly type = ActionTypes.MOVE_TO_REQUEST
	constructor(public payload: { files: MoveItems }) {}
}

export class MoveToRequestFailureAction implements Action {
	readonly type = ActionTypes.MOVE_TO_FAILURE
	constructor(public payload: { error: string }) {}
}

export class MoveToRequestSuccessAction implements Action {
	readonly type = ActionTypes.MOVE_TO_SUCCESS
	constructor(public payload: { files: MoveItems }) {}
}
