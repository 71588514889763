import { Injectable } from '@angular/core'
import {
	ContextMenuMoveTo,
	ContextMenuRename,
	ContextMenuDelete,
	ContextMenuNewFolder,
	IContextMenuAction,
	ContextMenuDownload,
	ContextMenuViewDetails,
	ContextMenuDownloadFolder,
} from '../models/context-menu.model'
import { RepositoryTypes } from '../common/helpers'

@Injectable({
	providedIn: 'root',
})
export class ContextMenuService {
	constructor() {}

	tab: RepositoryTypes

	setTab(tab: RepositoryTypes) {
		this.tab = tab
	}

	getContextMenu(type: string): IContextMenuAction[] {
		switch (type) {
			case 'asset': {
				if (this.tab && (this.tab === 'guidelines' || this.tab === 'library')) {
					return [ContextMenuMoveTo, ContextMenuRename, ContextMenuDelete, ContextMenuDownload]
				}
				return [ContextMenuMoveTo, ContextMenuRename, ContextMenuDelete, ContextMenuDownload, ContextMenuViewDetails]
			}
			case 'row-asset': {
				return [ContextMenuMoveTo, ContextMenuRename, ContextMenuDelete]
			}
			case 'search-asset': {
				return [ContextMenuDownload, ContextMenuViewDetails]
			}
			case 'search-folder': {
				return [ContextMenuDownloadFolder, ContextMenuViewDetails]
			}
			case 'folder': {
				if (this.tab && (this.tab === 'guidelines' || this.tab === 'library')) {
					return [ContextMenuMoveTo, ContextMenuRename, ContextMenuDelete]
				}
				return [ContextMenuMoveTo, ContextMenuRename, ContextMenuDelete, ContextMenuDownloadFolder, ContextMenuViewDetails]
			}
			case 'project':
				return [ContextMenuViewDetails]
			case 'main':
				return [ContextMenuViewDetails]
			case 'main-folder':
				return [ContextMenuNewFolder]
		}
	}
}
