import { Actions, ActionTypes } from './actions'
import { featureAdapter, initialState, State } from './state'
import { UserPermissions } from '../../models/permission.enum'

export function featureReducer(state = initialState, action: Actions): State {
	switch (action.type) {
		case ActionTypes.LOGIN_REQUEST: {
			return {
				...state,
				isLoading: true,
				error: null,
			}
		}
		case ActionTypes.LOGIN_SUCCESS: {
			return featureAdapter.addOne(action.payload.user, {
				...state,
				isLoading: false,
				isAuthenticated: true,
				error: null,
			})
		}
		case ActionTypes.LOGIN_FAILURE: {
			return {
				...state,
				isLoading: false,
				isAuthenticated: false,
				error: action.payload.error,
			}
		}
		case ActionTypes.LOGOUT_SUCCESS: {
			return featureAdapter.removeOne(action.payload.user.id, { ...state })
		}
		case ActionTypes.LOAD_PERMISSIONS_SUCCESS: {
			return {
				...state,
				isAdmin: UserPermissions.admins === action.payload.value || UserPermissions.fullAccess === action.payload.value,
			}
		}
		default: {
			return state
		}
	}
}
