import { Component, OnInit, OnDestroy } from '@angular/core'
import { AlertToastService } from '../../services/alert-toast.service'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { Alert } from '@app/models'

@Component({
	selector: 'dam-alert-toast',
	templateUrl: './alert-toast.component.html',
	styleUrls: ['./alert-toast.component.css'],
})
export class AlertToastComponent implements OnInit, OnDestroy {
	alerts: Array<Alert> = []
	destroy$ = new Subject()

	constructor(private alertService: AlertToastService) {}

	ngOnInit() {
		this.alertService
			.getAlert()
			.pipe(takeUntil(this.destroy$))
			.subscribe((t: Alert) => this.alerts.push(t))

		this.alertService
			.getAlertDismissed()
			.pipe(takeUntil(this.destroy$))
			.subscribe((t: Alert) => this.remove(t))
	}

	remove(alert: Alert) {
		if (!alert) {
			this.alerts = []
		} else {
			this.alerts = this.alerts.filter(t => alert !== t)
		}
	}

	// TODO implement the styles for each state and add in the template

	// getStyle(alert: Alert) {
	//   if (!alert) {
	//     return;
	//   }

	//   switch (alert.type) {
	//     case AlertType.Success:
	//       return 'alert alert-success';
	//     case AlertType.Error:
	//       return 'alert alert-danger';
	//     case AlertType.Info:
	//       return 'alert alert-info';
	//     case AlertType.Warning:
	//       return 'alert alert-warning';
	//   }
	// }

	ngOnDestroy(): void {
		this.destroy$.next(true)
	}
}
