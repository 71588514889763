import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core'

@Component({
	selector: 'dam-loading',
	templateUrl: './loading.component.html',
	styleUrls: ['./loading.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent implements OnInit {
	@Input()
	internal: boolean

	@Input()
	small: boolean

	@Input()
	offset: boolean = true

	constructor() {}

	ngOnInit() {}
}
