import { Injectable } from '@angular/core'

@Injectable({
	providedIn: 'root',
})
export class DndToSelectService {
	ds: any
	dndItems = new Map()

	constructor() {}

	add(elem: any, value: any) {
		if (!this.ds) {
			throw new Error('Null Reference Exception: ds')
		}

		let id = value.file_id ? value.file_id : value.folder_id

		this.dndItems.set(id, value)
		this.ds.addSelectables(elem)
	}

	register(ds) {
		this.ds = ds
	}

	get(elem: any) {
		if (!Array.isArray(elem)) {
			elem = [elem]
		}
		return elem.map(t => this.dndItems.get(t))
	}
}
