import { Action } from '@ngrx/store'
import { Breadcrumb } from '@app/models'

export enum ActionTypes {
	LOAD_REQUEST = '[Breadcrumb] Load Request',
	LOAD_FAILURE = '[Breadcrumb] Load Failure',
	LOAD_SUCCESS = '[Breadcrumb] Load Success',
	CLEAR = '[Breadcrumb] Clear',
}

export class LoadRequestAction implements Action {
	readonly type = ActionTypes.LOAD_REQUEST
	constructor(public payload: { repositoryId: string; folderId: string }) {}
}

export class LoadFailureAction implements Action {
	readonly type = ActionTypes.LOAD_FAILURE
	constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
	readonly type = ActionTypes.LOAD_SUCCESS
	constructor(public payload: { items: Breadcrumb[] }) {}
}

export class ClearAction implements Action {
	readonly type = ActionTypes.CLEAR
}

export type Actions = LoadRequestAction | LoadFailureAction | LoadSuccessAction | ClearAction
