import { Directive, Input, ElementRef } from '@angular/core'
import { DndToSelectService } from '../services/dnd-to-select.service'

@Directive({
	selector: '[damDndToSelectItem]',
})
export class DndToSelectItemDirective {
	@Input('damDndToSelectItem')
	set model(value: any) {
		this.dndToSelectService.add(this.el.nativeElement, value)
	}

	constructor(private dndToSelectService: DndToSelectService, private el: ElementRef) {}
}
