import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { Observable } from 'rxjs'
import { Action } from '@ngrx/store'
import * as featureActions from './actions'
import { map, mergeMap, tap } from 'rxjs/operators'
import { FolderService } from '../../services'
import { mapRepositoryIdToName } from '../../common/helpers'
import { Breadcrumb } from '@app/models'

@Injectable()
export class BreadcrumbStoreEffects {
	constructor(private actions$: Actions, private folderService: FolderService) {}

	@Effect()
	loadRequestEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.LoadRequestAction>(featureActions.ActionTypes.LOAD_REQUEST),
		mergeMap(action =>
			this.folderService.getBreadcrumb(action.payload.repositoryId, action.payload.folderId).pipe(
				map((t: any) => {
					const rootUrl = `/${mapRepositoryIdToName(t.repo_id)}`

					let mapedItems: Breadcrumb[] = [
						{
							id: t.repo_id,
							label: '',
							url: rootUrl,
							level: '0',
							repo_id: t.repo_id,
						},
					]

					let path = t.children.data
						.map(b => {
							// const rootUrl = `/${mapRepositoryIdToName(b.data.repo_id)}`
							const folderUrl = `/${mapRepositoryIdToName(t.repo_id)}/folders/${b.folder_id}`
							// let url = b.data.folder_id === t.repo_id ? rootUrl : folderUrl
							return { id: b.folder_id, label: b.folder_name, url: folderUrl, level: `${b.level}`, repo_id: t.repo_id }
						})
						.reverse()

					mapedItems.push(...path)

					return new featureActions.LoadSuccessAction({ items: mapedItems })
				}),
			),
		),
	)

	// @Effect()
	// loadRequestEffect$: Observable<Action> = this.actions$.pipe(
	// 	ofType<featureActions.LoadRequestAction>(featureActions.ActionTypes.LOAD_REQUEST),
	// 	mergeMap(action =>
	// 		this.folderService.getBreadcrumb(action.payload.repositoryId, action.payload.folderId).pipe(
	// 			map(t => {

	// 				let mapedItems = t.map(b => {
	// 					const rootUrl = `/${mapRepositoryIdToName(b.data.repo_id)}`
	// 					const folderUrl = `/${mapRepositoryIdToName(b.data.repo_id)}/folders/${b.data.folder_id}`
	// 					let url = b.data.folder_id === b.data.repo_id ? rootUrl : folderUrl
	// 					return { id: b.data.folder_id, label: b.label, url: url, level: b.data.level, repo_id: b.data.repo_id }
	// 				})
	// 				return new featureActions.LoadSuccessAction({ items: mapedItems })
	// 			}),
	// 		),
	// 	),
	// )
}
