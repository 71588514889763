import { Injectable, OnDestroy } from '@angular/core'
import * as localforage from 'localforage'
import { Subject, interval, Observable, from } from 'rxjs'
import { takeUntil, map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { Folder, MoveItems } from '@app/models'

interface ICache {
	lastRead: number
	response: any
	expire: boolean
}

interface ITree {
	children: ITree[]
	collapsedIcon: string
	data: any
	expandedIcon: string
	label: string
}

@Injectable({
	providedIn: 'root',
})
export class RequestCacheService implements OnDestroy {
	destroy$ = new Subject()
	maxAge = 900000 // 15min

	constructor() {
		localforage.config({
			driver: localforage.INDEXEDDB,
			name: 'damCache',
			storeName: 'damstore',
		})

		interval(300000)
			.pipe(takeUntil(this.destroy$))
			.subscribe(t => {
				const expired = Date.now() - this.maxAge
				localforage.iterate((value: ICache, key) => {
					if (value.expire && value.lastRead < expired) {
						localforage.removeItem(key)
					}
				})
			})
	}

	ngOnDestroy(): void {
		this.destroy$.next(true)
	}

	async get<T>(key: string): Promise<T> {
		const cache = (await localforage.getItem(key)) as ICache

		if (!cache) {
			return undefined
		}

		return cache.response as T
	}

	getObservable<T>(key: string): Observable<T> {
		return from(localforage.getItem(key)).pipe(
			map(t => t as ICache),
			map(t => (!t ? undefined : (t.response as T))),
		)
	}

	put(key, response: any, expire: boolean = true): void {
		const entry = { key, response: { ...response }, lastRead: Date.now(), expire }
		localforage.setItem(key, entry)
	}

	async expire(key: any = '') {
		if (key) {
			await localforage.removeItem(key)
		} else {
			await localforage.clear()
		}
	}

	async expireByFolderPath(key: any = '') {
		let currentTree = (await localforage.getItem(key)) as any
		if (!currentTree) return

		let keys = await localforage.keys()
		let treeKyes = keys.filter(k => k.startsWith('tree-'))

		for (let treeKey of treeKyes) {
			let treeEntry = (await localforage.getItem(treeKey)) as any

			if (!!~treeEntry.response.folder_path.indexOf(currentTree.response.folder_path)) {
				await localforage.removeItem(treeKey)
			}
		}
	}

	//TODO Fabricio verify put tree for each interaction
	deleteItemTree(folders: Partial<Folder>[]) {
		return new Promise<void>((resolve, reject) => {
			this.get('tree').then((cache: any) => {
				if (cache && cache.tree) {
					let items = cache.tree
					for (const folder of folders) {
						let node = this.findInTree(folder.folder_id, items)
						let parent = this.findInTree(node.data.parent_id, items)
						let index = parent.children.findIndex(x => x.data.folder_id == folder.folder_id)
						if (parent.children.length === 1) {
							delete parent.children
						} else {
							parent.children.splice(index, 1)
						}
						this.put('tree', cache, false)
					}
				}
				resolve()
			})
		})
	}

	updateItemTree(folder: Partial<Folder>) {
		this.get('tree').then((cache: any) => {
			if (cache && cache.tree) {
				let items = cache.tree
				let node = this.findInTree(folder.folder_id, items)
				node.label = folder.name
				this.put('tree', cache, false)
			}
		})
	}

	async addItemTree(repositoryId: string, parentId: string, folderId: string, folderName: string) {
		let cache = (await this.get('tree')) as any //.then((: any) => {
		if (!cache || !cache.tree) {
			return
		}

		let node = this.findInTree(parentId, cache.tree)

		if (!node) {
			return
		}

		if (!node.children) {
			node.children = []
		}

		node.children.push({
			label: folderName,
			data: {
				folder_id: folderId,
				repo_id: repositoryId,
				parent_id: parentId,
				level: '99',
			},
		})
		this.put('tree', cache, false)
		// });
	}

	moveItemTree(items: MoveItems) {
		if (items.folders && items.folders.length) {
			this.get('tree').then((cache: any) => {
				if (!cache || !cache.tree) {
					return
				}
				// get all nodes to move
				let nodes = []
				for (let folder of items.folders) {
					const node = { ...this.findInTree(folder.folder_id, cache.tree) }
					node.data = { ...node.data, parent_id: items.folder_id }
					nodes.push(node)
				}
				// remove nodes from tree
				let cacheTree = cache.tree
				for (const folder of items.folders) {
					let node = this.findInTree(folder.folder_id, cacheTree)
					let parent = this.findInTree(node.data.parent_id, cacheTree)
					let index = parent.children.findIndex(x => x.data.folder_id == folder.folder_id)
					if (parent.children.length === 1) {
						delete parent.children
					} else {
						parent.children.splice(index, 1)
					}
				}

				// add nodes to new location
				let parentNode = this.findInTree(items.folder_id, cache.tree)
				if (!parentNode.children) {
					parentNode.children = []
				}

				parentNode.children.push(...nodes)
				this.put('tree', cache, false)
			})
		}
	}

	async validateVersion() {
		let cacheVersion = await this.get('version')
		if (!cacheVersion || cacheVersion['value'] != environment.version) this.clearCache()
	}

	async clearCache() {
		localforage
			.iterate((value: ICache, key) => {
				localforage.removeItem(key)
			})
			.then(x => this.put('version', { value: environment.version }, false))
	}

	private findInTree(folderId: string, node: any) {
		if (node.data.folder_id === folderId) {
			return node
		}
		if (node.children) {
			for (let child of node.children) {
				let result = this.findInTree(folderId, child)
				if (result) {
					return result
				}
			}
		}
	}
}
