import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store'
import { State, featureAdapter } from 'src/app/root-store/user-store/state'
import { User } from '@app/models'

export const getError = (state: State): any => state.error
export const getIsLoading = (state: State): boolean => state.isLoading
export const isAuthenticated = (state: State): boolean => state.isAuthenticated
export const getIsAdmin = (state: State): boolean => state.isAdmin

export const selectUserState: MemoizedSelector<object, State> = createFeatureSelector<State>('user')

export const selectUserIsLoading: MemoizedSelector<object, boolean> = createSelector(selectUserState, getIsLoading)
export const selectUserError: MemoizedSelector<object, any> = createSelector(selectUserState, getError)

export const selectAllUserItems: (state: object) => User[] = featureAdapter.getSelectors(selectUserState).selectAll

export const selectUser = createSelector(selectAllUserItems, (allUsers: User[]) => (allUsers.length ? allUsers[0] : null))

export const selectUserToken = createSelector(selectAllUserItems, (allUsers: User[]) => {
	return allUsers.length ? allUsers[0].authToken : ''
})

export const selectIsAdmin = createSelector(selectUserState, getIsAdmin)
