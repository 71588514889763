import { Component, OnInit, Output, EventEmitter } from '@angular/core'

@Component({
	selector: 'dam-alert-modal',
	templateUrl: './alert-modal.component.html',
	styleUrls: ['./alert-modal.component.css'],
})
export class AlertModalComponent implements OnInit {
	@Output()
	onOk = new EventEmitter()

	constructor() {}

	ngOnInit() {}
}
