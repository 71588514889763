import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core'

@Component({
	selector: 'dam-bottom-dialog',
	templateUrl: './bottom-dialog.component.html',
	styleUrls: ['./bottom-dialog.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomDialogComponent implements OnInit {
	@Input()
	isMinimized: boolean = true

	@Output()
	onExpandCollapseClick = new EventEmitter()

	constructor() {}

	ngOnInit() {}

	expandCollapseClick() {
		// if (this.files.length) {
		this.onExpandCollapseClick.emit()
		// }
	}
}
