<form class="new-project-form form" (ngSubmit)="submit()" #loginForm="ngForm" autocomplete="off" novalidate>
    <h3>Provide C3 access</h3>
    <p>You are required to provide email / password to connect the DAM application to C3.<br/>If you're not sure which email/password to use, please contact your administrator.</p>
  <div class="form-group">
    <label class="form-label">
      C3 ID
      <input class="form-input" type="text" name="id" [(ngModel)]="model.email" #id="ngModel" required placeholder="Email">
    </label>
  </div>
  <div class="form-group">
    <label class="form-label">
      Password
      <input class="form-input" type="password" name="password" [(ngModel)]="model.password" #password="ngModel" required placeholder="Password">
    </label>
  </div>
  <div class="form-actions">
    <button class="form-btn" mat-button type="submit" [disabled]="!loginForm.form.valid">CONTINUE</button>
  </div>
</form>