import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core'

@Component({
	selector: 'dam-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit {
	@Output()
	onSearchInputChange: EventEmitter<string> = new EventEmitter()

	@Output()
	onSearch: EventEmitter<void> = new EventEmitter()

	@Output()
	onSearchInputError: EventEmitter<void> = new EventEmitter()

	@Output()
	onClearSearch: EventEmitter<void> = new EventEmitter()

	@Input()
	placeholder: string

	constructor() {}

	ngOnInit() {}

	executeSearch(value: string) {
		if (value.length < 3) {
			this.onSearchInputError.emit()
			return
		}
		this.onSearch.emit()
	}

	clear() {
		this.onClearSearch.emit()
	}
}
