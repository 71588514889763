export class ApmTag {
	query: string = ''
	files: string = ''
	folders: string = ''
	source_folder_id: string = ''
	destination_folder_id: string = ''
	repo_id: string = ''
	folder_id: string = ''
	file_name: string = ''
	file_id: string = ''
	wf_id: string = ''
	name: string = ''
	parent_id: string = ''
	folder_name: string = ''
	description: string = ''
	permissions: string = ''
}
