import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { User } from '@app/models'

export const featureAdapter: EntityAdapter<User> = createEntityAdapter<User>({
	selectId: model => model.id,
	sortComparer: (a: User, b: User): number => b.id.toString().localeCompare(a.id),
})

export interface State extends EntityState<User> {
	isAuthenticated: boolean
	isAdmin: boolean
	isLoading?: boolean
	error?: any
}

export const initialState: State = featureAdapter.getInitialState({
	isAuthenticated: false,
	isAdmin: false,
	isLoading: false,
	error: null,
})
