import { Actions, ActionTypes } from './actions'
import { featureAdapter, initialState, State } from './state'
import { FileUpload } from '@app/models'
import { generateId } from '@app/common/helpers'

export function featureReducer(state = initialState, action: Actions): State {
	switch (action.type) {
		case ActionTypes.ENQUEUE: {
			let payload = !Array.isArray(action.payload) ? [action.payload] : action.payload

			for (let p of payload) {
				p.status = 'queue'
				p.id = generateId()
				p.file_name = p.data.name
			}

			return featureAdapter.addMany(payload, {
				...state,
				isLoading: false,
			})
		}

		case ActionTypes.UPLOAD_1_REQUEST: {
			return featureAdapter.updateOne(
				{
					id: action.payload.model.id,
					changes: {
						status: 'uploading',
					},
				},
				state,
			)
		}

		case ActionTypes.UPLOAD_2_REQUEST: {
			return featureAdapter.updateOne(
				{
					id: action.payload.model.id,
					changes: {
						file_id: action.payload.model.file_id,
						file_name: action.payload.req.file_name,
						folder_id: action.payload.req.folder_id,
						progress: 0,
					},
				},
				state,
			)
		}

		case ActionTypes.UPLOAD_3_SUCCESS: {
			// completa el proceso
			return featureAdapter.updateOne(
				{
					id: action.payload.model.id,
					changes: { progress: 100, completed: true, status: 'completed' },
				},
				state,
			)
		}

		case ActionTypes.UPLOAD_REPORT_PROGRESS: {
			const fileInfo = { ...action.payload.info }
			if (fileInfo.progress == 100) {
				fileInfo.progress = 99 // Wait for step 3 to complete
			}

			return featureAdapter.updateOne({ id: action.payload.info.id, changes: fileInfo }, { ...state })
		}

		case ActionTypes.TOGGLE_UPLOAD_DIALOG: {
			return {
				...state,
				isMinimized: !state.isMinimized,
			}
		}

		case ActionTypes.SET_UPLOAD_DIALOG: {
			return {
				...state,
				isMinimized: !action.payload,
			}
		}

		case ActionTypes.CLEAR_UPLOAD: {
			return featureAdapter.removeOne(action.payload.file.id, {
				...state,
				isMinimized: state.ids.length == 1 ? true : state.isMinimized,
			})
		}

		case ActionTypes.CLEAR_UPLOAD_ALL: {
			const ids = featureAdapter
				.getSelectors()
				.selectAll(state)
				.filter(t => t.status === 'completed' || t.status === 'error')
				.map(t => t.id)

			return featureAdapter.removeMany(ids, state)
		}

		case ActionTypes.DUPLICATE_FILE_REQUEST: {
			return {
				...state,
				duplicateFiles: { ...action.payload },
				isLoading: true,
			}
		}

		case ActionTypes.DUPLICATE_FILE_CANCEL:
		case ActionTypes.DUPLICATE_FILE_SUCCESS: {
			return {
				...state,
				duplicateFiles: null,
				isLoading: false,
			}
		}

		case ActionTypes.DUPLICATE_FOLDER_REQUEST: {
			return {
				...state,
				duplicateFolders: { ...action.payload },
				isLoading: true,
			}
		}

		case ActionTypes.DUPLICATE_FOLDER_SUCCESS: {
			return {
				...state,
				duplicateFolders: null,
			}
		}

		case ActionTypes.VERSION_FILE_REQUEST: {
			return {
				...state,
				isLoading: true,
			}
		}

		case ActionTypes.REMOVE_ELEMENT_UPLOAD: {
			return featureAdapter.removeOne(action.payload, state)
		}

		case ActionTypes.VERIFY_UPLOAD_REQUEST: {
			return {
				...state,
				isLoading: true,
			}
		}
		case ActionTypes.UPLOAD_VALIDATE_STRUCTURE_SUCCESS: {
			return {
				...state,
				isLoading: false,
			}
		}

		case ActionTypes.REPLACE_FILE_STEP1_REQUEST: {
			const fileUpload = {
				id: action.payload.id,
				file_id: action.payload.version.file_id,
				file_name: action.payload.file.name,
				folder_id: action.payload.folder.folder_id,
				progress: 0,
			} as FileUpload

			return featureAdapter.upsertOne(fileUpload, {
				...state,
				isLoading: false,
			})
		}

		case ActionTypes.VERSION_FILE_FAILURE:
		case ActionTypes.REPLACE_FILE_STEP1_FAILURE:
		case ActionTypes.REPLACE_FILE_STEP2_FAILURE:
		case ActionTypes.REPLACE_FILE_STEP3_FAILURE: {
			return {
				...state,
				isLoading: false,
			}
		}

		case ActionTypes.REPLACE_FILE_STEP3_SUCCESS: {
			return featureAdapter.updateOne(
				{
					id: action.payload.id,
					changes: { progress: 100, completed: true, status: 'completed' },
				},
				state,
			)
		}

		case ActionTypes.UPLOAD_VALIDATE_STRUCTURE_REQUEST: {
			return {
				...state,
				isLoading: true,
			}
		}

		case ActionTypes.UPDATE_REFRESH_ENTITY_STATE: {
			return featureAdapter.map(entity => {
				if (!entity.refreshed && entity.status === 'completed') {
					return {
						...entity,
						refreshed: true,
					}
				}
				return entity
			}, state)
		}

		default: {
			return state
		}
	}
}
