import { Component, OnInit, Input } from '@angular/core'

@Component({
	selector: 'dam-empty-dialog-item',
	templateUrl: './empty-dialog-item.component.html',
	styleUrls: ['./empty-dialog-item.component.css'],
})
export class EmptyDialogItemComponent implements OnInit {
	@Input()
	message: string

	constructor() {}

	ngOnInit() {}
}
