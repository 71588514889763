import { Directive, Output, EventEmitter, ElementRef } from '@angular/core'
import * as DragSelect from 'dragselect'
import { DndToSelectService } from '../services/dnd-to-select.service'

@Directive({
	selector: '[damDndToSelect]',
})
export class DndToSelectDirective {
	@Output('damDndToSelect')
	dndCallback: EventEmitter<any> = new EventEmitter<any>()

	constructor(private dndToSelectService: DndToSelectService, el: ElementRef) {
		let ds = new DragSelect({
			area: el.nativeElement,
			callback: e => {
				let result = this.dndToSelectService.get(e.map(t => t.id))
				this.dndCallback.emit(result)
			},
		})

		this.dndToSelectService.register(ds)
	}
}
