import { Component, OnInit, OnDestroy } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { SearchStoreSelectors, RootStoreState, FolderStoreSelectors } from 'src/app/root-store'
import { Store } from '@ngrx/store'
import { ModalService } from 'src/app/services'
import { environment } from 'src/environments/environment'
import { takeUntil } from 'rxjs/operators'
import { IContextMenuAction } from 'src/app/models/context-menu.model'
import { DamContext } from '@app/models'

@Component({
	selector: 'dam-main-project',
	templateUrl: './main-project.component.html',
	styleUrls: ['./main-project.component.css'],
	providers: [ModalService],
})
export class MainProjectComponent implements OnInit, OnDestroy {
	damContext: DamContext
	destroy$ = new Subject()
	contextMenuActions: IContextMenuAction[] = []
	selectActiveProjectsFilter$: Observable<boolean>
	selectMyProjectsFilter$: Observable<boolean>

	constructor(private store$: Store<RootStoreState.State>) {}

	ngOnInit() {
		this.selectActiveProjectsFilter$ = this.store$.select(SearchStoreSelectors.selectActiveProjectsFilter)
		this.selectMyProjectsFilter$ = this.store$.select(SearchStoreSelectors.selectMyProjectsFilter)

		this.store$
			.select(FolderStoreSelectors.selectCurrentFolder)
			.pipe(takeUntil(this.destroy$))
			.subscribe(t => {
				this.damContext = {
					folder_type: !t.folder_id ? 'repository' : 'folder',
					folder_id: t.folder_id || environment.libraryRepositoryId,
					repo_id: environment.libraryRepositoryId,
				}
			})
	}

	ngOnDestroy(): void {
		this.destroy$.next(true)
	}
}
