import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Store } from '@ngrx/store'
import { RootStoreState, SearchStoreActions, RepositoryStoreActions } from '../..'

@Injectable()
export class RepositoryManageResolver {
	constructor(private store$: Store<RootStoreState.State>) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		this.store$.dispatch(new SearchStoreActions.SetEnabled({ value: false }))
		this.store$.dispatch(new RepositoryStoreActions.RepoRequestAction())
	}
}
