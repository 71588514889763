<div class="navigation-user" [popper]="userActionPopover" [popperShowOnStart]="false" [popperTrigger]="'click'"
  [popperHideOnClickOutside]="true" [popperHideOnScroll]="true" [popperApplyClass]="'context-menu'" [popperPlacement]="'bottom-end'">
    {{ user && user.firstName && user.firstName[0].toLocaleUpperCase()}}
</div> <span class="navigation-user-name">{{user?.name}}</span>

<popper-content #userActionPopover>
  <ul>
    <li (click)="signOut()">
      <div class="menu-item">
        <img class="icon" src="./../../../assets/images/icons/logout.svg" alt="Sign out"> Sign out
      </div>
    </li>
  </ul>
</popper-content>