<main class="main-content">
    <div class="container wf-view">
        <dam-wf-projects class="projects" [damProjects]="damProjects$ | async" [projects]="projects$ | async"
            (onAddProject)="addProject($event)" (onLinkProject)="openLinkProject($event)" *ngIf="credentials"
            (onSyncProject)="syncProject($event)" (onGenerateStorageProjectsFile)="showModal('confirmation-generate')"
            (onRefreshProjects)="getProjects(credentials)"></dam-wf-projects>
        <dam-wf-login class="login" (onSubmit)="getProjects($event)" *ngIf="!credentials"></dam-wf-login>
        <dam-loading *ngIf="(isLoading$ | async) || (isDamProjectsLoaded$ | async)"></dam-loading>
    </div>

    <dam-modal id="sync-projects">
        <dam-wf-sync-modal
            (onSubmit)="syncProjects($event)" [isLoading]="isLoading$ | async"
            [outdatedPorjects]="selectOutdateProjects$ | async" (onCancel)="closeModal()">
        </dam-wf-sync-modal>
    </dam-modal>

    <dam-modal id="link-project">
        <dam-wf-link-project-modal (onSave)="saveProjectLink($event)"></dam-wf-link-project-modal>
    </dam-modal>

    <dam-modal id="confirmation-generate">
        <dam-generate-confirm-modal (onSubmit)="generateStorageProjectsFile()" (onCancel)="closeModal()">
        </dam-generate-confirm-modal>
    </dam-modal>
</main>