<header class="asset-navigation">
  <ng-content></ng-content>
  <popper-content #helpPopover>
    <ul>
      <li>
        <a href="https://design-pwccreative.slack.com/messages/CCTKFJ81Z/details/?" target="_blank" rel="noopener noreferrer">
          <div class="menu-item">
            <label>Help</label>
          </div>
        </a>
      </li>
      <li>
        <a href="https://pwc-spark.com/groups/us-creative-team-internal/projects/creative-operations/content?filterID=contentstatus%5Bpublished%5D~category%5Bcreative-dam%5D"
          target="_blank" rel="noopener noreferrer">
          <div class="menu-item">
            <label>Guidance</label>
          </div>
        </a>
      </li>
    </ul>
  </popper-content>
</header>