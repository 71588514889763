import { Injectable } from '@angular/core'
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Store } from '@ngrx/store'
import { RootStoreState, UserStoreActions } from '../..'
import { BreadcrumbStoreActions } from '../../breadcrumb-store'
import { DownloadFolderStoreActions } from '../../download-store'
import { DamContext } from '@app/models'
import { SocialAuthService } from 'angularx-social-login'
import { ApiGeeService } from '@app/services/api-gee.service'
import { first } from 'rxjs/operators'

@Injectable()
export class MainResolver implements Resolve<DamContext> {
	constructor(private store$: Store<RootStoreState.State>, private authService: SocialAuthService, private apiGeeService: ApiGeeService) {
		this.authService.authState.pipe(first()).subscribe(user => {
			if (user !== null) {
				this.apiGeeService.googleAuthToken = user.authToken
				this.apiGeeService.userEmail = user.email
				this.apiGeeService.idToken = user.idToken
				this.apiGeeService
					.authenticate()
					.pipe(first())
					.subscribe(t => {
						this.store$.dispatch(new UserStoreActions.LoginSuccessAction({ user: { ...user, accessToken: t.access_token } }))
					})
			}
		})
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		this.store$.dispatch(new BreadcrumbStoreActions.ClearAction())
		this.store$.dispatch(new DownloadFolderStoreActions.LoadRequestAction())
		this.store$.dispatch(new DownloadFolderStoreActions.SynchronizeRequestAction())
		return null
	}
}
