<div class="container">
    <div>
        <h3>Utils</h3>
        <input type="text" #wfUserInput placeholder="workfront user">
        <input type="text" #wfPasswordInput placeholder="workfront password"><br>
        <label>{{message}}</label>
        <button (click)="findDuplicatedMetadataFiles()">Find projects with duplicated metadata file</button><br>
        <button (click)="fixDuplicatedMetadataFiles()">Fix duplicated</button>
        <hr>
        <button (click)="findProjectsWithoutMetadata()">Find projects without metadata</button><br>
        <ul>
            <li *ngFor="let p of projectsToFix">{{p.project.name}}</li>
        </ul>
        <ul>
            <li *ngFor="let p of projectsToFixWithoutMetadata">
                <hr> <span> {{p.project.name}}</span> <button
                    (click)="addMetadataFile(wfUserInput.value, wfPasswordInput.value, p.project.name, p.project.folder_id)">add metadata</button></li>
        </ul>

        <br>
        <hr>
        <button (click)="findProjectsWithDuplicateName()">Find projects with duplicate name</button><br>

    </div>

    <div>
        <button (click)="getFolders()">Load Project Repository</button>
        <button (click)="getFoldersOldApi()">Load Project Repository (old)</button>
        <ul>
            <li *ngFor="let fol of folders?.folders">
                <span>{{fol.name}}</span>

                <a (click)="$event.preventDefault();getFolder(fol.folder_id, fol.name)" href>{{fol.folder_id}}</a>
                <button (click)="removeFolder(fol.folder_id, fol.name, folders.repo_id)">Remove</button>
                <hr>
            </li>
        </ul>
    </div>

    <div>
        <h3>Load folder by id:</h3>
        <input type="text" #folderInput>
        <button (click)="getFolder(folderInput.value, '')">Load</button>
        <hr>
         <button (click)="addMetadataFile(wfUserInput.value, wfPasswordInput.value, projectName, folder.folder_id)">Upload metadata file from workfront-api</button>
         <br>
         <input type="text" #wfIdInput>
         <button (click)="addMetadataFileFromWfId(wfUserInput.value, wfPasswordInput.value, wfIdInput.value, folder.folder_id)">Upload metadata from wfId</button>
        <hr>

        <h3>Update metadata from workfront</h3>
        
        <input type="text" #metadataFileId name="metadataFileId">
        <label for="metadataFileId">SDM Id for metadata file</label><br>
        <input type="text" #metadataWfId>
        <label for="metadataWfId">Project Workfront Id</label> <br>
        <button (click)="updateMetadataFromWf(wfUserInput.value, wfPasswordInput.value, folder.folder_id, metadataFileId.value, metadataWfId.value)">Upload metadata from wfId</button>
        <hr>


        <h3>Folders</h3>
        <ul>
            <li *ngFor="let p of folder?.folders">
                <b>{{p.name}}</b>
                <span>{{p.folder_id}}</span>
                <button (click)="removeFolder(p.folder_id, p.name, folder.folder_id)">Remove</button>
                <hr>

            </li>
        </ul>
        <h3>Files</h3>
        <ul>
            <li *ngFor="let file of folder?.files">
                <b>{{file.name}}</b>
                <span>{{file.file_id}}</span>
                <span>Status:{{file.status}}</span>
                <button (click)="removeFile(file.file_id, file.name, folder.folder_id)">Remove</button>
                <hr>
            </li>
        </ul>
    </div>
</div>