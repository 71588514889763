import { Action } from '@ngrx/store'
import { TabBottomSidenav } from '@app/models'

export enum ActionTypes {
	SORT_FIELD_CHANGE = '[Layout][Sort] Change sort field',
	SORT_DIRECTION_CHANGE = '[Layout][Sort] Change sort direction',
	SHOW_ALERT_TOAST = '[Layout][Toast] Show alert',
	VIEW_CHANGE = '[Layout][View] Change view',
	SHOW_DETAIL_SIDENAV = '[Layout][Sidenav] Toggle detail sidenav',
	SET_SIDENAV_ACTIVE_TAB = '[Layout][Sidenav] Set active tab',
	SET_BOTTOM_SIDENAV = '[Layout][Bottom Sidenav] Show or hide sidenav',
	TOGGLE_BOTTOM_SIDENAV = '[Layout][Bottom Sidenav] Toggle bottom sidenav',
	SET_BOTTOM_SIDENAV_ACTIVE_TAB = '[Layout][Bottom Sidenav] Set active tab bottom sidenav',
	TABLE_SET_PAGINATION = '[Table][Pagination] Set pagination config',
	TABLE_CHANGE_PAGE = '[Table][Pagination] Change page',
}

export class SortFieldChange implements Action {
	readonly type = ActionTypes.SORT_FIELD_CHANGE
	constructor(public payload: { field: string }) {}
}

export class SortDirectionChange implements Action {
	readonly type = ActionTypes.SORT_DIRECTION_CHANGE
	constructor() {}
}

export class ViewChange implements Action {
	readonly type = ActionTypes.VIEW_CHANGE
	constructor(public payload: { view: string }) {}
}

export class ShowAlertToast implements Action {
	readonly type = ActionTypes.SHOW_ALERT_TOAST
	constructor(public payload: { message: string; autoDismiss?: boolean }) {}
}

export class ShowDetailSidenav implements Action {
	readonly type = ActionTypes.SHOW_DETAIL_SIDENAV
	constructor(public payload: { visible: boolean }) {}
}

export class SetSidenavActiveTab implements Action {
	readonly type = ActionTypes.SET_SIDENAV_ACTIVE_TAB
	constructor(public payload: { tab: 'activity' | 'details' }) {}
}

export class SetBottomSidenav implements Action {
	readonly type = ActionTypes.SET_BOTTOM_SIDENAV
	constructor(public payload: { visible: boolean }) {}
}

export class SetActiveTabBottomSidenav implements Action {
	readonly type = ActionTypes.SET_BOTTOM_SIDENAV_ACTIVE_TAB
	constructor(public payload: { tab: TabBottomSidenav }) {}
}

export class ToggleBottomSidenav implements Action {
	readonly type = ActionTypes.TOGGLE_BOTTOM_SIDENAV
}

export class TableSetPagination implements Action {
	readonly type = ActionTypes.TABLE_SET_PAGINATION
	constructor(public payload: { page?: number; limit?: number; count: number }) {}
}

export class TableChangePage implements Action {
	readonly type = ActionTypes.TABLE_CHANGE_PAGE
	constructor(public payload: { page?: number; limit?: number; count?: number }) {}
}

export type Actions =
	| SortFieldChange
	| SortDirectionChange
	| ShowAlertToast
	| ShowDetailSidenav
	| ViewChange
	| SetSidenavActiveTab
	| SetBottomSidenav
	| ToggleBottomSidenav
	| SetActiveTabBottomSidenav
	| TableSetPagination
	| TableChangePage
