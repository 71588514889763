import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable, of, from, Subject, throwError } from 'rxjs'
import { map, retry, switchMap, filter, mergeMap, tap, share, catchError, take, shareReplay } from 'rxjs/operators'
import { RequestCacheService } from './request-cache.service'
import { FolderActionResult, FolderWrapper, FolderCreate, Folder, User } from '@app/models'
import { FileService } from '@app/services/file.service'
import { environment } from '@environment/environment'
import { Store } from '@ngrx/store'
import * as LayoutStoreActions from '@app/root-store/layout-store/actions'
import * as RootStoreState from '@app/root-store/state'

@Injectable({
	providedIn: 'root',
})
export class FolderService {
	createdFolderCache: FolderActionResult[]

	constructor(
		private http: HttpClient,
		private cacheService: RequestCacheService,
		private fileService: FileService,
		private store$: Store<RootStoreState.State>,
	) {}

	getFolder(repositoryId: string, folderId: string = '', useCache: boolean = true): Observable<FolderWrapper> {
		let param = new HttpParams().set('repo_id', repositoryId)

		if (folderId) {
			param = param.append('folder_id', folderId)
		}

		const subject = new Subject<FolderWrapper>()
		const cacheKey = `${repositoryId}-${folderId || ''}`

		this.cacheService.get<FolderWrapper>(cacheKey).then(item => {
			if (item && useCache) {
				subject.next(item)
				subject.complete()
			} else {
				this.http
					.get<FolderWrapper>(`${environment.apiBaseUrl}${environment.folderApiUrl}`, { params: param })
					.pipe(
						map(t => {
							t.folder_id = folderId
							t.repo_id = repositoryId
							return t
						}),
					)
					.subscribe(
						t => {
							subject.next(t)
							subject.complete()
							this.cacheService.put(cacheKey, t)
						},
						err => {
							subject.error(err)
							subject.complete()
						},
					)
			}
		})

		return subject.asObservable()
	}

	getFolderOldApi(repositoryId: string, folderId: string = '', useCache: boolean = true): Observable<FolderWrapper> {
		let param = new HttpParams().set('repo_id', repositoryId)

		if (folderId) {
			param = param.append('folder_id', folderId)
		}

		const subject = new Subject<FolderWrapper>()
		const cacheKey = `${repositoryId}-${folderId || ''}`

		this.cacheService.get<FolderWrapper>(cacheKey).then(item => {
			if (item && useCache) {
				subject.next(item)
				subject.complete()
			} else {
				this.http
					.get<FolderWrapper>(`${environment.oldApiBaseUrl}${environment.folderApiUrl}`, { params: param })
					.pipe(
						map(t => {
							t.folder_id = folderId
							t.repo_id = repositoryId
							return t
						}),
					)
					.subscribe(
						t => {
							subject.next(t)
							subject.complete()
							this.cacheService.put(cacheKey, t)
						},
						err => {
							subject.error(err)
							subject.complete()
						},
					)
			}
		})

		return subject.asObservable()
	}

	getTreeObs$: Observable<any>

	getTree(repositoryId: string): Observable<any> {
		return from(this.cacheService.get('tree')).pipe(
			mergeMap((item: any) => {
				if (item && item.tree) {
					this.getTreeObs$ = null
					return of(item)
				} else {
					if (!this.getTreeObs$) {
						let param = new HttpParams().set('repo_id', repositoryId)
						this.getTreeObs$ = this.http
							.get<any>(`${environment.apiBaseUrl}${environment.folderTreeApiUrl}`, { params: param })
							.pipe(
								tap(t => this.cacheService.put('tree', t, false)),
								share(),
							)
					}
					return this.getTreeObs$
				}
			}),
		)
	}

	folderHierarchyObs$: any = {}

	getFolderHierarchy(repositoryId: string, folderId: string) {
		const cacheKey = `tree-${repositoryId}-${folderId}`
		return from(this.cacheService.get(cacheKey)).pipe(
			mergeMap((item: any) => {
				if (item && item.children) {
					this.folderHierarchyObs$[cacheKey] = null
					return of(item)
				} else {
					if (!this.folderHierarchyObs$[cacheKey]) {
						let param = new HttpParams().set('repo_id', repositoryId).set('folder_id', folderId)

						this.folderHierarchyObs$[cacheKey] = this.http
							.get<any>(`${environment.apiBaseUrl}${environment.folderHierarchyApiUrl}`, { params: param })
							.pipe(
								map(t => {
									if (t.children.data.length) {
										for (let i = 0; i < t.children.data.length; i++) {
											t.children.data[i].level++
										}
									}
									return t
								}),
								share(),
								tap(t => this.cacheService.put(cacheKey, t, true)),
							)
					}
					return this.folderHierarchyObs$[cacheKey]
				}
			}),
		)
	}

	getBreadcrumb(repositoryId: string, folderId: string): Observable<any> {
		return this.getFolderHierarchy(repositoryId, folderId)
	}

	getTreeWrapper(repositoryId, folderId): Observable<Array<any>> {
		return this.getTree(repositoryId).pipe(
			map(t => {
				let breadcrumb = this.buildBreadcrumb(folderId, t.tree) || []
				return breadcrumb.reverse()
			}),
		)
	}

	private buildBreadcrumb(folderId: string, node: any, breadcrumb: any = []) {
		if (node.data.folder_id === folderId) {
			breadcrumb.push(node)
			return breadcrumb
		}
		if (node.children) {
			for (let child of node.children) {
				if (this.buildBreadcrumb(folderId, child, breadcrumb)) {
					breadcrumb.push(node)
					return breadcrumb
				}
			}
		}
	}

	find(repositoryId: string, folderId: string) {
		return this.getTree(repositoryId).pipe(
			// return from(this.cacheService.get('tree')).pipe(
			map((t: any) => {
				if (t && t.tree) {
					return this.findInTree(folderId, t.tree)
				}
				return null
			}),
		)
	}

	findProject(repositoryId: string, folderId: string): Observable<any> {
		const key = `findProject-${repositoryId}-${folderId}`

		const subject = new Subject()

		this.cacheService.get(key).then((item: any) => {
			if (item && item.repo_id) {
				subject.next(item)
				subject.complete()
			} else {
				// get project folder id based on breadcrumb
				this.getBreadcrumb(repositoryId, folderId)
					.pipe(
						switchMap(breadcrumb => {
							return breadcrumb.children.data.length ? from(breadcrumb.children.data).pipe(filter((t: any) => t.level == 1)) : of([])
						}),
					)
					.subscribe(
						t => {
							subject.next(t)
							this.cacheService.put(key, t, false)
							subject.complete()
						},
						err => {
							subject.error(err)
							subject.complete()
						},
					)
			}
		})

		return subject.asObservable()
	}

	private findInTree(folderId: string, node: any) {
		if (node.data.folder_id === folderId) {
			return node
		}
		if (node.children) {
			for (let child of node.children) {
				let result = this.findInTree(folderId, child)
				if (result) {
					return result
				}
			}
		}
		return null
	}

	create(folder: FolderCreate, validateIfExist: boolean = false): Observable<FolderActionResult> {
		// force reload tree cache
		return this.createFolderRequest(folder).pipe(
			switchMap(async x => {
				await this.cacheService.addItemTree(folder.repo_id, folder.parent_id, x.folder.folder_id, folder.folder_name)
				return x
			}),
		)
	}

	async createFileStructure(uploadData: any[], folderId: string, repositoryId: string) {
		let files = []
		this.createdFolderCache = []

		for (let data of uploadData) {
			await this.readUploadEntry(data, files, folderId, repositoryId)
		}
		return [files, this.createdFolderCache]
	}

	async readUploadEntry(data, damFiles: any[], folderId: string, repositoryId: string) {
		for (let j of Object.keys(data)) {
			if (data[j] instanceof File) {
				damFiles.push({ data: data[j], folderId: folderId })
			} else {
				await new Promise((resolve, reject) => {
					this.createFolderIfNotExist(folderId, j, repositoryId).subscribe(
						newFolderId => {
							this.readUploadEntry(data[j], damFiles, newFolderId, repositoryId).finally(() => {
								resolve(newFolderId)
							})
						},
						error => {
							reject(error)
						},
					)
				})
			}
		}
		return damFiles
	}

	/**
	 * @deprecated
	 * This method finds the parentid in the tree and looks for the name of the folder in its children
	 * if doesn't exist, the folder is created
	 *
	 * @param parentId
	 * @param folderName
	 * @param repositoryId
	 */
	createFolderIfNotExist(parentId: string, folderName: string, repositoryId: string): Observable<string> {
		return this.find(repositoryId, parentId).pipe(
			map(currentFolder => {
				if (currentFolder && currentFolder.children) {
					let folder = currentFolder.children.find(t => t.label === folderName)
					if (folder) {
						return folder.data.folder_id
					}
				}
				return null
			}),
			switchMap(folderId => {
				if (folderId) {
					return of(folderId)
				}

				const folderModel: FolderCreate = {
					folder_name: folderName,
					parent_id: parentId,
					repo_id: repositoryId,
				}
				return this.createFolderRequest(folderModel).pipe(
					switchMap(newFolder =>
						from(this.cacheService.addItemTree(repositoryId, parentId, newFolder.folder.folder_id, folderName)).pipe(
							map(f => {
								this.createdFolderCache.push(newFolder)
								return newFolder.folder.folder_id
							}),
						),
					),
				)
			}),
			catchError(err => {
				return of(err)
			}),
		)
	}

	private createFolderRequest(folder: FolderCreate) {
		this.store$.dispatch(
			new LayoutStoreActions.ShowAlertToast({
				message: 'Creating folder: ' + folder.folder_name,
				autoDismiss: true,
			}),
		)
		this.cacheService.expire(`${folder.repo_id}-${folder.parent_id}`)

		return this.http.post<FolderActionResult>(`${environment.apiBaseUrl}${environment.folderCreateApiUrl}`, folder).pipe(
			catchError(error => {
				console.log('error create', error)
				if (error.status == 400) {
					return this.getFolderAux(folder.repo_id, folder.parent_id).pipe(
						map((folderAux: FolderWrapper) => {
							const sdmFolder = folderAux.folders.find(f => f.name == folder.folder_name)
							if (sdmFolder) {
								return {
									folder: {
										createdBy: sdmFolder.createdBy,
										createdOn: sdmFolder.createdOn,
										disp_sequence: sdmFolder.disp_sequence,
										folder_id: sdmFolder.folder_id,
										repo_id: sdmFolder.repo_id,
										name: sdmFolder.name,
										status: sdmFolder.status,
									},
								} as FolderActionResult
							}
							throw error
						}),
					)
				}

				return throwError(error)
			}),
			retry(3),
			shareReplay(),
		)
	}

	update(folder: Folder, user: User) {
		// force reload tree cache
		const { folder_id, repo_id, name } = folder

		this.cacheService.expireByFolderPath(`tree-${repo_id}-${folder_id}`)

		return this.http.put(`${environment.apiBaseUrl}${environment.folderUpdateApi}`, {
			folder_id,
			repo_id,
			folder_name: name,
			modified_by: user.email,
		})
	}

	getProjectMetadataFromStorage(folderId: string) {
		let url = `${environment.workfrontApiUrl}${environment.workfrontApiGetStorageProjects}/getbyid`
		return this.http.post(url, { folder_id: folderId })
	}

	getProjectFromStorage(currentFilter) {
		const cacheKey = `${environment.projectRepositoryId}-`
		const subject = new Subject<FolderWrapper>()

		let url = `${environment.workfrontApiUrl}${environment.workfrontApiGetStorageProjects}/${currentFilter ? 'getcurrent' : 'get'}`

		// Fix for the issue with the cache when navigate to the project page from workfront
		if (localStorage.getItem('pSource') === 'workfront' && window.location.href.indexOf('workfront') === -1) {
			this.cacheService.expire(cacheKey)
			localStorage.setItem('pSource', '')
		}

		this.cacheService.get<FolderWrapper>(cacheKey).then(item => {
			if (item) {
				subject.next(item)
				subject.complete()
			} else {
				this.http.post<FolderWrapper>(url, {}).subscribe(
					t => {
						subject.next(t)
						subject.complete()
						this.cacheService.put(cacheKey, t, true)
					},
					err => {
						subject.error(err)
						subject.complete()
					},
				)
			}
		})

		return subject.asObservable()
	}

	getAllProjectFromStorage(page: number, limit: number, sortColumn: string, sortOrder: string, myProjectFilter?: boolean, user?: User) {
		const params = {
			page,
			limit,
			sort: {},
		} as any

		if (sortColumn) {
			params.sort[sortColumn] = sortOrder
		}

		if (myProjectFilter) {
			params.query = { 'property.value': { $regex: user.email } }
		}

		let url = `${environment.workfrontApiUrl}${environment.workfrontApiGetStorageProjects}/get`
		return this.http.post(url, params).pipe(
			map((data: any) => {
				return {
					folders: data.docs,
				} as FolderWrapper
			}),
		)
	}

	getAllProjectFromStorageCount(query?: any) {
		let url = `${environment.workfrontApiUrl}${environment.workfrontApiGetStorageProjects}/count`
		return this.http.post<number>(url, query)
	}

	getProjectsByNameFromStorage(projects: string[]) {
		return this.http.post<Folder[]>(`${environment.workfrontApiUrl}${environment.workfrontApiGetStorageProjects}/getbyname`, projects)
	}

	getProjectsFolders(repositoryId: string): Observable<any> {
		let result = new Subject()

		let param = new HttpParams().set('repo_id', repositoryId)

		let projects = []

		this.http
			.get<any>(`${environment.apiBaseUrl}${environment.folderApiUrl}`, { params: param })
			.subscribe(async root => {
				const chunkedFolders = this.chunk(root.folders, (window as any).wfBufferSize || 100)

				for (let folders of chunkedFolders) {
					let promiseChunk = []
					for (let folder of folders) {
						promiseChunk.push(
							new Promise((resolve, reject) => {
								try {
									this.getFolderAux(folder.repo_id, folder.folder_id)
										.pipe(take(1))
										.toPromise()
										.then(folderAux => {
											const metadataFileIndex = folderAux.files && folderAux.files.findIndex(i => i.name === environment.metadataFileName)
											if (metadataFileIndex > -1) {
												const fileId = folderAux.files[metadataFileIndex].file_id

												this.fileService
													.getFileDict(fileId)
													.pipe(take(1))
													.toPromise()
													.then(fileInfo => {
														for (let i = 0; i < fileInfo.file.length; i++) {
															delete (fileInfo.file[i] as any).unique_id
														}

														const project = {
															...folder,
															metadataLoaded: true,
															metadataFileId: fileId,
															property: fileInfo.file.property,
															createdBy: fileInfo.file.createdBy,
															createdOn: fileInfo.file.createdOn,
															isEmptyFolder: !(
																(folderAux.files && folderAux.files.length > 1) ||
																(folderAux.folders && folderAux.folders.length)
															),
														}
														projects.push(project)
													})
													.finally(() => {
														resolve('')
													})
											} else {
												resolve('')
											}
										})
										.catch(error => {
											console.log('[Error]: getFolderAux then', folder)
											resolve('')
										})
								} catch (error) {
									console.log('[Error]: getFolderAux', folder)
								}
							}),
						)
					}

					await Promise.all(promiseChunk)
					promiseChunk = []
				}

				result.next(projects)
				result.complete()
			})

		return result.asObservable()
	}

	chunk(array: any[], size: number) {
		const chunked = []
		for (let i = 0; i < array.length; i = i + size) {
			chunked.push(array.slice(i, i + size))
		}
		return chunked
	}

	getFolderAux(repositoryId: string, folderId: string = ''): Observable<FolderWrapper> {
		let param = new HttpParams().set('repo_id', repositoryId)
		if (folderId) {
			param = param.append('folder_id', folderId)
		}

		return this.http.get<FolderWrapper>(`${environment.apiBaseUrl}${environment.folderApiUrl}`, { params: param })
	}

	updateFolderStateCache(folder: Partial<Folder>, currentProjects: Folder[]) {
		try {
			const cacheKey = `${environment.projectRepositoryId}-`

			// If project list not loaded, force update project
			if (!currentProjects || !currentProjects.length || folder.isEmptyFolder === undefined) {
				return true
			}

			let proj = { folders: currentProjects }

			const index = proj.folders.findIndex(p => p.folder_id === folder.folder_id)
			if (index !== -1) {
				if (proj.folders[index].isEmptyFolder != folder.isEmptyFolder) {
					proj.folders[index].isEmptyFolder = folder.isEmptyFolder
					this.cacheService.put(cacheKey, proj)
					return true
				}
			}
			return false
		} catch (err) {
			return false
		}
	}
}
