import { Component, AfterViewInit, Output, EventEmitter } from '@angular/core'

@Component({
	selector: 'dam-file-upload-dialog-launch',
	templateUrl: './file-upload-dialog-launch.component.html',
})
export class FileUploadDialogLaunchComponent implements AfterViewInit {
	constructor() {}

	@Output()
	onFileUpload = new EventEmitter()

	ngOnInit() {}

	ngAfterViewInit(): void {}

	onFileChange(event) {
		this.onFileUpload.emit(event.target.files)
		event.target.value = ''
	}
}
