<dam-actions-header>
  <ul class="navigation-links">
    <li class="navigation-item">
      Guides
    </li>
  </ul>
  <button class="add-new-btn" type="button" [popper]="newActionPopover" [popperShowOnStart]="false" [popperTrigger]="'click'"
    [popperHideOnClickOutside]="true" [popperHideOnScroll]="true" [popperApplyClass]="'context-menu'" [popperPlacement]="'bottom-end'">
    <img src="../../../assets/images/icons/plus.svg" alt="Add"> New
  </button>
</dam-actions-header>

<popper-content #newActionPopover>
  <ul>
    <li (click)="showModal('create-folder')">
      <div class="menu-item">
        <img class="icon" src="./../../../assets/images/icons/folder-create.svg" alt="New Folder">New Folder...
      </div>
    </li>
    <li>
      <dam-file-upload-dialog-launch (onFileUpload)="uploadFile($event);newActionPopover.hide()"></dam-file-upload-dialog-launch>
    </li>
    <li>
      <dam-folder-upload-dialog-launch (onFileUpload)="uploadFile($event);newActionPopover.hide()"></dam-folder-upload-dialog-launch>
    </li>
  </ul>
</popper-content>

<router-outlet></router-outlet>