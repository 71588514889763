import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { ModalService } from 'src/app/services'
import { environment } from '../../../environments/environment'
import { getMetadataBase } from 'src/app/common/helpers'
import { Project, Folder } from '@app/models'

@Component({
	selector: 'dam-wf-projects',
	templateUrl: './wf-projects.component.html',
	styleUrls: ['./wf-projects.component.css'],
	providers: [ModalService],
})
export class WFProjectsComponent implements OnInit {
	wfUrl = environment.workfrontSiteUrl + '/project/view?ID='

	projects: Project[]
	damProjects: Folder[]

	@Input('projects')
	set setProjects(value: Project[]) {
		this.projects = value
		if (this.damProjects && this.damProjects.length) this.verifyCreatedProjects()
	}

	@Input('damProjects')
	set setDamProjects(value: Folder[]) {
		this.damProjects = value
		if (this.projects && this.projects.length) this.verifyCreatedProjects()
	}

	@Output()
	onAddProject: EventEmitter<Project> = new EventEmitter()

	@Output()
	onSyncProject: EventEmitter<Project> = new EventEmitter()

	@Output()
	onLinkProject: EventEmitter<Project> = new EventEmitter()

	@Output()
	onGenerateStorageProjectsFile: EventEmitter<Project> = new EventEmitter()

	@Output()
	onRefreshProjects: EventEmitter<any> = new EventEmitter()

	searchFilter: string

	constructor() {}

	ngOnInit() {}

	getMetadata(project, key) {
		return getMetadataBase(project.metadata, key)
	}

	onSearch() {}

	onSearchInputChange($event) {
		this.searchFilter = $event
	}

	workfrontSignOut() {
		sessionStorage.removeItem('wfusr')
		sessionStorage.removeItem('wfpass')
		window.location.reload()
	}

	verifyCreatedProjects() {
		Array.prototype.forEach.call(this.projects, project => {
			project.isCreated = this.isCreated(project)
		})
	}

	isCreated(project) {
		return (
			this.damProjects &&
			this.damProjects.some(
				proj =>
					proj.property &&
					proj.property.find(x => x.name.toLowerCase() == environment.metadata.workfrontId.name.toLowerCase()).value ==
						project.workfront_id,
			)
		)
	}

	linkProject(project) {
		const metadataDamProject = this.damProjects.find(t => {
			return (
				t.property &&
				t.property.some(
					p => p.name.toLowerCase() === environment.metadata.workfrontId.name.toLowerCase() && p.value === project.workfront_id,
				)
			)
		})
		this.onLinkProject.emit({
			...project,
			metadata: [...metadataDamProject.property],
		})
	}

	isParentProject(project: Project) {
		const damProject = this.damProjects.find(t => {
			const workfrontId = getMetadataBase(t.property, environment.metadata.workfrontId.name)
			return project.workfront_id === workfrontId
		})

		return this.damProjects.some(t => {
			const parentMetadata = getMetadataBase(t.property, environment.metadata.parentProject.name)
			return parentMetadata === damProject.folder_id
		})
	}

	generateStorageProjectsFile() {
		this.onGenerateStorageProjectsFile.emit()
	}

	refresh() {
		this.onRefreshProjects.emit()
	}
}
