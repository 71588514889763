import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core'
import { SocialAuthService } from 'angularx-social-login'
import { Store } from '@ngrx/store'
import { RootStoreState, RouteActions, UserStoreActions } from '../../root-store'
import { User } from '@app/models'
import { ApiGeeService } from '@app/services/api-gee.service'

@Component({
	selector: 'dam-user',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserComponent implements OnInit {
	@Input()
	user: User

	constructor(public authService: SocialAuthService, private store$: Store<RootStoreState.State>, private apiGeeService: ApiGeeService) {}

	ngOnInit() {
		this.authService.authState.subscribe(user => {
			if (user !== null) {
				this.apiGeeService.googleAuthToken = user.authToken
				this.apiGeeService.userEmail = user.email
				this.apiGeeService.idToken = user.idToken
				this.apiGeeService.authenticate().subscribe(t => {
					this.store$.dispatch(new UserStoreActions.LoginSuccessAction({ user: { ...user, accessToken: t.access_token } }))
				})
			}
		})
	}

	signOut() {
		this.authService
			.signOut()
			.then(t => {
				this.store$.dispatch(new UserStoreActions.LogoutSuccessAction({ user: this.user }))
				this.store$.dispatch(new RouteActions.Go({ path: ['login'] }))
			})
			.catch(err => {
				this.store$.dispatch(new RouteActions.Go({ path: ['login'] }))
			})
	}
}
