import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core'
import { ModalService } from 'src/app/services'
import { environment } from '../../../environments/environment'
import { Folder, SyncFolder } from '@app/models'

@Component({
	selector: 'dam-wf-sync-modal',
	templateUrl: './wf-sync-modal.component.html',
	styleUrls: ['./wf-sync-modal.component.css'],
	providers: [ModalService],
})
export class WFSyncModal implements OnInit {
	wfUrl = environment.workfrontSiteUrl + '/project/view?ID='

	syncCompleted: boolean = false

	@Output()
	onSubmit: EventEmitter<any> = new EventEmitter()

	@Output()
	onCancel: EventEmitter<any> = new EventEmitter()

	@Input()
	isLoading: boolean

	outdatedPorjects: SyncFolder[]
	@Input('outdatedPorjects')
	set projects(value: SyncFolder[]) {
		this.outdatedPorjects = value

		this.syncCompleted = value.every(t => t.syncCompleted)
	}

	constructor() {}

	ngOnInit() {}

	getID(project) {
		return project.property.find(x => x.name == environment.metadata.workfrontId.name).value
	}

	submit(project) {
		this.onSubmit.emit([project])
	}

	submitAll() {
		this.onSubmit.emit(this.outdatedPorjects)
	}

	cancel() {
		this.onCancel.emit()
	}

	getReferenceNumber(project) {
		return project.property.find(x => x.name == environment.metadata.referenceNumber.name).value
	}
}
