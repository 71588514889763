import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core'

@Component({
	selector: 'dam-actions-header',
	templateUrl: './actions-header.component.html',
	styleUrls: ['./actions-header.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsHeaderComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
