import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { RootStoreState, RepositoryStoreActions } from '@app/root-store'
import { Observable } from 'rxjs'
import { Permission, UserPermissions, User, RepositoryWrapper } from '@app/models'
import { map, shareReplay, startWith, switchMap, filter } from 'rxjs/operators'
import { FormControl } from '@angular/forms'
import { RepositoryService } from '@app/services'
import { environment } from '@environment/environment'
import { Actions, ofType } from '@ngrx/effects'
import { ActivatedRoute } from '@angular/router'

@Component({
	selector: 'dam-repository-manage',
	templateUrl: './repository-manage.component.html',
	styleUrls: ['./repository-manage.component.css'],
})
export class RepositoryManageComponent implements OnInit {
	repo$: Observable<RepositoryWrapper>
	repo: RepositoryWrapper
	admins$: Observable<Permission[]>
	fullAccess$: Observable<Permission[]>
	viewers$: Observable<Permission[]>
	editors$: Observable<Permission[]>
	isLoading$: Observable<boolean>
	control = new FormControl()
	filteredUsers$: Observable<User[]>
	selectedUser: User
	newUsers: Map<string, Permission> = new Map()
	newUserPermissions: string = UserPermissions.editors
	addUserVisible: boolean = false

	constructor(
		private route: ActivatedRoute,
		private store$: Store<RootStoreState.State>,
		private repositoryService: RepositoryService,
		private actions$: Actions,
	) {}

	ngOnInit(): void {
		this.repo$ = this.store$
			.select(t => t.repository)
			.pipe(
				filter(t => t.repo !== null),
				shareReplay({ refCount: false, bufferSize: 1 }),
			)

		this.admins$ = this.repo$.pipe(map(t => t.repo.permissions.filter(p => p.permission_type === UserPermissions.admins)))
		this.fullAccess$ = this.repo$.pipe(map(t => t.repo.permissions.filter(p => p.permission_type === UserPermissions.fullAccess)))
		this.viewers$ = this.repo$.pipe(map(t => t.repo.permissions.filter(p => p.permission_type === UserPermissions.viewers)))
		this.editors$ = this.repo$.pipe(map(t => t.repo.permissions.filter(p => p.permission_type === UserPermissions.editors)))

		this.isLoading$ = this.store$.select(t => t.repository.isBusy)

		this.filteredUsers$ = this.control.valueChanges.pipe(
			startWith(''),
			switchMap(t => this.repositoryService.lookup(t, environment.projectRepositoryId)),
		)

		this.repo$.subscribe(t => {
			this.repo = t
		})

		this.actions$
			.pipe(
				ofType(RepositoryStoreActions.ActionTypes.UPDATE_REPO_SUCCESS),
				map(t => {
					this.newUsers = new Map()
					//	this.store$.dispatch(new RepositoryStoreActions.RepoRequestAction())
				}),
			)
			.subscribe()

		this.route.queryParams.subscribe(params => {
			this.addUserVisible = params['manage']
		})
	}

	add() {
		const userPer = {
			permission_type: this.newUserPermissions,
			user_display_name: this.selectedUser.name,
			user_email: this.selectedUser.email,
			user_type: 'user',
			user_action: 'new',
		} as Permission

		if (this.newUsers.has(userPer.user_email)) {
			this.newUsers.delete(userPer.user_email)
		}

		this.newUsers.set(userPer.user_email, userPer)
	}

	remove(user: User) {
		this.newUsers.delete(user.email)
	}

	save() {
		const users = Array.from(this.newUsers.values())

		const newRepo = {
			...this.repo,
			repo: {
				...this.repo.repo,
				permissions: [...this.repo.repo.permissions, ...users],
			},
		}
		this.store$.dispatch(new RepositoryStoreActions.UpdateRequestAction(newRepo))
	}

	displayFn(user: User): string {
		return user && user.email ? user.email : ''
	}
}
