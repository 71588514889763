import { Injectable } from '@angular/core'
import { FolderService } from './folder.service'
import { environment } from '../../environments/environment'
import { of, Observable } from 'rxjs'
import { mergeMap, switchMap, map, filter, delay } from 'rxjs/operators'
import { HttpClient, HttpEventType } from '@angular/common/http'
import { FileService } from './file.service'
import { RepositoryService } from './repository.service'
import {
	Project,
	FolderCreate,
	FolderActionResult,
	Metadata,
	FileUploadStep1,
	FileUploadStep3,
	RepositorySchema,
	FileUpdate,
	Folder,
	ProjectActionResult,
	FileDeleteMulti,
} from '@app/models'
import { RequestCacheService } from './request-cache.service'

@Injectable({
	providedIn: 'root',
})
export class ProjectService {
	constructor(
		private http: HttpClient,
		private folderService: FolderService,
		private fileService: FileService,
		private repositoryService: RepositoryService,
		private cacheService: RequestCacheService,
	) {}

	create(project: Project, folder: Folder) {
		// invalidate cache
		const cacheKey = `${environment.projectRepositoryId}-`
		this.cacheService.expire(cacheKey)

		const projFolder: FolderCreate = {
			folder_name: project.name,
			repo_id: environment.projectRepositoryId,
		}
		let obs: Observable<ProjectActionResult>
		const uploadMetadataFileOperator = mergeMap((rootFolder: FolderActionResult) => {
			return this.uploadMetadataFile(rootFolder.folder.repo_id, rootFolder.folder.folder_id, project.metadata).pipe(
				filter(t => t.status && (t.status === 'Active' || t.status === 'success')),
				map(r => {
					return { rootFolder, metadataFile: r }
				}),
			)
		})

		if (folder) {
			const payload: FolderActionResult = {
				folder: {
					createdBy: folder.createdBy,
					createdOn: folder.createdOn,
					disp_sequence: '',
					folder_id: folder.folder_id,
					name: folder.name,
					parent_id: folder.parent_id,
					repo_id: folder.repo_id,
					status: folder.status,
				},
			}
			obs = of(payload).pipe(delay(1000), uploadMetadataFileOperator)
		} else {
			obs = this.folderService.create(projFolder).pipe(delay(1000), uploadMetadataFileOperator)
		}

		return obs
	}

	uploadMetadataFile(repositoryId: string, folderId: string, metadata: Array<Metadata>, progress: boolean = false) {
		// check if metadata file exists
		return this.folderService.getFolder(repositoryId, folderId, false).pipe(
			switchMap(currentFolder => {
				const metadataIndex = currentFolder.files && currentFolder.files.findIndex(i => i.name === environment.metadataFileName)
				if (metadataIndex !== undefined && metadataIndex !== -1) {
					if (currentFolder.files[metadataIndex].status === 'Active') {
						return of(currentFolder.files[metadataIndex])
					} else {
						// if project state isn't Active, delete it
						const payload = {
							files: [{ file_id: currentFolder.files[metadataIndex].file_id }],
							folders: [],
							repo_id: environment.projectRepositoryId,
						} as FileDeleteMulti

						this.fileService.delete(payload, currentFolder.folder_id).subscribe(t => {})
					}
				}

				return this.getMetadataConfigFile().pipe(
					switchMap(file => {
						/**
						 * Get repository metadata schema
						 */
						return this.repositoryService.getMetadataSchema(repositoryId).pipe(
							map(schema => this.updateMetadataUniqueIdFromSchema(schema, metadata)),
							switchMap(repoMetadata => {
								const step1Model = {
									file_description: '',
									file_name: environment.metadataFileName,
									// file_permissions: [],
									file_properties: repoMetadata,
									folder_id: folderId,
									repo_id: repositoryId,
								} as FileUploadStep1

								/**
								 * UPLOAD STEP 1
								 */
								return this.fileService.uploadStep1(step1Model, false).pipe(
									switchMap(resultStep1 => {
										/**
										 * UPLOAD STEP 2
										 */

										return this.fileService
											.uploadStep2(resultStep1.upload_url, new File([file], environment.metadataFileName), progress)
											.pipe(
												mergeMap(resultStep2 => {
													if (resultStep2.type === HttpEventType.Response) {
														const step3Model = {
															bucket_name: resultStep1.bucket_name,
															file_description: '',
															file_id: resultStep1.file_id,
															file_name: environment.metadataFileName,
															// file_permissions: [],
															file_properties: repoMetadata,
															folder_id: step1Model.folder_id,
															object_name: resultStep1.object_name,
															repo_drive_id: resultStep1.repo_drive_id,
															repo_id: step1Model.repo_id,
															// is_sync: true,
														} as FileUploadStep3

														/**
														 * UPLOAD STEP 3
														 */

														return this.fileService.uploadStep3(step3Model, false).pipe(
															map((t: any) => {
																return {
																	...t,
																	file_id: step3Model.file_id,
																}
															}),
														)
													} else {
														return of({ status: 'Uploading' })
													}
												}),
											)
									}),
								)
							}),
						)
					}),
				)
			}),
		)
	}

	getMetadataConfigFile() {
		return this.http.get(`${environment.metadataFilePath}${environment.metadataFileName}`, { responseType: 'blob' })
	}

	getMetadata(repositoryId: string, folderId: string) {
		this.folderService.findProject(repositoryId, folderId).pipe(switchMap(t => this.folderService.getFolder(repositoryId, t.folder_id)))
	}

	updateMetadataUniqueIdFromSchema(repoSchema: Array<RepositorySchema>, metadata: Array<Metadata>): Array<Metadata> {
		return metadata.map(t => {
			let m = { ...t }
			const schema = repoSchema.find(s => s.field_name.toLowerCase() === m.name.toLowerCase())
			if (schema) {
				m.name = schema.field_name
				m.metadata_id = schema.unique_id
			}
			return m
		})
	}

	updateProjectMetadata(project: FileUpdate) {
		return this.http.put(`${environment.apiBaseUrl}${environment.fileMetadataUpdateApiUrl}?key=${environment.apiKey}`, project)
	}
}
