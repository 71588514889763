import { MemoizedSelector, createSelector } from '@ngrx/store'
import { FolderStoreSelectors } from './folder-store'
import { UserStoreSelectors } from './user-store'
import { SearchStoreSelectors } from './search-store'
import { UploadStoreSelectors } from './upload-store'

export const selectError: MemoizedSelector<object, string> = createSelector(
	FolderStoreSelectors.selectError,
	UserStoreSelectors.selectUserError,
	(error: string) => error,
)

export const selectIsLoading: MemoizedSelector<object, boolean> = createSelector(
	FolderStoreSelectors.selectIsLoading,
	SearchStoreSelectors.selectIsLoading,
	UploadStoreSelectors.selectIsLoading,
	(folderIsLoading: boolean, searchIsLoading: boolean, uploadIsLoading: boolean) => folderIsLoading || searchIsLoading || uploadIsLoading,
)
