import { Injectable } from '@angular/core'
import { Breadcrumb, FileUpload } from '@app/models'
import { environment } from '@environment/environment'
import { Observable, of } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { FolderService } from '.'

@Injectable({
	providedIn: 'root',
})
export class ProcessFileService {
	constructor(private folderService: FolderService) {}

	async makedirFromDrag(entries) {
		// const systems = entries.map(entry => this.traverse(entry, []))

		let fs = []
		let calls = []
		for (let e of entries) {
			calls.push(this.traverse(e, fs))
		}

		await Promise.all(calls)
		return fs
	}

	async traverse(entry, fs) {
		if (entry.isDirectory) {
			let dirReader = entry.createReader()

			await new Promise<void>((resolve, reject) => {
				dirReader.readEntries(async entries => {
					for (let e of entries) {
						await this.traverse(e, fs)
					}
					resolve()
				}, reject)
			})
		} else if (entry.isFile && !entry.name.startsWith('.')) {
			await new Promise<void>((resolve, reject) => {
				entry.file(file => {
					let data = {
						data: file,
						path: entry.fullPath,
					}

					fs.push(data)
					resolve()
				}, reject)
			})
		}
		return fs
	}

	async backtraverse(entry, fs) {
		if (entry.isDirectory) {
			fs[entry.name] = {}
			let dirReader = entry.createReader()

			await new Promise<void>((resolve, reject) => {
				dirReader.readEntries(async entries => {
					for (let e of entries) {
						await this.traverse(e, fs[entry.name])
					}
					resolve()
				}, reject)
			})
		} else if (entry.isFile && !entry.name.startsWith('.')) {
			await new Promise<void>((resolve, reject) => {
				entry.file(file => {
					fs[entry.name] = file
					resolve()
				}, reject)
			})
		}
		return fs
	}

	makedirFromInput(entries) {
		if (!entries.length) {
			return []
		}

		let result = {}
		for (let entry of entries) {
			if (entry.name.startsWith('.')) {
				continue
			}
			let path = entry.webkitRelativePath.split('/')
			if (path.length > 1) {
				this.traverseInput(path.reverse(), entry, result)
			} else {
				result[entry.name] = new File([entry], entry.name)
			}
		}

		return [result]
	}

	traverseInput(entryPath: any[], file, fs) {
		if (entryPath.length === 1) {
			let path = entryPath.pop()
			fs[path] = new File([file], file.name)
		} else {
			let path = entryPath.pop()
			fs[path] = fs[path] || {}
			this.traverseInput(entryPath, file, fs[path])
		}
	}

	makeUploadEntryFromDrag(
		entries: any[],
		currentbreadcrumb: Breadcrumb[],
		folder_id: string,
		insideFolder: boolean,
	): Observable<FileUpload[]> {
		let result = []
		let breadcrumbObs = insideFolder ? this.buildUploadBreadcrumb(folder_id) : of([...currentbreadcrumb])

		return breadcrumbObs.pipe(
			map(resp => {
				const data = Array.isArray(resp) ? resp : resp?.children?.data
				let breadcrumb = data.filter(b => b.level != '0')?.sort((a, b) => (+a.level > +b.level ? 1 : -1))

				// breadcrumb.shift() // remove repository from list
				let currentFolderTree = breadcrumb.map((t, index) => {
					return {
						folder_name: t.label || t.folder_name,
						folder_level: `${index}`,
					}
				})

				for (let entry of entries) {
					if (entry.data.name.startsWith('.')) {
						continue
					}

					let entryPath: string[] = entry.path.split('/').filter(t => !!t)
					entryPath.pop() // remove filename from list

					let entryFolderTree = entryPath.map((t, index) => {
						return {
							folder_name: t,
							folder_level: `${index + currentFolderTree.length}`,
						}
					})

					result.push({
						data: entry.data,
						location: entryFolderTree.length || insideFolder ? 'subFolder' : 'root',
						folder_id: folder_id,
						folder_upload_level: currentFolderTree.length,
						folder_tree: [...currentFolderTree, ...entryFolderTree],
					} as FileUpload)
				}

				return result
			}),
		)
	}

	makeUploadEntryFromInput(entries: any[], currentbreadcrumb: Breadcrumb[], folder_id: string): FileUpload[] {
		let result = []
		let breadcrumb = [...currentbreadcrumb]
		breadcrumb.shift() // remove repository from list
		let currentFolderTree = breadcrumb.map((t, index) => {
			return {
				folder_name: t.label,
				folder_level: `${index}`,
			}
		})

		for (let entry of entries) {
			if (entry.name.startsWith('.')) {
				continue
			}

			let entryPath: string[] = entry.webkitRelativePath.split('/')
			entryPath.pop() // remove filename from list

			let entryFolderTree = entryPath.map((t, index) => {
				return {
					folder_name: t,
					folder_level: `${index + currentFolderTree.length}`,
				}
			})

			result.push({
				data: new File([entry], entry.name),
				location: entryFolderTree.length ? 'subFolder' : 'root',
				folder_id: folder_id,
				folder_upload_level: currentFolderTree.length,
				folder_tree: [...currentFolderTree, ...entryFolderTree],
			} as FileUpload)
		}

		return result
	}

	buildUploadBreadcrumb(folderId: string) {
		return this.folderService.getBreadcrumb(environment.projectRepositoryId, folderId)
	}
}
