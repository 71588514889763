import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core'

@Component({
	selector: 'dam-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
	constructor() {}

	ngOnInit() {}

	@Output()
	onSearchInputChange: EventEmitter<string> = new EventEmitter()

	@Output()
	onSearchInputError: EventEmitter<void> = new EventEmitter()

	@Output()
	onSearch: EventEmitter<void> = new EventEmitter()

	@Output()
	onClearSearch: EventEmitter<void> = new EventEmitter()

	@Input()
	isSearchEnabled: boolean

	onHeaderSearch() {
		this.onSearch.emit()
	}

	onHeaderSearchInputChange($event) {
		this.onSearchInputChange.emit($event)
	}

	onHeaderClearSearch() {
		this.onClearSearch.emit()
	}

	onHeaderSearchInputError() {
		this.onSearchInputError.emit()
	}
}
