import { State } from './state'
import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store'
import { mapSearchFileResultToFile, mapSearchFoldersResultToFolder } from '../../common/helpers'
import { SearchCriteria, SearchFolderCriteria, DamFile, Folder } from '@app/models'

export const getError = (state: State): any => state.error
export const getIsLoading = (state: State): boolean => state.isLoading
export const getSearchCriteria = (state: State): SearchCriteria => state.searchCriteria
export const getFolderSearchCriteria = (state: State): SearchFolderCriteria => state.searchFolderCriteria
export const getIsSearchActive = (state: State): boolean => state.isSearchFilesActive || state.isSearchFolderActive
export const getFilterMyProject = (state: State): boolean => state.filterMyProjects
export const getFilterActiveProject = (state: State): boolean => state.filterActiveProjects
export const getFilterLoS = (state: State): string => state.filterLoS
export const getFilterBusinessUnit = (state: State): string => state.filterBusinessUnit
export const getSearchFiles = (state: State): Array<DamFile> => mapSearchFileResultToFile(state.files)
export const getSearchFolders = (state: State): Array<Folder> => mapSearchFoldersResultToFolder(state.folders)
export const getSearchProjects = (state: State) => state.projects

export const selectSearchState: MemoizedSelector<object, State> = createFeatureSelector<State>('search')

// export const selectSearchMyProjects = createSelector(
//     selectSearchState,
//     selectUser,
//     (state, user) => {
//         if (!state.filterMyProjects) {
//             return state.projects;
//         }

//         return state.projects.filter(t => {
//             const ownersJSON = t.property.find(p => p.name === environment.metadataProjectUserFieldName);
//             if (ownersJSON) {
//                 const owners = JSON.parse(ownersJSON.value || '');
//                 return owners ? owners.some(u => u == user.email) : false;
//             }
//             return false;
//         });
//     });
export const isSearchEnabled = createSelector(selectSearchState, (state): boolean => state.enabled)
export const selectSearchFiles = createSelector(selectSearchState, getSearchFiles)
export const selectSearchFolders = createSelector(selectSearchState, getSearchFolders)
export const isSearchVisible = createSelector(selectSearchState, (state): boolean => !!state.files.length)
export const selectSearchCriteria = createSelector(selectSearchState, getSearchCriteria)
export const selectFolderSearchCriteria = createSelector(selectSearchState, getFolderSearchCriteria)
export const selectMyProjectsFilter = createSelector(selectSearchState, getFilterMyProject)
export const selectActiveProjectsFilter = createSelector(selectSearchState, getFilterActiveProject)
export const selectFilterLoS = createSelector(selectSearchState, getFilterLoS)
export const selectFilterBusinessUnit = createSelector(selectSearchState, getFilterBusinessUnit)
export const isSearchActive = createSelector(selectSearchState, getIsSearchActive)
export const selectError = createSelector(selectSearchState, getError)
export const selectIsLoading = createSelector(selectSearchState, getIsLoading)
export const selectAllProjects = createSelector(selectSearchState, getSearchProjects)
export const selectProjectFromSearch = (folderId: string, name: string) =>
	createSelector(selectSearchState, state => {
		const searchFile = state.projects.find(t => t.folder_id === folderId)
		return searchFile ? { ...searchFile, name: name } : {}
	})
