import { Injectable } from '@angular/core'
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects'
import { Observable, from, of, throwError } from 'rxjs'
import { Action, Store, select } from '@ngrx/store'
import { switchMap, catchError, withLatestFrom, mergeMap, map, filter, delay, concatMap } from 'rxjs/operators'
import { WorkfrontService } from '../../services/workfront.service'
import { ProjectService, FolderService, FileService } from '../../services'
import { environment } from '../../../environments/environment'
import { FolderActions } from '../folder-store/actions'
import { LayoutStoreActions } from '../layout-store'
import { RootStoreState } from './../'
import { SearchStoreActions } from './../search-store'
import * as featureActions from './actions'
import * as featureSelector from './selectors'
import { Project, FolderBase, Folder, UpdateStorageProject, ProjectMetadata, FileDeleteMulti } from '@app/models'
import { selectUser } from '@app/root-store/user-store/selectors'

@Injectable()
export class WorkfrontStoreEffects {
	constructor(
		private workfrontService: WorkfrontService,
		private projectService: ProjectService,
		private folderService: FolderService,
		private fileService: FileService,
		private actions$: Actions,
		private store$: Store<RootStoreState.State>,
	) {}

	noopAction: Action = { type: '[Workfront] NoopAction' }

	@Effect()
	loadRequestEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.LoadWorkfrontProjects>(featureActions.ActionTypes.LOAD_WF_PROJECTS),
		mergeMap(action =>
			this.workfrontService.getProjects(action.payload.email, action.payload.password).pipe(
				switchMap(projects => [
					new SearchStoreActions.SetFilterActiveProjectsAction({ value: false }),
					new featureActions.LoadWorkfrontProjectsSuccess({ data: projects }),
					new FolderActions.LoadProjectFromStorageRequestAction({ setFolders: true, disablePagination: true }),
					//new FolderActions.LoadRequestAction({ repositoryId: environment.projectRepositoryId, folderId: null }),
				]),
				catchError(error => [
					new LayoutStoreActions.ShowAlertToast({
						message: `An error has occurred loading Workfront projects: ${error.statusText}`,
					}),
					new featureActions.LoadWorkfrontProjectsFailure({ error }),
				]),
			),
		),
	)

	createProjectEffect$: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType<featureActions.CreateProjectRequestAction>(featureActions.ActionTypes.CREATE_PROJECT_REQUEST),
			mergeMap(action => {
				return this.folderService.getProjectsByNameFromStorage([action.payload.project.name]).pipe(
					// return this.folderService.getFolderAux(environment.projectRepositoryId).pipe(
					// map(t => t.folders),
					switchMap((folders: any) => {
						console.log('project from storage', folders)
						let index = folders.findIndex(x => x.name == action.payload.project.name)
						let folder = index != -1 ? folders[index] : null

						return this.projectService.create(action.payload.project, folder).pipe(
							mergeMap(project => {
								const projectMetadata = {
									metadataFileId: project.metadataFile.file_id,
									projectId: project.rootFolder.folder.folder_id,
									metadata: action.payload.project.metadata,
								} as ProjectMetadata

								return [
									// new featureActions.CreateProjectSuccessAction({
									// 	folder: project.rootFolder,
									// 	wf_id: action.payload.project.workfront_id,
									// }),
									new featureActions.AddStorageProjectAction({
										folder: project.rootFolder.folder,
										wf_id: action.payload.project.workfront_id,
									}),
									// new LayoutStoreActions.ShowAlertToast({ message: 'Project created' }),
									new FolderActions.CreateSuccessAction({ folder: project.rootFolder }),
									new FolderActions.LoadProjectMetadataSuccessAction({ data: projectMetadata }),
								]
							}),
							catchError(error => [
								new LayoutStoreActions.ShowAlertToast({
									message: `An error has occurred while creating the project : ${error.statusText}`,
								}),
								new featureActions.CreateProjectFailureAction({ error }),
							]),
						)
					}),
					catchError(error => [
						new featureActions.GenerateStorageProjectsFileFailureAction(error),
						new LayoutStoreActions.ShowAlertToast({
							message: 'An error has occurred during the validation process',
						}),
					]),
				)
			}),
		),
	)

	// @Effect()
	// syncProjectsRequestEffect$: Observable<Action> = this.actions$.pipe(
	// 	ofType<featureActions.SyncProjectsRequestAction>(featureActions.ActionTypes.SYNC_PROJECTS_REQUEST),
	// 	withLatestFrom(this.store$.pipe(select(featureSelector.getUserInfo))),
	// 	mergeMap(([action, user]) => {
	// 		return this.workfrontService.getProjectsByID(user.user, user.password, action.payload.fileProjects).pipe(
	// 			mergeMap((projects: Project[]) =>
	// 				from(projects).pipe(
	// 					concatMap(p => of(p).pipe(delay(1000))),
	// 					mergeMap((project: Project) => {
	// 						let fileProject = action.payload.fileProjects.find(
	// 							x =>
	// 								x.property.find(x => x.name == environment.metadata.workfrontId.name).value == project.workfront_id,
	// 						);
	// 						return this.fileService.getFileDict(fileProject.metadataFileId).pipe(
	// 							map(dict => dict.file.property),
	// 							mergeMap(schema => {
	// 								const fileUpdate = this.workfrontService.createFileUpdate(
	// 									fileProject.metadataFileId,
	// 									schema,
	// 									project,
	// 								);

	// 								const folder: FolderBase = {
	// 									name: fileProject.name,
	// 									folder_id: fileProject.folder_id,
	// 									repo_id: fileProject.repo_id,
	// 								};

	// 								this.store$.dispatch(new featureActions.UpdateProjectNameRequestAction({ folder }));
	// 								return of(fileUpdate);
	// 							}),
	// 						);
	// 					}),
	// 					toArray(),
	// 					mergeMap(filesUpdate => {
	// 						return [new featureActions.UpdateMetadataProjectRequestAction({ filesUpdate })];
	// 					}),
	// 					catchError(error => [
	// 						new LayoutStoreActions.ShowAlertToast({
	// 							message: 'An error has occurred while synchronizing Workfront projects',
	// 						}),
	// 						new featureActions.LoadWorkfrontProjectsFailure({ error }),
	// 					]),
	// 				),
	// 			),
	// 		);
	// 	}),
	// );

	@Effect()
	syncProjectsRequestEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.SyncProjectsRequestAction>(featureActions.ActionTypes.SYNC_PROJECTS_REQUEST),
		mergeMap(action => {
			return from(action.payload.projects).pipe(
				concatMap(p => of(p).pipe(delay(1000))),
				switchMap(project => of(new featureActions.SyncProjectRequestAction({ project }))),
			)
		}),
	)

	@Effect()
	syncProjectRequestEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.SyncProjectRequestAction>(featureActions.ActionTypes.SYNC_PROJECT_REQUEST),
		withLatestFrom(this.store$.pipe(select(featureSelector.getUserInfo))),
		mergeMap(([action, user]) => {
			return this.workfrontService.getProjectsByID(user.user, user.password, [action.payload.project]).pipe(
				switchMap((projects: Project[]) => {
					const fileProject = action.payload.project
					const wfProject = projects.length ? projects[0] : null

					return this.fileService.getFileDict(fileProject.metadataFileId).pipe(
						switchMap(dict => {
							if (dict.status === 'failed' || dict.file.status === 'Inactive') {
								return throwError(dict.error_msg)
							}

							const schema = dict.file.property
							const fileUpdate = this.workfrontService.createFileUpdate(fileProject.metadataFileId, schema, wfProject)

							const folder: FolderBase = {
								// name: fileProject.name,
								name: wfProject?.name || fileProject.name,
								folder_id: fileProject.folder_id,
								repo_id: fileProject.repo_id,
							}

							const updateProject = {
								...action.payload.project,
								name: wfProject?.name || fileProject.name,
							}

							return [
								new featureActions.UpdateProjectNameRequestAction({ folder }),
								new featureActions.UpdateMetadataProjectRequestAction({ project: updateProject, fileUpdate }),
							]
						}),
						catchError(error => {
							const payload = {
								...action.payload.project,
								error: {},
							}

							// err2 el id de metadata esta mal o el arhivo no existe
							if (error.status) {
								// SDM api ERROR
								payload.error = {
									code: '600',
									message: error.message,
								}
								return of(new featureActions.SyncProjectFailureAction({ project: payload }))
							}

							payload.error = {
								...action.payload.project.error,
								code: '2',
								message: error,
							}

							if (action.payload.project?.error?.count) {
								return of(new featureActions.SyncProjectFailureAction({ project: payload }))
							}
							return of(new featureActions.SyncProjectFixMetadataRequestAction({ project: payload }))
						}),
					)
				}),
				catchError(error => {
					const payload = {
						...action.payload.project,
						error: {
							code: '500', // server crash,
							message: error.statusText,
						},
					}

					if (error.status === 422) {
						// err1 problema con el wfID no se encontro el proyecto

						payload.error = {
							code: '1',
							message: error.statusText,
						}
					}

					return of(new featureActions.SyncProjectFailureAction({ project: payload }))
				}),
			)
		}),
	)

	@Effect()
	syncInvalidMetadataFileEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.SyncProjectFixMetadataRequestAction>(featureActions.ActionTypes.SYNC_PROJECT_FIX_METADATA_REQUEST),
		filter(action => action.payload.project.error.code === '2'),
		mergeMap(action => {
			return this.folderService.getFolder(action.payload.project.repo_id, action.payload.project.folder_id, false).pipe(
				switchMap(folder => {
					if (action.payload.project?.error?.count) {
						return of(new featureActions.SyncProjectFailureAction({ project: action.payload.project }))
					}

					const metadataFile = folder.files && folder.files.filter(t => t.name == environment.metadataFileName)
					//
					// upload metadata file
					//
					if (!metadataFile || !metadataFile.length) {
						return this.projectService
							.uploadMetadataFile(action.payload.project.repo_id, action.payload.project.folder_id, action.payload.project.property, true)
							.pipe(
								filter(t => t.status && t.status === 'Active'),
								mergeMap((result: any) => {
									const newProject = action.payload.project
									newProject.metadataFileId = result.file_id

									this.store$.dispatch(new featureActions.SyncProjectRequestAction({ project: newProject }))
									return of(this.noopAction)
								}),
								catchError(err => {
									action.payload.project.error.code = '2.1'
									return of(new featureActions.SyncProjectFailureAction({ project: action.payload.project }))
								}),
							)
					}
					//
					// multiple files, delete duplicated metadata files
					//
					else if (metadataFile.length > 1) {
						const filesDelete = metadataFile.map(file => {
							return {
								file_id: file.file_id,
								// file_name: file.name
							}
						})

						const currentMetadataFile = filesDelete.pop()

						const payload = {
							files: filesDelete,
							repo_id: environment.projectRepositoryId,
						} as FileDeleteMulti

						return this.fileService.delete(payload, action.payload.project.folder_id).pipe(
							switchMap(result => {
								const newProject = action.payload.project
								newProject.metadataFileId = currentMetadataFile.file_id

								// return of(new featureActions.SyncProjectRequestAction({ project: newProject }))
								this.store$.dispatch(new featureActions.SyncProjectRequestAction({ project: newProject }))
								return of(this.noopAction)
							}),
						)
					}

					//
					// The metadataId in storage is incorrect, update it from sdm file
					//
					const newProject = action.payload.project
					newProject.metadataFileId = metadataFile[0].file_id

					// return of(new featureActions.SyncProjectRequestAction({ project: newProject }))
					this.store$.dispatch(new featureActions.SyncProjectRequestAction({ project: newProject }))
					return of(this.noopAction)
				}),
			)
		}),
	)

	@Effect()
	updateProjectNameEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.UpdateProjectNameRequestAction>(featureActions.ActionTypes.UPDATE_PROJECT_NAME_REQUEST),
		withLatestFrom(this.store$.pipe(select(selectUser))),
		mergeMap(([action, user]) => {
			return this.folderService.update(action.payload.folder as Folder, user).pipe(
				map(t => new featureActions.UpdateProjectNameSuccessAction()),
				catchError(error => [
					new LayoutStoreActions.ShowAlertToast({ message: 'An error has occurred while while updating the project' }),
					new featureActions.UpdateProjectNameFailureAction(error),
				]),
			)
		}),
	)

	@Effect()
	updateProjectMetadataEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.UpdateMetadataProjectRequestAction>(featureActions.ActionTypes.UPDATE_METADATA_PROJECT_REQUEST),
		mergeMap(action => {
			// from(action.payload.filesUpdate).pipe(
			// mergeMap((fileUpdate: FileUpdate) => {
			return this.projectService.updateProjectMetadata(action.payload.fileUpdate).pipe(
				switchMap((projectFile: any) => {
					let projectName = projectFile.file.folder_path.split('> ').pop()

					const updateStorageProject: UpdateStorageProject = {
						name: action.payload.project?.name || projectName,
						folder_id: projectFile.file.folder_id,
						property: action.payload.fileUpdate.file_properties,
						metadataFileId: action.payload.fileUpdate.file_id,
					}

					return [
						new FolderActions.UpdateFolderMetadata({ data: action.payload.fileUpdate }), // local update
						new featureActions.UpdateStorageProjectsAction({ project: action.payload.project, updateStorageProject }),
					]
				}),
				catchError(error => [
					new featureActions.SyncProjectFailureAction({ project: action.payload.project }),
					new LayoutStoreActions.ShowAlertToast({
						message: 'An error has occurred while while updating the project metadata',
					}),
				]),
			)
		}),
	)

	@Effect()
	updateStorageProjectFileEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.UpdateStorageProjectsAction>(featureActions.ActionTypes.UPDATE_STORAGE_PROJECTS_REQUEST),
		mergeMap(action => {
			return this.workfrontService.updateStorageProjects(action.payload.updateStorageProject).pipe(
				switchMap((t: any[]) => {
					return [
						new LayoutStoreActions.ShowAlertToast({
							message: `Project '${action.payload.updateStorageProject.name}' updated`,
						}),
						// new featureActions.UpdateStorageProjectSuccessAction(),
						new featureActions.SyncProjectSuccessAction({ project: action.payload.project }),
					]
				}),
				catchError(error => [
					// new featureActions.UpdateStorageProjectsFailureAction(error),
					new featureActions.SyncProjectFailureAction({ project: action.payload.project }),
					new LayoutStoreActions.ShowAlertToast({
						message: 'An error has occurred while while updating the project metadata',
					}),
				]),
			)
		}),
	)

	@Effect()
	generateStorageProjectFileEffect$: Observable<Action> = this.actions$.pipe(
		ofType<featureActions.GenerateStorageProjectsFileAction>(featureActions.ActionTypes.GENERATE_STORAGE_PROJECTS_FILE_REQUEST),
		mergeMap(action => {
			return from(this.folderService.getProjectsFolders(environment.projectRepositoryId)).pipe(
				switchMap((folders: any) => {
					return this.workfrontService.generateStorageProjectsFile(folders).pipe(
						switchMap((response: any) => [
							new featureActions.GenerateStorageProjectsFileSuccessAction(),
							new LayoutStoreActions.ShowAlertToast({
								message: 'Project file generated successfully',
							}),
						]),
					)
				}),
				catchError(error => [
					new featureActions.GenerateStorageProjectsFileFailureAction(error),
					new LayoutStoreActions.ShowAlertToast({
						message: 'An error has occurred generating projects',
					}),
				]),
			)
		}),
	)

	addStorageProjectEffect$: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType<featureActions.AddStorageProjectAction>(featureActions.ActionTypes.ADD_STORAGE_PROJECT_REQUEST),
			mergeMap(action => {
				return this.folderService.getFolderAux(action.payload.folder.repo_id, action.payload.folder.folder_id).pipe(
					mergeMap(folder =>
						from(folder.files || []).pipe(
							filter(file => file.name === environment.metadataFileName),
							mergeMap(file => {
								return this.fileService.getFileInfo(file.repo_id, file.file_id).pipe(
									mergeMap(fileInfo => {
										for (let f of fileInfo.file) {
											if (f.unique_id) delete f.unique_id
										}

										const project = {
											...action.payload.folder,
											metadataLoaded: true,
											metadataFileId: file.file_id,
											property: fileInfo.file,
											isEmptyFolder: true,
										}

										return this.workfrontService.addStorageProjects([project]).pipe(
											mergeMap(t => {
												return [
													new featureActions.AddStorageProjectSuccessAction(),
													new LayoutStoreActions.ShowAlertToast({ message: 'Project created' }),
													new featureActions.CreateProjectSuccessAction({
														folder: project.rootFolder,
														wf_id: action.payload.wf_id,
													}),
												]
											}),
											catchError(error => [new featureActions.AddStorageProjectFailureAction(error)]),
										)
									}),
									catchError(error => [new featureActions.AddStorageProjectFailureAction(error)]),
								)
							}),
						),
					),
				)
			}),
		),
	)
}
