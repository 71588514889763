import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http'
import { environment } from '@environment/environment'
import { AuthenticationResponse } from '@app/models/api-gee.model'
import { BehaviorSubject, defer, from, Observable, of } from 'rxjs'
import { catchError, exhaustMap, filter, map, take, tap } from 'rxjs/operators'
import { GoogleLoginProvider, SocialAuthService } from 'angularx-social-login'

@Injectable({
	providedIn: 'root',
})
export class ApiGeeService {
	constructor(private http: HttpClient, private authService: SocialAuthService) {}

	userEmail: string
	googleAuthToken: string
	idToken: string

	private tokenSubject$ = new BehaviorSubject<string | null>(null)

	token$ = this.tokenSubject$.pipe(
		filter(token => token !== null),
		take(1),
	)

	refreshToken$: Observable<any> = defer(() => {
		if (this.tokenSubject$.value === null) {
			return this.token$
		}
		// Defer allows us to easily execute some action when the Observable
		// is subscribed. Here, we set the current token to `null` until the
		// refresh operation is complete. This ensures no requests will be
		// sent with a known bad token.
		this.tokenSubject$.next(null)

		return this.refreshGoogleToken().pipe(
			// exhaustMap(user => this.refreshToken()),
			tap(res => {
				this.tokenSubject$.next(res.authToken)
			}),
			catchError(err => {
				throw err
			}),
		)
	})

	get token(): string | null {
		return this.tokenSubject$.value
	}

	authenticate() {
		this.tokenSubject$.next(this.googleAuthToken)
		return of({ access_token: this.googleAuthToken })

		// return this.refreshToken().pipe(
		// 	tap(token => {
		// 		this.tokenSubject$.next(token.access_token)
		// 	}),
		// )
	}

	refreshGoogleToken() {
		this.authService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID)
		return this.authService.authState.pipe(
			take(1),
			map(user => {
				if (user !== null) {
					this.googleAuthToken = user.authToken
					this.userEmail = user.email
					this.idToken = user.idToken
					return user
				}
				return null
			}),
		)
	}

	// refreshToken(): Observable<AuthenticationResponse> {
	// 	const httpHeaders = new HttpHeaders()
	// 		.append('Authorization', `Bearer ${this.googleAuthToken}`)
	// 		.append('key', environment.apiKey)
	// 		.append('apiKey', environment.apiGeeKey)
	// 		.append('apiKeySecret', environment.apiGeeSecret)

	// 	return this.http.get<AuthenticationResponse>(`${environment.apiGeeAuthentication}${this.userEmail}`, { headers: httpHeaders })
	// }
}
