import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { featureReducer } from './reducer'
import { SearchStoreEffects } from './effects'

@NgModule({
	imports: [CommonModule, StoreModule.forFeature('search', featureReducer), EffectsModule.forFeature([SearchStoreEffects])],
	declarations: [],
})
export class SearchStoreModule {}
