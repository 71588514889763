<header class="modal-header">
  <button class="modal-header-btn modal-back" type="button" alt="Back" *ngIf="parentId" (click)="navigateTo(parentId)"
    mat-button matTooltip="Back">
    <img class="icon" src="./../../../assets/images/icons/chevron-left.svg">
  </button>
  <div class="menu-title-wrapper">
    <h2 class="modal-title">
      {{currentFolder?.label}}
    </h2>
    <!-- TODO: Conditionally display the input and hide the title -->
    <!-- <input *ngIf="false" class="modal-title-input" type="text" placeholder="New Folder"> -->
  </div>
  <button class="modal-header-btn modal-close" type="button" (click)="onCancel.emit()" mat-button matTooltip="Close">
    <img src="../../../assets/images/icons/close.svg" alt="Close">
  </button>
</header>


<div class="modal-moveto-content__loading" *ngIf="loading">
  <dam-loading></dam-loading>
</div>

<div class="modal-moveto-content" *ngIf="!loading">
  
  <div class="folder-empty" *ngIf="modalId && !currentFolder?.children">
    <img class="folder-empty-img" src="../../../assets/images/icons/file.svg">
    <span class="folder-empty-text">This folder does not contain subfolders</span>
  </div>

  <ul>
    <li *ngFor="let folder of currentFolder?.children" class="menu-item nav-item" [matTooltip]="folder.displayError"
      [matTooltipDisabled]="!folder.disabled">
      <button class="menu-item-label" [disabled]="folder.disabled" (click)="navigateTo(folder.data.folder_id)">
        <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#808080">
          <path d="M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z" />
        </svg>
        {{folder.label}}
      </button>
      <img src="./../../../assets/images/icons/chevron-right.svg" alt="">
    </li>
    <li *ngFor="let asset of currentFolder?.files" class="menu-item nav-item">
      <div class="menu-item-label">
        <img class="icon icon--small" [src]="asset?.icon" alt="">{{asset.name}}
      </div>
    </li>
  </ul>
</div>
<footer class="modal-footer">
  <button mat-button title="Move here" (click)="moveTo()" *ngIf="parentId">MOVE HERE
  </button>
</footer>