import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { featureReducer } from './reducer'
import { LayoutStoreEffects } from './effects'

@NgModule({
	imports: [CommonModule, StoreModule.forFeature('layout', featureReducer), EffectsModule.forFeature([LayoutStoreEffects])],
	declarations: [],
})
export class LayoutStoreModule {}
