<header class="modal-header">
  <h2 class="modal-title">
    Duplicate folder
  </h2>
  <button class="modal-header-btn modal-close" type="button" (click)="onCancel.emit()">
    <img src="../../../assets/images/icons/close.svg" alt="Close" title="Close">
  </button>
</header>
<div class="modal-content">
  <form class="new-project-form form" #frm="ngForm" autocomplete="off" novalidate>
    <div class="form-group">
      
      <ng-container *ngFor="let action of folders">"<b>{{action.name}}</b>"</ng-container>, matches an existing item in this folder.

    </div>
    <br>
    <div class="form-actions modal-footer">
      <button class="form-btn modal-close" mat-button type="button" (click)="onCancel.emit()">CANCEL</button>
      <button class="form-btn" type="button" mat-button (click)="onRename.emit()">KEEP SEPARATE</button>
    </div>
  </form>
</div>