import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core'
import { ModalService } from 'src/app/services'
import { takeUntil, filter, map } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { environment } from 'src/environments/environment'

@Component({
	selector: 'dam-wf-link-project-modal',
	templateUrl: './wf-link-project-modal.component.html',
	styleUrls: ['./wf-link-project-modal.component.css'],
})
export class WfLinkProjectModalComponent implements OnInit, OnDestroy {
	@Output()
	onSave: EventEmitter<any> = new EventEmitter()

	searchText: string
	destroy$ = new Subject()
	model: any
	selectedProject: any[]

	constructor(private modalService: ModalService) {}

	ngOnInit() {
		this.modalService
			.getContext()
			.pipe(
				takeUntil(this.destroy$),
				filter(ctx => ctx.model && ctx.model.action.type == 'link-project'),
				map(ctx => ctx.model),
			)
			.subscribe(context => {
				this.model = { ...context.action }
				const parentProject = this.model.project.metadata.find(
					t => t.name.toLowerCase() == environment.metadata.parentProject.name.toLowerCase(),
				)
				if (parentProject && parentProject.value) {
					const parent = this.model.parents.find(t => t.folder_id == parentProject.value)
					parent && (parent.selected = true)
					this.selectedProject = [parent]
				} else {
					this.selectedProject = []
					this.deselectAllProjects(this.model.parents)
				}
			})
	}

	handleSelection(event) {
		if (event.option.selected) {
			event.source.deselectAll()
			event.option._setSelected(true)
			this.selectedProject = [event.option.value]
		} else {
			this.selectedProject = []
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next(true)
	}

	save() {
		this.modalService.close('link-project')
		if (this.model.project.isRootProject) {
			return
		}

		this.onSave.emit(this.selectedProject.length ? this.selectedProject[0] : {})
		this.selectedProject = []
		this.deselectAllProjects(this.model.parents)
	}

	cancel() {
		this.modalService.close('link-project')
		this.selectedProject = []
		this.deselectAllProjects(this.model.parents)
	}

	deselectAllProjects(projects) {
		for (let p of projects) {
			p.selected = false
		}
	}
}
