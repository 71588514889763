import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core'
import { ContextMenuService } from '../../services/context-menu.service'
import { IContextMenuAction } from '../../models/context-menu.model'
import { RootStoreState, FolderStoreSelectors, UserStoreSelectors, SearchStoreSelectors } from 'src/app/root-store'
import { Store } from '../../../../node_modules/@ngrx/store'
import { Observable, Subject } from '../../../../node_modules/rxjs'
import { takeUntil } from 'rxjs/operators'
import { DamFile, Folder, User } from '@app/models'

@Component({
	selector: 'dam-asset-item',
	templateUrl: './asset-item.component.html',
	styleUrls: ['./asset-item.component.css'],
})
export class AssetItemComponent implements OnInit, OnDestroy {
	destroy$ = new Subject()
	model: DamFile
	image: string
	contextMenuActions: IContextMenuAction[] = []
	selectedItems$: Observable<(DamFile | Folder)[]>
	user$: Observable<User>

	fileActiveStatus: string = 'Active'

	@Input()
	layout: 'grid' | 'list' = 'grid'

	@Output()
	onContextAction: EventEmitter<any> = new EventEmitter()

	@Output()
	onSelect: EventEmitter<DamFile> = new EventEmitter()

	@Input('model')
	set setModel(value: DamFile) {
		this.model = value

		if (this.model.fileInfo && this.model.fileInfo.thumbnailLink) {
			this.image = this.model.fileInfo.thumbnailLink
		}
	}

	constructor(private contexMenuService: ContextMenuService, private store$: Store<RootStoreState.State>) {
		this.contextMenuActions = this.contexMenuService.getContextMenu('asset')
		this.store$
			.select(SearchStoreSelectors.isSearchActive)
			.pipe(takeUntil(this.destroy$))
			.subscribe(isSearchActive => {
				this.contextMenuActions = isSearchActive
					? this.contexMenuService.getContextMenu('search-asset')
					: this.contexMenuService.getContextMenu('asset')
			})
	}

	ngOnInit() {
		this.selectedItems$ = this.store$.select(FolderStoreSelectors.selectedItems)
		this.user$ = this.store$.select(UserStoreSelectors.selectUser)
	}

	ngOnDestroy(): void {
		this.destroy$.next(true)
	}

	onClickContextMenu(event) {
		if (!this.model.isBusy) {
			this.onContextAction.emit({ action: event, payload: this.model })
		}
	}

	setSelectedItem() {
		this.onSelect.emit(this.model)
	}

	isVisible(selectedItems, action) {
		return selectedItems.length > 1
			? !selectedItems.every(x => x.file_id) && action.type == 'DownloadAsset'
				? false
				: action.multiple
			: true
	}
}
