import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core'
import { Observable } from 'rxjs'
import { Store } from '@ngrx/store'
import { RootStoreState, LayoutStoreSelectors } from 'src/app/root-store'

@Component({
	selector: 'dam-asset-grid',
	templateUrl: './asset-grid.component.html',
	styleUrls: ['./asset-grid.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetGridComponent implements OnInit {
	view$: Observable<string>

	constructor(private store$: Store<RootStoreState.State>) {
		this.view$ = this.store$.select(LayoutStoreSelectors.selectView)
	}

	ngOnInit() {}
}
