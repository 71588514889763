import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable, forkJoin, of, zip, Subject, combineLatest } from 'rxjs'
import { catchError, map, switchMap, tap, zipAll } from 'rxjs/operators'
import { RequestCacheService } from './request-cache.service'
import { environment } from '../../environments/environment'
import { Folder, FolderDownload } from '@app/models'
import { arrayUpdate } from '@app/common/extensions'

@Injectable({
	providedIn: 'root',
})
export class DownloadFolderService {
	constructor(private http: HttpClient, private cacheService: RequestCacheService) {}
	cacheKey = `donwloadFolders`

	download(folder: Partial<FolderDownload>): Observable<Partial<FolderDownload>> {
		let params = new HttpParams()
			.set('repo_id', folder.repo_id)
			.set('folder_id', folder.folder_id)
			.set('notify_user', String(folder.notify_user || false))

		return this.http
			.get<FolderDownload>(`${environment.downloadApiBaseUrl}${environment.downloadFolderUrl}`, { params })
			.pipe(
				map(t => {
					let sdata = sessionStorage.getItem(this.cacheKey)
					let data = { items: [] }

					if (sdata) {
						data = JSON.parse(sdata)
					}

					let item = { ...folder, ...t, completed: false }
					data.items.push(item)
					sessionStorage.setItem(this.cacheKey, JSON.stringify(data))
					return item
				}),
			)
	}

	load(): Observable<FolderDownload[]> {
		let downloadFolders = []
		let sData = sessionStorage.getItem(this.cacheKey)
		if (sData) {
			downloadFolders = JSON.parse(sData).items
		}
		return of(downloadFolders)
	}

	synchronize(): Observable<FolderDownload[]> {
		let sdata = sessionStorage.getItem(this.cacheKey)
		let data = { items: [] }
		if (sdata) {
			data = JSON.parse(sdata)
		}

		let statusRequest = data.items
			.filter(item => !item.completed)
			.map(folder => {
				let params = new HttpParams()
					.set('request_id', folder.request_id)
					.set('folder_id', folder.folder_id)
					.set('notify_user', String(folder.notify_user || false))

				return this.http.get<FolderDownload>(`${environment.downloadApiBaseUrl}${environment.downloadFolderStatusUrl}`, { params })
			})

		return combineLatest(statusRequest).pipe(
			map(statusList => {
				for (let status of statusList) {
					const index = data.items.findIndex(t => t.request_id === status.request_id)
					if (index != -1) {
						let item = data.items[index]
						item.description = status.description
						item.download_link = status.download_link
						item.statistics = status.statistics || ''
						if (status.description == 'Completed') {
							item.completed = true
						}
						data.items = arrayUpdate(data.items, item, index)
					}
				}
				sessionStorage.setItem(this.cacheKey, JSON.stringify(data))
				return data.items
			}),
		)
	}

	remove(folder: Partial<FolderDownload>): Observable<Partial<FolderDownload>> {
		const sData = sessionStorage.getItem(this.cacheKey)
		const data = JSON.parse(sData)
		if (data) {
			const index = data.items.findIndex(t => t.folder_id === folder.folder_id)
			if (index != -1) {
				data.items.splice(index, 1)
			}
			sessionStorage.setItem(this.cacheKey, JSON.stringify(data))
		}
		return of(folder)
	}

	clearAll(): void {
		sessionStorage.removeItem(this.cacheKey)
	}

	markFolderAsDownloaded(folder: Partial<FolderDownload>) {
		const data = JSON.parse(sessionStorage.getItem(this.cacheKey))
		if (data) {
			const index = data.items.findIndex(t => t.folder_id === folder.folder_id)
			if (index != -1) {
				data.items[index].downloaded = true
				sessionStorage.setItem(this.cacheKey, JSON.stringify(data))
			}
		}
		return of(true)
	}

	notifyFolderDownload(folder: Partial<FolderDownload>) {
		const data = JSON.parse(sessionStorage.getItem(this.cacheKey))
		if (data) {
			const index = data.items.findIndex(t => t.folder_id === folder.folder_id)
			if (index != -1) {
				data.items[index].notify_user = folder.notify_user
				sessionStorage.setItem(this.cacheKey, JSON.stringify(data))
			}
		}
	}
}
