<ng-container [ngSwitch]="layout">
  <div class="asset-item" [contextMenu]="assetContextMenu" *ngSwitchCase="'list'">
    <div class="asset-item-name " [title]="model.name">
      <dam-asset-item-icon [name]="model.name"></dam-asset-item-icon>
      <span class="text-ellipsis asset-list-item-title">
        {{model.name}}
      </span>
    </div>
    <div class="asset-item-owner text-ellipsis">
      {{model.createdBy == (user$ | async)?.email ? 'me' : model.createdBy || '-'}}
    </div>
    <div class="asset-item-update text-ellipsis">
      {{(model.createdOn | date:'MMMM dd, yyyy') || '-'}}
    </div>
    <div class="asset-item-size text-ellipsis">
      <!-- {{model.size && (model.size / 1024).toFixed(2)}} KB -->
      {{model?.size ? (model?.size | fileSize) : '-' }}
    </div>
    <div class="asset-item-actions text-ellipsis">
      <dam-asset-item-status class="item-status" [file]="model" *ngIf="!model?.isBusy"></dam-asset-item-status>
      <div [hidden]="model?.isBusy">
        <button *ngFor="let action of contextMenuActions" [hidden]="model?.status != fileActiveStatus" (click)="onClickContextMenu(action)" class="button" matTooltip="{{action.displayName}}">
          <img alt="download" src="../../../assets/images/icons/{{action.icon}}.svg">
        </button>
      </div>
      <div class="loader-wrapper">
        <div class="loader" *ngIf="model?.isBusy">Loading...</div>
      </div>
    </div>
  </div>
</ng-container>

<!-- Context Menu -->
<context-menu #assetContextMenu [disabled]="model?.status != fileActiveStatus">
  <ng-template contextMenuItem *ngFor="let action of contextMenuActions" let-item [visible]="isVisible((selectedItems$ | async),action)"
    [enabled]="true" (execute)="onClickContextMenu(action)">
    <div class="menu-item">
      <img class="icon" src="./../../../assets/images/icons/{{action.icon}}.svg" alt=""> {{action.displayName}}
    </div>
  </ng-template>
</context-menu>