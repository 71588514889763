import { Injectable } from '@angular/core'
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Store } from '@ngrx/store'
import { FolderStoreActions } from './../../folder-store'
import { RootStoreState, LayoutStoreActions } from '../..'
import { SearchStoreActions } from '../../search-store'
import { BreadcrumbStoreActions } from '../../breadcrumb-store'
import { mapRepositoryNameToId, RepositoryTypes } from 'src/app/common/helpers'
import { environment } from 'src/environments/environment'
import { DamContext } from '@app/models'

@Injectable()
export class CommonFolderResolver implements Resolve<DamContext> {
	constructor(private store$: Store<RootStoreState.State>) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		// Get repository id based on url /guides /library /projects
		const regex = '([^/]+)'
		const result = state.url.match(regex)
		const repositoryId = mapRepositoryNameToId(result[0] as RepositoryTypes)
		const folderId = route.paramMap.get('id') || repositoryId

		this.store$.dispatch(
			new SearchStoreActions.AddContextContentAction({
				folderId: folderId || repositoryId,
				repositoryId: repositoryId,
			}),
		)

		this.store$.dispatch(new SearchStoreActions.ClearAction())

		if (environment.projectRepositoryId === repositoryId) {
			this.store$.dispatch(new FolderStoreActions.LoadProjectMetadataRequestAction({ repositoryId: repositoryId, folderId: folderId }))
		}
		/// NOTE: esto hace el request para traer todos los proyectos en cada carpeta, hacer test a ver si era necesario o habia quedado mal
		//this.store$.dispatch(new FolderStoreActions.LoadProjectFromStorageRequestAction({ setFolders: false }))

		this.store$.dispatch(new BreadcrumbStoreActions.LoadRequestAction({ repositoryId: repositoryId, folderId: folderId }))
		this.store$.dispatch(new FolderStoreActions.LoadRequestAction({ repositoryId: repositoryId, folderId: folderId }))
		this.store$.dispatch(new LayoutStoreActions.ShowDetailSidenav({ visible: false }))
		this.store$.dispatch(new FolderStoreActions.LoadFileMetadataRequestAction({ repositoryId, folderId })) // Search
		this.store$.dispatch(new SearchStoreActions.SetEnabled({ value: true }))

		return {
			folder_id: folderId,
			repo_id: repositoryId,
			folder_type: 'folder',
			tab: result[0],
		} as DamContext
	}
}
